.report-annotation-container {

  background-color: rgba(0,0,0,0.5);
  z-index: 1001;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;

  &.shut {
    display: none;
  }


  .report-annotation {
    // background: rgba(255,255,255,1);
    background-color: $purple;
    color: #eee;
    position: fixed;
    // height: calc(100% - 8em);
    width: 500px;
    top: 8em;
    right: 2em;
    z-index: 1001;
    padding: 2em;
    border-radius: 4px;
    // box-shadow: -1px 1px 3px rgba(0,0,0,0.15);
    // box-shadow: 0px 0px 0px 3000px rgba(0,0,0,0.5);
    transition: 0.2s;

    header {
      background-color: #fff;
      color: #222;
      margin: -2em -2em 2em;
      padding: 1em;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      h3 {
        padding: 0;
        margin: 0;
        font-weight: 300;
        font-size: 1.5em;
      }
    }

    h4 {
      color: rgba(255,255,255,0.8);
      font-weight: 300;
      font-size: 1.25em;
    }

    nav {
      margin-bottom: 1em;
      // background-color: rgba(255,255,255,0.8);
      background-color: #eee;
      margin: -2em -2em 2em;
      border-top: 1px solid rgba(0,0,0,0.2);
      a {
        color: $dark-blue;
        float: left;
        width: 50%;
        text-align: left;
        height: 3em;
        line-height: 3em;
        padding-left: 1em;

        &:hover {
          color: $blue;
        }

      }
      a.next-annotation {
        text-align: right;
        padding-right: 1em;
        float: right;
      }
    }

    article {
      display: none;

      p {
        margin-bottom: 0;
      }

      &.active {
        display: block;
        position: relative;
        // background-color: #f0f;
      }

      .counter {
        margin: auto;
        display: block;
        text-align: center;
        position: absolute;
        margin: auto;
        top: -4.25em;
        z-index: 2;
        color: #222;
        left: 50%;
        width: 250px;
        margin-left: -125px;
      }


      footer {
        margin-top: 2em;
        a.end-tour {
          float: left;
          width: calc(50% - 2em);
          width: auto;
          background-color: $blue;
          color: #fff;
          padding: 0.5em 1em;
          border-radius: 4px;
          margin: 0 1em 0 0;
          display: block;
          text-align: center;
          // margin: 0 1em;
          white-space: nowrap;
          box-shadow: -1px 1px 0px rgba(0,0,0,0.15);
        }
      }
    }
  }

}


.annotated-walkthrough-landing-page {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50px;
  left: 0px;
  background: $light-gray;
  overflow: auto;

  header {
    background: #444 url('/assets/images/annotation-background.jpg') no-repeat;
    background-size: cover;
    padding: 0 10%;

    h3 {
      padding: 1em 0 0;
      font-size: 2.5em;
      font-weight: 300;
      color: rgba(255,255,255,0.9);
    }
    h4 {
      padding: 0 2em 2em 0;
      font-size: 2em;
      font-weight: 300;
      color: rgba(255,255,255,0.9);
    }
  }

  .roles {
    clear: both;
    max-width: 1500px;
    margin: auto;
    padding: 5em;
    display: grid;
    grid-gap: 2em;
    grid-template-columns:
      minmax(200px, 1fr)
      minmax(200px, 1fr)
      minmax(200px, 1fr)
      minmax(200px, 1fr);

    > a {
      // float: left;
      display: block;
      // width: calc(25% - 8em);
      // margin: 0 4em;
      text-align: center;

      h5 {
        color: #fff;
        font-weight: 300;
        font-size: 1.3em;
        text-transform: none;
        letter-spacing: normal;
        white-space: nowrap;
        border: none;
      }

      &.brand-manager h5 {
        background-color: $pink;
      }
      &.data-scientist h5 {
        background-color: $purple;
      }
      &.vp-insights h5 {
        background-color: $coral;
      }
      &.cmo h5 {
        background-color: $red;
      }

      .tile {
        display: block;
        height: 400px;
        border-radius: 6px;
        box-shadow: 0px 0px 3px rgba(0,0,0,0.15);
        background: #fff;
        transition: 0.2s;

        img {
          width: 100%;
        }
        p {
          margin: 1em;
          color: #555;
        }
      }
      .button {
        padding: 0.5em 1.5em;
        margin: 1em;
        @include linear-gradient(90deg, $coral-gradient);
        color: rgba(255,255,255, 0.9);
        text-shadow: 0px 1px 0 rgba(0,0,0,0.1);
        box-shadow: 0px 0px 0 0 rgba(0,0,0,0.25);
        transition: 0.2s;
      }

      &:hover {
        .tile {
          box-shadow: 0px 0px 30px rgba(0,0,0,0.25);
        }
        .button {
          box-shadow: 0px 0px 10px $coral;
        }
      }

    }
  }
}






























