// TODO: wat dis -PR, April 2017

$editColor: #00867c;
$deleteColor: #c00;

input.search {
  // background: url(/assets/images/icon-loupe.png) 8px 5px no-repeat;
  font-style: italic;
  padding-left: 35px;
}

.admin-table {
  width: 100%;
  font-size: 0.9em;
  text-align: left;

  thead {
    border-bottom: 1px solid #eee;

    tr th {
      cursor: pointer;
      font-weight: bold;
      line-height: 30px;
      // width: 6%;

      &:first-of-type span {
        padding-left: 0px;
      }

      &.rotate {
        height: 140px;
        white-space: nowrap;

        div {
          transform: translate(25px, 51px) rotate(315deg);
          // transform: translate(25px, 51px) rotate(270deg);
          width: 30px;
          span {
            border-bottom: 1px solid #ccc;
            padding: 5px 10px;
          }
        }
      }
    }
  }

  tr {
    border-bottom: 1px solid #ccc;

    &.expanded-report {
      border-bottom: none;
    }

    &.edit {
      background-color: #f7f7f7;

      td.name {
        input {
          width: 46%;
          display: inline-block;
        }
      }

      td {
        padding: 12px 0;
      }

      input,
      select {
        margin-bottom: 0;
      }
    }

    &.heading {
      cursor: pointer;
      td {
        border: none;
        padding: 30px 0 0 0;
        font-size: 120%;
        font-weight: 500;

        span {
          font-size: 90%;
          font-weight: 300;
        }
      }
    }

    td {
      padding: 10px 0px;
      width: 6%;

      &:first-of-type {
        min-width: 10%;
      }

      &:nth-of-type(2) {
        min-width: 10%;
      }

      &.buttons {
        text-align: right;
      }

      &.status{
        white-space: nowrap;
        overflow: hidden;
      }

      a.save {
        background-color: #00A79B;
        color: #fff;
      }

      input {
        // padding: 10px;
        margin-left: 5px;
        width: 100%;
      }

      select {
        padding: 5px;
      }
    }
  }

  .combinations-box {
    position: relative;
    margin-top: 0;
  }

  .combo-box-toggle-side {
    // float: right;
    border-left: 2px solid #fff;
    padding: 3px 5px;
    margin-left: 0px;
    color: #222 !important;
    font-weight: bold;
    background: #eee !important;
    position: relative;
    z-index: 3;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;

    &:after {
      content: "\2193";
    }
  }

  .combo-box-open {
    .combo-box-toggle-side:after {
      content: "\2191";
    }
  }
}

.admin-campaign-nav {
  hr {
    color: white;
    border-bottom: 1px solid #777;
    margin: 10px 0;
  }
}

.admin-prefs {
  // float: left;
  // width: 49%;

  // &:nth-of-type(2) {
  //   margin-left: 1%;
  // }

  .asset-heading {
    display: block;
    width: 100%;
    background: #eee;
    padding: 10px;
    margin-top: 10px;
    position: relative;

    label {
      display: inline-block;
    }

    &.add-new {

      h4 {
        background-color: #333;
        color: #fff;
        margin-bottom: -10px;
      }
    }

    &.open h4 {
      margin-bottom: 20px;
    }

    h4 {
      margin-bottom: 0px;
      float: none;
      background: $teal;
      color: #fff;
      margin: -10px -10px -10px -10px;
      padding: 10px;

      // Because inputs won't width-auto to fill space on their own
      .input-wrapper {
        // width: auto;
        flex: 1;
      }


      input[type="text"] {
        width: 100%;
        display:block;
        // width: auto;
        background: rgba(255,255,255,0.7);
        font-size: 0.9em;
        padding: 12px;
        border: 0px;
        margin: -10px 20px -10px -10px;
        box-sizing: border-box;
      }

      span {
        // width: 90%;
        cursor: pointer;
        display: block;
        flex: 1;
        // background-color: #ff0;
        margin: -10px 10px -10px -10px;
        padding: 10px;
        -webkit-transition: .2s;
        -moz-transition: .2s;
        -o-transition: .2s;
        transition: .2s;
        box-sizing: border-box;

        &:hover {
          background-color: rgba(0,0,0,0.25);
        }
        &.edit {
          float: right;
        }
        &:before {
          content: "▼";
          font-size: 0.6em;
          opacity: 0.5;
          float: left;
          margin-right: 0.5em;
          margin-top: 0.5em;
        }
      }
    } //h4

    &.open h4 span:before{
      content: "▲";
    }


    .asset-details {
      margin: 10px;
      display: block;

      .auto-suggest-wrap {
        width: 100%;
        .auto-suggest {
          width: 100%;
          &.open .results {
            margin-top: 0;
          }
          a.button.inline.tiny-add-button {
            margin-top: 0;
          }
          a.button.custom-value {
            margin-top: -30px !important;
          }
          .close, .glyph {
            margin-bottom: 0;
          }
        }
      }

      div {

        &:last-of-type{
          margin-bottom: 15px;
        }

        input[type="text"] {
          // width: 90%;
          margin-bottom: 0;
          background-color: transparent;
          border-bottom: 1px solid $teal;
        }

        input[type="checkbox"] {
          margin-right: 8px;
        }

      }
      button {
        border: 1px solid #666;
        margin-bottom: 0.375em;

        &.delete {
          border-color: #c00;
          color: #c00;

          &:hover {
            background-color: #c00;
            color: #fff;
          }
        }

      }

      .asset-action {
        margin: 0px;
        flex: 0;
        background: $teal;
        opacity: 1;
      } //.asset-action


    }

    .asset-action {
      color: #fff;
      min-width: 40px;
      opacity: 0.5;
      padding: 10px;
      margin: -10px;
      -webkit-transition: .2s;
      -moz-transition: .2s;
      -o-transition: .2s;
      transition: .2s;
      background-color: rgba(0,0,0,0.2);
      text-align: center;

      &.save {
        width: calc(10% + 20px);
        text-align: center;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}

.grid-container {
  .title{
    float: right;
  }
  .third-wide{
    position: relative;
    max-height: 100%;
    max-height: calc(100% - 120px);
    overflow-y: auto;
    //TODO bourbon
    // @include span-columns(3);
  }

  .third-wide-back{
    position: fixed;
    margin-left: 30px;
    top: 155px !important;
    max-height: calc(100% - 120px);
    overflow-y: auto;
    //TODO bourbon
    // @include span-columns(3);
  }

  .shift-two-thirds{
    //TODO bourbon
    // @include shift(3);
    //TODO bourbon
    // @include span-columns(9);
    float: initial;
  }

  .shift-two-thirds-back{
    margin-top: 25px;
    //TODO bourbon
    // @include shift(3);
    //TODO bourbon
    // @include span-columns(9);
  }

  .two-wide {
    //TODO bourbon
    // @include span-columns(12);
  }

  .half-wide{
    //TODO bourbon
    // @include span-columns(6);
  }
}

.grid-container-no-margin{
  .two-wide {
    //TODO bourbon
    // @include span-columns(12);
  }
  .half-wide{
    //TODO bourbon
    // @include span-columns(6);
  }
}

.maxHeight{
  max-height: 410px;
  overflow: auto;
  padding-right: 10px;
}

.groups{
  margin-top: 50px;
}

.usersHeader{
  margin-top: 100px;
}

.trail{
  display: inline-block;
}

.trailDate{
  width: 160px;
  height: 30px;
}

a.disable {
  background-color: #fee;
  color: rgba(200,0,0,.5);

  &:hover {
    background-color: #c00;
    color: #fff;
  }
}

.pushDown{
  margin-top: 10px;
}

.spaceDown{
  margin-bottom: 10px;
}
.pushRight{
  margin-left: 90px;
}
.backBtn{
  position: fixed;
  margin-left: 220px;
}

.addReport{
  margin-top: 20px;

  &.filter {
    margin: 0;
  }

  input, select, button{
    display: inline-block;
  }
  .reportIdInput{
    width: calc(100% - 125px);
  }
  .companyIdInput{
    width: calc(100% - 37px);
  }

  .add{
    top: -5px;
    height: 40px;
    padding: 10px;
  }

  .networkToggle{
    margin: 0;
    width: 140px;
    display: inline-block;
  }

  .reportSearch{
    width: calc(100% - 150px);
    display: inline-block;

    input {
      margin: 0;
    }
  }
}

.new-select {
  padding: 5px 25px 6px 5px;
  width: auto;
  border: none;
  background-color: transparent;
  color: $teal;
  opacity: 0.8;
  cursor: pointer;
  margin: 0px 10px 0px 0px;
  // position: relative;
  top: 3px;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  box-shadow: 0px 0px 0px rgba(0,0,0,0.2);
  // float: right;

  &:hover, &:focus {
    box-shadow: 0px 5px 20px -3px rgba(0,0,0,0.2);
  }
}

.is{
  display: inline-block;
  float: left;
}

.adAccountInput{
  width: calc(100% - 45px) !important;
  display: inline-block;
}

.adAccountBtn{
  display: inline-block;
}

.admin-title{
  margin-left: 15px;
}

.admin-buttons{
  margin-left: 10px;
}


.target-csv-button {
    position: relative;
    overflow: hidden;
    background: #555;
    color: #999;
    margin: 10px;
    display: inline-block;
    padding: 5px 10px;
}

.target-csv-box {
  margin-top: 20px;

  span {
    margin: 0 !important;
  }
}
.target-csv-button input.csv-handle-upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
    border: 1px solid #666 !important;
    margin-bottom: 0.375em;
}

.weaker-font{
  font-weight: lighter;
}

.addC2C{
  min-height: 200px;
  margin-top: 20px;
  width: calc(100% - 37px);
  display: inline-block;
}

.plusRelation{
  display: inline-block;
}

.adminEdit{
  font-size: 18px;
  float: right;
  height: 100%;
  padding: 0px 10px;
  color: #555;

  &:hover{
    color: #00867c;
  }
}

.adminCreate{
  font-size: 16px;
  height: 100%;
  // margin-top: -20px;
  margin-bottom: 20px;
  // padding: 0px 10px;
  color: white;
  background-color: $editColor;

  &:hover{
    background-color: darken($editColor, 2)
  }
}

.adminDisable{
  font-size: 16px;
  height: 100%;
  // margin-top: -20px;
  margin-bottom: 20px;
  // padding: 0px 10px;
  color: white;
  background-color: $deleteColor !important;

  &:hover{
    background-color: darken($deleteColor, 5) !important;
  }
}

.adminDelete{
  font-size: 18px;
  float: right;
  height: 100%;
  padding: 0px 10px;
  color: #555;

  &:hover{
    color: #c00;
  }
}

.adminSave{
  font-size: 18px;
  float: right;
  height: 100%;
  color: #555;

  &:hover{
    color: #00867c;
  }
}

.adminToggle{
  .fa-toggle-on{
    color: $editColor;
    font-size: 18px;
    &:hover{
      cursor: pointer;
      color: darken($editColor, 5);
    }
  }
  .fa-toggle-off{
    color: #c00;
    font-size: 18px;
    &:hover{
      cursor: pointer;
      color: darken($deleteColor, 5)
    }
  }
}

h2 small{
  font-weight: lighter;
}

.c2c{
  min-height: 300px;
}

.content-proper.metrics{
  .new-select{
    float: left;
    display: inline-block;
    height: 40px;
  }
  .spark-line{
    display: block;
    top: 0px;
    height: 200px;
  }
  p{
    font-weight: lighter;
  }
  .hover-tip{
    display: inline-block;
    top: 10px !important;
  }
}

.idDelete{
  &:hover{
    cursor: pointer;
    color: darken($deleteColor, 5)
  }
}

.loader-holster{
  margin-left: 200px;
}
