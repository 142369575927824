/*==================================================================
=Timeline tracking Growth/Loss etc.
----------------------------------------------------------------- */

.time-series {
  margin-top: 20px;
  width: 100%;
  margin-bottom: 50px;
}

.time-series .c3-chart-line .c3-line {
  opacity: .5 !important;
}

.time-series .c3-chart-line .c3-line-all {
  stroke-width: 4px;
  opacity: 1 !important;
}

.view-bar.cumulativesize a.cumulativesize,
.view-bar.percentchange a.percentchange,
.view-bar.dailygrowth a.dailygrowth {
  color: $blue;
}

.vertical-bar-chart .c3-chart-lines .c3-line-all{
  stroke-width: 4px;
  opacity: 1 !important;
}

.ad-time-series .c3-chart-line .c3-line {
  opacity: 0 !important;
}

.ad-time-series .c3-area {
  opacity: .4 !important;
}

.ad-time-series rect.c3-zoom-rect {
  fill: #eee;
  opacity: 1 !important;
}

.ad-time-series .c3-region rect {
  /*width: 1px;*/
  stroke: rgba(0,0,0,0.25);
  stroke-width: 0px;
  fill-opacity: 1 !important;
  opacity: 0;
  fill: url('#regional-gradient');
}

/*-- Focus --*/

.ad-time-series .c3-target.c3-focused path.c3-line {
  stroke-width: 4px;
}

.ad-time-series .c3-target.c3-focused path.c3-area,
.ad-time-series.stacked .c3-target.c3-focused path.c3-area,
.ad-time-series.sortof .c3-target.c3-focused path.c3-area {
  opacity: .8 !important;
}

.ad-time-series .c3-chart-line.c3-target.c3-focused .c3-line {
  opacity: 1 !important;
  stroke-width: 3px;
}

.ad-scatter .c3-focused circle {
  stroke-width: 30px;
  stroke: #eee;
}

.ad-scatter circle {
  opacity: .75 !important;
}

.ad-scatter .c3-axis,
.ad-time-series .c3-axis {
  opacity: 0.1 !important;
  -webkit-transition: .4s;
  -moz-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
}

.ad-scatter:hover .c3-axis,
.ad-time-series:hover .c3-axis {
  opacity: 1 !important;
}

.metric-stamp {
  display: block;
  text-align: center;
  clear: both;
  margin-bottom: 50px;
}

.metric-stamp h3 {
  font-size: 1.8em;
  font-style: normal;
  font-weight: 200;
}
.metric-stamp h4 {
  font-size: 5em;
  font-family: 'Arvo', serif;
  margin: -20px 0px;
  font-weight: 400;
}

.metric-stamp h5 {
  font-size: 1.3em;
  text-transform: none;
  letter-spacing: 0em;
  font-style: italic;
  font-weight: 400;
  border: none;
}

.content-proper#ads header {
    background-color: #fff;
    margin: -30px -30px 0px;
    padding: 20px 30px 0px;
    width: 100%;
    /* border-bottom: 1px solid #ddd; */
    /* color: #8CC63F; */
    box-shadow: 0px 0px 5px rgba(0,0,0,0.25);
    position: relative;
    z-index: 2;
}

.content-proper#ads .untop header {
    margin-top: -20px;
}

.content-proper#ads header h2 {
  font-weight: 400;
  max-width: 80%;
}

.content-proper#ads .vis-options {
  padding: 40px 0px 20px 0px;
}
