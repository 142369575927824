.traits-gallery {
  width: 100%;
  .trait-chart {
    background: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
    width: calc(50% - 1em);
    padding: 1em;
    margin: 0 1em 0 0;
    float: left;
    border-radius: 4px;
  }
}
