.highlight-linechart {
  // min-height: 320px;
  .line-chart {
    // height: 100%;
    // width: 75%;
    // margin-left: 3.75%;
    // margin-top: 1.5em;
    // margin-bottom: 2.5em;
    // width: 100%;
    overflow: visible;
    path.line {
      stroke: #666;
      stroke-width: 1;
      fill: none; // max-height: 150px;
    }
    text {
      font-size: 0.65em;
    }
    .x-axis>path,
    .y-axis>path {
      fill: none; // stroke: grey;
      stroke-width: 1;
      shape-rendering: crispEdges;
    }
    .overlay {
      fill: none;
      pointer-events: all;
      width: 100%;
    }
    .focus circle {
      fill: #3c9;
      stroke: #3c9;
    }
    .max circle {
      fill: #4DB930;
      stroke: #FFBA33;
      stroke-width: 3px;
    }
    .highlight circle {
      fill: $blue;
      stroke: #fff;
      stroke-width: 6px;
    }
    .min circle {
      fill: #c00;
      stroke: #FFBA33;
      stroke-width: 3px;
    }
    .date {
      fill: red;
    }
  }
}
