.affinio-table-cell-percent{
  height: 100%;
  .percent{
    display: flex;
    align-items: center;
    height: 100%;
    span {
      padding: 0 0.5rem;
      border-radius: 10px;
    }
  }
}
