.cluster-modifier {
  .tabbed-interface {
    .content {
      background: none;
      .card {
        padding: 1rem;
        .cluster-list {
          margin-bottom: 1rem;
          list-style: disc;
          padding-left: 2rem;
          li .edit-notes {
            cursor: pointer;
          }
        }
        button {
          margin-top: 1rem;
        }
        label {
          max-width: 30rem;
        }
      }
    }
  }
}
