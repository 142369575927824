.affinio-table-cell-radio {
  padding: 0 0 0 10px;

  label {
    border-radius: 14px;
    background-image: none;
    border: 2px solid $blue;
    height: 1.5rem;
    width: 1.5rem;
  }

  &.checked label {
    border-width: 8px;
  }
}
