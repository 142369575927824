.audience-overlap {
  width: 49%;
  .metric-visualization{
    width: 100%;
  }
  .preferences {
    .select-dropdown {
      float: right;
      padding-right: 30px;
      position: absolute;
      width: auto;
      margin-left: 10px;
      top: -44px;
      right: 0px;
    }
  }
}
