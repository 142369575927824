.data-source-crud-actions {
  display: flex;
  padding: 0 10px;
  a {
    color: initial;
  }
  .fa-pencil{
    color: lighten(#333333, 10);
    transition: 0.2s;
    &:hover{
      color: #333333;
    }
  }
  .fa-trash{
    transition: 0.2s;
    color: lighten(#E53935, 10);
    &:hover{
      color: #E53935;
    }
  }
  .hovertip{
    position: relative;
    top: -13px;
  }
}
