.preferences .instance-search-wrapper {
  // background-color: #f0f;
  // text-align: center;
  width: 100%;
  position: relative;
  // top: 0px;
  height: 0px;
  margin-top: -5px;
  margin-bottom: -20px;
  // overflow: hidden;
  transition: 0.5s;
  text-transform: none;
  letter-spacing: 0em;
  // background-color: #eee;
  margin: 0 -1em -0.5em;
  width: calc(100% + 2em);

  &.open {
    height: 150px;
    background-color: #f5f5f5;
    padding: 1em;
    box-shadow:
      inset 0px 5px 5px -5px rgba(0,0,0,.1),
      inset 0px -5px 5px -5px rgba(0,0,0,.1);
    .instance-search-toggle {
      bottom: -1em;
      line-height: 22px;
    }
  }

  .instance-search-toggle {
    text-align: center;
    background-color: #fff;
    border-radius: 50px;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.25);
    line-height: 25px;
    width: 25px;
    height: 25px;
    display: block;
    position: absolute;
    left: 50%;
    // top: calc(50% - 38px);
    bottom: -1.25em;
    margin-left: -12.5px;
    overflow: visible;
    font-size: 0.8em;
  }

  input {
    width: 100%;
    // @include animation($name: slideDownFadeIn, $duration: 0.5s, $delay: 0.2s);
    position: relative;
    font-size: 1em;
    padding: 0.5em;
    margin-top: 5px;
  }

  // h5 {
  //   text-transform: none;
  //   @include animation($name: slideDownFadeIn, $duration: 0.5s, $delay: 0.4s);
  //   position: relative;
  //   letter-spacing: 0em;
  //   // text-align: left;
  //   padding: 0;
  //   margin: 0 0 0.5em 0;
  //   border: none;
  //   font-size: 0.8em;
  //   color: #999;
  // }

  .word-cloud-sizing {
    // @include animation($name: slideDownFadeIn, $duration: 0.5s, $delay: 0.6s);
    position: relative;
    text-transform: none;
    text-align: left;
    letter-spacing: 0em;
    font-weight: 300;
    display: grid;
    grid-template-columns: max-content max-content max-content;
    grid-gap: 1em;
    input {
      animation: none;
    }
    label {
      display: grid;
      grid-template-columns: 20px auto;
      margin-right: 6px;
    }
    form {
      display: flex;
    }
  }

  .metric-saturation-slider {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .multi-slider {
      margin: 0.5em 1em 0 1em;
    }

    h3 {
      small {
        font-size: 60%;
        font-weight: 300;
      }
    }

    .apply-to-all-toggle {
      display: grid;
      grid-template-columns: 24px 1fr;
      .custom-checkbox {
        padding-top: 8px;
      }
    }

  }

  .option-set {
    position: relative;
    border-bottom: 1px solid #ddd;
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    > strong {
      font-weight: 500;
    }
    &:last-of-type {
      padding-bottom: 0;
      border-bottom: none;
      margin-bottom: 0;
    }
    .hovertip {
      float: none;
      display: inline-block;
      top: -18px;
      margin-left: 0;
      margin-right: 15px;
    }
  }

  @for $i from 1 through 10 {
    & > .option-set:nth-of-type(#{$i}) {
      @include animation($name: slideDownFadeIn, $duration: 0.5s, $delay: ($i * 0.2s));
    }
  }

}

.content-proper:not(.aggregate-level) {
  .metric-visualization.word-cloud .preferences .instance-search-wrapper {
    &.open {
      height: 210px;
    }
  }
}
