/*==================================================================
=CompIntel Pie Chart
----------------------------------------------------------------- */
// TODO update this file, is now `pie-chart`

.donut {
  width: 700px;
  clear: both;
}

.c3-tooltip td {
  white-space: nowrap;
}

.pie-chart {
  .legend {
    float: right;
    position: relative;
    top: 50px;
    z-index: 2;
  }

  .legend span {
    display: block;
    float: left;
    clear: both;
    margin-bottom: 6px;
    position: relative;
    left: 0px;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    cursor: pointer;
    width: 148px;
    white-space: nowrap;
  }

  .legend span em {
    font-size: 0.8em;
    color: #555;
    margin-left: 5px;
    display: inline-block;
  }

  .legend span strong {
    display: inline-block;
    font-size: 0.8em;
    color: #555;
    margin-left: 5px;
    display: inline-block;
    font-weight: normal;
  }

  .legend span.toggled {
    opacity: 0.15;
  }

  .legend span strong:before {
    content: '(hide)';
    display: none;
  }

  .legend span strong:after {
    content: '(show)';
    display: none;
  }

  .legend span.hovered strong:before {
    display: inline-block;
  }
  .legend span.toggled.hovered strong:before {
    display: none;
  }

  .legend span.hovered em {
    display: none
  }
  .legend span.toggled.hovered em {
    display: inline-block
  }

  .legend h6 {
    font-weight: bold;
    margin-bottom: 10px;
  }

}
