.affinio-table-cell-select-apply-all {
  padding: 0 10px 0 10px;
  position: relative;

  .ember-basic-dropdown{
    display: inline-block;
    width: 50%;
    .ember-power-select-trigger{
      background-color: transparent !important;
      border-bottom: none;
    }
    &:hover{
      cursor: pointer;
    }
  }
  .ember-power-select-selected-item{
    margin-left: 0px;
  }
  .ember-power-select-options{
    font-size: 0.7em;
  }

  .apply-to-all {
    display: none;
    position: absolute;
    top: 0.5rem;
    padding-left: 1rem;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  &:hover {
    .apply-to-all {
      display: initial;
    }
  }
}
