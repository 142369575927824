.dropzone-box .dropzone {
  display: none;
  border: none;
  background: transparent;
  padding: 0px;
  margin: 0px;
  clear: both;
  padding-top: 25px;
  margin-bottom: 65px;
}

.dropzone-box .dropzone .dz-preview .dz-details {
  width: auto;
  height: auto;
  background: transparent;
  padding: 0px;
  margin: 0px;
  float: left;
}

.dropzone-box .dropzone .dz-preview .dz-error-mark,
.dropzone-box .dropzone-previews .dz-preview .dz-error-mark,
.dropzone-box .dropzone .dz-preview .dz-success-mark,
.dropzone-box .dropzone-previews .dz-preview .dz-success-mark {
  float: left;
  position: relative;
  left: 10px;
  top: 20px;
}

.dropzone-box .dz-success-mark svg{
  width: 32px;
}

.dropzone-box .dropzone .dz-preview, .dropzone-previews .dz-preview {
  border: none;
  box-shadow: none;
  padding: 0px;
  margin: 0px;
}

.dropzone-box .dropzone .button {
  display: block;
}

.dropzone-box .dropzone.dz-started {
  display: block;
}

.dropzone-box .dropzone {
  min-height: 0px;
}

.dropzone .dz-preview .dz-details .dz-size, .dropzone-previews .dz-preview .dz-details .dz-size {
  position: static;
}

//TODO lol omg sorry kill me smdh ikno rite fml - not jason
//make this real css
.dropzone {
  * span {
    float: none !important;
  }
  .dz-details {
    float: none !important;
    display: flex;
    flex-direction: column-reverse;
    > div{
      bottom: 0 !important;
      left: 0 !important;
      position: relative !important;
      float: none !important;
    }
    .dz-size {
      color: #999999;
      font-size: 0.75em;
      span, span strong {
        font-weight: 300 !important;
      }
    }
    .dz-filename {
      max-width: 400px;
      span {
        margin-bottom: 0;
      }
    }
  }
  .dz-error-mark {

  }
  .dz-remove {
    max-width: 300px;
    text-align: center;
    display: inline-block !important;
    z-index: 5;
  }
  .dz-progress {
    display: block;
    position: relative;
    top: 80px !important;
  }
  .dz-error-message {
    top: 0 !important;
    left: 0 !important;
    position: relative !important;
    margin-top: 10px !important;
    opacity: 1 !important;
    span {
      margin-bottom: 0 !important;
    }
  }
  .dz-remove {
    margin-top: 10px !important;
  }
  .dz-success-mark, .dz-error-mark {
    float: none !important;
    // position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    display: none;
    margin-top: 10px !important;
    svg {
      width: 34px;
      height: 34px;
    }
  }
}
