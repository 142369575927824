.admin-selector {
  .lists{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include animation(fadeIn)
    // @for $i from 0 through 200 {
    //   li:nth-of-type(#{$i}) {
    //     @include animation($name: slideUpFadeIn, $duration: 0.2s, $delay: ($i*0.03s));
    //   }
    // }
    .one{
      float: right;
    }
    .two{
    }
    .arrows{
      color: lighten($dark-blue, 10);
      font-size: 28px;
      position: relative;
      top: 40px;
      margin-left: 10px;
      margin-right: 10px;
      @include animation(fadeIn)
    }
    .list{
      width: 100%;
      display: inline-block;
      .title{
        border-bottom: 1px solid $dark-blue;
        margin-bottom: 7px;
        span{
          display: inline-block;
        }
        .switch-all{
          float: right;
          font-size: 14px;
          padding: 0px 7px 0px 7px;
          border-radius: 3px;
          color: #fff;
          background-color: lighten($dark-blue, 10);
          box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.2);
          &:hover{
            box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.6);
          }
        }
      }
      span{
        margin-top: 3px;
        display: block;
        font-weight: 300;
      }
      ul{
        li{
          margin-bottom: 0px;
          padding: 0px 15px;
          transition: 0.1s;
          background-color: #fff;
          // box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.2);
          border-radius: 3px;
          line-height: 2.75em;
          border-bottom: 1px #eee solid;
          clear: both;
          .email{
            display: inline-block;
            margin-left: 5px;
            font-weight: 300;
          }
          .add{
            visibility: hidden;
            float: right;
            transition: 0.1s;
            color: green;
            position: relative;
            top: -2px;
          }
          .remove{
            visibility: hidden;
            float: right;
            transition: 0.1s;
            color: red;
            position: relative;
            top: -2px;
          }
          &:hover{
            background-color: #f5f5f5;
            span{
              visibility: visible;
            }
          }
        }
      }
    }
  }
  .search-field-mini{
    margin-bottom: 10px;
    background-color: #fff;
    padding-top: 5px;
    border-radius: 3px;
    box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.2);
    i{
      margin-left: 10px;
      margin-right: 5px;
      display: inline-block;
      color: lighten($dark-blue, 10);
      font-size: 18px;
    }
    input[type=text]{
      padding: 0;
      padding-bottom: 2px;
      padding-top: 9px;
      border: none;
      border-bottom: 1px solid lighten($dark-blue, 10);
      width: calc(100% - 65px);
      display: inline-block;
      border-radius: 0px;
      &:focus{
        border: none;
        border-bottom: 1px solid lighten($dark-blue, 10);
      }
    }
  }

}
