/*==================================================================
=profile-cards
----------------------------------------------------------------- */

// TODO there is too much fragile/unused/wrong place css in this file IMO - js
// interest grid css shouldnt be in here - js

.profile-card {
  display: block;
  // width: 4.8%;
  // TODO verify removing this doesn't break other things
  // overflow: hidden;
  float: left;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  position: relative;
  color: #222;
}

.tribe-info .profile-card {
  width: 60px;
}

// interest-vis css goto own scs file
.list .interests-vis-main .profile-card.member {
  border-top: none;
  border-right: none;
  border-bottom: none;
}

.profile-card.member.in-cluster {
  border: 0px solid #fff;
}


.profile-card .pumpup {
  padding-bottom: 100%;
  height: 0px;
}

.profile-card > img {
  position: absolute;
  width: 100%;
  height: 100%;
  // object-fit: cover;
  // max-width: 100px;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}


// Page Likes broke height without this
.list .profile-card > img {
  height: auto;
}

.tribe-overview .profile-card > img {
  max-width: none;
}

// .interests-vis-main {
//   clear: both;
// }

// TODO holy cow this is in the wrong place - js
// .has-influencers-sidebar .interests-vis-main {
//   margin-left: 200px;
// }

.has-influencers-sidebar .sub-nav.influencers-nav {
  margin-left: 170px;
  width: calc(100% - 440px);
}

.interests-vis-main nav a.unique-toggle {
  border-right: 0px solid #fff;
}

.grid .vis-options nav a.grid-toggle,
.list .vis-options nav a.list-toggle,
.heatmap .vis-options nav a.heatmap-toggle,
.delta .vis-options nav a.delta-toggle,
.relevance-sort .interests-vis-main .vis-options nav a.relevance-toggle,
.affinity-sort .interests-vis-main .vis-options nav a.affinity-toggle,
.followers-sort .interests-vis-main .vis-options nav a.followers-toggle,
.interests-vis-main.uniques nav a.unique-toggle,
.interests-vis-main.uniques a.unique-toggle {
  color: #00A89C;
}

.vis-options.daily .daily,
.vis-options.total .total,
.vis-options.budget .budget,
.vis-options.comparative .comparative {
  color: #00A89C;
}

// TODO shouldnt be here - js
// .interests-vis-main .vis-options nav span {
//   display: block;
//   float: left;
//   padding: 0px 0px 0px 5px;
//   color: #222;
//   margin-left: 20px;
// }

// TODO shouldnt be in this file - js
.influencers.chart {
  margin: 10px 0px;
  background-color: #fff;
  position: relative;
}

// .needs-bottom-padding {
//   padding-bottom: 60px;
// }
.members .grid .interests-vis-main .influencers.chart .profile-card.is-hightlited {
  opacity: 1;
}

.grid .influencers.chart .profile-card:not(.is-hightlited) {
  opacity: 0.1;
}

.profile-card.immune {
  // opacity: 1 !important;
}

.profile-card .graphed-bar {
  margin-top: 5px;
}

.influencers.unique-view .profile-card,
.top-interests.unique-view .profile-card {
    opacity: 0.1;
}
.influencers.unique-view .profile-card.is-unique {
  opacity: 1;
}

.influencers.unique-view .profile-card.is-unique {
  opacity: 1;
}

.list .interests-vis-main.uniques .influencers.chart .profile-card:not(.is-unique) {
  height: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  border-width: 0px;
}

.grid .interests-vis-main .full,
.grid .interests-vis-main strong.affinity-score,
.delta .interests-vis-main .full,
.delta .interests-vis-main strong.affinity-score {
  display: none;
  opacity: 0;
}

.grid .interests-vis-main .bottomscore {
  display: none;
  opacity: 0;
}

.list .interests-vis-main .influencers {
  margin-top: 20px;
}

.chart .interests-vis-main .influencers {
  margin-top: 10px;
}

.list .interests-vis-main .assets-grid .influencers.chart {
  margin-top: 0px;
}

.list .interests-vis-main .full {
  position: relative;
  opacity: 1;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}
.list.interests-vis-main.snapshot {
  .no-cover{
    margin-top: 80px;
  }
}
.list .interests-vis-main .profile-card.covered {
  margin: 0 0 20px 0;
  // box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
}

.profile-card.covered .full .influencer-cover {
  width: calc(100% + 20px);
  margin: -10px -10px 10px -10px;
  overflow: hidden;
  max-height: 180px;
}

.list .interests-vis-main .profile-card.covered .full .influencer-cover .banner {
  width: 100%;
  height: auto;
  max-width: 100%;
}

.list .interests-vis-main .profile-card.covered .full .influencer-cover .header_img {
  // width: 100%;
  // height: auto;
  // max-width: 100%;
  position: absolute;
  top: 0;
}

.list .interests-vis-main .profile-card.covered .member-image {
  position: absolute;
  left: 10px;
  top: 100px;
  z-index: 2;
  border: 2px solid #fff;
}

.list .interests-vis-main strong.affinity-score {
  margin-top: 10px;
  display: inline-block;
  font-weight: 900;
  width: 100%;
  /*margin-right: 20px;*/
  &.local-affinity {
    margin-top: 30px;
  }
}

.list .interests-vis-main strong.affinity-score small {
  font-size: 70%;
  opacity: 0.5;
}

.list .interests-vis-main strong.affinity-score span {
  font-weight: 400;
}

.list .interests-vis-main .profile-card {
  width: 100% !important;
  /*border-bottom: 1px solid #ccc;*/
  margin-bottom: 20px;
  padding: 10px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(0,0,0,0.15);
  overflow: hidden;
}

.interests-vis-main .assets-grid .profile-card {
  width: 80px !important;
}

.list .interests-vis-main .profile-card:hover {
  color: #222;
  background-color: #eee;
}

.list .interests-vis-main .profile-card .name {
  font-weight: bold;
  display: block;
}

.list .interests-vis-main .profile-card .name span {
  font-weight: normal;
  float: right;
  font-size: 0.9em;
  color: #999;
}

.list .interests-vis-main .profile-card .name em {
  font-weight: normal;
  color: #999;
}

.list .interests-vis-main .profile-card .user-description {
  word-break: break-word;
}

.list .interests-vis-main .bottomscore {
  /*height: 3px;*/
  border-bottom: 3px solid #00A89C;
  width: auto;
  max-width: 100%;
  position: relative;
  text-align: right;
  color: #00A89C;
  white-space: nowrap;
  margin-top: 0px;
  font-weight: 900;
}

.list .interests-vis-main .bottomscore span {
  color: #333;
  display: block;
  float: left;
  margin-right: 5px;
  font-weight: 400;
}

.list .interests-vis-main .bottomscore-morelikely {
  /*height: 3px;*/
  border-bottom: 3px solid #E46BB0;
  width: auto;
  position: relative;
  text-align: right;
  color: #E46BB0;
}

.list .interests-vis-main .profile-card > .member-image {
  position: relative;
  float: left;
  width: 64px;
  // height: 64px;
  margin: 0px 20px 0px 0px;
}

.list .interests-vis-main .profile-card .pumpup {
  display: none !important;
}

.interest-follow {
  display: none;
}

.list .interest-follow {
  display: inline-block;
  float: right;
  padding: 2px 8px;
  background-color: #1EB0EB;
  color: #fff;
  border-radius: 2px;
  // height: 22px;
}

.list .interest-follow:hover {
  color: #fff;
  background-color: #48BFEF;
}

.list .interest-follow img {
  width: 16px !important;
  height: auto !important;
  margin: 2px 5px 0 0 !important;
}


/*==================================================================
=Delta View
----------------------------------------------------------------- */

.influencers.chart.delta .profile-card {
  clear: both;
  float: none;
}


/*==================================================================
=Delta / Change over Time considerations
----------------------------------------------------------------- */

.influencers.chart.delta {

  position: relative;

  .profile-card {
    position: static !important;
    width: 100%;
    float: left;
    clear: both;
    height: 50px;
    padding: 5px 0px;
    // display: inline; //well this is super dumb but necessary to get svg to pos:abs and top:0 properly. -PR, Oct 16

    img {
      position: absolute;
      right: calc(20% - 60px);
      width: 40px;
    }

    svg {
      position: absolute;
      left: 0px;
      height: 800px;
      width: 100%;
      opacity: 1;
      top: 0px;
      overflow: visible;

      text {
        dominant-baseline: text-before-edge;
        font-size: 0.8em;
        font-weight: bold;
        text-align: right;
      }

      line {
        stroke: #000;
        stroke-width: 0.75px;
      }
    } //svg

    // .bar {
    //   width: 100% !important;
    //   font-weight: bold;
    //   background-color: transparent;
    //   position: static;

    //   label {
    //     left: 80%;
    //     -webkit-transition: .4s;
    //     -moz-transition: .4s;
    //     -o-transition: .4s;
    //     transition: .4s;
    //   }

    //   .cluster-stats {
    //     display: none;
    //   }

    //   em {
    //     display: none;
    //   }
    // } //.bar
  } //.graphed-bar
}

.profile-card .banner-image {
  background-color: #cee3f8;
  width: calc(100% + 20px);
  margin-left: -10px;
  margin-top: -10px;
  margin-bottom: 10px;
  height: 120px;
  position: relative;
  background-origin: center;
  background-size: cover;
  .header_img {
    position: absolute !important;
    bottom: 10px;
    left: 10px;
    max-height: 50px;
    max-width: 250px;
    width: auto !important;
    height: auto !important;
  }
}
