.modal-container .modal-join-data-source{
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  .affinio-table{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .has-required-fields{
    color: red;
  }
  .ember-basic-dropdown{
    .ember-power-select-trigger{
      margin-left: 0;
      .ember-power-select-placeholder{
        margin-left: 0;
      }
    }
    .ember-power-select-trigger--active{
      border-bottom: 1px solid $blue;
      .ember-power-select-placeholder{
        visibility: hidden;
      }
    }
  }
}
