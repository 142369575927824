.profile-cover {
  // TODO isolate styles
  display: flex;
  flex-wrap: wrap;

  .img-wrap {
    display: flex;
    flex-wrap: wrap;
    height: 50px
  }

  img {
    width: 33%;
    height: auto;
    object-fit:  contain;
    &.empty {
      opacity: 0;
    }
  }
  .more {
    width: 33%;
    color: white;
    font-size: 0.8rem;
    text-align: center;
    vertical-align: middle;
    line-height: 25px;
  }
  &.length-1 {
    img {
      width: 100%;
      height: 100%;
    }
  }
  &.length-2 {
    img:nth-of-type(2) {
      align-self: flex-end;
    }
  }
  .title {
    width: 100%;
    color: #999;
    font-size: 0.6rem;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  // background-color: $dark-gray;
  // display: flex;
  // flex-wrap: wrap;
  // width: 80px;
  // height: 80px;
  // border-right: 3px solid $dark-gray;
  // border-top: 3px solid $dark-gray;
  // border-bottom: 3px solid $dark-gray;
  // img {
  //   width: 50%;
  //   height: 50%;
  // }
}
