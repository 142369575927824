.campaign-nav {

}

// TODO lets figure this out
#campaign-nav {
  // position: absolute;
  // top: 55px;
  position: fixed;
  top: 125px;
  bottom: 30px;
  width: 250px;
  left: 0px;
  overflow: auto;
  // background-color: $menu-gray;
  // height: 100%;
  // background-color: transparent;
  // height: calc(100% - 50px);
  padding-bottom: 0px;
  // padding-right: 5px;
  // padding-left: 10px;
  // -webkit-box-shadow: inset -50px 0px 50px -50px rgba(0,0,0,1);
  // -moz-box-shadow: inset -50px 0px 50px -50px rgba(0,0,0,1);
  // box-shadow: inset -50px 0px 50px -50px rgba(0,0,0,1);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 5;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  // min-height: calc(100% - 80px);
  // border: 10px solid #f00;

  // &.free {
  //   width: 190px;
  // }

  &::-webkit-scrollbar-thumb {
    background: #ddd !important;
  }

  input.search-box {
    text-align: left;
    color: #222;
    margin: 50px 30px 10px 30px;
    width: calc(100% - 60px);
  }

}
