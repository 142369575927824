.report-nav-editor {
  width: 80%;
  margin: auto;
  max-height: 485px;
  overflow-y: scroll;
  padding: 1em;
  input{
    font-size: 1em;
    padding: 0px;
    padding: 0.5em;
    margin: 0px;
    margin-right: 0.5em;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25)
  }
  input:disabled:hover{
    border: none;
  }
  .no-buttons{
    margin-left: 1.6em;
    margin-right: 1.8em;
  }
  .no-move-button{
    margin-left: 1.6em;
  }
  .section{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: transparent;
    &.no-subcategories {
      margin-bottom: 1em;
    }
    .move-buttons{
      font-size: 1em;
      margin-right: 0.5em;
      .fa-arrows{
        transition: 0.5s;
        &:hover{
          cursor: pointer;
          color: #bbb;
        }
      }
    }
    .fa-trash-o{
      color: #fff;
      transition: 0.5s;
      &:hover{
        color: #f44336;
        cursor: pointer;
      }
    }
  }
  .section.uneditable{
    margin-top: 0.5em;
  }
  .sub-section {
    margin-left: 2em;
  }
  .new-section-input{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 3.7em;
    .fa-plus{
      color: #fff;
      transition: 0.5s;
      &:hover {
        color: #4caf50;
        cursor: pointer;
      }
    }
  }
  .new-category {
    margin-left: 1.4em;
  }
  .drag-wrapper{
    transition: 0.5s;
    .drop-zone{
      width: 100%;
      display: inline-block;
      transition: 0.5s;
      border-radius: 10px;
    }
    .drop-zone.subsection{
      margin-left: 2em;
      width: calc(100% - 2em);
    }
  }
  .drag-wrapper.small-target{
    height: 1em;
    .drop-zone{
      height: 1em;
      background-color: transparent;
    }
  }
  .dragged-over.small-target{
    margin-top: 5px;
    margin-bottom: 5px;
    height: 1.5em;
    .drop-zone{
      height: 1.5em;
      background-color: rgba(0, 0, 0, 0.25);
    }
  }
  .drag-wrapper.big-target{
    height: 1.5em;
    .drop-zone{
      height: 1.5em;
      background-color: transparent;
    }
  }
  .dragged-over.big-target{
    margin-top: 5px;
    margin-bottom: 5px;
    height: 1.5em;
    .drop-zone{
      height: 1.5em;
      background-color: rgba(0, 0, 0, 0.25);
    }
  }
  .reset-btn{
    margin-top: 1.5em;
    border-radius: 10px;
    width: 100%;
    text-align: center;
  }
}

.report-nav-editor::-webkit-scrollbar
{
    width: 0.5em;
    background-color: transparent;
}

.report-nav-editor::-webkit-scrollbar-track
{
    background-color: transparent;
}

.report-nav-editor::-webkit-scrollbar-thumb
{
    background-color: #fff;
    border-radius: 4px;
}
