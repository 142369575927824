.talkwalker-query {

  .affinio-table .ember-table {
    max-height: 300px;

    tr {
      cursor: pointer;
    }
  }
}

.format-name {
  display: grid;
}

.no-sourcepanel-warning {
  color: grey;
  font-style: italic;
}