/*=========================================
Affinio Forms
Define user input styles, error styles, etc.
=========================================*/

label.no-click { cursor: default; }

input[type="text"],
input[type="number"],
textarea,
input[type="password"],
input[type="email"],
input[type="url"],
.fake-input {
  font-size: 1.2em;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid #aaa;
  margin-bottom: 10px;
  padding: 5px 0px;
  width: 100%;
  background: transparent;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;

  &.alt {
    background: #fff;
    border: 2px solid #fff;
    // -webkit-border-radius: 4px;
    // -moz-border-radius: 4px;
    // border-radius: 4px;
    padding: 5px;
  }
}

.fake-input {
  border-color: #eee;
}

textarea {
  border: 2px solid #eee;
  padding: 5px;
}

input[type="text"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="number"]:focus,
input[type="url"]:focus,
textarea:focus,
input[type="password"]:focus {
  border-color: $blue;
  outline: none;
  border-bottom-width: 2px;
  color: $dark-gray;
}

// TODO: looks like this is actually handled in _select-box.scss - verify then remove this.
// select {
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   width: 100%;
//   padding: 10px;
//   -webkit-border-radius: 0px;
//   -moz-border-radius: 0px;
//   border-radius: 0px;
//   display: block;
//   background-image: url('/assets/images/downer.png') ;
//   background-position: calc(100% - 5px) 50%;
//   background-repeat: no-repeat;
//   border: 1px solid #ddd;
// }

/*==================================================================
=User Forms
----------------------------------------------------------------- */

.userForm {
  clear: both;
}

.userForm .form-proper {
  float: left;
  min-width:500px;
  max-width: calc(100% - 320px);

  .tweet-content-toggle {
    &.active {
      background: green;
      border: 1px solid black;
    }
  }
}

.newCampaignForm {
  min-height: 600px;
}

.newCampaignForm .estimated-audience {
  position: relative;
  float: right;
  width: 300px;
  margin-bottom: 20px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 20px;
  border-left: 1px solid #eee;
}

.newCampaignForm .illustration-box {
  float:left;
}

.continue-box{
  max-width: 900px;
}

.newCampaignForm .floaty-left{
  float: left;
}

.newCampaignForm .pyramid-explainer {
  float: left;
  width: 400px;
  text-align: center;
  text-indent: -30px;
  position: absolute;
  right: 0px;
  border: 5px solid #FFB900;
  height: calc(100% - 20px);
  z-index: 2;
  width: 420px;
  right: -20px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: .4s;
  -moz-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
}

.newCampaignForm .pyramid-explainer.is-content-report {
  height: 133px;
}

.newCampaignForm .pyramid-explainer.is-visitors-report {
  height: 0px;
  border-width: 0px;
  -webkit-transition: 0s;
  -moz-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
}

.pyramid-explainer strong,
.pyramid-explainer span {
  display: block;
}

.newCampaignForm .illustration-box img {
  float: left;
  width: 370px;
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.newCampaignForm .illustration-box img.site-report-illustration {
  position: relative;
  width: 400px;
  margin-left: 20px;
}

.newCampaignForm .reportTypeBox{
  width: 485px;
}

p.helper,
.adsForm p.helper {
  margin-bottom: 20px;
  max-width: 600px;
}

.newCampaignForm p.helper,
p.helper {
  color: #999;
  font-size: .85em;
  line-height: 180%;
  margin-bottom: 40px;
  clear: both;
}

.newCampaignForm.step3 p.helper {
  margin-top: 50px;
}

.newCampaignForm p.optional-helper {
  font-weight: 400;
  font-size: 1em;
  // border-bottom: 1px solid #ccc;
  // padding-bottom: 20px;
  // margin-bottom: 20px;
  font-style: italic;
  color: #555;
}

.updateForm .form-proper {
  width: auto;
  max-width: 1500px;
}

.newCampaignForm .estimated-audience p:last-of-type {
  margin-bottom: 0px;
}

.newCampaignForm header .hovertip > span {
  width: auto;
  left: 0px;
  z-index: 5;
  top: 0px;
  position: relative;
  clear: both;
}

.newCampaignForm label.promo .tiny-add-button {
  width: auto;
  margin-top: 5px;
}

.userForm textarea {
  height: 120px;
  resize: none;
}

.userForm .tweet-about .tiny-add-button {
  background-color: $blue;
  color: #fff;
  float: right;
  margin-bottom: 20px;
  position: relative;
  right: 0px;
  // top: 50px;
}

.userForm .tweet-about:not(.advanced-boolean) .tiny-add-button {
  position: absolute;
}

.userForm .tweet-about .tiny-add-button[disabled="disabled"],
.userForm .tweet-about .tiny-add-button:disabled {
  background-color: #ccc;
}

.userForm hr {
  margin: 50px 0px;
}

.newCampaignForm .basicButton,
.newCampaignForm .fullButton {
  background: transparent url(/assets/images/radio1.png) 0% 50% no-repeat;
  background-size: 20px;
  color: #222;
  height: 25px;
  line-height: 25px;
  -webkit-transition: 0s;
  -moz-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
  padding: 0px 0px 0px 25px;
  font-size: 1em;
  margin-bottom: 10px;
}

.userForm p.warning {
  padding: 10px;
  background-color: #ffc;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin-top: -20px;
  clear: both;

  &.lite {
    margin-top: 15px;
  }
}

.newCampaignForm .basicButton:hover,
.newCampaignForm .fullButton:hover,
.newCampaignForm .basicButton:focus,
.newCampaignForm .fullButton:focus {
  background-image: url(/assets/images/radio2.png);
}

.newCampaignForm .basicButton.basic,
.newCampaignForm .fullButton.full {
  background-image: url(/assets/images/radio3.png);
}

.newCampaignForm .socialnetworks button,
.newCampaignForm .report-types button,
.newCampaignForm .tweet-content-types a {
  float: left;
  margin: 0px 10px 10px 0px;
  background: #aaa 10px 50% no-repeat;
  padding-left: 40px;
  background-size: 24px;
  font-size: 1em;
  color: #fff;
  font-weight: 300;
}

.newCampaignForm .report-types button,
.newCampaignForm .tweet-content-types a {
  padding-left: 10px;
}

.newCampaignForm .tweet-about select {
  width: inherit;
  display: inline-block;
  font-size: 85%;
}

.newCampaignForm hr.optional {
  margin: 10px 0px;
}

.newCampaignForm .report-types,
.newCampaignForm .tweet-content-types {
  margin-bottom: 20px;
  &.nomargin {
    margin-bottom: 0;
  }
}
// TODO delete all this
.newCampaignForm.content .report-types button.content-button,
.newCampaignForm.content-consumption .report-types button.content-consumption-button,
.newCampaignForm.graph .report-types button.graph-button,
.newCampaignForm.post-content .report-types button.post-content-button,
.newCampaignForm.upload .report-types button.upload-button,
.newCampaignForm.visitors .report-types button.visitors-button,
.newCampaignForm.dynamics .report-types button.dynamics-button {
  background-color: #333;
}

.newCampaignForm .socialnetworks button[disabled="disabled"] {
  opacity: 0.3;
}

// .tweet-content-types:not(.boolean) a {
//   &.input {
//     background-color: #333;
//   }
// }

// .tweet-content-types.boolean a {
//   &.boolean {
//     background-color: #333;
//   }
// }

.newCampaignForm .socialnetworks .pinterestButton { background-image: url(/assets/images/icon-pinterest.png) }
.newCampaignForm .socialnetworks .twitterButton { background-image: url(/assets/images/icon-twitter.png) }
.newCampaignForm .socialnetworks .instagramButton { background-image: url(/assets/images/icon-instagram.png) }

.newCampaignForm .socialnetworks .twitterButton:hover,
.newCampaignForm.Twitter .socialnetworks .twitterButton {
  background-color: $dark-gray;
}

.newCampaignForm .socialnetworks .instagramButton:hover,
.newCampaignForm.Instagram .socialnetworks .instagramButton {
  background-color: #3e6c93;
}

.newCampaignForm .socialnetworks .pinterestButton:hover,
.newCampaignForm.Pinterest .socialnetworks .pinterestButton {
  background-color: #cb2027;
}


.newCampaignForm .locationcheck {
  float: left;
  margin-top: -10px;
}

.newCampaignForm .locationcheck input {
  margin-right: 5px;
}

.userForm .button:not[type="submit"]:focus {
  background-image: url(/assets/images/radio2.png);
}

.userForm label,
.userForm .labeldiv {
  display: block;
  margin-bottom: 20px;
  clear: both;
  position: relative;
}

.userForm label span,
.userForm .labeldiv span {
  display: block;
  font-size: 1.2em;
  margin-bottom: 10px;
  font-weight: 500;
}

.userForm .labeldiv label {
  margin-bottom: 0px;
}

.userForm label .hovertip > span {
  display: none;
  font-size: 0.8em;
}

.userForm label .hovertip.hovered > span {
  display: block;
}

.userForm label .button.inline {
  float: right;
}

.userForm {
  .form-proper {
    .info-notification {
      max-width: 600px;
      margin: 20px 0px;
    }
  }
}


.newCampaignForm .submit {
  background-color: $blue;
  color: #fff;
  width: 100%;
  font-size: 1.3em;
  font-weight: 300;
}


.payment-errors {
  padding: 10px;
  background-color: #fcc;
  border: 1px solid #900;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 30px;
  display: block;
}

.newCampaignForm .summary {
  border: 1px solid #ccc;
  padding: 20px;
  clear: both;
  background-color: #f5f5f5;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  margin-top: 50px;
  width: 100%;
  float: left;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 50px;
}

.newCampaignForm .summary {
  border: 0px solid #ccc;
  margin-top: 20px;
  margin-bottom: 0px;
}

.newCampaignForm .summary h4 {
  font-size: 1.3em;
  margin-bottom: 10px;
}

.billingPrompt .button {
  font-size: 1.5em;
  margin-bottom: 10px;
  font-weight: 300;
}

.advertisingForm p,
.updateForm p {
  margin-bottom: 30px;
}

.advertisingForm label,
.updateForm label {
  margin-bottom: 5px;
}

.newCampaignForm input[type="radio"],
.advertisingForm input[type="radio"],
.userForm input[type="radio"],
.updateForm input[type="radio"] {
  margin-top: 4px;
  margin-right: 5px;
  float: left;
}

.advertisingForm .form-proper {
  width: auto;
}

.newCampaignForm .radio-label {
  margin-bottom: 0px;
  display: inline-block;
  margin-right: 20px;
  margin-top: -20px;
  clear: none;
  font-size: .9em;
  white-space: nowrap;
  position: relative;
  top: -20px;
}

.updateForm .update-now-instead {
  display: inline-block;
  padding: 0px 5px;
  font-size: 0.85em;
  background-color: #eee;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.updateForm .cancel-updates {
  display: inline-block;
  width: auto;
  background-color: #f55;
  color: #fff;
}

.updateForm .cancel-updates:hover {
  background-color: #c00;
}

.userForm label.minmax input:first-of-type {
  clear: both;
}

.userForm label.minmax input {
  width: 100px;
  float: left;
  margin-right: 10px;
}

.userForm .refine-date {
  background-color: #f5f5f5;
  padding: 10px;
  margin-bottom: 50px;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
  border-left: 5px solid $teal;
  p {
    margin-bottom: 0px;
    max-width: 600px;
    &.warning {
      margin: 10px 0px;
    }
  }
}

.userForm .split-2 {
  clear: both;
  label {
    float: left;
    clear: none;
    width: calc(50% - 10px);
    margin-right: 10px;
    margin-bottom: 0px;
    &:last-child {
      margin-right: 0px;
      margin-left: 10px;
    }
  }
}

// This feels wrong - JK Aug 2016
.userForm label.topspacing {
  margin-top: 25px;
}

.updateForm .step {
  float: left;
  width: 33%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 50px;
}

.userForm.updateForm label span {
  font-size: 1em;
}

.disabled-form-fields-container {

}

.disabled-form-fields {
  opacity: 0.3;
}

//TODO: reduce size

fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-weight: 600;
  margin-bottom: $small-spacing / 2;
  padding: 0;
}

label {
  display: block;
  // margin-bottom: $small-spacing / 2;
}

input,
select,
textarea {
  display: block;
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-weight: 400;
}

input[type=text],
input[type="number"],
input[type="email"]:not(.fake-input),
select[multiple] {
  background-color: $base-background-color;
  border: $base-border;
  border-radius: $base-border-radius;
  // box-shadow: $form-box-shadow;
  box-sizing: border-box;
  margin-bottom: $small-spacing;
  padding: $base-spacing / 3;
  transition: border-color $base-duration $base-timing;
  width: 100%;

  &:hover {
    border-color: shade($base-border-color, 20%);
  }

  &:focus {
    border-color: $action-color;
    border-bottom-width: 1px;
    // box-shadow: $form-box-shadow-focus;
    outline: none;
  }

  &:disabled {
    background-color: shade($base-background-color, 5%);
    cursor: not-allowed;

    &:hover {
      border: $base-border;
    }
  }
}

.newCampaignForm {
  select.report-translation-selector {
    max-width: 250px;
  }
}

textarea {
  resize: vertical;
}

[type="search"] {
  appearance: none;
}

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: $small-spacing / 2;
}

[type="file"] {
  margin-bottom: $small-spacing;
  width: 100%;
}

select {
  margin-bottom: $small-spacing;
  max-width: 100%;
  width: auto;
}
