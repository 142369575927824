.card-container {
  //TODO: bourbon
  // @include span-columns(5);
  .content-proper{
    padding: 3px 10px 10px 5px;
    margin-bottom: 2px;
    z-index: -2;
    background-color: #eee;
    box-shadow: none;
  }
  .four-wide{
    max-width: 95%;
    margin-left: 20px;
    height: 30px;
    //TODO: bourbon
    // @include span-columns(12);
    .bar1{
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $teal;
      height: 100%;
    }
    .bar2{
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
      z-index: -1;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #424242;
      height: 15px;
      margin-bottom: 15px;
    }
    .percent{
      font-weight: bold;
      font-size: 13px;
      color: $teal;
    }
    .percent2{
      font-weight: bold;
      font-size: 10px;
      color: #424242;
    }
    .route{
      top: 0;
      color: black;
      font-size: 10px;
      margin-left: 10px;
      margin-right: 10px;
    }
    .backdrop{
      margin-top: -20px;
      display: inline-block;
      background-color: rgba(250,250,250,0.9);
      border-radius: 5px;
    }
  }
}

.campareSelect{
  margin-bottom: 20px;
  .compareLabel{
    display: inline-block;
  }
  .new-select{
    display: inline-block;
  }
}

.contentPush{
  margin-left: 240px;
}

.overall{
  margin-left: -30px;
}

h2{
  .no-wrap{
    white-space: nowrap;
  }
}

.featureHolder{
  margin-top: 30px;
  //TODO: bourbon
  // @include span-columns(2);
  display: inline-block;
  .featurePercent{
    font-size: 30px;
    color: $teal;
    font-weight: 300;
    text-align: center;
  }
}

.metricHr{
  padding-bottom: 10px;
  &:nth-child(odd){
    padding-top: 50px
  }
}
.spanLight{
  color: #888;
  margin-top: -50px;
}
.featureH2{
   margin-bottom: 0px;
}
