.delta-card {
  position: relative;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.1);
  margin-bottom: 2em;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  height: 234px;
  h2 {
    // max-width: 300px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .metric-icon {
    position: absolute;
    left: -18px;
    top: 25px;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    a {
      // border: 5px solid #eee;
      // box-sizing: content-box;
    }


  }
  .metric-visualization {
    width: 100%;
    .bar.uncluster {
      margin-bottom: 0;
    }
  }
  > section:first-of-type {
    padding-left: 3em;
    padding-right: 2em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .header {
    margin-top: 1em;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    .label {
      width: 100%;
    }
    a {
      display: inline-block;
    }
    h2 {
      display: block;
    }
    .label + section {
      width: 350px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

    }
  }
  .header + section {
    margin-bottom: 1em;
    // height: 100%;
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    > section {
      width: 50%;
      &.instances {
        float: left;
      }
      &.rank {
        float: right;
      }
    }
    h4 {

    }
  }
  .direction {
    margin-top: 0.5em;
  }
  .bar-graph {
    padding: 0px;
    margin: 5px 0px;
    margin-bottom: 0px;
    .bar {
      margin-bottom: 0px;
      background-color: rgba(0,0,0,0);
    }
  }
  .value-metrics {
    // color: #999;
    font-size: 0.8em;
  }
  .date-metrics, .value-metrics {
    color: #999;
    font-size: 0.8em;
    .period-end {
    }
    strong {
      color: #333;
      margin-left: 5px;
    }
  }
  .metric-units {
    font-size: 0.80em;
    margin-top: 0.6em;
    color: #333;
  }
  .decrease strong, .decrease .fa {
    color: #F44336;
    margin-left: 0px;
  }
  .increase strong, .increase .fa {
    color: #00C853;
    margin-left: 0px;
  }
  &:hover {
    box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.08);
    background-color: #f5f5f5;
    .metric-icon {
      // transform: scale(1.1);
    }
    .cluster-stats {
      height: 100% !important;
    }
    .metric-units {
      color: #666;
    }
    .header + section {
      h4 {
        color: #333;
      }
    }
  }
}
