/*==================================================================
=User Assets
----------------------------------------------------------------- */

.your-assets {
  float: left;
  width: 300px;
  margin-left: 20px;
}

.your-assets a {
  color: #222;
}

.assetForm .form-proper {
  width: 400px;
}

.asset-row {
  // margin-bottom: -3px;
  display: flex;
  flex-direction: column;
  clear: both;
}

.asset-row.tribe-names {
  width: auto;
  position: relative;
  z-index: 1;
  display: block;
  width: auto;
  max-width: 100%;
  margin-right: 0px;
  clear: right;
  float: none;
  margin-left: 0px;
  max-width: 250px;
  // height: 200px;
  // move .score-cell to proper css file, or simplify components so not as fractured.
  .score-cell {
    &:first-of-type {
      // margin-bottom: 18px;
    }
    white-space: normal;
    font-weight: 900;
    font-style: italic;
    text-overflow: ellipsis;
    // transform: translate(80px, 141px) rotate(315deg);
    max-width: 150px;
    text-align: left;
    height: auto;
    max-height: 100%;
    background-color: transparent;
    // padding: 0px;
    overflow: hidden;
    position: relative;
    border-right: none;
    padding: 5px;
    &:not(:first-of-type) {
      border-top: solid 1px #eee;
    }
    // height: 80px;
    // width: 80px;
    float: none;
    display: inline-block;
    // margin-right: -4px;
    // border-right: 1px solid #999;
    span {
      // border-bottom: 1px solid #999;
      display: inline-block;
      // width: 200px;
      // padding-bottom: 10px;
      padding-right: 1em;
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
      position: relative;
      // top: -10px;
    }
  }
  .hidden-from-heat-map{
    display: none;
  }
}

.asset-row header {
  height: 80px;
}

.asset-row .profile-card {
  width: 80px;
  opacity: 1 !important;
  float: left;
}

.list .asset-row .profile-card {
  margin-bottom: 0px;
  padding: 0px;
}

.list .asset-row .profile-card img {
  width: 100%;
  height: 100%;
  margin: auto;
  float: none;
}

// .asset-row img {
//   width: 100%;
// }

.asset-tribes {
  display: block;
  width: auto;
  white-space: nowrap;
  float: none;
  display: flex;
  flex-direction: column;
}
