.new-module {
  width: 49%;
  // height:

  form {
    input[type="submit"] {
      margin-top: 1rem;
    }
  }
}
