//GQL WORK:
.metric-visualization {
  width: 49%;
  // height: 665px;
  .metric-instance {
    clear: both;
  }
}
// END GQL WORK
/*==================================================================
=Basic Bar Graph
----------------------------------------------------------------- */

.content-proper .metric-visualization {
  // width: 50%;
  // float: left;
  // box-sizing: border-box;
  // padding-left: 5px;
  // padding-right: 5px;
  //
  // &.full-width {
  //   width: 100%;
  // }
  //
  .chart {
    position: relative;
    overflow: hidden;
  }
  legend.saturations {
    position: sticky;
    top: 94px;
    z-index: 3;
    background-color: rgba(255,255,255,0.9);
    li {
      display: inline-block;
      padding: 0.25em;
      margin-right: 1em;
      line-height: 100%;

      &:hover, &.hovered {
        background-color: #ccc;
      }
      span {
        display: inline-block;
        height: 10px;
        width: 10px;
        margin-right: 0.25em;
      }
      label {
        display: inline;
        font-weight: 400;
        font-style: italic;
        font-size: 0.8em;
        cursor: default;
      }
    }
  }
}

.content-proper .component .metric-visualization {
  // width: initial;
  // float: initial;
}

.profile-card .metric-visualization {
  // padding: 0 !important;
  // margin-top: 5px;
}

.user-box .metric-visualization {
  // margin: 10px 0px -10px;
  // padding: 0 !important;

  .metric-instance {
    // margin-left: -10px;
    // margin-right: -10px;
  }
}

.content-proper .stream .metric-visualization {
  // padding-left: 0px;
  // padding-right: 0px;
}

.content-proper .metric-visualization:nth-of-type(odd) {
  clear: both;
}


.metric-visualization {

  h4 {
    float: left;
    font-size: 1em;
    max-width: calc(100% - 8rem);
  }

  .preferences {
    // float: right;
    // clear: right;
    padding: 0px;

    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.05em;

    .select-dropdown {
      float: right;
      padding-right: 30px;
      position: absolute;
      width: auto;
      margin-left: 10px;
      top: -44px;
      right: 0px;
      max-width: 8rem;
    }
  }

  .chart {
    clear: both;
  }
}

.metric-visualization .bar {
  margin-bottom: 1px;
  color: #222;
  white-space: nowrap;
  padding: 5px 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
  -webkit-transition: .4s;
  -moz-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
  position: relative;
  height: 28px;
  z-index: 2;
  font-size: 0.8em;
}

.metric-visualization.unfulfilled {

  .bar {
    border-bottom: 1px dashed #ddd;
    width: 100%;

    label {
      display: none;
    }
  }

  .global-bar {
    display: none;
  }

  .word-cloud {
    visibility: hidden;
  }
}

.metric-visualization.fulfilled .bar {
  background: rgba(0,0,0,0.08);
}

.embedded-media .metric-visualization,
.brick .metric-visualization {
  margin-bottom: -1px;
  padding-bottom: 0px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  z-index: 2;
}

.embedded-media .metric-visualization .bar,
.brick .metric-visualization .bar {
  background: transparent;
}

.brick .metric-visualization .bar {
  width: 100% !important;
}

.embedded-media .metric-instance .bar label,
.embedded-media .metric-instance .bar a,
.brick .metric-instance .bar label,
.brick .metric-instance .bar a {
  margin-left: 15px;
  position: relative;
  top: -3px;
}

.brick .metric-visualization .bar label,
.brick .metric-visualization .bar a {
  margin-left: 0px;
}

.brick .metric-visualization {
  background-color: rgba(255,255,255,0.75);
}

.bar label, .bar a {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.1;
  z-index: 3;
  transition: color background-color .4s;
  color: #222;

}

.bar label {
   margin-left: 5px;
   left: 0px;
}

.term-relative.metric-visualization label, .term-relative.metric-visualization a,
.cluster-bias.metric-visualization label, .cluster-bias.metric-visualization a,
.niche-ranked.metric-visualization label, .niche-ranked.metric-visualization a {
  overflow: hidden;
  text-overflow: ellipsis;
}

.cluster-bias.metric-visualization a:hover, .term-relative.metric-visualization a:hover, .niche-ranked.metric-visualization a:hover {
  // TODO this highlights the hovertip ? as green, is it used for anything else?
  color: #00A79B;
}

.bar label em, .bar a em {
  padding: 0px 5px;
  font-size: 0.8em;
  opacity: 0.8;
}

.normalized.metric-visualization .bar.uncluster,
.cluster-bias.metric-visualization .bar.uncluster,
.niche-ranked.metric-visualization .bar.uncluster {
  width: 100% !important;
}

.normalized.metric-visualization .bar.uncluster,
.metric-normalized.metric-visualization .bar.uncluster,
.cluster-bias.metric-visualization .bar.uncluster,
.niche-ranked.metric-visualization .bar.uncluster {
  width: 100% !important;
}

.normalized .bar label,
.normalized .bar a,
.metric-normalized .bar label,
.metric-normalized .bar a,
.niche-ranked .bar label,
.niche-ranked .bar a,
.global-comparison .bar label,
.global-comparison .bar a,
.cluster-bias .bar label
{

  background: rgba(255,255,255,0.8);
  padding: 0px 3px;
}

.timeline .bar label,
.timeline .bar a {
  background: rgba(0,0,0,.05);
  padding: 3px;
  margin-left: 0px;
  margin-top: 25px;
  &:hover {
    background-color: rgba(0,0,0,0.2);
  }
}

.metric-visualization.in-tweet {
  margin: 0px 0px 15px;
  position: absolute;
  bottom: -5px;
  width: calc(100% - 14px);
  z-index: 1;
  left: 6px;
}

.metric-visualization.in-tweet .bar .cluster-stats {
  height: 100% !important;
}


.metric-visualization .bar .cluster-stats {
  -webkit-transition: .4s;
  -moz-transition: .4s;
  -o-transition: .4s;
  transition: 0.6s;
  transition-delay: 0.2s;
  position: absolute;
  height: 32px;
  bottom: 0px;
  width: 100%;
  overflow: hidden;
  // margin-left: -5px;
  height: 3px;

  &.full-height {
    height: 100%;
  }

  span {
    position: relative;
    transition: 0.5s;
  }
}

.metric-visualization .metric-instance {
  @for $i from 1 to 50 {
    &:nth-of-type(#{$i}) .cluster-stats span {
      transition-delay: 0.02s * $i;
    }
  }
}

.niche-ranked.metric-visualization .metric-instance .cluster-stats span {
  transition-delay: 0s;
}

.embedded-media .metric-visualization .bar .cluster-stats,
.brick .metric-visualization .bar .cluster-stats {
  height: 5px !important;
}

.embedded-media .metric-visualization .bar .cluster-stats span,
.brick .metric-visualization .bar .cluster-stats span {
  height: 100%;
}

.embedded-media:hover .metric-visualization .bar .cluster-stats,
.brick:hover .metric-visualization .bar .cluster-stats {
  height: 32px !important;
}

.normalized.metric-visualization .bar .cluster-stats,
.cluster-bias.metric-visualization .bar .cluster-stats,
.niche-ranked.metric-visualization .bar .cluster-stats,
.metric-normalized.metric-visualization .bar .cluster-stats,
.metric-visualization .bar:hover .cluster-stats {
  height: 100%;
}

.metric-visualization .bar .cluster-stats span,
.profile-card .cluster-stats span,
.user-box .cluster-stats span {
  /*border-right: 2px solid #f00;*/
  overflow: hidden;
  display: block;
  float: left;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 32px;
  width: 0px;
  /*border-left: 3px solid;*/
}

.user-box .cluster-stats span {
  height: 16px;
}

.profile-card .cluster-stats span {
  height: 30px;
}

.cluster-stats span em {
  display: none;
}

.profile-card .cluster-stats span em {
  display: block;
  font-style: normal;
  color: #fff;
  padding: 3px;
  font-weight: 900;
  font-size: 0.8em;
  text-shadow: 1px 1px 0px rgba(0,0,0,0.2);
  line-height: 24px;
  text-align: center;
}

// @for $m from 1 through 8 {
//   .metric-visualization:nth-of-type(#{$m}) {
//     @for $i from 1 through 50 {
//       .metric-instance:nth-of-type(#{$i}) {
//         @for $r from 1 through 20 {
//           .bar .cluster-stats span:nth-of-type(#{$r}) {
//             @include animation($name: fadeIn, $duration: 0.2s, $delay: ($r*0.02s) + ($i*0.01s) + ($m*0.2s));
//           }
//         }
//       }
//     }
//   }
// }

// TODO find a better spot for this? - js

.cluster-bar-graph a .bar {
  border-left: 3px solid;
}

.cluster-bar-graph a .bar[data-color-order='0'] {
  border-color: rgba(31,119,180,1);
}
.cluster-bar-graph a .bar[data-color-order='1'] {
  border-color: rgba(174,199,232,1);
}
.cluster-bar-graph a .bar[data-color-order='2'] {
  border-color: rgba(255,127,14,1);
}
.cluster-bar-graph a .bar[data-color-order='3'] {
  border-color: rgba(255,187,120,1);
}
.cluster-bar-graph a .bar[data-color-order='4'] {
  border-color: rgba(44,160,44,1);
}
.cluster-bar-graph a .bar[data-color-order='5'] {
  border-color: rgba(152,223,138,1);
}
.cluster-bar-graph a .bar[data-color-order='6'] {
  border-color: rgba(214,39,40,1);
}
.cluster-bar-graph a .bar[data-color-order='7'] {
  border-color: rgba(255,152,150,1);
}
.cluster-bar-graph a .bar[data-color-order='8'] {
  border-color: rgba(148,103,189,1);
}
.cluster-bar-graph a .bar[data-color-order='9'] {
  border-color: rgba(197,176,213,1);
}
.cluster-bar-graph a .bar[data-color-order='10'] {
  border-color: rgba(140,86,75,1);
}
.cluster-bar-graph a .bar[data-color-order='11'] {
  border-color: rgba(196,156,148,1);
}
.cluster-bar-graph a .bar[data-color-order='12'] {
  border-color: rgba(227,119,194,1);
}
.cluster-bar-graph a .bar[data-color-order='13'] {
  border-color: rgba(247,182,210,1);
}
.cluster-bar-graph a .bar[data-color-order='14'] {
  border-color: rgba(127,127,127,1);
}
.cluster-bar-graph a .bar[data-color-order='15'] {
  border-color: rgba(199,199,199,1);
}
.cluster-bar-graph a .bar[data-color-order='16'] {
  border-color: rgba(188,189,34,1);
}
.cluster-bar-graph a .bar[data-color-order='17'] {
  border-color: rgba(219,219,141,1);
}
.cluster-bar-graph a .bar[data-color-order='18'] {
  border-color: rgba(23,190,207,1);
}
.cluster-bar-graph a .bar[data-color-order='19'] {
  border-color: rgba(158,218,229,1);
}


.cluster-bar-graph a .hovered .bar[data-color-order='0'] {
  background-color: rgba(31,119,180,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='1'] {
  background-color: rgba(174,199,232,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='2'] {
  background-color: rgba(255,127,14,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='3'] {
  background-color: rgba(255,187,120,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='4'] {
  background-color: rgba(44,160,44,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='5'] {
  background-color: rgba(152,223,138,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='6'] {
  background-color: rgba(214,39,40,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='7'] {
  background-color: rgba(255,152,150,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='8'] {
  background-color: rgba(148,103,189,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='9'] {
  background-color: rgba(197,176,213,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='10'] {
  background-color: rgba(140,86,75,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='11'] {
  background-color: rgba(196,156,148,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='12'] {
  background-color: rgba(227,119,194,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='13'] {
  background-color: rgba(247,182,210,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='14'] {
  background-color: rgba(127,127,127,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='15'] {
  background-color: rgba(199,199,199,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='16'] {
  background-color: rgba(188,189,34,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='17'] {
  background-color: rgba(219,219,141,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='18'] {
  background-color: rgba(23,190,207,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='19'] {
  background-color: rgba(158,218,229,1);
}


/*==================================================================
=Snapshot/Growth Bar
----------------------------------------------------------------- */

.growth-bar > .started {
  background-color: #eee;
}

.growth-bar > .bar.started {
  padding: 0px 5px;
}

.growth-bar > .added {
  background-color: #7CBF60;
}

.growth-bar > div {
  float: left;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 32px;
  line-height: 32px;
}

circle {
  z-index: 3;
  position: relative;
}

path, line {
  z-index: 1;
}

.campaign-header h6 {
  width: 220px;
  float: left;
  font-weight: 300;
  height: 50px;
  line-height: 50px;
  font-size: 1em;
  background-color: #222;
  color: #fff;
  padding: 0px 5px;
  text-align: center;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.campaign-header nav.your-campaigns {
  background: #444;
  width: 220px;
}

.campaign-header nav.your-campaigns a {
  line-height: 1em;
  height: auto;
  border-bottom: 1px solid #555;
  padding: 10px 5px;
  display: block;
}

.campaign-header .options select {
  display: inline-block;
  width: auto;
  padding: 4px 5px 4px 10px;
  margin-right: 5px;
  border: 0px solid #eee;
  border-radius: 6px;
  background-color: #eee;
  color: #555;
  font-weight: 500;
  font-size: 0.9em;
}

.campaign-header .options {
  float: right;
  height: 50px;
  line-height: 50px;
  padding: 0px 0px 0px 30px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.campaign-header .options.gear-options {
  padding-left: 10px;
  position: relative;
  float: left;
}

.campaign-header .options.fuller {
  float: none;
  padding-right: 30px;
}

.campaign-header .options span.gear {
  display: block;
  text-align: right;
  cursor: pointer;
}

.campaign-header .options span.gear img {
  height: 18px;
  margin: 10px 0px 12px 5px;
}

.campaign-header .options:hover ul {
  max-height: 100%;
}

.campaign-header .options ul li a:hover {
  color: #3CBFBC;
}

