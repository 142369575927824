.audience-share {
  .preferences {
    .select-dropdown {
      float: right;
      padding-right: 30px;
      position: absolute;
      width: auto;
      margin-left: 10px;
      top: -44px;
      right: 0px;
    }
  }
  .pie-chart {
    .legend {
      position: initial;
      float: none;
      span {
        display: inline-block;
        float: none;
        width: initial;
        min-width: 5rem;
      }
    }

    #chart { // Boo id :(
      svg {
        overflow: hidden;
        margin: 0 auto;
        display: block;
      }
    }
  }
}
