.note-field {
  textarea{
    @include animation(fadeIn);
    min-height: 100px;
    background: #fff;
    padding: 1em;
    &:focus{
      border: none;
      box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.2);
    }
  }
}
