.pagination-pager {
  width: 100%;
  display: flex;
  justify-content: space-between;

  a {
    color: #bbb;
    padding: 0px 15px;
    line-height: 36px;
    font-size: 0.75em;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.05em;

    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

    &:hover {
      color: $teal;
    }

    &.active {
      color: $blue;
    }

    &.disabled {
      color: #f7f7f7;
      cursor: default;
    }
  }
}
