.reset-password-section {
  .wrapper {
    margin: 1.5em auto;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,.15);
    padding: 1em;
    border-radius: 4px;

    header {
      border-bottom: 1px solid #eee;
      margin: -1em -1em 1em;
      padding: 1em;
      h4 {
        font-size: 2em;
        font-weight: 300;
        margin-bottom: 0;
        color: #444;
      }
    }

    .userForm .form-proper {
      min-width: initial;
      max-width: initial;
      width: 100%;
    }
  }
}
