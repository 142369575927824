// Categorical Colors: Ellsworth Kelly
$colors: (
  1: #F8B800,
  2: #4DB930,
  3: #00A14D,
  4: #008874,
  5: #0758BB,
  6: #093CB3,
  7: #58266E,
  8: #AB2049,
  9: #DE032C,
  10: #F52324,
  11: #FA540E,
  12: #F8B800,
  13: #4DB930,
  14: #00A14D,
  15: #008874,
  16: #0758BB,
  17: #093CB3,
  18: #58266E,
  19: #AB2049,
  20: #DE032C
);

#world-container.ellsworthKelly, .modal-container.ellsworthKelly {
  @each $num, $color in $colors {
    $spot: $num - 1;
    // Tribe sidebar borders
    .sub-clusters .cluster-sub-nav.cluster-order-#{$num} {

      border-color: $color;
      color: $menu-gray;

      &:hover, &.active, &.held {
        background-color: $color;
        color: #fff;
      }

    } // .cluster-sub-nav

    .hiddenCluster:nth-of-type(#{$num}) {
      border-left: 5px solid;
      border-color: $color;
      color: $menu-gray;

      &:hover, &.active, &.held {
        background-color: $color;
        color: #fff;
      }

    } // .cluster-sub-nav

    // Cluster stats on bar charts
    .bar-graph .bar .cluster-stats span:nth-of-type(#{$num}) {
      background-color: $color;
      border-color: $color;
    } //.bar-graph .bar .cluster-stats span

    // Cluster bar graphs
    .cluster-bar-graph a {
      .bar[data-color-order='#{$num - 1}'] {
        border-color: $color;
      }
      .hovered .bar[data-color-order='#{$num - 1}'] {
        background-color: $color;
      }
    } //.cluster-bar-graph a
    .cluster-bar-graph.spot_#{$spot} a .spot_#{$spot} .bar {
      background-color: $color;
    }

    // Members Profiles image borders
    .member-profile.member.spot_#{$spot} {
      border-color: $color;
    }

    .map-options label:nth-of-type(#{$num}).map-shown .toggle {
      background-color: $color;
    }

    .metric-instance .bar .cluster-stats span[data-color-order='#{$num}'] {
      background-color: $color;
      border-color: $color;
    }
  }
}
