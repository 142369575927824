.bar-chart {
  section {
    span {
      border: solid 2px #fff;
      box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.2);
      margin: 2px;
      transition: 200ms;
      &.highlight {
        // box-sizing: content-box;
        outline: 3px solid $blue;
      }
    }
  }
  .labels {
    li {
      color: #999;
      font-size: 0.85em;
      white-space: nowrap;
      display: flex;
      align-content: center;
      align-items: center;
      height: 36px;
      justify-content: center;
    }
  }
  &.vertical {
    height: 150px;
    section {
      height: calc(100% - 2em);
      display: flex;
      flex-direction: row;
      align-items: baseline;
      span {
        display: inline-block;
        width: calc(100% / 7);
      }
    }
    .labels {
      display: flex;
      flex-direction: row;
      li {
        width: calc(100% / 7);
      }
    }
  } // vertical
  &.horizontal {
    width: 150px;
    section {
      height: calc( (36px * 24) );
      display: flex;
      flex-direction: column;
      float: right;
      width: calc(100% - 80px);
      span {
        display: inline-block;
        height: 32px;
      }
    }
    .labels {
      display: flex;
      flex-direction: column;
      float: left;
      width: 80px;

      li {
        // width: calc(100% / 7);
      }
    }
  } // vertical
}
