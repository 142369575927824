.member-demographics {
  .demographic-legend {
    font-size: 80%;
    label {
      display: inline-block;
      &:not(:first-of-type) {
        padding-left: 1rem;
      }
      span {
        width: 0.5rem;
        height: 0.5rem;
        background: #777777;
        display: inline-block;

        &.baseline {
          background: #222;
        }
      }
    }
  }
}
