.report-builder .text-input {
  > section {
    display: flex;
    flex-direction: row;
    align-content: center;
    > button {
      margin-left: 1em;
    }
  }
  ul{
    li{
      display: inline-block;
      margin-right: 0.5em;
    }
  }
  select{
    width: 20%;
    margin-right: 1em;
    margin-bottom: 0px;
  }
}
