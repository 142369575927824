.module-grid {
  border: solid 1px rgba(0,0,0,0);

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 1rem;

  padding-right: 1rem;

  width: 100%;
  min-height: 300px;

  .card {
    background-color: #fff;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.25);
    border-radius: 4px;
  }

  &.is-empty {
    .module-sizer {
      display: flex;
    }
  }

  .affinio-module-wrapper {
    position: relative;

    &.full-width {
      // https://stackoverflow.com/a/47320149
      grid-column: 1 / -1;
    }

    &.stretch {
      grid-column: 1 / -2;
    }
  }

  &.edit-mode:not(.uneditable) {
    .affinio-module-wrapper {
      min-height: 400px;
    }
  }

  .module {
    padding: 1em;
    width: 100%;

    .card {
      background-color: #fff;
      box-shadow: 0px 1px 3px rgba(0,0,0,0.25);
      border-radius: 4px;
    }

    .preference-label {
      display: flex;
      align-items: center; /* align vertical */
    }

    > header {
      border-bottom: 1px solid #eee;
      padding: 0.5em 0em;
      margin: 0 0em 1em 0em;
      > .preferences {
        position: relative;
        width: 100%;
        clear: both;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        // float: right;
        // clear: right;
      }
      h4 {
        float: left;
        font-size: 1.3em;
        line-height: 1em;
        margin-bottom: 0;
        padding-bottom: 0.6em;
        color: $darkish-gray;
      }

      &.hide-header {
        border: none;
        h4 {
          visibility: hidden;
        }
      }
    }
  }
}