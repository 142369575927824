.screenshot-modules {
  .header {
    display: flex;
    flex-direction: row;
    > div {
      width: 50%;
      outline: #eee solid 1px;
      &.category {
        width: 100%;
        position: relative;
        background-color: $menu-gray;
        color: #fff;
        transition: 200ms;
        cursor: pointer;
        &:hover {
          // background-color: #124a81;
          .fa {
            &::after {
              right: 0px;
            }
          }
        }
        .fa {
          position: absolute;
          right: 20px;
          top: 20px;
          overflow: hidden;
          height: 40px;
          &::after {
            transition: 200ms ease-out;
            width: 20px;
            height: 20px;
            content: ' ';
            position: absolute;
            right: -20px;
            border-bottom: solid 2px #fff;
          }
        }
      }
      strong {
        padding: 15px;
        display: block;
      }
    }
  } // header
  ul.screenshot-categories {
    li.category {
      margin-bottom: 2em;
    }
  }
  ul.modules {
    background-color: #fff;
    display: none;
    &.expanded {
      display: block;
    }
    > li {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      > section, > ul {
        width: 50%;
      }
      .label {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // align-items: center;
        padding: 15px;
        outline: #eee solid 1px;
      }
      .preferences {
        outline: #eee solid 1px;
        > .preference {
          display: flex;
          flex-direction: column;
          color: #999;
          font-size: .85em;
          line-height: 1.7em;
          &.selected {
            color: $base-font-color;
          }
          h4 {

          }
          .options {
            width: 100%;
            height: 100%;
            .option {
              transition: 200ms;
              > label {
                width: 100%;
                padding: 15px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                > section {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  input {
                    margin-top: 5px;
                  }
                }
                ul.counts {
                  margin-top: 10px;
                  li {
                    label {
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      color: #999;
                      &.active, &:hover {
                        color: #333;
                      }
                      input {
                        margin-top: 5px;
                      }
                    }
                  }
                }
              }
              .screenshot-list-combine-toggle {
                padding-left: 1rem;
              }
              // outline: #eee solid 1px;
              &:hover {
                background-color: #eee;
                color: #333;
              }
            }
          }
        }
      }
    }
  }
  .cluster-list-heading {
    cursor: pointer;

    &.missing-clusters {
      .fa-exclamation-triangle {
        color: red;
      }
    }
  }
  .cluster-list-body {
    margin-bottom: 1rem;

    ul.screenshot-clusters {
      margin: 0.5rem 0 0 1rem;
    }
  }
}
