// Categorical Colors: Titan
$colors: (
    1: #EDAA3B,
    2: #C62F38,
    3: #4CB076,
    4: #3981F7,
    5: #B92B63,
    6: #7F5DF6,
    7: #EC672C,
    8: #69DE4F,
    9: #35C0CB,
    10: #33754F,
    11: #EA338F,
    12: #F7CE4A,
    13: #AF604B,
    14: #902CF5,
    15: #56AAA0,
    16: #65201F,
    17: #4C97C7,
    18: #EA4338,
    19: #BDEC4A,
    20: #4F4DD7
);

#world-container.titan, .modal-container.titan {
    @each $num, $color in $colors {
        $spot: $num - 1;
        // Tribe sidebar borders
        .sub-clusters .cluster-sub-nav.cluster-order-#{$num} {
          border-color: $color;
          color: $menu-gray;
    
          &:hover, &.active, &.held {
            background-color: $color;
            color: #fff;
          }
    
        } // .cluster-sub-nav
    
        .hiddenCluster:nth-of-type(#{$num}) {
          border-left: 5px solid;
          border-color: $color;
          color: $menu-gray;
    
          &:hover, &.active, &.held {
            background-color: $color;
            color: #fff;
          }
        }
    
        // Cluster stats on bar charts
        .bar-graph .bar .cluster-stats span:nth-of-type(#{$num}) {
          background-color: $color;
          border-color: $color;
        } //.bar-graph .bar .cluster-stats span
    
        // Cluster bar graphs
        .cluster-bar-graph a {
          .bar[data-color-order='#{$num - 1}'] {
            border-color: $color;
          }
          .hovered .bar[data-color-order='#{$num - 1}'] {
            background-color: $color;
          }
        } //.cluster-bar-graph a
        .cluster-bar-graph.spot_#{$spot} a .spot_#{$spot} .bar {
          background-color: $color;
        }
    
        // Members Profiles image borders
        .member-profile.member.spot_#{$spot} {
          border-color: $color;
        }
    
        .map-options label:nth-of-type(#{$num}).map-shown .toggle {
          background-color: $color;
        }
    
        .metric-instance .bar .cluster-stats span[data-color-order='#{$num}'] {
          background-color: $color;
          border-color: $color;
        }
      }
    }
    