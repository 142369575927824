// Categorical Colors: Affinio Custom
$colors: (
  1: #00c4bc,
  2: #ff0068,
  3: #ffba33,
  4: #3f9eea,
  5: #99ccff,
  6: #ff9900,
  7: #7feaea,
  8: #999999,
  9: #666666,
  10: #cccccc,
  11: #00c4bc,
  12: #ff0068,
  13: #ffba33,
  14: #3f9eea,
  15: #99ccff,
  16: #ff9900,
  17: #7feaea,
  18: #999999,
  19: #666666,
  20: #cccccc
);

#world-container.affinio, .modal-container.affinio {
  @each $num, $color in $colors {
    $spot: $num - 1;
    // Tribe sidebar borders
    .sub-clusters .cluster-sub-nav.cluster-order-#{$num} {
      border-color: $color;
      color: $menu-gray;

      &:hover, &.active, &.held {
        background-color: $color;
        color: #fff;
      }

    } // .cluster-sub-nav

    .hiddenCluster:nth-of-type(#{$num}) {
      border-left: 5px solid;
      border-color: $color;
      color: $menu-gray;

      &:hover, &.active, &.held {
        background-color: $color;
        color: #fff;
      }
    }
    // Cluster stats on bar charts
    .bar-graph .bar .cluster-stats span:nth-of-type(#{$num}) {
      background-color: $color;
      border-color: $color;
    } //.bar-graph .bar .cluster-stats span

    // Cluster bar graphs
    .cluster-bar-graph a {
      .bar[data-color-order='#{$num - 1}'] {
        border-color: $color;
      }
      .hovered .bar[data-color-order='#{$num - 1}'] {
        background-color: $color;
      }
    } //.cluster-bar-graph a
    .cluster-bar-graph.spot_#{$spot} a .spot_#{$spot} .bar {
      background-color: $color;
    }

    // Members Profiles image borders
    .member-profile.member.spot_#{$spot} {
      border-color: $color;
    }

    .map-options label:nth-of-type(#{$num}).map-shown .toggle {
      background-color: $color;
    }

    .metric-instance .bar .cluster-stats span[data-color-order='#{$num}'] {
      background-color: $color;
      border-color: $color;
    }
  }
}
