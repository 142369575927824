// Categorical Colors: alternateCategorical

$colors: (
  1: #393b79,
  2: #5254a3,
  3: #6b6ecf,
  4: #9c9ede,
  5: #637939,
  6: #8ca252,
  7: #b5cf6b,
  8: #cedb9c,
  9: #8c6d31,
  10: #bd9e39,
  11: #e7ba52,
  12: #e7cb94,
  13: #843c39,
  14: #ad494a,
  15: #d6616b,
  16: #e7969c,
  17: #7b4173,
  18: #a55194,
  19: #ce6dbd,
  20: #de9ed6
);

#world-container.alternateCategorical, .modal-container.alternateCategorical {
  @each $num, $color in $colors {
    $spot: $num - 1;
    // Tribe sidebar borders
    .sub-clusters .cluster-sub-nav.cluster-order-#{$num} {
      border-color: $color;
      color: $menu-gray;

      &:hover, &.active, &.held {
        background-color: $color;
        color: #fff;
      }
    } // .cluster-sub-nav

    .hiddenCluster:nth-of-type(#{$num}) {
      border-left: 5px solid;
      border-color: $color;
      color: $menu-gray;

      &:hover, &.active, &.held {
        background-color: $color;
        color: #fff;
      }
    }
    // Cluster stats on bar charts
    .bar-graph .bar .cluster-stats span:nth-of-type(#{$num}) {
      background-color: $color;
      border-color: $color;
    } //.bar-graph .bar .cluster-stats span

    // Cluster bar graphs
    .cluster-bar-graph a {
      .bar[data-color-order='#{$num - 1}'] {
        border-color: $color;
      }
      .hovered .bar[data-color-order='#{$num - 1}'] {
        background-color: $color;
      }
    } //.cluster-bar-graph a
    .cluster-bar-graph.spot_#{$spot} a .spot_#{$spot} .bar {
      background-color: $color;
    }

    // Members Profiles image borders
    .member-profile.member.spot_#{$spot} {
      border-color: $color;
    }

    .map-options label:nth-of-type(#{$num}).map-shown .toggle {
      background-color: $color;
    }

    .metric-instance .bar .cluster-stats span[data-color-order='#{$num}'] {
      background-color: $color;
      border-color: $color;
    }
  }
}
