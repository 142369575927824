.ember-table{
  .affinio-table-cell-string{
    padding: 10px;
    display: flex;
    align-items: center;
    input{
      border-left: none;
      border-right: none;
      border-top: none;
      border-bottom: none;
      border-radius: 4px;
      margin: 0;
      padding: 5px;
      background-color: transparent;
      font-size: 1em;
      font-weight: 400;
      &:disabled{
        border-left: none;
        border-right: none;
        border-top: none;
        border-bottom: none;
      }
    }
    input.required{
      background-color: rgba(255, 0, 0, 0.2);
    }
  }
}
