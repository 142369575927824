.modal-boolean {
  .ok-button {
    background-color: lighten($dark-blue, 10);
    color: #eee;
    cursor: pointer;
    &:hover{
      background-color: $dark-blue;
      color: #eee;
    }
  }
  .cancel-button{
    background-color: #eee;
    color: #000;
    &:hover{
      background-color: darken(#eee, 10);
      color: #000;
    }
  }
  .question{
    font-size: 20px;
  }
}
