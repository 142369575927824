.segment-dashboard {
  width: 100%;
  .top {
    width: 100%;
    display: flex;
    .lead {
      width: 50%;
      h3 {
        em {
          text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
        }
      }
    }
    .aside {
      width: 50%;
      .network-graph {
        height: 450px;
      }
    }
    .look-a-like {
      .multi-slider {
        margin-top: 1rem;
      }
    }
  }
  .segment-report-builder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .report-builder-inputs {
      width: 70%;
      input[type="number"] {
        max-width: 150px;
        display: inline;
      }
      > label {
        position: relative;
        &.disabled span {
          opacity: 0.5;
        }
      }
      .tiny-add-button {
        position: absolute;
        top: 30px;
        line-height: 0.1;
      }
      .assetList {
        display: flex;
        flex-direction: column;
      }
      .auto-suggest {
        .tiny-add-button {
          line-height: 1;
        }
        > .tiny-add-button {
          display: none;
        }
      }
      .benchmark-groups {
        h3 {
          display: inline-block;
          margin-bottom: 1em;
        }
        hr {
          margin: 20px 0px;
        }
        .hovertip {
          float: none;
          display: inline-block;
          top: -20px;
        }
      }
      .admin-prefs {
        // position relative and z-index 2 are used
        // so hovertips don't overlay
        position: relative;
        z-index: 2;
        > section:last-of-type {
          margin-top: 3em;
        }
      }
    }
    .report-builder-staging {
      width: 25%;
      .button.primary {
        margin-top: 1em;
      }
      > section {
        margin-top: 1em;
        padding-bottom: 1em;
        border-bottom: solid 1px #eee;
        display: flex;
        flex-direction: column;
        // align-items: center;
        &.benchmark-groups {
          > a {
            margin-top: 1em;
          }
        }
        ul {
          max-height: 325px;
          overflow-y: auto;
          li {
            background-color: #eee;
            padding: 10px;
            margin-bottom: 5px;
            margin-right: 10px;
            // width: 300px;
            position: relative;
            word-wrap: break-word;
            p {
              margin-bottom: 0;
              font-size: 0.8em;
              color: #999;
            }
            a {
              position: absolute;
              top: 5px;
              right: 10px;
              color: #999;
              font-weight: 300;
              font-size: 1.5em;
            }
          }
        }
      }
    }

  }
  .booleanSwitcher {
    left: initial !important;
  }
}
