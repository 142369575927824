.apiKey-table {
  width: 100%;
  text-align: left;
  margin-bottom: 20px;

  thead {
    tr th {
      padding-left: 10px;
      font-size: 16px;
      font-weight: 300;
      line-height: 30px;
    }
  }
  tbody{
    tr {
      border-left: 5px solid darken($teal,20);
      // box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
      td {
        background-color: $teal;
        padding: 10px 0px;
        width: 10%;
        line-height:35px;

        &:first-of-type {
          color: #fff;
          padding-left: 10px;
          min-width: 10%;
        }

        &:nth-of-type(2) {
          color: #fff;
          padding-left: 10px;
          min-width: 10%;
        }
        &:nth-of-type(3) {
          padding-left: 10px;
          min-width: 10%;
          color: #fff
        }
        .primaryKeyGen{
          cursor: pointer;
        }
        .secondaryKeyGen{
          cursor: pointer;
        }
      }
    }
  }
}

.stats-table {
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
  thead {
    tr th {
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
    }
  }
  tbody{
    tr {
      // box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid #eee;
      td {
        // background-color: #fff;
        padding: 10px 0px 10px 0px;
        width: 10%;
        line-height:10px;
      }
    }
  }
}

.apiHeaderSpan{
  font-weight: 300;
}

.groupByParam{
  display: inline-block;
  margin-bottom: 10px;
}

.idSpan{
  font-weight: 300;
}

.api-group{
  display: block;
  background-color: $teal;
  border-left: 5px solid darken($teal,20);
  font-size: 1.1em;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.25);
  color: #fff;
  font-weight: 400;
  clear: both;
  text-align: left;
  padding: 10px;
  padding-top: 20px;
  margin-bottom: 10px;

  .title{
    margin-right: 10px;
    float: right;
  }

  h3{
    font-weight: 400;
  }
}

.api-active{
  font-weight: 300;
}
