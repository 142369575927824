.password-manager {
  width: 100%;
  span{
    display: block;
  }
  span:first-child {
    padding-top: 1em;
  }
  button {
    margin: 1em 0;
  }
}
