// TODO fix the naming nomenclature
.tribes-table .active-interest .user-box {
  max-width: 250px;
  width: 250px;
  white-space: normal;
}

.assets-grid .active-interest {
  width: auto;
  left: 0%;
}

.assets-grid .influencers.chart:hover .active-interest.on {
  opacity: 1;
}

.assets-grid .active-interest .user-box {
  max-width: 250px;
  width: 250px;
}

.assets-grid .active-interest .user-box p {
  white-space: normal;
}

.active-interest .user-box {
  color: #fff;
  background-color: rgba(0, 0, 0, 1);
  padding: 10px;
  margin-left: 0px;
  margin-top: 5px;
  min-width: calc(100% + 40px);
  font-size: 0.9em;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  z-index: 1000;
}

.active-interest .metric-instance {
  margin: 10px -10px -14px;
  height: 20px;
  width: calc(100% + 20px);
}

.active-interest .user-box em,
.active-interest .user-box strong {
  display: block;
}

.active-interest .user-box strong small,
small.of-100 {
  opacity: 0.5;
  font-size: 70%;
}

.active-interest .user-box p {
  margin: 10px 0px;
}

.user-box .influencer-cluster-bar {
  margin: 10px -10px -10px -10px;
  height: 20px;
  width: calc(100% + 20px);
}

.profile-card .influencer-cluster-bar {
  margin-top: 5px;
  height: 30px;
}

.user-box .up-arrow {
  position: absolute;
  top: -5px;
  left: 10px;
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #222;
  overflow: hidden;
}

.active-interest.righty .user-box .up-arrow {
  left: inherit;
  right: 10px;
}

.active-interest.bottom .user-box .up-arrow {
  top: inherit;
  bottom: -5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #222;
  border-bottom: none;
}

.active-interest .righty .user-box .scorebar {
  background: #555;
  height: auto;
  width: 100%;
  display: block;
  margin-top: 5px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 2px;
}

.user-box .scorebar .score {
  background: #00A89C;
  display: block;
  height: 5px;
}

.user-box .influencer-cover {
  width: calc(100% + 20px);
  margin: -10px -10px 10px -10px;
  overflow: hidden;
  height: 80px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
}

.active-interest {
  position: absolute;
  left: 120%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  opacity: 0;
  width: 180px;

  .baseline-diff-explainer {
    background-color: rgba(255,255,255,0.25);
    font-weight: 400;
    display: block;
    margin: -10px -10px 10px -10px;
    padding: 10px;
    &.greater {
      background-color: $baseline-greater;
    }
    &.lesser {
      background-color: $baseline-lesser;
    }
    &.not-found-in-baseline {
      background-color: $medium-gray;
    }
    em {
      display: inline;
    }
  }
}

.active-interest .user-box {
  max-width: 180px;
}

.grid .chart:hover .active-interest.on {
  opacity: 1;
}

.tribes-table .interests-vis-main .influencers.chart.hovered .active-influencer,
.grid .interests-vis-main .influencers.chart.hovered .active-influencer,
.grid .interests-vis-main .influencers.chart.hovered .active-influencer {
  opacity: 1;
}

.list .interests-vis-main .active-interest {
  display: none;
}

.tribes-table .active-interest .user-box {
  max-width: 250px;
  width: 250px;
  white-space: normal;
}

.assets-grid .active-interest {
  width: auto;
  left: 0%;
}

.assets-grid .influencers.chart:hover .active-interest.on {
  opacity: 1;
}

.assets-grid .active-interest .user-box {
  max-width: 250px;
  width: 250px;
}

.assets-grid .active-interest .user-box p {
  white-space: normal;
}

.influencers.chart {
  margin: 10px 0px;
  background-color: #fff;
  position: relative;
  clear: right;
  float: right;
  width: 100%;
}



