.trait-chart {
  position: relative;
  div.header-trait-selection {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 1em;
    .power-select{
      width: calc(100% - 150px);
      .ember-power-select-trigger{
        margin-top: 0.1em;
        line-height: 1.4;
        min-height: 1.5em;
        height: 1.5em;
        font-size: 1.3em;
        font-weight: 300;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .ember-power-select-options{
        line-height: 1.4;
      }
    }
    .power-select-views{
      width: 85px;
    }
  }
  h3 {
    font-size: 1.3em;
    margin-bottom: 0;
    font-weight: 300;
    padding: 0 0.25em;
    white-space: nowrap;
  }

  .trait-instance {
    display: block;
    width: 100%;
    @include animation($name: traitSlideOut, $delay: 0.5s, $duration: 0.5s);
    position: relative;
    overflow: visible;
    transition: 0.2s;
    transition-delay: 0s;
    left: 0px;
    top: 0px;
    padding: 0.5em 0 0.5em 32px;
    border-radius: 4px;
    height: 3em;
    margin-bottom: 0.2em;

    // &.has-baseline {
    //   border-left: 5px solid;
    // }

    &:hover {
      background-color: rgba(220, 220, 220, 0.8);
      z-index: 2;
      .saturation, .metric {
        display: block;
      }
      svg, .icon {
        box-shadow: 1px 1px 10px rgba(0,0,0,0.2);
      }
    }

    svg, .icon {
      overflow: hidden;
      background-color: #fff;
      box-sizing: content-box;
      border-radius: 100px;
      margin: 3px 0;
      padding: 3px;
      width: 36px !important;
      height: 36px !important;
      border: 3px solid #fff;
      box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.1);
      position: absolute;
      z-index: 2;
      left: 0px;
      top: calc(50% - 24px);
      transition: 0.2s;
    }

    .icon {
      height: 32px;
      width: 32px;
      padding: 0;
    }

    label {
      color: $dark-gray;
      font-weight: 300;
      white-space: nowrap;
      overflow: hidden;
      position: absolute;
      top: calc(50% - 1.3em);
      left: 48px;
      text-overflow: ellipsis;
      width: 85%;
    }

    .trait-bar-holster {
      position: absolute;
      width: calc(100% - 42px);
      left: 42px;
      top: calc(50% + 0.1em);

      .trait-bar {
        height: 4px;
        border-radius: 4px;
        display: block;
        position: relative;
        min-width: 5px;
        &.baseline-bar {
          top: 1px;
        }
      }
    }


    .saturation, .metric {
      width: 50%;
      font-size: 0.6em;
      font-weight: 700;
      padding: 0em 0.25em 0em 1.2em;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: none;
      overflow: hidden;
      color: $medium-gray;
      position: absolute;
      right: 0.5em;
      @include animation($name: traitMetaSlideIn, $delay: 0.3s, $duration: 0.2s);
      // bottom: 0.25em;
      top: 0.25em;
      text-align: right;
      &.baseline-saturation {
        top: 0.25rem;
      }
    }
    .saturation {
      top: initial;
      bottom: 0.1em;
    }
    .metric {
      text-align: left;
      left: 48px;
      top: initial;
      bottom: 0.1em;
      padding: 0em 0 0em 0.25em;
      @include animation($name: traitMetaSlideIn, $delay: 0.2s, $duration: 0.2s);
    }

  }
}

#overview {
  @for $i from 1 to 20 {
    .cluster-summary:nth-child(#{$i}) {
      @for $j from 1 to 20 {
        .trait-instance:nth-of-type(#{$j}) {
          animation-delay: (0.05s * $j) + (0.1s * $i);
        }
      }
    }
  }
}
