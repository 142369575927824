.flexbox {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;

  &.is-hide {
    display: none;
  }

  &.is-shown {
    display: flex;
  }

  &.is-column {
    flex-direction: column !important;
  }

  &.is-every-third-padding {
    :nth-child(3n + 0) {
      padding-left: 6px;
    }
    :nth-child(3n + 1) {
      padding-right: 6px;
    }
    :nth-child(3n + 2) {
      padding-right: 3px;
      padding-left: 3px;
    }
  }

  &.align-item-center {
    align-items: center;
  }

  &.is-every-padding {
    & > :nth-child(n) {
      padding-left: 6px;
    }

    & > :first-child {
      padding-left: 0;
    }
  }

  & > .flex-item {
    padding-top: 8px;
    flex-grow: 0;

    &.is-one-third {
      flex-basis: 33.33%;
      max-width: 33.33%;
    }

    &.is-static-width {
      min-width: 62px;
    }

    &.is-full {
      flex-basis: 100%;
    }

    &.is-half {
      flex-basis: 50%;
    }

    & > .button {
      width: 100%;

      &.text-ellipsis-control {
        display: flex;
        justify-content: center;

        span {
          padding: 0;

          &.text-ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &:nth-of-type(2) {
            flex-shrink: 0;
          }

        }
      }

      span.capitalize {
        text-transform: capitalize;
      }
    }
  }
}
