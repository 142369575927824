.asset-group {
  label.csv-upload {
    display: inline-block;
    p.helper {
      border-bottom: dashed 1px #eee;
      margin-bottom: 1em;
      display: inline-block;
      max-width: none;
    }
  }
  .global-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: solid 1px #eee;
    margin: 0px 10px;
    label {
      margin: 10px 0px;
    }
    button {
      margin: 5px;
    }

  }
  .benchmarks {
    max-height: 500px;
    overflow-y: auto;
    p.helper {
      margin-bottom: 0em;
    }
    .benchmark {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 0px;
      transition: 200ms;
      border-bottom: 1px solid #eee;
      > span {
        margin: 0px 10px;
      }
      .handle {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .delete-asset {
        color: $medium-gray;
        padding: 5px;
        cursor: pointer;
        transition: 200ms;
        &:hover {
          background-color: #ddd;
        }
      }
      &:hover {
        background-color: #F8F9FA;
      }
    }
  }
}
