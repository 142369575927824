.list .interests-vis-main .delta-metric {
  display: inline;
  margin-left: 10px;
  font-weight: 400;
  color: #222;
}

.graphed-bar .delta-metric {
  background-color: #FFFFFF !important;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.bar .delta-metric,
.profile-card .delta-metric {

  &.positive {
    // border-bottom: 2px solid $teal;
    .fa {
      color: $teal;
    }
  }
  &.negative {
    // border-bottom: 2px solid $red;
    .fa {
      color: $red;
    }
  }
  &.neutral {
    opacity: 0.75;
  }
  &.new {
    display: none;
  }

  em {
    margin: 0px;
    padding: 0px;
  }
}
