/*==================================================================
=Embedded Media
----------------------------------------------------------------- */

.embedded-media iframe {
  width: 100%;
  height: calc(100% - 30px);
  /*position: absolute;*/
}

.embedded-media .shares {
  display: block;
  padding: 5px 0px;
  font-size: 0.8em;
  height: 30px;
  width: 100%;
  color: #222;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  left: 20px;
  bottom: 10px;
}

.embedded-media canvas {
  width: 100% !important;
  height: auto !important;
}

.embedded-media {
  position: relative;
  padding: 10px;
  margin: 10px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /*max-height: 325px;*/
  background-color: #fff;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 0px 20px 20px 0px;
  width: 400px;
  height: 280px;
  padding: 0px;
  border: 1px solid #eee;
  float: left;
}

.insta-frame {
  position: relative;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /*max-height: 325px;*/
  overflow: hidden;
  margin: 0px 20px 20px 0px;
  padding: 10px;
  width: 340px;
  height: 410px;
  border: 1px solid #ddd;
  float: left;
}


.embedded-media.is-iframe {
  float: left;
  border: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
}

.embedded-media.is-shown {
  display: block;
}

.embedded-media .non-embed-link {
  position: relative;
  z-index: 2;
}

.embedded-media .non-embed-link > a {
  display: block;
  margin: -20px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px;
  width: 420px;
  height: 300px;
}

.embedded-media .non-embed-link a:hover {
  background-color: rgba(0,0,0,0.05);
}

.embedded-media .non-embed-link em {
  font-size: 0.65em;
  opacity: 0.8;
  position: absolute;
  left: 20px;
  bottom: 15px;
}

.link-image {
  float: left;
  width: 100px;
  margin-right: 20px;
  margin-bottom: 20px;
  max-height: 110px;
}

.embedded-media .title {
  font-size: 1.2em;
  display: block;
  margin-bottom: 10px;
  color: #222;
  overflow: hidden;
}

.embedded-media p.description {
  color: #999;
  background-color: transparent;
  padding: 0px;
  margin: 0px;
  font-size: 0.8em;
}

.embedded-media p.description.bumped {
  margin-left: 120px;
}

.embedded-media .source_url {
  display: block;
  font-weight: 400;
  margin-bottom: 10px;
  font-style: italic;
  font-size: 0.8em;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.visited-site-section {
  margin-bottom: 50px;
  padding-bottom: 50px;
  clear: both;
}
