.monitor-dashboard {
  width: 99%;
  .benchmark-groups {
    display: flex;
    flex-direction: row;
    h3 {
      display: inline-block;
      margin-bottom: 1em;
    }
    hr {
      margin: 20px 0px;
    }
    .hovertip {
      float: none;
      display: inline-block;
      top: -20px;
    }
    .admin-prefs {
      // position relative and z-index 2 are used
      // so hovertips don't overlay
      position: relative;
      z-index: 2;
      // margin-top: 3em;
      > section:first-of-type {
        margin-bottom: 3em;
      }
    }
    .benchmarks-dashboard {
      width: 70%;
    }
    .benchmarks-staging {
      width: 30%;
      margin-left: 2em;
      .summary-group {
        > a.remove {
          float: right;
          margin-right: 10px;
        }
      }
    }
  }
}
