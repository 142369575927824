.ember-table{
  .affinio-table-cell-metrics{
    padding: 0 10px 0 10px;
    .mini-metrics{
      font-size: 0.8em;
      display: flex;
      flex-direction: column;
      margin-top: 5px;
      .metrics-block{
        display: flex;
        justify-content: space-around;
        font-size: 0.8em;
        width: 280px;
        .metric-block{
          margin-right: 0.8em;
          display: inline-block;
          max-width: 33%;
          span{
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
