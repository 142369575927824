.engagement-grid {
  // TODO handle with skeleton state
  min-height: 1133px;
  .heatmap-grid {
    width: calc(100% - 150px);
    float: left;
  }
  .bar-chart.vertical {
    width: calc(100% - 150px);
  }
  .bar-chart.horizontal {
    float: left;
  }

  .heat-block {
    margin: 2px;
    // min-width: 46px; // max-width: 64px;
    // opacity: 0.1;
    min-height: 32px;
    max-height: 50px;
    border: solid 2px #fff;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.2);
    transition: 1s !important;

    &.highlight.stickied {
      border: solid 3px $blue;
      opacity: 1 !important;
      transition: 0ms 0ms !important;
    }
    &.active {
      border: solid 5px #FFBA33; // opacity: 1;
    }
    &.active.highlight {
      border: solid 5px #FFBA33 !important;
    }
    &:hover {}
  }

}
