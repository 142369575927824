/*=========================================
Report Builder (Facebook Hackathon)
=========================================*/

.newCampaignForm.content.step3 {
  input{
    padding-right: 70px;
  }
}

.newCampaignForm.content.step3 input[type="text"] {
  background-color: transparent;
}

.newCampaignForm.content.step3 .form-proper {
  width: calc(100% - 320px);
  max-width: 100%;
  transition: 0.2s;
  min-height: 1000px;
}

.newCampaignForm .content-option-buttons {
  margin: 10px 0px 10px;
  a {
    margin: 0px 20px 20px 0px;
    font-size: 0.9em;
    color: #999;
    text-decoration: underline;
  }
}

.newCampaignForm label.tweet-about {
  transition: 0.2s;
  overflow: hidden;
  &.active-results {
    height: 0px;
  }
}

#campaign-nav .credits {
  padding: 1em;
  .support-button {
    background: transparent;
    color: $blue;
    padding: 0;
    font-style: italic;
  }
}

.newCampaignForm {
  .card {
    padding: 1em;
  }
}

#universe-container.reportsNewStep1,
#universe-container.reportsNewStep2,
#universe-container.reportsNewStep3,
#universe-container.reportsNewStep4,
#universe-container.reportsNewStep5,
#universe-container.reportsNewStep6 {
  .content-proper {
    width: calc(100% - 200px);
  }
}

.newCampaignForm .runningTime span {
  font-weight: initial;
  font-size: initial;
}

// TODO better report builder css
#universe-container.reportsNewStep5 {
  .filterDescription {
    // margin-bottom: 3em;
  }
  .content-proper h3 {
    display: inline-block;
    margin-bottom: 1em;
  }
  .content-proper hr {
    margin: 20px 0px;
  }
  .hovertip {
    float: none;
    display: inline-block;
    top: -20px;
  }
  .admin-prefs {
    // position relative and z-index 2 are used
    // so hovertips don't overlay
    position: relative;
    z-index: 2;
    // margin-top: 3em;
    > section:first-of-type {
      margin-bottom: 3em;
    }
  }
}
