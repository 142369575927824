.traits-demographics {
  width: 50%;
  // height: 400px;
  // left: 0% !important;
  h4{
    display: inline-block;
  }
  a{
    color: #999;
    font-size: 0.9em;
    float: right;
  }
  .helper{
    margin-bottom: 0px;
  }
}
