.audience-header {
  width: 99%;
  display: flex;
  flex-direction: row;
  h4 {
    font-size: 1.3em;
    line-height: 1em;
    margin-bottom: 1em;
    margin-top: 0.5em;
    padding-bottom: 1em;
    color: #444;
    border-bottom: 1px solid #eee;
  }
  .report-name {
    width: 50%;
    margin: 0em 1em 0em 0em;
  }
  .audience-details {
    width: 25%;
    margin: 0em 1em 0em 4em;
    .filter-header{
      font-weight: bold;
      border-bottom: 2px solid #eee;
    }
    .union-boolean{
      font-weight: 300;
      font-style: italic;
      display: block;
    }
    .content-period{
      display: block;
    }
  }
  .audience-source {
    width: 25%;
    margin: 0em 1em;
  }
}
