.modal-persona {
  h4{
    margin-bottom: 20px;
  }
  .personas{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    flex-wrap: wrap;
    .persona{
      margin: 2px 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 20px;
      border: 1px solid #E9E9E9;
      box-sizing: border-box;
      border-radius: 20px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #A8A8A8;
      transition: border-color 0.2s ease, color 0.2s ease;
      &:hover{
        border: 1px solid #459FE7;
        color: #459FE7;
      }
    }
    .active{
      border: 1px solid #459FE7;
      color: #459FE7;
    }
  }
  .filters{
    margin-top: 20px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    .filter-header{
      color: #333333;
      font-weight: 500;
    }
    .filter-string{
      color: #777777;
    }
    .mb {
      margin-bottom: 20px;
    }
  }
}
