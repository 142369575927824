.toolbox-tool {
  @include animation($name: slideDownFadeIn, $duration: 1s, $delay: 0s);

  &.is-expanded {
    position: relative;
    z-index: 2;
  }

  .tool-label {
    color: $medium-gray;
    cursor: pointer;
    display: block;

    .tool-description {
      i {
        // TODO abstract this to an inline version of hovertip used for title attribute?
        display: inline-block;
        background: #eee;
        color: #222;
        font-weight: bold;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        height: 20px;
        width: 20px;
        line-height: 20px;
        text-align: center;
        border: 3px solid #fff;
        font-size: 0.8em;
        box-sizing: content-box;
      }
    }
  }
  .tool-inputs {
    @include animation($name: slideDownFadeIn, $duration: 1s, $delay: 0s);
    display: inline-flex;
    flex-direction: row;
    padding: 5px 15px 5px 15px;
    background-color: $base-background-color;
    border-radius: 4px;
    box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    margin-top: 5px;
    margin-left: 25px;
    .input-label{
      width: 450px;
      margin-top: 5px;
      display: block;
      border-bottom: 1px solid lighten($medium-gray, 30);
    }
    .tool-input{
      display: inline-block;
      color: $medium-gray;
      font-size: 12px;
      margin-right: 10px;
      .auto-suggest{
        @include animation($name: fadeIn, $duration: 1s, $delay: 0s);
      }
      .selected-entity{
        @include animation($name: fadeIn, $duration: 1s, $delay: 0s);
        margin-top: 5px;
        font-weight: 400;
        font-size: 14px;
        display: inline-block;
        margin-right: 5px;
        margin-bottom: 5px;
      }
      .remove-input{
        @include animation($name: fadeIn, $duration: 1s, $delay: 0s);
        float: left;
        margin-top: 1em;
        margin-right: 1em;
        .fa{
          font-size: 18px;
          top: 15px;
          position: relative;
          cursor: pointer;
          &:hover{
            color: darken(red, 10)
          }
        }
      }
      input{
        width: 450px;
        margin-top: 5px;
        margin-right: 10px;
      }
      .results{
        @include animation($name: slideDownAutoSuggest, $duration: 1s, $delay: 0s);
        font-size: 16px;
        border: 1px solid $blue;
        border-radius: 4px;
        width: 450px;
        .loading-spinner{
          position: relative;
          top: 32.5%;
          margin-left: 35%;
        }
        .active{
          outline: 1px solid $blue;
          span{
            color: $blue;
          }
        }
      }
    }
    .toolbox-btn{
      background-color: lighten($dark-blue, 10);
      color: $base-background-color;
      max-height: 35px;
      margin-top: 30px;
      padding: 1px 7px 2px;
      &:hover{
        background-color: $dark-blue;
      }
    }

    &.expanded-inputs {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      height: 20rem;
      overflow-x: scroll;
    }
  }
  .auto-suggest .results .result span:first-child{
    min-width: 0px;
  }
}
