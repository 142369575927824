.new-report-summary {
  margin-top: 1em;
  h1{
    font-size: 1.3em;
    // font-weight: 600;
    margin-bottom: 0.5em;
  }
  .selected-number-of-clusters {
    .bar {
      width: 100%;
      height: 5px;
      display: flex;
      margin-bottom: 18px;
      span {
        display: inline-block;
        // width:
        height: 5px;
        border-radius: 2px;
        margin-left: 5px;
        &:first-of-type {
          margin-left: 0px;
        }
      }
    }
  }
  .summary-item {
    margin-bottom: 0.5em;
    .label {
      transition: 250ms;
      display: block;
      // color: $darkish-gray;
      color: $medium-gray;
    }
    > .value {
      font-weight: 400;
      // text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    }
    svg{
      position: relative;
      top: 0.1em;
      width: 1em;
      height: 1em;

      &.x-twitter-icon {
        fill: $dark-gray;
        transform: translateY(2px);
        height: 1.1em;
        width: 1.1em;
      }
    }
  }
  .filter-section {
    display: flex;
    flex-direction: column;
    .summary-item {
      .label {
        // color: $blue;
      }
    }
    .filter-type-header {
      display: flex;
      transition: 250ms;
      justify-content: space-between;
      padding-bottom: 5px;
      border-bottom: 1px solid rgba(0,0,0,0);
      background-color: $light-gray;
      cursor: pointer;
      // &.expanded {
      //   .label {
      //     color: $dark-gray;
      //   }
      //   border-color: $dark-gray;
      // }
      // &.collapsed {
      //
      // }
      .label {
        display: flex;
        align-items: center;
        .fa {
          font-size: 0.65em;
          font-weight: 300;
          margin-right: 1em;
        }
      }
      &:hover {
        .label {
          color: $dark-gray;
        }
        border-color: shade($light-gray, 25%);
        // background-color: shade($light-gray, 5%);
      }
      .clear-all {
        color: #999;
        cursor: pointer;
        transition: 250ms;
        &:hover {
          color: $darkish-gray;
        }
      }
    }
    .filter {
      // order: 6;
    }
    .content-query {
      order: 0;
    }
    .followers-of {
      // order: 1
    }
    .bio-keywords {
      order: 2
    }
    .bio-locations {
      order: 3;
    }
    .content-from{
      order: 4;
    }
    .content-to{
      order: 5;
    }
    .type-header {
      color: #999;
      display: block;
    }
    .filter-values {
      margin-top: 0.5em;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .type-filters {
        width: 100%;
        .summary-group {
          .header {
            font-size: 0.85em;
            position: relative;
            padding-bottom: 1em;
            border-bottom: 1px solid #eee;
            cursor: pointer;
            transition: 200ms;
            > span {
              transition: 200ms;
              color: $medium-gray;
              strong {
                color: $medium-gray;
                font-weight: 500;
                transition: 200ms;
              }
            }
            .remove {
              position: absolute;
              top: 0px;
              right: 0px;
            }
            &:hover {
              > span {
                color: $dark-gray;
              }
              strong {
                color: $dark-gray;
              }
            }
          }
        }
      }
      .hidden{
        visibility: none;
      }
    }
    .remove {
      // position: absolute;
      // top: 0px;
      // right: 0px;
      font-size: 0.85em;
      line-height: 0.85;
      transition: 250ms;
      background-color: rgba(0,0,0,0);
      &:hover {
        color: shade(red, 25%);
      }
    }
  }

  // TODO see if still neccessary
  .boolean-holder{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 0.3em;
    margin-bottom: 0.3em;
    &:hover{
      .switch {
        visibility: visible;
      }
    }
    // TODO see if this needed still
    &.primary{
      margin-top: 1em;
      margin-bottom: 1em;
      width: 100%;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
    }
  }
  .datasource-boundary {
    border: 1px solid rgba(0,0,0,0.25);
    padding: 0.5rem;
    border-radius: 10px;
  }
  .datasource-heading {
    border-bottom: 1px solid rgba(0,0,0,0.25);
  }
}
