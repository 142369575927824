.activate-twitter-tailored-audience {
  width: 100%;
  display: flex;
  padding-top: 1em;

  .lead {
    width: 100%;
    h3 {
      em {
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
      }
    }
  }

  .aside {
    width: 100%;
    .network-graph {
      max-width: 100%;
      margin: 0 auto;
      @media screen and (min-width: $xlarge-screen) {
        max-width: 100%;
      }
    }
  }

  .ad-accounts {
    width: auto;
    padding-right: 3em;
  }

  .audience-size {
    .userForm label span {
      font-weight: initial;
      font-size: initial;
    }
  }
}
