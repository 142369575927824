

.tabular-list {

  li {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    background-color: #f2f2f2;
    padding: 1rem;
    border: 1px solid #ccc;

    span {
      display: block;
      text-align: left;
    }

    span:nth-child(1) {
      grid-column: 1 / 2;
    }

    span:nth-child(2) {
      grid-column: 2 / 3;
    }

    span:nth-child(3) {
      grid-column: 3 / 4;
    }

    span:nth-child(4) {
      grid-column: 4 / 5;
    }
  }

  .action-wrapper{
    display: flex;
    align-items: center;
    
    input{
      margin-bottom: 0px;
      margin-right: 10%;
    }
  }
}
