.affinio-table-cell-checkbox{
  padding: 0 0 0 10px;
  height: 100%;
  overflow: hidden;
  .custom-checkbox{
    height: 100%;
    width: 100%;
    .checkbox{
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
    }
  }
}
