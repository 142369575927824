.community-stats .cluster-bar-graph {
  position: relative;
}
.cluster-bar-graph label {
  margin-bottom: 0.2em ;
}
.community-stats .cluster-bar-graph {
  display: none;
  margin-top: 20px;
  width: 100%;
  float: left;
  clear: both;
}

.head {
  min-height: 2.5em;
}
.lurker-average {
  > div {
    display: none;
  }
  .show-lurker-average {
    display: block;
  }
}

.cluster-bar-graph {
  .show-lurker-average em {
    display: block;
    white-space: nowrap;
    color: #aaa;
    font-size: 0.8em;
  }
  .show-lurker-average strong:first-of-type {
    color: #333;
  }

}


.community-stats.lurkers .cluster-bar-graph,
.community-stats.engagement .cluster-bar-graph,
.community-stats.engagement_comments .cluster-bar-graph,
.community-stats.engagement_shares .cluster-bar-graph,
.community-stats.engagement_likes .cluster-bar-graph,
.community-stats.uniqueInfluencers .cluster-bar-graph,
.community-stats.influencerAffinity .cluster-bar-graph,
.community-stats.density .cluster-bar-graph{
  display: block;
  visibility: visible;
}

.cluster-bar-graph a .bar {
  border-left: 3px solid;
  margin-bottom: 0;
}

.cluster-bar-graph .comparison {
  background-color: #222;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  -o-transition: .5s;
  transition: .5s;

  strong {
    line-height: 1.1;
    white-space: nowrap;
    background-color: rgba(255,255,255,0.8);
    display: inline-block;
    font-size: 0.8em;
    margin: 5px;
    padding: 0px 2px;
  }

}

.cross-tribe-lurkers label {
  padding: 0px 2px;
  transition: .4s;
  color: #222 !important;
  background-color: rgba(255,255,255,0.8);
}
.cross-tribe-lurkers em {
  color: #222;
}
.cluster-bar-graph a > div {
  transition: background-color .4s, width .4s;
  margin-bottom: 1px;
}
.cross-tribe-lurkers a > div {
  height: 28px;margin-bottom: 1px;
  box-shadow: inset 0 0 0 0 rgba(0,0,0,0) ;
  transition: background-color .4s, width .4s, box-shadow .4s;
  background-color: $audience-summary-gray;
}
.cross-tribe-lurkers a > div:hover {
 transition: box-shadow .4s;
 box-shadow: inset -44px 0px 30px 6px rgba($blue-gray, 0.4);

}
.cross-tribe-lurkers a .bar {
  background-color: #222;
  border-right: 1px solid #999 !important;
}

.cluster-bar-graph a .bar[data-color-order='0'] {
  border-color: rgba(31,119,180,1);
}
.cluster-bar-graph a .bar[data-color-order='1'] {
  border-color: rgba(174,199,232,1);
}
.cluster-bar-graph a .bar[data-color-order='2'] {
  border-color: rgba(255,127,14,1);
}
.cluster-bar-graph a .bar[data-color-order='3'] {
  border-color: rgba(255,187,120,1);
}
.cluster-bar-graph a .bar[data-color-order='4'] {
  border-color: rgba(44,160,44,1);
}
.cluster-bar-graph a .bar[data-color-order='5'] {
  border-color: rgba(152,223,138,1);
}
.cluster-bar-graph a .bar[data-color-order='6'] {
  border-color: rgba(214,39,40,1);
}
.cluster-bar-graph a .bar[data-color-order='7'] {
  border-color: rgba(255,152,150,1);
}
.cluster-bar-graph a .bar[data-color-order='8'] {
  border-color: rgba(148,103,189,1);
}
.cluster-bar-graph a .bar[data-color-order='9'] {
  border-color: rgba(197,176,213,1);
}
.cluster-bar-graph a .bar[data-color-order='10'] {
  border-color: rgba(140,86,75,1);
}
.cluster-bar-graph a .bar[data-color-order='11'] {
  border-color: rgba(196,156,148,1);
}
.cluster-bar-graph a .bar[data-color-order='12'] {
  border-color: rgba(227,119,194,1);
}
.cluster-bar-graph a .bar[data-color-order='13'] {
  border-color: rgba(247,182,210,1);
}
.cluster-bar-graph a .bar[data-color-order='14'] {
  border-color: rgba(127,127,127,1);
}
.cluster-bar-graph a .bar[data-color-order='15'] {
  border-color: rgba(199,199,199,1);
}
.cluster-bar-graph a .bar[data-color-order='16'] {
  border-color: rgba(188,189,34,1);
}
.cluster-bar-graph a .bar[data-color-order='17'] {
  border-color: rgba(219,219,141,1);
}
.cluster-bar-graph a .bar[data-color-order='18'] {
  border-color: rgba(23,190,207,1);
}
.cluster-bar-graph a .bar[data-color-order='19'] {
  border-color: rgba(158,218,229,1);
}


.cluster-bar-graph a .hovered .bar[data-color-order='0'] {
  background-color: rgba(31,119,180,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='1'] {
  background-color: rgba(174,199,232,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='2'] {
  background-color: rgba(255,127,14,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='3'] {
  background-color: rgba(255,187,120,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='4'] {
  background-color: rgba(44,160,44,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='5'] {
  background-color: rgba(152,223,138,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='6'] {
  background-color: rgba(214,39,40,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='7'] {
  background-color: rgba(255,152,150,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='8'] {
  background-color: rgba(148,103,189,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='9'] {
  background-color: rgba(197,176,213,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='10'] {
  background-color: rgba(140,86,75,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='11'] {
  background-color: rgba(196,156,148,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='12'] {
  background-color: rgba(227,119,194,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='13'] {
  background-color: rgba(247,182,210,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='14'] {
  background-color: rgba(127,127,127,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='15'] {
  background-color: rgba(199,199,199,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='16'] {
  background-color: rgba(188,189,34,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='17'] {
  background-color: rgba(219,219,141,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='18'] {
  background-color: rgba(23,190,207,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='19'] {
  background-color: rgba(158,218,229,1);
}

.lurker-legend {
  width: 100%;
  float: left;
  clear: both;
  position: relative;
  margin-top: 15px;
  span {
    height: 15px;
    float: left;
    font-size: 0.7em;
    text-indent: 5px;
    margin-right: 10px;
    margin-bottom: 2px;
    line-height: 15px;
    color: #333;
    border-left: 15px solid;

    &.lurkers-icon {
      border-color: #222;
    }

    &.posters-icon {
      border-color: $audience-summary-gray;
    }
  }

}

.cluster-bar-graph a .bar {
  border-left: 3px solid;
}

.cluster-bar-graph a .bar[data-color-order='0'] {
  border-color: rgba(31,119,180,1);
}
.cluster-bar-graph a .bar[data-color-order='1'] {
  border-color: rgba(174,199,232,1);
}
.cluster-bar-graph a .bar[data-color-order='2'] {
  border-color: rgba(255,127,14,1);
}
.cluster-bar-graph a .bar[data-color-order='3'] {
  border-color: rgba(255,187,120,1);
}
.cluster-bar-graph a .bar[data-color-order='4'] {
  border-color: rgba(44,160,44,1);
}
.cluster-bar-graph a .bar[data-color-order='5'] {
  border-color: rgba(152,223,138,1);
}
.cluster-bar-graph a .bar[data-color-order='6'] {
  border-color: rgba(214,39,40,1);
}
.cluster-bar-graph a .bar[data-color-order='7'] {
  border-color: rgba(255,152,150,1);
}
.cluster-bar-graph a .bar[data-color-order='8'] {
  border-color: rgba(148,103,189,1);
}
.cluster-bar-graph a .bar[data-color-order='9'] {
  border-color: rgba(197,176,213,1);
}
.cluster-bar-graph a .bar[data-color-order='10'] {
  border-color: rgba(140,86,75,1);
}
.cluster-bar-graph a .bar[data-color-order='11'] {
  border-color: rgba(196,156,148,1);
}
.cluster-bar-graph a .bar[data-color-order='12'] {
  border-color: rgba(227,119,194,1);
}
.cluster-bar-graph a .bar[data-color-order='13'] {
  border-color: rgba(247,182,210,1);
}
.cluster-bar-graph a .bar[data-color-order='14'] {
  border-color: rgba(127,127,127,1);
}
.cluster-bar-graph a .bar[data-color-order='15'] {
  border-color: rgba(199,199,199,1);
}
.cluster-bar-graph a .bar[data-color-order='16'] {
  border-color: rgba(188,189,34,1);
}
.cluster-bar-graph a .bar[data-color-order='17'] {
  border-color: rgba(219,219,141,1);
}
.cluster-bar-graph a .bar[data-color-order='18'] {
  border-color: rgba(23,190,207,1);
}
.cluster-bar-graph a .bar[data-color-order='19'] {
  border-color: rgba(158,218,229,1);
}

.cluster-bar-graph a .hovered .bar[data-color-order='0'] {
  background-color: rgba(31,119,180,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='1'] {
  background-color: rgba(174,199,232,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='2'] {
  background-color: rgba(255,127,14,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='3'] {
  background-color: rgba(255,187,120,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='4'] {
  background-color: rgba(44,160,44,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='5'] {
  background-color: rgba(152,223,138,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='6'] {
  background-color: rgba(214,39,40,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='7'] {
  background-color: rgba(255,152,150,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='8'] {
  background-color: rgba(148,103,189,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='9'] {
  background-color: rgba(197,176,213,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='10'] {
  background-color: rgba(140,86,75,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='11'] {
  background-color: rgba(196,156,148,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='12'] {
  background-color: rgba(227,119,194,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='13'] {
  background-color: rgba(247,182,210,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='14'] {
  background-color: rgba(127,127,127,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='15'] {
  background-color: rgba(199,199,199,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='16'] {
  background-color: rgba(188,189,34,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='17'] {
  background-color: rgba(219,219,141,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='18'] {
  background-color: rgba(23,190,207,1);
}
.cluster-bar-graph a .hovered .bar[data-color-order='19'] {
  background-color: rgba(158,218,229,1);
}

.twitter-average,
.report-average {
  position: absolute;
  background: transparent;
  width: 0px;
  top: 20px;
  bottom: 0;
  left: 0px;
  z-index: 2;
  z-index: 0;
  transition: all .4s;
  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  opacity: 0;
}

.showAverage  .report-average {
  border-right: 1px dashed $blue;
}

.showAverage  .twitter-average {
  border-right: 1px dashed #aaa;
  top: 15px;
}

.show-lurker-average .lurker-average{
  transition: opacity .4s;
  -webkit-transition: opacity .4s;
  -moz-transition: opacity .4s;
  opacity: 1;
}
.showAverage .twitter-average,
.showAverage .report-average{
  opacity: 1;
    margin-bottom: 5px;
}

.twitter-average#average-1,
.twitter-average#average-2,
.twitter-average#average-3,
.twitter-average#average-4 {
  width: 25%;
  border-top: 5px solid;
  top: 25px;
}
.twitter-average#average-1 {
  border-top-color: #99010d;
}
.twitter-average#average-2 {
  border-top-color: #add076;
}
.twitter-average#average-3 {
  border-top-color: #1f9259;
}
.twitter-average#average-4 {
  border-top-color: #07b0f1;
}

.twitter-average#average-1 em,
.twitter-average#average-2 em,
.twitter-average#average-3 em,
.twitter-average#average-4 em{
  position: relative;
  text-align: center;
  left: 0px;
  float: none;
  top: -25px;
}

.twitter-average.perc em {
  right: -15px;
  background-color: #fff;
}
.lurker-legend {
  padding-top: 20px;
  padding-bottom: 10px;
  background-color: #fff;
  // padding-bottom: 5px;
  span {
    border-left: 15px solid;
  }
  .network-average-icon {
    border-color: #ccc;
  }
  .posters-icon,
  .audience-average-icon {
    border-color: $blue;
  }

}
.report-average em {
  display: block;
  white-space: nowrap;
  position: absolute;
  top: 0px;
  color: $blue;
  margin-left: 5px;
  font-size: 0.8em;
}
.twitter-average em {
  display: block;
  white-space: nowrap;
  float: left;
  position: absolute;
  top: -20px;
  color: #aaa;
  margin-left: 5px;
  font-size: 0.8em;
}
