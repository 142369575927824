select {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: border-box;
  // width: 100%;
  padding: 10px;
  font-size: 0.7em;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  display: block;
  background-image: url('/assets/images/downer.png');
  background-position: calc(100% - 5px) 50%;
  background-repeat: no-repeat;
  border: 1px solid #ddd;
}

.select-big {
  padding: 0 2em 0 0.75em;
  font-size: 16px;
  height: 2.75em;
  line-height: 2.75em;
}
