.modal-focus-container {
  .backdrop {
    background: rgba(230, 230, 230, 0.95) 50% 0% no-repeat;
    @include animation($name: fadeIn, $delay: 0s, $duration: 0.3s);
  }
  .modal {
    // background: rgba(230, 230, 230, 1) 50% 0% no-repeat;
    border-radius: 4px;
    margin-top: 50px;
    padding-top: 100px;
    @include animation($name: slideUpFadeIn, $delay: 0s, $duration: 0.5s);
    background: transparent;
    box-shadow: none;
    left: 0px;
    margin-left: 0;
    width: 100%;
    overflow: hidden;

    .icon {
      background-color: #fff;
      box-sizing: content-box;
      border-radius: 100px;
      padding: 3px;
      box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.2);
      transition: 0.2s;
    }

    header {
      width: 600px;
      margin: auto;

      h3 {
        .close-modal {
          border-radius: 50%;
          background-color: white;
          float: right;
          font-size: 1.5em;
          padding: 0;
          text-align: center;
          height: 1em;
          width: 1em;
          line-height: 0.75em;
          position: relative;
          top: -100px; // icon height above it
          box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
        }

        position: relative;
        font-weight: 300;
        border: none;
        @include animation($name: slideUpFadeIn, $delay: 0.2s, $duration: 0.5s);
        span {
          display: block;
          &.title {
            font-size: 1.3em;
            width: 600px;
            word-wrap: break-word;
            // padding-top: 1.5em;
            & > a {
              color: $blue;
            }
          }
          &.metric {
            font-size: 0.7em;
          }
        }
      }

      .icon {
        @include animation($name: slideUpFadeIn, $delay: 0s, $duration: 0.5s);
        width: 100px;
        height: 100px;
        position: absolute;
        left: calc(50% - 53px);
      }

    }

    nav {
      margin: 1em auto;
      width: 600px;
      @include animation($name: slideUpFadeIn, $delay: 1.8s, $duration: 0.5s);

      a {
        text-transform: uppercase;
        color: $dark-gray;
        display: inline-block;
        padding: 0.5em 1em;
        font-weight: 700;
        font-size: 0.8em;
        letter-spacing: 0.1em;
        border-radius: 4px;
        transition: 0.2s;
        background-color: transparent;
        &:hover, &.active {
          // background: linear-gradient(45deg, $coral-gradient);
          background-color: $pink;
          box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
          color: #fff;
        }
      }
    }

    .article-box {
      position: relative;
      @include animation($name: slideUpFadeIn, $delay: 0.5s, $duration: 0.5s);
      left: 0;
      // background-color: #f0f;
      width: 400%;
      transition: 0.3s;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      &.position-0 {
        left: 0;
        article:nth-child(2) {
          left: calc(-12.5% + 200px)
        }
      }
      &.position-1 {
        left: -100%;
        article:nth-child(1) {
          left: calc(12.5% - 200px)
        }
        article:nth-child(3) {
          left: calc(-12.5% + 200px)
        }
      }
      &.position-2 {
        left: -200%;
        article:nth-child(2) {
          left: calc(12.5% - 200px)
        }
        article:nth-child(4) {
          left: calc(-12.5% + 200px)
        }
      }
      &.position-3 {
        left: -300%;
        article:nth-child(3) {
          left: calc(12.5% - 200px)
        }
      }
    }

    article {
      background-color: #fff;
      box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
      border-radius: 4px;
      padding: 1em;
      margin: 0 auto 1em;
      width: 600px;
      max-height: 800px;
      overflow: auto;
      height: min-content;
      left: 0;
      position: relative;
      transition: 0.3s;
      // display: none;
      // position: absolute;
      // width: 100%;
      // top: 0;
      // opacity: 0;
      // @include animation($name: focusModalArticleOut, $duration: 0.5s);

      &.overview {
        overflow: hidden;
        p {
          position: relative;
          @include animation($name: slideUpFadeIn, $delay: 0.8s, $duration: 0.5s);
        }
      }
      &.examples {
        min-height: 400px;
      }

      &.active {
        // display: block;
        // @include animation($name: focusModalArticle, $delay: 0.25s, $duration: 0.5s);

        .cluster-occurrence-comparison {

          @for $i from 1 to 21 {
            li:nth-of-type(#{$i}) {
              @include animation($name: slideUpFadeIn, $delay: (1s + 0.1s * $i), $duration: 0.5s);
            }
          }
        }

      }

      &:not(.active) {
        cursor: pointer;
      }


      &.related {
        // background-color: transparent;
        // box-shadow: none;
        height: 800px;

        .icon-box {
          width: 100%;
          height: calc(800px - 3em);
            // width: 900px;
            // height: 900px;
          line {
            stroke: #222;
            transition: 0.2s;
          }

          @for $i from 1 to 21 {
            .node:nth-child(#{$i}) {
              transition-delay: 0.2s + 0.1s * $i;
            }
            line:nth-child(#{$i}) {
              transition-delay: 0.4s + 0.1s * $i;
            }
          }

          .node {
            transition: 0.4s;
            // transition-delay: 0.2s;
            cursor: pointer;
            opacity: 0;

            .text-background {
              // stroke: rgba(0,0,0,0.1);
              fill: rgba(230, 230, 230, 0.75);
            }
          }
          position: absolute;
          top: 0;
          left: 0;
        }

        &:not(.active) {
          .icon-box {
            line {
              opacity: 0;
            }
            .node {
              transform: translate(300px, 400px) !important;
            }
          }
        }
        &.active {
          .icon-box {
            line {
              opacity: 1;
            }
            .node {
              opacity: 1;
              transform: inherit;
            }
          }
        }

      }

      h4 {
        color: $menu-gray;
        font-size: 1.5em;
        font-weight: 900;
      }

      p {
        font-size: 1.3em;
        strong, em {
          word-wrap: break-word;
        }
      }

      .cluster-occurrence-comparison {

        li {
          position: relative;
          display: grid;
          grid-template-columns:
            10em
            calc(0.5em + 16px + 6px)
            1fr;
          align-items: center;
          padding-right: 0.5em;
          top: 40px;
          opacity: 0;
          // opacity: 0.3;
          &.active {
            // opacity: 1;
            background-color: #eee;
            // border: 1px solid #ccc;
            border-radius: 4px;
            padding: 0.5em 0.5em 0.5em 0;
            // margin: 0.5em 0;
          }
        }


        span.name {
          display: inline-block;
          width: 10em;
          text-overflow: ellipsis;
          overflow: hidden;
          text-align: right;
          white-space: nowrap;
        }
        .occurrence-bar {
          height: 4px;
          display: block;
          border-radius: 4px;
          position: relative;
          top: 0;
          // border-left: 0.25em solid #fff;;
          // border-right: 0.25em solid #fff;
        }
        .icon {
          width: 16px;
          height: 16px;
          position: relative;
          z-index: 2;
          margin-left: 0.5em;
        }
      }

      h3.timeline-title {
        font-weight: 400;
        font-size: 1em;
        position: relative;
        transition-delay: 0s;
        transition: 0.5s;
        top: 50px;
        opacity: 0;
        margin: 2em 0 -1em;
    }
      &.active {
        h3.timeline-title {
          top: 0px;
          opacity: 1;
          transition-delay: 2.5s;
        }
      }

      .spark-bars {
        bottom: -1em;
        top: inherit;
        width: calc(100% + 2em);
        left: -1em;
        .spark-bar {
          height: 0px;
        }
      }

      &:not(.active) {
        .spark-bars {
          .spark-bar {
            height: 0px !important;
          }
        }
      }

    }

    .ridge-lines {
      padding-bottom: 3rem;
      margin: 0;
    }
  }
}

