.report-builder .auto-suggest-input {
 .auto-suggest {
   display: flex;
   flex-direction: row;
   input {
    //  width: 90%;
   }
   > button {
     line-height: 2.5;
     position: relative;
     margin-top: 0px;
     right: 0px;
     margin-right: 0px;
     margin-left: 1em;
   }
   .results {
     position: absolute;
     margin-top: 0;
     left: 0;
     top: 45px;
     width: calc(100% - 50px - 1em);
      z-index: 15;
      li {
        // display: block;
        cursor: default;
        img {
          margin-right: 1em;
        }
        > span {
          width: 25%;
          display: inline-block;
        }
        button {
          cursor: pointer;
        }
      }
     .close {
       padding: 1em 0em;
       .glyph {
         color: red;
       }
     }
   }
 }
}
