.data-source-creator {
  .card {
    padding: 2rem;
  }
  .intro{
    margin-bottom: 2rem;
    label{
      .hovertip {
        float: none;
        display: inline-block;
        top: -18px;
        margin: 0;
      }
      .hovertip.hovered {
        z-index: 6;
      }
      input {
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
      }
    }
    div > button {
      margin-right: 0.75rem;
    }
  }
  .table {
    .affinio-table .ember-table {
      margin-bottom: 1.5rem;
    }
    .helper {
      margin-bottom: 1rem;
      [role="button"] {
        cursor: pointer;
      }
      span:not(:first-of-type):not(:nth-of-type(2)) {
        margin-left: 1rem;
      }
    }
  }
}
