.hover-tip-two {
  display: inline-block;
  margin-left: 0.25rem;

  > a {
    display: block;
    background: #eee;
    color: #222;
    font-weight: initial;
    border-radius: 50px;
    height: 20px;
    width: 20px;
    line-height: 20px;
    text-align: center;
    z-index: 2;
    border: 1px solid #fff;
    font-size: 0.75rem;
    box-sizing: content-box;
  }

  & + .ember-popover {
    padding: 0.75rem;
    z-index: 100;
    max-width: 20rem;
    background: #eee;
    word-break: break-word;
  }
}
