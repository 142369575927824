.comparison-bar {

  &:first-of-type {
    margin-top: 5px;
  }

  .comparison {
    height: 12px;
    background: #777777;
    margin: 2px 0 2px 0;

    &.no-comparsion {
      background: transparent;
      height: 24px;
      margin-bottom: -22px;
      margin-top: 0;
    }
  }

  .active-bar {
    transition: background-color .4s, width .4s !important;
    height: 24px;
    top: 12px;
    background: lightblue;
    z-index: 1;
    &.skeleton {
      width: 100% !important;
    }
  }

  .baseline {
    height: 12px;
    background: #222;
    margin-bottom: 10px;

    &.no-baseline {
      background: transparent;
      height: 24px;
      margin-bottom: -22px;
    }
  }

  .label {
    font-weight: 600;
    font-size: 0.8em;
    margin: 3px 0 0 4px;
    position: absolute;
    z-index: 2;
    background: rgba(255,255,255,1);
    padding: 0px 3px;

    span {
      font-size: 90%;
    }

    .comparison-label {
      color: #888;
    }

  } //.label
} //.comparison-bar

.no-baseline:not(.no-compare) {
  .comparison-bar {
    .comparison {
      margin-bottom: 10px;
    }
  }
}

.warning {
  margin: 10px 0;
  padding: 5px 10px;
  background-color: #FFC;
}
