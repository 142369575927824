.x-details {
  h3 {
    cursor: pointer;
  }

  .content {
    display: none;
    &.open {
      display: block;
    }
  }
}
