
  .cluster-panel {
    padding: 1em 1em 2em 1em;
    margin: -16px -16px 0px -16px;
    // box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
    -moz-transition: background-color 200ms !important;//sorry
    -webkit-transition: background-color 200ms !important;//sorry
    transition: background-color 200ms !important;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    input[type="text"] {
      padding: 4px;
      font-family: 'Roboto', sans-serif;
      font-size: 1.4em;
      font-weight: 300;
      box-sizing: border-box;
      margin-bottom: 0.5em;
      // &:hover, &:focus {
      //   color: #fff;
      //   border-bottom: 2px solid #eee;
      // }
      // &:disabled {
      //   background: none;
      //   opacity: 0.7;
      // }
    }
    h2 {
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
      color: white;
      font-size: 1.8em;
      font-weight: 300;
      margin-bottom: 0.5em;
    }
    p {
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
      color: white;
      font-weight: 300;
      margin-bottom: 2em;
      a {
        color: tint(#00A79B, 25%);
        &:hover {
          color: tint(#00A79B, 45%);
          // color: #00A79B;
        }
      }
    }
  }
