.ember-table{
  .joined-source {
    padding: 0 10px 0 10px;
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 14px;
    .info{
      display: flex;
      flex-direction: column;
      .label{
        width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .fa-fw{
        width: 0.6em;
        &:hover{
          cursor: pointer;
        }
      }
    }
    .actions{
      padding-left: 1em;
      display: flex;
      flex-direction: column;
    }
    .join-action{
      .fa-fw{
        width: 0.6em;
      }
      &:hover{
        cursor: pointer;
      }
    }
  }
}
