
/*==================================================================
=Print Styles
----------------------------------------------------------------- */

@media print {
  * {
    color: black !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
    // -webkit-print-color-adjust: exact;
    -webkit-transition: 0ms !important;
    transition: 0ms !important;
  }
  .modal-container {
    position: static !important;
  }
  .modal-container.shown .modal {
    margin-top: 0px;
  }
  // -webkit-print-color-adjust:exact;
  .notexisting {
    position: relative;
  }
  .close-modal, .print-summary, .print-button, .support-button {
    display: none !important;
  }
  #campaign-upper,
  .campaign-nav,
  .cluster-nav,
  .module .preferences,
  .ember-notify-cn,
  .module-sizer,
  .button.print,
  .module-grid,
  button.name-change-input {
    display: none !important;
  }

  .content-proper {
    width: 100% !important;
    left: 0 !important;
    margin: 0px !important;
    padding: 0px !important;
  }
  .module-grid {
    width: 100% !important;
    // height: 99% !important;
    // height:auto !important;
    // page-break-inside: always !important;
    .module {
      // background-color: $blue !important; // debug
      position:relative !important;
      left:auto !important;
      top:auto !important;
      float:none;
      display: inline-block !important;
      vertical-align: top;
      page-break-inside: avoid !important;
      box-shadow: none;
      // prevent blank last page of pdf
      &:first-of-type {
        page-break-before: avoid !important;
        margin-top: 0px !important;
        padding-top: 0px !important;
        // height: auto !important;
      }
      &:last-of-type {
        page-break-after: auto !important;
        margin-bottom: 0px !important;
        padding-bottom: 0px !important;
        // height: auto !important;
      }
    }
  }
  // prevent blank last page of pdf
  html, body {
    height: 99% !important;
  }
} // print
