.image-explorer {
  width: 100%;
  .header {
    display: flex;
    flex-direction: row;
    .metric-visualization {
      width: 100%;
      .bar {
        background: rgba(0,0,0,.08);
      }
    }
    .image-labels {
      width: 100%;
      display: inline-block;
      margin: 1em 0em;
      ul {
        display: flex;
        flex-wrap: wrap;
      }
      li {
        color: #666;
        font-size: 0.8em;
        text-shadow: 0px 0px 1px rgba(102, 102, 102, 0.4);
        font-weight: 300;
        background: lightgrey;
        padding: 6px 10px;
        border-radius: 10px;
        white-space: nowrap;
        margin: 10px 10px 10px 0px;
        i {
          // margin-right: 5px;
          width: 10px;
          font-size: 0.8em;
        }
      }
    } // image-labels
    .top-hashtags {
      margin-left: 2em;
      width: 30%;
      display: inline-block;
      max-width: 250px;
      li {
        font-size: 0.8em;
        padding: 5px 0px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.0980392);
        i {
          color: #666;
          font-size: 0.85em;
        }
      }
    }
  } // header
  .image-share {
    width: 32%;
    // float: right;
    position: absolute;
    top: 0px;
    right: 10px;
    .cluster-bar-graph {
      display: block !important;
      margin-bottom: 2em;
      // flex-basis: 50%;
      // float: right;
      a {
        padding: 0;
      }
      .lurker-average, .averageCategories, .showAverage {
        display: none;
      }
    }
  } // image-share
  .search-input {
    width: 60%;
  }
  .searched-labels {
    span {
      padding: 10px;
      background-color: #7e8890;
      color: #fff;
      margin-right: 10px;
      margin-top: 10px;
      display: inline-block;
      a {
        transition: 0.2s;
        color: #aaa;
        width: 25px;
        height: 25px;
        display: inline-block;
        text-align: center;
        margin-left: 10px;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
        &:hover {
          color: #aaa !important;
          background-color: #5f666c !important;
        }
      }
    }
  }
  .image-frame {
    // float: left;
    min-width: 150px;
    .frame {
      position: relative;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
      background-color: white;
      // -webkit-transform: translate3d(0, 0, 0);
      cursor: pointer;
      -webkit-transition:  opacity 0.2s 200ms;
      -moz-transition:  opacity 0.2s 200ms;
      -o-transition:  opacity 0.2s 200ms;
      transition:  opacity 0.2s 200ms;
      opacity: 0;
      // padding: 10px 10px 18px 10px;
      padding: 10px;

      &.done {
        opacity: 1;
      }
    } // frame
    .matte, >a {
      display: block;
      overflow: hidden;
      position: relative;
    }
    .matte {
      max-height: 350px;
    }
    .metric-visualization {
      height: 30px;
      width: calc(100% - 20px);
      position: absolute;
      bottom: 10px;
      overflow: hidden;
      .metric-instance {
        height: 30px;
        label {
          opacity: 1;
          bottom: -12px;
        }
      }
    }
    &.hovery:not(.immune) {
      // opacity: 0.65;
      .frame{
        background-color: transparentize(#fff, 0.8);
      }
      // box-shadow: 0 0 3px rgba(0,0,0,0);
      img{
        // filter: brightness(35.5%);
        // -webkit-filter: brightness(35.5%); /* Chrome, Safari, Opera */
        // -webkit-transform: scale(1.325);
        filter: brightness(35%);
        -webkit-filter: brightness(35%); /* Chrome, Safari, Opera */
        -webkit-transform: scale(1.125);
      }
      .info {
        opacity: 0.3;
      }
      .labels, .labels li {
        opacity: 0.3 !important;
      }
      .bar-graph {
        // opacity: 0.3;
      }
    }
    &:hover {
      // z-index: 2;
      // box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
      .source-tweet {
        // left: 5px;
        transform: translateX(150px);
        background-color: #fff;
      }
      img {
        filter: brightness(100%);
        -webkit-filter: brightness(100%);
        -webkit-transform: scale(1);
      }
      .info{
        color: #666 !important;
      }
      .labels {
        color: #333 !important;
      }
      .metric-instance {
        // height: 10px !important;
        // height: 100% !important;
        transform: translateY(-20px);
      }
    }
    img {
      display: block;
      width: 100%;
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
      opacity: 1;
      max-width: 200%;
      position: relative;
      // padding-bottom: auto;
      // top: 0px;
      // left: 0px;
      // -moz-transition: filter .8s .4s;
      // -o-transition: filter .8s .4s;
      // transition: filter .8s .4s;
      // -webkit-transition: -webkit-filter .8s .4s;
      filter: brightness(90%);
      -webkit-filter: brightness(90%); /* Chrome, Safari, Opera */

      -moz-transition: transform .8s .4s, filter .8s .4s;
      -o-transition: transform .8s .4s, filter .8s .4s;
      transition: transform .8s .4s, filter .8s .4s;
      -webkit-transition: -webkit-transform .8s .4s, -webkit-filter .8s .4s;
      // -webkit-transition: width 0s;
      // -moz-transition: width 0s;
      // -o-transition: width 0s;
      // transition: width 0s;
    } // img
    .info {
      -webkit-transition: opacity .8s;
      -moz-transition: opacity .8s;
      -o-transition: opacity .8s;
      transition: opacity .8s;
      color: #999;
      font-size: 0.8em;
      // left: 0;
      // text-transform: uppercase;
      // margin-top: 15px;
      // position: absolute;
      // right: 0;
      // padding: 2px 5px;
      white-space: nowrap;
      // bottom: -8px;
      font-weight: bold;
      opacity: 0;
      &.done {
        opacity: 1;
      }
    } // info
    .bar-graph {
      // -webkit-transition: opacity 0.8s;
      // -moz-transition: opacity 0.8s;
      // -o-transition: opacity 0.8s;
      // transition: opacity 0.8s;

      // -webkit-transform: translate3d(0, 0, 0);
      position: absolute;
      bottom: 10px;
      margin: 0;
      // margin-top: -17px;
      padding: 0;
      width: calc(100% - 20px);
      left: 10px;
      height: 30px;
      overflow: hidden;
    } // bar graph
    .graphed-bar {
      height: 100%;
    }
    .bar.uncluster {
      -moz-transition: none !important;
      -webkit-transition: none !important;
      transition: none !important;
      width: 100% !important;
      clear: both;
      margin: 0px;
      height: 100%;
      background: none;
      padding: 0;
    }
    .bar label {
      position: absolute !important;
      color: white;
      text-shadow: 0px 1px 2px rgba(0,0,0, 0.4);
      // visibility: hidden;
      bottom: -22px;
      opacity: 0;
      margin-top: 8px;
      -moz-transition: transform .2s .2s;
      -webkit-transition: transform .2s .2s;
      transition: transform .2s .2s;
      em {
        display: none;
      }
    } // bar label
    .cluster-stats {
      -moz-transition: none !important;
      -webkit-transition: none !important;
      transition: none !important;
      -moz-transition: transform .4s !important;
      -webkit-transition: transform .4s !important;
      transition: transform .4s !important;
      // -webkit-transition: height 0.4s 0.8s !important;
      // -moz-transition: height 0.4s 0.8s !important;
      // -o-transition: height 0.4s 0.8s !important;
      // transition: height 0.4s 0.8s !important;
      // -webkit-transition-delay: height 8s !important;
      // -moz-transition-delay: height 8s !important;
      // -o-transition-delay: height 8s !important;
      // transition-delay: height 8s !important;//lol wtf
      // height: 100% !important;
      // top: 0;
      // height: 30px !important;
      bottom: -20px !important;
      height: 100% !important;
      span {
        -moz-transition: none !important;
        -webkit-transition: none !important;
        transition: none !important;
        height: 100% !important;
      }
    } // cluster-stats
    .labels {
      -webkit-transition: opacity .8s, color .4s;
      -moz-transition: opacity .8s, color .4s;
      -o-transition: opacity .8s, color .4s;
      transition: opacity .8s, color .4s;
      color: #666;
      cursor: pointer;
      // left: 0;
      // text-transform: uppercase;
      // position: absolute;
      // padding: 2px 5px;
      white-space: nowrap;
      // bottom: -8px;
      font-weight: 300;

      ul {
        // margin: auto 10px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        li {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 1em;
          opacity: 0 ;
          text-shadow: 0px 0px 1px transparentize(#666, 0.6);
          // margin: 5px;
          -webkit-transition: 0.2s 300ms;
          -moz-transition: 0.2s 300ms;
          -o-transition: 0.2s 300ms;
          transition: 0.2s 300ms;
          -webkit-transition: opacity 0.2s 800ms;
          -moz-transition: opacity 0.2s 800ms;
          -o-transition: opacity 0.2s 800ms;
          transition: opacity 0.2s 800ms;
        }

        li:first-of-type{
          // margin-left: 0;
        }
        li:after {
            margin-left: -3px;
            margin-right: 5px;
            content: ','
        }

        li:last-of-type:after {
            content:none;
        }
      }
      &.done {
        ul li {
          opacity: 1;
        }
      }
    } // labels
    .source-tweet {
      z-index: 6;
      -webkit-transition: .2s ease-in;
      -moz-transition: .2s ease-in;
      -o-transition: .2s ease-in;
      transition: .2s ease-in;
      position: absolute;
      top: 5px;

      background-color: shade(#fff, 5%);

      // padding: 5px 10px;
      display: flex;
      align-items: center;
      // box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
      font-size: 0.8em;
      &:hover,
      &:focus {
        // background-color: #fff;
      }


      span {
        margin: 5px;
        padding-right: 5px;
      }
    } // source-tweet
    .source-tweet {
      left: -145px;
      i {
        margin: 5px;
        color: #1da1f2;
        font-size: 1.5em;
      }
    } // source tweet
  } // image-frame
  .backbutton {
    transition: 200ms;
    background-color: rgba(0,0,0,0);
    color: $dark-gray;
    padding-right: 30px;
    margin-bottom: 1em;
    &:hover {
      background-color: $dark-gray;
      color: #fff;

    }
    // display: inline-block;
    // float: left;
  }
  .image-explorer-no-data {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    margin-top: 50px;
    padding: 25px;
  }
}
