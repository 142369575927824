/*==================================================================
=Folders
----------------------------------------------------------------- */

.campaign-folder.New.Folder {
  display: none;
  background-color: #00A79B;
  color: #fff;
}

.campaign-folder.Remove.From.Folder {
  display: none;
  background-color: #fee;
  color: rgba(200,0,0,0.5);
}

.campaign-folder header {
  border-bottom: 1px solid #ccc;
  padding: 10px 0px;
  cursor: pointer;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.campaign-folder .dragged-over header {
  background-color: #eee;
  padding: 20px 20px 20px 10px;
  border: 1px solid #00A79B;
}

.campaign-folder.New.Folder .dragged-over header {
  background-color: #00A79B;
  color: #fff;
  border: 1px solid #00A79B;
}

.campaign-folder.Remove.From.Folder .dragged-over header {
  background-color: #fee;
  color: rgba(200,0,0,0.5);
  border: 1px solid #f00;
}

.campaign-folder header span.folder-icon,
.campaign-folder header span.back-icon {
  width: 20px;
  height: 20px;
  display: block;
  float: left;
  line-height: 15px;
  margin-right: 13px;
  margin-left: 10px;
}

.campaign-folder header span.folder-icon img,
.campaign-folder header span.back-icon img {
  width: 20px;
}

.campaign-folder.expanded header span.folder-icon,
.campaign-folder header span.back-icon {
  display: none;
}

.campaign-folder.expanded header span.back-icon {
  display: block;
}

.campaign-folder h3 {
  float: left;
  margin-bottom: 10px;
}

.campaign-folder small {
  float: left;
  line-height: 25px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 0.7em;
  font-style: italic;
  opacity: .5;
}

.campaign-folder.New.Folder small,
.campaign-folder.New.Folder a.edit-folder-name,
.campaign-folder.New.Folder a.share-folder-button,
.campaign-folder.Remove.From.Folder small,
.campaign-folder.Remove.From.Folder a.edit-folder-name,
.campaign-folder.Remove.From.Folder a.share-folder-button {
  display: none;
}

.campaign-folder:not(.Unsorted) .table-holster {
  display: none;
}

.campaign-folder.Unsorted header {
  display: none !important;
}

.campaign-folder table.campaigns-table tr,
.campaign-folder table.campaigns-table td {
  position: relative;
}

.campaign-folder table.campaigns-table tr.pending td {
  opacity: 0.5;
}

.campaign-folder table.campaigns-table td:first-of-type {
  padding-left: 25px;
}

.campaign-folder table.campaigns-table td:first-of-type img {
  margin-right: 10px;
}

.campaign-folder table.campaigns-table tr:hover td {
  background-color: #eee;
  cursor: move;
}

.campaign-folder.Unsorted table.campaigns-table td:first-of-type {
  padding-left: 10px;
}

.modal input.new-folder-name {
  margin-top: 10px;
  padding: 4px;
  width: 100%;
  border: 2px solid #ccc;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#campaign-nav p.folder-intro {
  color: rgba(255,255,255,0.7);
  padding: 20px;
}

.campaign-folder header strong {
  float: left;
}

a.edit-folder-name {
  display: block;
  float: left;
  margin-left: 0px;
  width: 24px;
  height: 24px;
  line-height: 20px;
  text-align: center;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
}

a.edit-folder-name img {
  width: 12px;
  opacity: 0.4;
}

a.edit-folder-name:hover {
  background-color: #ccc;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

a.edit-folder-name:hover img {
  opacity: 1;
}

a.share-folder-button {
  float: right;
}

a.share-folder-button:hover {
  background-color: #00A79B;
  color: #fff;
}

.modal .campaign-checkboxes {
  max-height: 400px;
  overflow: auto;
  background-color: rgba(0,0,0,0.05);
  border-top: 1px solid rgba(0,0,0,0.2);
  border-bottom: 1px solid rgba(0,0,0,0.2);
  padding: 0px;
  margin: 10px -20px 0px -20px;
}

.modal .campaign-checkboxes label.checkboxbox {
  display: block;
  border-bottom: 1px solid #bbb;
  margin: 0px;
  padding: 10px 20px;
  font-size: .9em;
  border-top: 1px solid #fff;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.modal .campaign-checkboxes label.checkboxbox input {
  margin-right: 10px;
}

.modal .campaign-checkboxes label.checkboxbox.prompted {
  background-color: rgba(0,0,0,0.8);
  border-top: 1px solid #222;
  color: #fff;
}
