input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  margin: 12px 0;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: #00A79B;
  border-radius: 1.3px;
}
input[type=range]::-webkit-slider-thumb {
  border: 2px solid #00A79B;
  height: 18px;
  width: 18px;
  border-radius: 15px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8.5px;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: #00A79B;
  border-radius: 1.3px;
}
input[type=range]::-moz-range-thumb {
  border: 2px solid #00A79B;
  height: 18px;
  width: 18px;
  border-radius: 15px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8.5px;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: transparent;

  border-width: 8px 0;
  border-color: transparent;
  color: transparent;

}
input[type=range]::-ms-fill-lower {
  background: #00A79B;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-fill-upper {
  background: #00A79B;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
  border: 2px solid #00A79B;
  height: 18px;
  width: 18px;
  border-radius: 15px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -2px;
}
input[type=range]:focus::-ms-fill-lower {
  background: rgba(48, 113, 169, 0.78);
}
input[type=range]:focus::-ms-fill-upper {
  background: rgba(54, 126, 189, 0.78);
}
