.folder-list {
  .folder-description {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .folder-list-item {
    position: relative;
    .button {
      font-weight: 300;
      font-size: 0.8em;
    }

    &:hover {
      nav {
        display: block;
        // animation: fadeIn 0.4s ease-in;
      }
      .folder-description {
        width: calc(100% - 6em);
      }
    }
  }
  nav {
    display: none;
    height: 2.5em;
    position: absolute;
    right: 0;
    top: 0;
    // bottom: 0;

    & > button {
      float: left;
    }
    button, .button{
      height: 100%;
      width: 2.5em;
      padding: 0;
      font-size: 1.125em;
      background-color: transparent;
      text-align: center;
      font-weight: 900;
      &:hover {
        background-color: rgba(0,0,0,0.2);
        color: #555;
      }
      &:focus {
        background-color: shade($action-color, 20%);
        color: #fff;
      }
      .fa {
        width: initial;
      }
    }
  }
}