// TODO actually deconstruct this mess and have simpler div structure - js

.interests-vis {
  width: 99%;

  header {
    &:not(.Interest):not(.Categories) h4 {
      font-size: 1.8em;
      font-weight: 300;
      margin-bottom: 0.5em;
      color: black;
      padding-bottom: 10px;
      border-bottom: solid 1px #eee;
      float: left;
      width: 100%;

      .hovertip {
        left: 7rem !important;
      }
    }

    &.Interest.Categories {
      border-bottom: 1px solid #eee;
      padding: 0.5em 0em;
      margin: 0 0em 1em 0em;
  
      h4 {
        float: left;
        font-size: 1.3em;
        line-height: 1em;
        margin-bottom: 0;
        padding-bottom: 0.6em;
        color: #444;
        max-width: calc(100% - 8rem);
      }
    }
  }

  .asset-row:not(.tribe-names):first-of-type {
    .profile-cover {
      border-left: 3px solid $dark-gray;
    }
  }

  .asset-row.tribe-names .asset-tribes {
    .score-cell:first-of-type {
      height: 80px !important;
    }
  }

  .interests-message {
    font-size: 0.8em;
    text-align: center;
  }

  .scrollable {
    .interests-vis-main.list.snapshot {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }

    max-height: 1000px;
    overflow: scroll;
    overflow-x: hidden;
  }

  .list {
    .profile-cover {
      background-color: $dark-gray;

      width: 80px;
      height: 80px;
      border-right: 3px solid $dark-gray;
      border-left: 3px solid $dark-gray;
      border-top: 3px solid $dark-gray;
      border-bottom: 3px solid $dark-gray;
    }

    .profile-card {
      .baseline-diff-explainer {
        font-weight: 400;
        display: block;
        margin: -10px -10px -90px -10px;
        height: 95px;
        padding: 10px;
        color: #fff;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
        padding-left: 90px;
        width: calc(100% + 20px);

        &.greater {
          background-color: $baseline-greater;
        }

        &.lesser {
          background-color: $baseline-lesser;
        }

        &.not-found-in-baseline {
          background: $medium-gray;
        }

        em {
          display: inline;
        }
      }
    }
  }

  .grid {
    .profile-card {
      &.cover {
        .pumpup {
          padding-bottom: 0em;
        }
      }

      .profile-cover {
        background-color: $dark-gray;
        border-right: 3px solid $dark-gray;
        border-top: 3px solid $dark-gray;
        border-bottom: 3px solid $dark-gray;
        border-left: 3px solid $dark-gray;

        &.length-2 {
          img:nth-of-type(2) {
            align-self: flex-end;
            margin-left: 50%;
          }
        }
      }

      .baseline-diff-explainer {
        display: none;
      }
    }
  }

  .heatmap {
    .profile-cover {
      background-color: $dark-gray;

      width: 80px;
      height: 80px;
      border-right: 3px solid $dark-gray;
      border-top: 3px solid $dark-gray;
      border-bottom: 3px solid $dark-gray;
    }
  }

  .filter-display {
    margin-bottom: 8px;
  }

  .preferences {
    &:not(.Interest):not(.Categories) {
      float: none;
      position: relative;
      display: inline-flex;
      // float: right; from style.scss .vis-options { float: right; } but
      padding: 10px 0;
      font-size: 0.7em;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 0.05em;

      .select-dropdown {
        padding-right: 25px;
        font-size: 1em;
        width: auto;
      }
    }

    &.Interests .instance-search-wrapper {
      display: none;
    }

    &.Interest.Categories {
      padding: 0px;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 0.05em;
      position: relative;
      width: 100%;
      clear: both;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  
      .select-dropdown {
        float: right;
        padding-right: 30px;
        position: absolute;
        width: auto;
        margin-left: 10px;
        top: -44px;
        right: 0px;
        max-width: 8rem;
      }

      .instance-search-wrapper {
        margin-right: 0;
        margin-left: 0;

        &.open {
          height: 78px;


          .option-set {
            border-bottom: unset;
          }
        }

        .metric-saturation-slider {
          display: none;
        }
      }
    }

    > :nth-child(n + 2) {
      margin-left: 1em;
    }

    .preference-label + :nth-child(n + 2) {
      margin-left: 0.5em;
    }

    > section.interests-search-bar {
      input {
        font-size: 1.6em;
        margin-bottom: 0;
        width: 120px;
      }
    }

    > section.cluster-selector,
    section.tag-filter {
      position: relative;
      float: left;
      margin-top: 10px;
      //z-index: 4; // should probably remove this not sure why this was there
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      .checkbox-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 10px;

        & > div.checkbox {
          width: auto;
          label {
            display: inline-block;
            vertical-align: middle;
            font-weight: normal;
            text-transform: none;
          }
          input[type="checkbox"] {
            vertical-align: middle;
          }
        }

        & > div.brand-selector {
          span {
            text-transform: none;
            color: $blue;
          }
          span:hover{
            cursor: pointer;
          }
        }
      }



      & h6 {
        text-transform: none;
        padding-top: 8px;
      }

      & button {
        text-transform: capitalize;
        font-size: 1.2em;
        vertical-align: middle;
        //&:nth-child(1) {
        //  padding: 3px 15px;
        //  margin-left: 0;
        //  margin-right: 0;
        //  border-top-right-radius: 0px;
        //  border-bottom-right-radius: 0px;
        //}
        //&:nth-child(2) {
        //  float: right;
        //  border-left: 2px solid #fff;
        //  margin-left: 0px;
        //  color: #222;
        //  font-weight: bold;
        //  background: #eee;
        //  position: relative;
        //  z-index: 3;
        //  padding: 3px 5px;
        //  border-top-left-radius: 0px;
        //  border-bottom-left-radius: 0px;
        //  &::after {
        //    content: '\2193';
        //  }
        //  &.combo-box-open::after {
        //    content: '\2191'
        //  }
        //}
      }

      // putting this last to overwrite styles when open
      //&.open > button {
      //  &:nth-child(2) {
      //    border-left-color: #eee;
      //  }
      //
      //  border-bottom-left-radius: 0px;
      //  border-bottom-right-radius: 0px;
      //  padding-bottom: 10px;
      //  margin-bottom: -7px;
      //  color: #00A89C;
      //}
    }

    .select-inline {
      a {
        font-size: 1em;
        // padding: 0px 10px; not sure why was there before
        border-left: 1px solid #ccc;

        &:first-of-type {
          border-left: none;
        }
      }
    }

    span {
      display: inline;
      // float: left;
      // padding: 0 0 0 5px;
      //color: #222;
      // margin-left: 20px;
      white-space: nowrap;
    }
  }

  .cluster-modal {
    margin-top: 25px;
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1004; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

    &.is-hide {
      display: none;
    }

    &.is-shown {
      display: block;
    }
  }

  .cluster-modal-content {
    background-color: #fefefe;
    margin: 10% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    min-width: 30%; /* Could be more or less, depending on screen size */
    max-width: 420px; /* Could be more or less, depending on screen size */

    & > .modal-title {
      position: relative;
      font-size: 1.5em;
      color: $dark-gray;
      text-transform: none;
      padding: 5px 0;

      a > i {
        position: absolute;
        right: 0;
        top: 0;
        color: $medium-gray;
      }
    }

    span {
      text-transform: none;
    }

    & > .input-container {
      display: block;

      & input:checked ~ {
        background-color: $action-color;
      }

      & .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
      }

      & span {
        color: $medium-gray;
      }
    }
  }

  .content {
    width: 100%;

    &.benchmarks {
      max-width: 80%;
    }
  }

  &.top-interests-module {
    h4 {
      width: auto;
    }

    .hovertip {
      position: relative;
      float: left;
      z-index: 5;
      margin-left: 5px;
      top: -3px;
      text-align: left;
      left: 0;
      width: 0;
    }
  }

  .hovertip {
    float: none;
    top: 8px;
    left: 0;
    width: 5%;

    > span {
      width: 500px;
      left: -250px;
    }
  }

  &.is-benchmarks {
    .hovertip {
      left: 17rem;
    }
  }

  &.is-location {
    .hovertip {
      left: 15rem;
    }
  }

  .influencers.chart {
    margin: 10px 0px;
    background-color: #fff;
    position: relative;
    clear: right;
    float: right;
    width: 100%;
  }

  .assets-grid {
    overflow: auto;
    clear: right;
    // padding-bottom: 300px;
    .influencers.chart {
      display: flex;
      // align-items: center;
      float: left;
      width: 99%;
    }
  }

  .page-title {
    border-bottom: solid 1px #eee;

    > label {
      float: right;
    }
  }

  .wrapper {
    display: flex;
  }

  .content {
    width: 100%;
    // max-width: 80%;
  }

  .influencers-sidebar {
    h4 {
      border-bottom: solid 1px #eee;
      padding-bottom: 5px;
    }

    height: calc(100% - 50px);
    position: relative;
    width: 200px;
    background: #fff;
    margin-top: 20px;
    margin-right: 30px;
    padding: 0px;
    padding-top: 0px;
    box-sizing: border-box;
    z-index: 4;
  }

  .interests-vis-main {
    display: grid;
    grid-template-columns: repeat(20, 1fr);

    &.list.profile-card.initials {
      padding-bottom: 10px;
    }

    // TODO: several socialFlow hacks
    &.list.snapshot {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-gap: 0 1em;

      .profile-card {
        padding-bottom: 40px;

        .metric-visualization {
          position: absolute;
          bottom: 0.5em;
          left: 0.5em;
          width: calc(100% - 1em);
        }

        .full.no-banner {
          margin-top: 100px;
        }

        .member-image {
          position: absolute;
          z-index: 2;
          top: 1em;
          left: 1em;
          max-width: 72px;
          max-height: 72px;
          border: 0.25em solid #fff;
        }
      }
    }

    .profile-card {
      .member-image {
        img {
          width: 100%;
          height: 100%;
          max-height: 3rem;
          object-fit: contain;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .profile-cover {
      // height: 5%;
    }

    .heatmap-gallery {
      display: flex;
      max-width: 100%;
      overflow-x: auto;
      overflow-y: visible;
    }

    .averages {
      display: flex;
      flex-direction: column;
      margin-left: 1em;
      margin-right: 1em;
      padding-left: 1em;
      border-left: solid 1px #eee;

      h4 {
        padding: 5px;
        // margin-bottom: 18px;
        // height: 80px !important;
        margin-bottom: 0em;
        margin-top: 50px;
      }

      > span {
        border-top: solid 1px #eee;
        padding: 5px;
        height: 80px !important;
        width: 80px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .secondary {
          font-size: 0.8em;
          opacity: 0.5;
          font-weight: 400;
        }

        .type-info {
          opacity: 0.5;
          font-size: 70%;
        }
      }

      .hidden-from-heat-map {
        display: none;
      }
    }

    .legend {
      display: flex;
      flex-direction: column;
      margin-left: 3em;
      align-items: center;

      span {
        margin: 10px 0px;
        color: #999;
      }

      .range {
        width: 10px;
        height: 250px;
        @include linear-gradient(0deg, $blue-white-gradient);
      }
    }
  }

  &.heatmap {
    .interests-vis-main {
      grid-template-columns: 1fr;

      .profile-card {
        .member-image {
          img {
            max-height: unset;
          }
        }
      }
    }
  }

  .metric-visualization {
    width: 100%;
    margin-top: 5px;
  }
}

// compact view styles
.interests-vis.compact {
  header h4 {
    border: none;
    font-weight: normal;
  }

  .preferences {
    label {
      span {
        display: inline;
        color: #666;
      }

      float: right;
      padding-right: 30px;
      position: absolute;
      width: auto;
      margin-left: 10px;
      top: -30px;
      right: 0px;
    }

    float: left;
    padding: 0;
    text-transform: none;
    font-size: 1em;
    letter-spacing: 0;
    font-weight: normal;
  }
}

// .grid .vis-options nav a.grid-toggle,
// .list .vis-options nav a.list-toggle,
// .heatmap .vis-options nav a.heatmap-toggle {
//   color: $teal;
// }
