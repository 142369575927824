.tabbed-interface {
  $dark-tab: shade($audience-summary-gray, 10%);
  .tabs {
    display: flex;
    overflow-y: auto;
    border-bottom: 1px solid rgba(0,0,0,0.25);
    margin-bottom: 2rem;

    .tab {
      cursor: pointer;
      padding: 10px 20px;

      &:hover {
        color: $blue;
      }

      &.active {
        border-bottom: 3px solid $blue;
        color: $blue;
      }

      .remove {
        margin-left: 1rem;
        padding: 0.2rem;
      }

      &:last-of-type {
        border-right: none;
      }
    }
  }

  .content {
    min-height: 500px;
    background: $audience-summary-gray;
  }
}
