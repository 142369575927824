.report-builder {
  display: flex;
  margin-top: 7em;
  // margin-left: 200px;
  .sub-nav {
    z-index: 999;
    background-color: #fff;
    padding: 0px 30px;
    height: 44px;
    position: fixed;
    top: 50px;
    left: 0px;
    width: 100%;
    box-sizing: content-box;
    -webkit-box-shadow: 3px 0px 3px 0px rgba(0,0,0,.2);
    -moz-box-shadow: 3px 0px 3px 0px rgba(0,0,0,.2);
    box-shadow: 3px 0px 3px 0px rgba(0,0,0,.2);
    > a:first-of-type {
      // margin-left: 210px;
    }
    a {
      line-height: 44px;
      font-size: 0.7em;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 0.05em;
      display: inline-block;
      color: #666;
      outline: none;
      white-space: nowrap;
      padding: 0em 1em;
      // margin-right: 1em;
    }
    .active {
      background-color: $blue;
      color: #fff;
    }
    .disabled {
      opacity: 0.5;
    }
  }
  .report-builder-section {
    width: 75%;
    &.recycle {
      // width: 100%;
    }
    &.editing-data-source {
      width: 100%;
    }
    padding-right: 1em;
    .stage {
      padding: 1em;
      background-color: #fff;
      box-shadow: 0 1px 3px rgba(0,0,0,.25);
      border-radius: 4px;
    }
    .network-selector {
      h1 {
        font-size: 1.3em;
        margin-bottom: 0.5em;
      }
      .networks{
        display: flex;
        flex-wrap: wrap;
        .network-group{
          margin-right: 1em;
          position: relative;
          button {
            color: #fff;
            margin-bottom: 0.6em;
            float: left;
            &.active {
              color: #fff;
            }
            font-size: 1em;
            font-weight: 400;
            transition: 0.25s;
            display: flex;
            align-items: center;
          }
          .network-label {
            // border-top-right-radius: 0px;
            // border-bottom-right-radius: 0px;
            background-color: tint(#333333, 45%);
            text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
            &:hover{
              background-color: #333333;
            }
            svg{
              position: relative;
              top: 0.1em;
              width: 1.5em;
              height: 1.0em;
              margin-right: 0.3em;

              &.x-twitter-icon {
                height: 1.5em;
                width: 1.4em;
                fill: white;
                margin-top: -3px;
                margin-right: 10px;
                filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.4));
              }
            }
            i {
              font-size: 1.50em;
              margin-right: 0.3em;
              vertical-align: sub;
            }
          }
          .network-label.active{
            background-color: #333333;
          }
          .network-label.has-actions{
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
          }
          .network-actions{
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            background-color: tint(#333333, 45%);
            &:hover{
              background-color: #333333;
            }
          }
          .network-actions.active{
            background-color: #333333;
          }
          .source-actions{
            visibility: hidden;
            color: #333333;
            font-size: 14px;
            position: absolute;
            bottom: -32px;
            right: -20px;
            background-color:#fff;
            box-shadow: 0px 0px 3px rgba(0,0,0,0.2);
            border-radius: 4px;
            z-index: 2;
            span{
              padding: 5px 10px;
              display: block;
              &:hover{
                cursor: pointer;
              }
            }
            span.delete{
              // border-bottom: 1px solid rgba(0,0,0,0.1);
              color: lighten(#E53935, 10);
              &:hover{
                color:#E53935;
              }
            }
            span.edit{
              color: lighten(#333333, 10);
              &:hover{
                color: #333333;
              }
            }
          }
          .source-actions.can-edit{
            bottom: -63px;
          }
          .source-actions:before{
            content: "";
            position: absolute;
            top: -10px;
            right: 30px;
            bottom: auto;
            left: auto;
            border-style: solid;
            border-color: rgba(0,0,0,0.2) transparent;
            border-width: 0 10px 10px;
            width: 0;
            display: block;
          }
          .source-actions:after{
            content: "";
            position: absolute;
            top: -9px;
            right: 31px;
            bottom: auto;
            left: auto;
            border-style: solid;
            border-color: #fff transparent;
            border-width: 0 9px 9px;
            width: 0;
            display: block;
          }
          .source-actions.visible.one-item {
            @include animation($name: slideDownFadeInSourceActions, $duration: .25s);
            visibility: visible;
          }
          .source-actions.visible.two-item {
            @include animation($name: slideDownFadeInSourceActionsTwoItems, $duration: .25s);
            visibility: visible;
          }
          .source-actions.visible.three-item {
            @include animation($name: slideDownFadeInSourceActionsThreeItems, $duration: .25s);
            visibility: visible;
          }
          .twitter{
            background-color: tint($dark-gray, 45%);
            &:hover{
              background-color: $dark-gray;
            }
          }
          .twitter.active{
            background-color: $dark-gray;
          }
          .recycle{
            background-color: tint(green, 45%);
            &:hover{
              background-color: green;
            }
          }
          .recycle.active{
            background-color: green;
          }

        }
      }
      .add-custom-source-btn{
        color: #fff;
        padding-right: 1em;
        margin-bottom: 0.6em;
        background-color: $darkish-gray;
        &:hover {
          background-color: $dark-gray;
        }
        &.active {
          color: #fff;
        }
      }
      .twitter{
        background-color: tint($dark-gray, 45%);
        &:hover{
          background-color: $dark-gray;
        }
      }
      .twitter.active{
        background-color: $dark-gray;
      }
      .pinterest{
        background-color: tint(#cb2017, 45%);
        &:hover{
          background-color: #cb2017;
        }
      }
      .pinterest.active{
        background-color: #cb2017;
      }
      .youtube{
        background-color: tint(#ff0000, 45%);
        &:hover{
          background-color: #ff0000;
        }
      }
      .youtube.active{
        background-color: #ff0000;
      }
      .recycle{
        background-color: tint(green, 45%);
        &:hover{
          background-color: green;
        }
      }
      .recycle.active{
        background-color: green;
      }
      .first-party{
        background-color: tint(#333333, 45%);
        &:hover{
          background-color: #333333;
        }
      }
      .available-sources{
        margin-bottom: 1em;
      }
    }
    .report-type-selector {
      margin-bottom: 0.5em;
      h1 {
        font-size: 1.3em;
        margin-bottom: 0.5em;
      }
      ul{
        li.report-type {
          cursor: pointer;
          display: inline-block;
          background-color: #eee;
          border-radius: 4px;
          padding: 0.5em;
          margin: 0 0.5em 0.5em 0;
          transition: 0.25s;
          .report-type-label{
            color: #888;
            font-size: 0.8em;
            display: block;
          }
          .natural-language-label{
            transition: 0.25s;
          }

          &:hover {
            background-color: #ddd;
          }
        }
      }
    }
    .report-stage-section{
      &.stage {
        label.pill-container {
          display: block;
          position: relative;
          top: -1.75em;
          cursor: pointer;
          display: inline-block;
          border-radius: 5px;
          background-color: #fff;
          padding: 0.25em 0.5em;
          font-size: 0.8em;
          transition: 0.2s;
          border: 0px solid #eee;
          box-shadow: 0px 0px 2px rgba(0,0,0,0.25);
          &:hover, &.checked {
            background-color: #eee;;
            border-left: 5px solid $red;
            // box-shadow: 0px 0px 0px rgba(0,0,0,0.15);
          }
          &.new-followers {
            &.checked {
              .checkbox {
                line-height: 2.25rem;
              }
            }
            .new-follower-date {
              display: inline-block;
              max-width: 12rem;
              margin-bottom: 0;
              // Not a big fan of this, but :first-of-type doesn't work on classes
              &.first {
                margin-left: 0.5rem;
              }
            }
          }
        }

        h2 {
          font-size: 1.3em;
          margin-bottom: 0.5em;
          font-weight: 400;
        }
        @for $i from 1 to 100 {
          .module:nth-of-type(#{$i}) {
            z-index: #{$i};
          }
        }
        .module {
          margin-bottom: 1.75em;
          .module-header{
            display: flex;
            flex-direction: row;
            width: 100%;
            flex-basis: 100%;
            justify-content: space-between;
            .hovertip{
              position: relative;
              margin-left: 5px;
              float: right;
              transition: opacity 200ms;
              z-index: 1;
              > a {
                background-color: #fff;
                border-color: #eee;
                width: 38px;
                position: relative;
              }
            }
            .hovered{
              z-index: 2;
            }
          }
          .module-container{
            flex-direction: row;
            display: flex;
            button.primary{
              margin-left: 1em;
            }
          }
          input {
            // literally just used to clear styles that shouldnt exist for now
            margin-bottom: 0; // TODO we shouldnt have to clear things, there should just be less global CSS, this instance is in input.scss
          }
          > * {
            margin-bottom: 1em;
          }
        } // module
        .sql-legend {
          h3 {
            font-size: 1rem;
          }
          thead {
            tr {
              border-bottom: 1px solid rgba(0,0,0,0.25);
              th {
                font-weight: 600;
                &:nth-of-type(2) {
                  padding-left: 1.5rem;
                }
              }
            }
          }
          tbody {
            td:nth-of-type(2) {
              padding-left: 1.5rem;
            }
          }
        }
      } // &.stage
    } // report-stage-section
    .description{
      margin-top: 1em;
      display: block;
    }
    .continue-section {
      display: flex;
      flex-direction: row;
      .required-messaging {
        margin-left: 1em;
        em:not(:last-of-type) {
          &:after {
            content: ", "
          }
        }
      }
    }
    .continue {
      // margin-top: 1em;
      width: 200px;
      font-size: 1em;
      font-weight: 500;
      line-height: 1.5em;
      border-radius: 4px;
      padding: 10px;
      &.active {
        background-color: $blue;
      }
    }
    .auto-suggest{
      .results{
        z-index: 6;
      }
    }
  }
  .report-summary-section {
    width: 25%;
    margin-left: 2em;
    border-left: 1px solid #eee;
    padding-left: 1em;
  }
  .content-card{
    left: 0px;
    width: 100%;
  }
  .content-card.report-list li.list-header button:first-child{
    padding-left: 15px;
  }
  .source-stage-reset{
    display: block;
  }

  .personas{
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
    h4{
      font-size: 0.9em;
      font-weight: 400;
      margin-bottom: 0.5em;
      color: #999;
    }
    button{
      width: 200px;
    }
  }

  .audience-options {
    display: flex;
    align-items: center;
    height: 44px;

    > * {
      border-radius: 0;
      transition: background-color 0.2s ease, color 0.2s ease;
      padding: 10px 20px;

      &:not(.primary) {
        background-color: white;
        color: #0088e6;
    
        &:hover {
          background-color: #d3ecff
        }
      }

      &.primary {
        background-color: $blue !important;
      }
    }

    .express-option {
      font-weight: 500;
      color: #0088e6;
    }
  }
  
  .use-case-container {
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
    margin-top: 30px;

    .use-case-card {
      flex: 1 0 20%;
      padding: 16px;
      background-color: white;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      max-width: 250px;
      min-width: 250px;
      max-height: 300px;

      .card-title {
        font-weight: 500;
        font-size: 18px;
        white-space: nowrap;
      }

      .card-desc {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
      }

      .view-more-button {
        margin-top: auto;
        background-color: #e8f5ff;
        color: #0088e6;

        &:hover {
          background-color: #d3ecff
        }
      }
    }
  }

  .use-case-side-panel {
    position: fixed;
    z-index: 1;
    top: 94px;
    right: 0;
    height: calc(100% - 94px);
    padding: 18px 20px 50px 20px;
    background-color: white;
    width: Max(350px, 30vw);
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px rgba(204, 204, 204, 0.8);

    &::before {
      width: Max(350px, 30vw);
      height: 1px;
      position: absolute;
      top: 54px;
      right: 0;
      background-color: #ccc;
      content: "";
    }

    .title {
      margin-bottom: 16px;
      font-weight: 500;
    }

    .desc {
      margin-top: 20px;
    }

    .close-panel {
      position: absolute;
      background: none;
      top: 10px;
      right: 6px;
      padding: 0 8px;
      font-size: 24px;
      font-weight: 300;
      color: #444 !important;

      &:hover {
        background-color: #f1f1f1;
      }
    }

    .option-wrapper {
      display: flex;
      align-items: flex-start;
      margin-bottom: 6px;

      input {
        margin-top: 5px;
      }
    }

    .get-started {
      width: 100%;
      margin-top: auto;

      &-link {
        background-color: $action-color;
        color: #fff;
        transition: background-color 150ms ease, 0.4s ease;
        border-radius: 4px;
        -webkit-font-smoothing: antialiased;
        font-weight: 500;
        padding: 10px;
        user-select: none;
        white-space: nowrap;
        text-align: center;

        &:hover, &:focus {
          background-color: shade($action-color, 40%);
        }
      }
    }

  }

  .filter-options {
    display: flex;
    background-color: white;
    flex-flow: row wrap;
    gap: 25px;
    margin: 10px 0;
    padding: 25px;

    > .filter-card {
      min-width: 185px;
      width: min-content;
      height: 95px;
      display: flex;
      flex-direction: column;
      outline: 1px solid black;

      &:not(:hover):not(:focus) {
        background-color: #f3f3f3;
        outline-color: #dcdcdc;
      }

      &.disabled {
        filter: opacity(0.5);
        pointer-events: none;
      }

      > p {
        text-align: left;
      }

      .card-desc {
        font-weight: 400;
        font-size: 13px;
        white-space: normal;
        line-height: normal;
      }
    }
  }

  .report-search-wrapper {
    margin: 10px 0;
    padding: 1em;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,.25);
    border-radius: 4px;
    
    h1 {
      font-size: 1.3em;
      margin-bottom: 0.5em;
    }
  }
}
