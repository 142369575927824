.page-message {
  width: 99%;
  padding: 0 !important; // meh, could be better but nbd
  .info-notification{
    span{
      display: block;
    }
    a{
      // font-weight: 300;
      margin-top: 1em;
      font-size: 14px;
      text-decoration: none;
    }
  }
}
