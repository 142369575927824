.macro-menu {
  position: fixed;
  left: -220px;
  top: 0px;
  left: 0px;
  width: 220px;
  height: 50px;
  background: transparent;
  z-index: 1002;
  overflow: hidden;

  &.active {
    width: 100%;
    height: 100%;

    .macro-dropdown {
      transition: .2s;
      left: 0px;
    }

    .menu-trigger {
      background-color: #000;
    }

    #nav-toggle {
      span {
        background-color: transparent;

        &:before, &:after {
          top: 0;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }

      }
    }
    .backdrop {
      opacity: 1;
    }
  } // active

  .menu-trigger {
    display: block;
    position: absolute;
    height: 50px;
    width: 220px;
    background: $menu-gray;
    font-weight: 900;
    color: #eee;
    line-height: 50px;
    padding: 0px 20px 0px 40px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    z-index: 2;
  }

  .macro-dropdown {
    display: block;
    background: #eee;
    height: 100%;
    position: relative;
    top: 50px;
    left: 0px;
    width: 300px;
    height: 0px;
    z-index: 100;
    box-sizing: content-box;
    left: -400px;
    height: calc(100% - 50px);
  }

  .backdrop {
    background: rgba(0,0,0,0.8);
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
  }

  #nav-toggle {
    position: absolute;
    left: 20px;
    top: 14px;
    cursor: pointer;
    padding: 10px 35px 16px 0px;

    span, span:before, span:after {
      cursor: pointer;
      border-radius: 1px;
      height: 3px;
      width: 15px;
      background: #eee;
      position: absolute;
      display: block;
      content: '';
    }

    span:before {
      top: -5px;
    }

    span:after {
      bottom: -5px;
    }
  }

  .macro-top {
    padding: 20px 0 0 0;

    a, label {
      display: block;
      padding: 10px;
      box-sizing: border-box;
      color: #444;

      &:hover {
        background: rgba(0,0,0,0.1);
      }

      &.new {
        color: $blue;
      }

    }
  }

  .macro-tabs {
    .row {
      a {
        float: left;
        width: 50%;
        text-align: center;
        box-sizing: border-box;
        font-size: 115%;
        padding: 15px;
        font-weight: 700;
        color: #222;
        background: #d7d7d7;
        &.active {
          background: #eeeeee;
        }
      }
    }
  }

  .your-reports {
    margin: 20px 0px 0px;
    padding: 20px 0px 0px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    height: calc(100% - 150px);
    display: flex;
    flex-direction: column;

    .reports-list-header {
      padding: 10px;
      font-weight: 300;
      color: #222;
      font-size: 1.4em;
    }

    .reports-list {
      max-height: 100%;
      overflow-y: auto;
      flex: 1;

      a {
        word-break: break-word;
        padding: 10px;
        display: block;
        color: #666;
        box-sizing: border-box;
        transition: .2s;
        background: transparent calc(100% - 12px) 50% no-repeat;
        background-color: #fff;
        margin: 5px;
        box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.2);
        position: relative;
        left: 0px;
        width: calc(100% - 10px);
        outline: none;
        // left: -350px;
        border-left: 0px solid #fff;

        &:hover, &.active {
          border-left: 5px solid $blue;
          // width: calc(100% + 5px);
          padding-left: 15px;
        }

        &.active {
          font-weight: 900;
          color: #222;
        }

        span {
          width: calc(100% - 25px);
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          vertical-align: middle;
        }

        img {
          float: right;
        }

        svg {
          width: 20px;
          height: 20px;
          vertical-align: middle;
        }
      }

      span.Twitter a .fa {
        color: #1da1f2;
      }
      span.Instagram a .fa {
        color: #262626;
      }
      span.Pinterest a .fa {
        color: #bd2126;
      }
      span.Reddit a .fa {
        color: orangered
      }
    }
  }
}
