// .reportsReportVersionClusterOverviewTraits {
//   .page-title {
//     width: 50%;
//   }
// }
// // #overview.traits {
// //   height: 100%;
// // }
// .audiencesAudienceTribeOverviewTraits .content-proper {
//   padding-top: 0;
//   padding-bottom: 0;
//   // padding: 0 0 0 2em;
//   width: calc(100% - 165px);
//   background: rgba(0,0,0,0);
//   box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
//   margin-bottom: 0em;
//   .page-title {
//     // position: fixed;
//     top: 8em;
//   }
//   .networkgraph {
//     // position: fixed;
//     // width: calc( ( 100% - 250px ) / 2 ) !important;
//     // top: 8em;
//     // height: 100%;
//   }
//   .network-vis {
//     position: relative;
//     margin-top: -40px;
//     float: none;
//     padding: 0 !important;
//     margin-left: auto;
//     margin-right: auto;
//     max-width: 400px;
//     border-radius: 50%;
//     border: 10px solid #eee;
//     overflow: visible !important;
//   }
//   .cbg-vis-box {
//     // position: absolute;
//     // top: calc(50% + 8em);
//     h4 {
//       margin-bottom: 1em;
//     }
//     bottom: 0;
//     margin: auto 1.75em;
//     // width: calc(100% - 50px);
//     // position: relative;
//     .vis-options {
//       float: right;
//       margin-top: 0;
//     }
//     a {
//       color: #999;
//       &:hover, &:focus {
//         color: shade(#00A79B, 25%);
//       }
//     }

//     .comparison {
//       background-color: #222;
//       -webkit-transition: .5s;
//       -moz-transition: .5s;
//       -o-transition: .5s;
//       transition: .5s;

//       strong {
//         line-height: 1.1;
//         white-space: nowrap;
//         background-color: rgba(255,255,255,0.8);
//         display: inline-block;
//         font-size: 0.8em;
//         margin: 5px;
//         padding: 0px 2px;
//       }

//     }

//     p.helper {
//       margin-bottom: 1em;
//       margin-top: 1em;
//       &:first-of-type{
//         margin-top: 0;
//       }
//     }
//     .cluster-bar-graph a:not(.active) {
//       display:none;
//     }
//     article a {
//       float: right;
//       font-size: 0.75em;
//     }
//   }
//   ul {
//     color: white;
//     // padding: 2em 1em;
//     // box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
//     text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
//     font-size: 0.8em;
//     h2 {
//       // font-weight: 500;
//     }
//     li {
//       margin: 0.45em 0;
//       list-style: none;
//       i {
//         font-size: 1.25em;
//       }
//     }
//   }
//   .network-vis {
//     overflow: hidden;
//     padding: 5px;
//     float: none;
//     svg {
//       // border: 15px solid #eee;
//       padding: 1em;
//       // margin-top: 12em;
//       background-color: #fff;
//       box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.05);
//       border-radius: 50%;
//       overflow: visible;
//       margin-left: auto;
//       margin-right: auto;
//       max-width: 400px;
//       display: block;
//     }
//     &.fixed {
//       position: fixed;
//       top: 190px;
//     }
//   }
// }
