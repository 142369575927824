.google-map {
  width: 100%;
  height: 500px;
}

.map-holster {
  position:absolute;
  top:0;
  bottom:0;
  right:0;
  left:0;
}
