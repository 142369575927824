.affinio-table {
  $table-background-color: #F8F9FA;
  $table-border-color: #eeeeee;
  $table-hover-color: #F8F9FA;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);

  &.no-max-height {
    .ember-table {
      max-height: initial;
    }
  }

  .ember-table {
    max-height: 600px;
    width: 100%;
    table{
      height: 1px; // for whatever reason this is needed to make sure backgrounds take up the entire cell
      width: 100%;
    }
    thead {
      tr{
        height: 2.75em;
        th{
          background-color: $table-background-color;
        }
      }
    }
    tbody{
      tr {
        height: 2.75em;
        background-color: #fff;
        td {
          border-bottom: $table-border-color 1px solid;
        }
        &:hover,
        &.is-selected {
          background-color: $table-hover-color;
        }
      }
      tr .is-fixed-left {
        background-color: #fff;
      }
    }
  }
}
