.histo-gram {
  position: relative;
  transition: 0.3s;
  top: -100px;
  margin: -1em -1em -7.7em -1em;
  width: calc(100% + 3rem);
  left: -.5rem;

  svg {
    &.axis {
      width: 100%;
      position: absolute;
      top: 200px;
      left: 0;
      color: #999;

      g.x-axis {
        font-size: 2.5px;
        font-weight: 100;
        .tick {
          line {
            stroke-width: 0.2px;
          }
          text {
            text-anchor: start;
            transform: rotate(60deg) translate(2px, -4px);
          }
        }
      }

      .domain {
        display: none;
      }
    }

    &.chart {
      width: 100%;
      transition: 0.3s;
    }
  }

  span {
    display: block;
    text-align: center;
    border-top: 1px solid;
    font-size: 0.65em;
    color: #fff;
    text-shadow: 1px 1px 0px rgba(0,0,0,0.25);
  }
}
