.multi-slider {
  // margin-top: 65px !important;
  height: 5px;
  // @include animation($name: slideDownFadeIn, $duration: 0.5s, $delay: 0.4s);
  position: relative;
  margin: 0 1em 1em 0;
  text-align: center;

  .noUi-handle {
    height: 20px;
    width: 20px;
    border-radius: 40px;
    top: -9px;
    right: 0px;
    &.noUi-handle-upper {
      right: -2px;
    }
    &:before, &:after {
      display: none;
    }
  }

  .noUi-connect {
    background-color: #ccc;
  }
  .noUi-tooltip {
    display: none;
  }
  &.noUi-state-drag {
    .noUi-tooltip {
      display: block;
    }
  }
  .noUi-active {
    .noUi-tooltip {
      display: block;
    }
  }
}
