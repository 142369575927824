.cluster-nav {
  .sub-clusters:not(.screenshots){
    a:last-of-type{
      margin-bottom: 30px;
    }
  }
  .edit-notes{
    float: right;
  }
  .hasHiddenClusters {
    margin-top: 30px;
  }
  .hiddenClusters{
    position: relative;
    top: 0.8em;
    margin-left: 15px;
    color: #333c4e;
    opacity: 0.8;
    font-size: 0.85em;
    cursor: pointer;
  }
  .hiddenCluster{
    font-size: 0.85em;
    position: relative;
    top: 1.7em;
    padding: 10px 10px 10px 10px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    .hiddenClusterName{
      opacity: 0.8;
    }
  }
  .shouldHide{
    display: none;
  }
  .hiddenClustersList{
    position: relative;
    @include animation($name: slideDownFadeInHiddenClusters, $duration: .5s, $delay: 0s);
  }
  .unhide{
    float: right;
    opacity: 0.5;
    &:hover{
      opacity: 1;
      cursor: pointer;
    }
  }
  .adjustCogRight{
    padding-left: 25px;
  }
  .adjustCogCompare{
    padding-right: 7px;
  }
  .openActions{
    transform: rotate(-180deg);
    transition: .5s;
  }
  .closeActions{
    transform: rotate(180deg);
    transition: .5s;
  }
  .search{
    display: block;
    padding-left: 1em;
    padding-right: 1em;
    input{
      background: transparent url(/assets/images/icon-loupe.png) 0px 10px no-repeat;
      background-position: 10px;
      padding-left: 35px;
      // border-bottom: 1px solid #222;
      // width: calc(100% - 35px);
      display: inline-block;
      background-color: transparent;
      // border-radius: 0px;
      // border-top: none;
      // border-right: none;
      // border-left: none;
      &:focus{
        border-color: #a3a3a3
      }
    }
  }
  .cross-compare-search-result{
    opacity: 0.8;
    font-size: 0.85em;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    padding-left: 1em;
    padding-right: 1em;
    margin-bottom: 0.7em;
    color: #333c4e;
    display: block;
    // position: relative;
    // top: 1em;
    .cluster-name{
      display: block;
      padding-right: 2em;
    }
    .report-name{
      font-size: 0.80em;
      display: block;
      padding-right: 2em;
    }
    .toggle-spot{
      position: relative;
      top: -30px;
    }
    transition: .2s;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    -o-transition: .2s;
    &:hover{
      cursor: pointer;
      background-color: #424242;
      color: #eee;
    }
  }
  .related-clusters-info{
    margin-bottom: 0.7em;
    opacity: 0.8;
    font-size: 0.85em;
    display: block;
    padding-left: 1em;
  }
  .spinner{
    position: relative;
    left: 60px;
  }
  .selected-cross-report-cluster{
    margin-bottom: 0.7em;
    opacity: 0.8;
    font-size: 1em;
    // display: block;
    padding-left: 1em;
    position: relative;
    top: 0.3em;
  }
  .no-clusters-found{
    margin-bottom: 0.7em;
    opacity: 0.8;
    font-size: 1em;
    display: block;
    padding-left: 1em;
  }
  .selected-cluster-ul{
    margin-top: 1em;
    margin-bottom: 2em;
  }
  .title.underline {
    text-decoration: underline;
    text-decoration-color: rgba(0,0,0,0.25);
  }
  .cluster-cog-dropdown {
    position: absolute;
    right: 0px;
    background: white;
    z-index: 10000;
    li {
      padding: 1rem;
      color: #222;
      &:hover {
        background: rgba(0,0,0,0.1);
      }
    }
  }
  .cluster-sub-nav.active {
    .cluster-cog-dropdown {
      top: 30px;
    }
  }
}
