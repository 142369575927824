.loading-spinner {

  .spinner  {
    animation: rot 2.25s infinite linear;
  }

  @keyframes rot {
      0% {
      transform: rotate(0deg); transform-origin: center;
      }
      100% {
      transform: rotate(720deg); transform-origin: center;
    }
  }

  circle {
    transition: stroke 650ms,
    stroke-dasharray 1000ms,
    dashoffset 1500ms,
    transform 600ms;
    &.blue {
      stroke: #3F9EEA;
    }
    &.teal {
      stroke: #00A79B;
    }
    &.yellow {
      stroke: #FFBA33;
    }
    &.red {
      stroke: #FF0066;
    }
  }

  .spinner  {
    height: 80px;
    animation: rot 2.25s infinite linear;
  }

  .spinner circle:first-of-type {
    	fill: none;
    stroke: $light-gray;
  	stroke-width: 3;
  }

  .spinner circle:last-of-type {
  	fill: none;
    stroke: $blue;
  	stroke-width: 3;
  	stroke-linecap: round;
  	stroke-dasharray: 0, 0, 20, 60;
    &.admin {
      stroke: lighten($dark-blue, 10);
    }
  }

}

.loading-spinner-center-wrapper {
  text-align: center;
  margin-top: 15em;
}

.search-field.global-search .loading-spinner {
  display: inline;
  .spinner {
    height: 35px;
    margin-bottom: -12px;
  }
}
