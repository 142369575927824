.select-checkbox {
  font-size: 0.85em;
  color: #666;
  transition: 200ms;
  vertical-align: middle;
  input {
    // margin-top: 5px;
  }
  span {
    // margin-top: -5px
  }
  &:hover {
    color: #333;
  }
}
