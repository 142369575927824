.admin-list {
  li{
    width: 100%;
    // margin-bottom: 10px;
    padding: 0px 15px;
    transition: 0.1s;
    background-color: #fff;
    // box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    line-height: 2.75em;
    border-bottom: 1px #eee solid;
    &:hover{
      background-color: #f5f5f5;
    }
    span{
      display:inline-block;
      font-weight: 400;
    }
    a{
      color: #000;
      display: inline-block;
    }
  }
  .toggle-on{
    i{
      color: #4CAF50;
      cursor: pointer;
      &:hover{
        color: darken(#4CAF50, 10);
      }
    }
  }
  .toggle-off{
    i{
      color: #E53935;
      cursor: pointer;
      &:hover{
        color: darken(#E53935, 10);
      }
    }
  }
  .none{
    font-size: 18px;
    font-weight: 300;
  }
  .search-field, .input-field{
    @include animation($name: slideUpFadeIn, $duration: 0.2s, $delay: (0.03s));
    margin-top: 5px;
    background-color: #fff;
    width: 100%;
    padding-top: 5px;
    border-radius: 3px;
    box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.2);
    i{
      margin-left: 10px;
      margin-right: 5px;
      display: inline-block;
      color: lighten($dark-blue, 10);
      font-size: 18px;
    }
    input[type=text]{
      padding: 0;
      padding-bottom: 2px;
      padding-top: 9px;
      border: none;
      border-bottom: 1px solid lighten($dark-blue, 10);
      width: calc(95% - 65px);
      display: inline-block;
      border-radius: 0px;
      &:focus{
        border: none;
        border-bottom: 1px solid lighten($dark-blue, 10);
      }
    }
  }
  .input-field{
    margin-top: 10px;
    input[type=text]{
      width: calc(100% - 145px);
    }
    i{
      cursor: pointer;
      &:hover{
        color: $dark-blue;
      }
    }
    .disabled {
      i {
        cursor: initial;
        color: $medium-gray;
      }
    }
    .inline-share-options{
      color: $dark-blue;
      padding: 0px;
      margin-left: 10px;
      background-color: white;
      border: none;
      width: 70px;
      display: inline-block;
    }
  }
  .companies{
    margin-top: 10px;
    // max-height: 420px;
    overflow: auto;
    li{
      span:nth-child(4){
        width: 12%;
      }
      a{
        width: calc(100% - 110px);
        span:nth-child(1){
          width: 50%;
        }
        span:nth-child(2){
          margin-right: 4%;
          width: 12%;
        }
        span:nth-child(3){
          margin-right: 4%;
          width: 12%;
        }
      }
      span[title]:nth-child(3){
        float: right;
      }
    }
  }
  .accounts{
    margin-top: 10px;
    // max-height: 420px;
    overflow: auto;
    li{
      a{
        width: calc(100% - 110px);
        span:nth-child(1){
          width: 38%;
          margin-right: 2%;
        }
        span:nth-child(2){
          width: 38%;
          margin-right: 2%;
        }
      }
      span[title]:nth-child(3){
        float: right;
      }
    }
  }
  .groups{
    margin-top: 10px;
    // max-height: 420px;
    overflow: auto;
    li{
      a{
        width: calc(100% - 30px);
        span:nth-child(1){
          width: 50%;
          margin-right: 2%;
        }
        span:nth-child(2){
          width: 30%;
        }
      }
      .delete{
        i{
          color: #E53935;
          cursor: pointer;
          &:hover{
            color: darken(#E53935, 10);
          }
        }
      }
    }
  }
  .reports{
    margin-top: 10px;
    // max-height: 420px;
    overflow: auto;
  }
  .add-more{
    font-size: 18px;
  }
  .actions{
    margin-top: 7px;
    margin-bottom: 12px;
    .create-new-button{
      font-size: 14px;
      padding: 5px 5px 5px 5px;
      top: -1px;
      margin-left: 0px;
    }
    .no-add{
      position: relative;
      top: -1px;
      margin-left: 0px;
      padding: 5px 5px 5px 5px;
    }
    .export-accounts{
      top: -1px;
      cursor: pointer;
      position: relative;
      margin-left: 5px;
      border-radius: 3px;
      font-size: 14px;
      padding: 5px 5px 5px 5px;
      color: #fff;
      background-color: lighten($dark-blue, 10);
      box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.2);
      &:hover{
        box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.6);
        color: #fff;
      }
    }
  }
  .content-card{
    left: 0;
    margin-bottom: 0;
    width: 100%;
  }
  .content-card.report-list {
    background-color: transparent;
    padding: 0px;
    box-shadow: none;

    li.list-header {
      background-color: $blue;
      border: none;
      padding: 0px;
      margin-top:10px;
      button {
        color: rgba(255,255,255,0.8);
        &:first-child {
          padding-left: 55px;
        }
      }
    }

    .button, button {
      border-radius: 0;
    }

    // li.failed, li.pending {
    //   display: none;
    // }
    //
    // &.show-failed {
    //   li.failed {
    //     display: list-item;
    //   }
    // }
    //
    // &.show-pending {
    //   li.pending {
    //     display: list-item;
    //   }
    // }

    li {
      padding: 0;
      background-color: transparent;
      position: relative;
      line-height: 2.75em;
      border-bottom: 1px #eee solid;
      background-color: #fff;
      transition: 0.1s;
      &.is-dragged-over {
        background-color: #f5f5f5;
      }
      &:last-child {
        border-bottom: none;
      }
      // &:hover {
      //   nav {
      //     display: block;
      //     // animation: fadeIn 0.4s ease-in;
      //   }
      //   div.column:last-child {
      //     opacity: 0;
      //     // padding-right: 96px;
      //     // padding-left: 16px;
      //   }
      // }
      &:hover{
        // background-color: #f5f5f5;
      }
      &.hidden {
        display: none;
      }
      &.pending, &.failed {
        a {
          opacity: 0.4;
          cursor: grab;
        }
      }
      &.failed a {
        color: #c00;
      }

      label.report-check {
        // display: none;
        border: 2px solid $medium-gray;
        height: 20px;
        width: 20px;
        margin: 0.5em 0;
        line-height: 100%;
        text-align: center;
        border-radius: 2px;
      }

      &.list-header {
        label.report-check {
          border-color: $light-gray;
          &.checked {
            background-color: $darkish-gray;
            background-image: url(/assets/images/icon-checkmark-white.png);
            background-size: 12px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
          }
        }
      }

      &.checked {
        background-color: #f5f5f5;
        label.report-check {
          border: 2px solid $darkish-gray;
          background: $darkish-gray;
          background-image: url(/assets/images/icon-checkmark-white.png);
          background-size: 12px;
          background-repeat: no-repeat;
          background-position: 50% 50%;
        }
      }

      .column-checkbox {
        font-size: 1.5em;
      }

      .column-checkbox:hover {
        .report-check {
          // border: 2px solid $blue;
          background-color: $blue;
        }
      }
    }
    nav {
      display: none;
      height: 2.75em;
      position: absolute;
      right: 0;
      top: 0;
      color: $dark-gray;
      // bottom: 0;

      & > button {
        float: left;
      }
    }
    .list-header {
      border-color: #aaa;
    }
    li > a {
      height: 2.75em;
      width: 100%;
      display: block;
      // display: -webkit-flex;
      // display: flex;
      // -webkit-flex-direction: row;
      // flex-direction: row;
      color: inherit;
      outline: none;
      // transition: 0.1s;


      &:hover {
        background-color: #f5f5f5;
      }
    }
    .column {
      text-align: right;
      padding: 0 10px;
      vertical-align: middle;
      overflow: hidden;
      white-space: nowrap;
      float: left;
      // border-right: 1px #eee solid;
      &:last-child {
        padding-right: 20px;
        // text-align: right;
        // border-right: none;
      }
      &:first-child {
        padding-left: 15px;
      }
      // transition: opacity 0.2s ease-in;
    }
    .column-name {
      font-weight: 400;
      text-overflow: ellipsis;
      text-align: left;

      .fa {
        font-size: 1.2em;
        padding: 0 0 0 0px;
        // min-width: 1.5em;
        border-right: none;
        text-align: left;
        overflow: visible;
      }

      .fa-twitter {
        color: #1da1f2;
      }
      .fa-instagram {
        color: #262626;
      }
      .fa-pinterest {
        color: #bd2126;
      }
      .fa-pied-piper {
        color: #009600;
      }
      .fa-folder {
        color: #888;
      }
    }
    .column-name {

      p.no-reports {
        padding: 20px 20px 0px;
      }

      span.collecting {
        display: initial;
        font-style: italic;
        font-size: 0.8em;
        background-color: transparentize($yellow, 0.4);
        color: #222;
        padding: 1px 3px;
        border-radius: 4px;
      }
    }

    // .column {
    //   box-sizing: border-box !important;
    // }

    .column-checkbox {
      width: 40px;
    }

    .column-name {
      width: calc(100% - 570px);
    }
    .column-clusters {
      width: 100px;
      text-align: right;
    }
    .column-owner {
      width: 120px;
      text-overflow: ellipsis;
      text-align: right;
    }
    .column-members {
      width: 100px;
      text-align: right;
    }
    .column-created {
      width: 130px;
      text-align: right;
    }
    .column-options {
      width: 40px;
      text-align: right;
      color: $blue;
    }



    // .column-clusters {
    //   @include flex-width(112px);
    // }
    // .column-owner {
    //   @include flex-width(20%);
    // }
    // .column-members {
    //   @include flex-width(112px);
    // }
    // .column-created {
    //   @include flex-width(160px);
    // }

    .column-clusters, .column-owner, .column-members, .column-created  {
      font-size: 0.9em;
    }

    header {
      position: relative;
      height: 2.75em;
      width: 100%;
      display: -webkit-flex;
    	display: flex;
    	-webkit-flex-direction: row;
    	flex-direction: row;
      button.column {
        font-size: 1em;
        // background-color: transparent;
        height: 100%;
        border-radius: 0;
        position: relative;
        // padding-right: 20px;

        &:hover, &.active {
          background-color: rgba(0,0,0,0.1);
          // color: #555;
        }

        .fa {
          position: absolute;
          // right: 0px;
          bottom: 0px;
          left: 12px;
          font-size: 1em;
          opacity: 0.2;
          color: #000;
        }

        &.column-clusters .fa,
        &.column-members .fa,
        &.column-owner .fa,
        &.column-created .fa {
          left: inherit;
          right: 5px;
        }

      }

      &.ascending button.column .fa {
        bottom: inherit;
        top: 0px;
      }
    }
    button, .button{
      height: 100%;
      width: 2.5em;
      font-size: 1.125em;
      background-color: transparent;
      padding: 10px;
      &:hover {
        background-color: #fff;
        color: #00867c;

        .fa-trash {
          color: #c00;
        }
      }
      &:focus {
        background-color: shade($action-color, 20%);
        color: #fff;

        .fa-trash {
          color: #fff;
        }
      }
    }

  }

  .report-selection-nav {
      background: $blue;
      color: #eee;
      position: fixed;
      left: 0px;
      width: 100%;
      top: 0px;
      z-index: 100002;
      height: 50px;
      line-height: 50px;
      padding-left: 250px;
      @include animation($name: slideDownFadeIn, $duration: 0.2s, $delay: 0s);
      box-shadow: 0px 0px 30px rgba(0,0,0,0.5);
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        background-color: darken($blue, 10);
        box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
      }
  }

  .report-list-options {
    // display: none; // TODO: disabled until we have more info on Failures
    font-size: 0.8em;
    // padding-bottom: 0em;
    padding: 1em;
    // background-color: transparent;
    // text-align: right;
    position: relative;
    // @include animation($name: slideUpFadeIn, $duration: 0.2s, $delay: 1s);

    label {
      color: $blue;
      display: block;
      // margin-left: 0.5em;
      padding: 0.25em 0.5em;
      margin: 0px;

      &.disabled {
        opacity: 0.4;
      }

      input {
        margin: 0px;
      }

    }
  }
}
