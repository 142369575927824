.sign-in {
  form {
    background: #fff;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.15);
    padding: 1em;
    border-radius: 4px;

    header {
      border-bottom: 1px solid #eee;
      margin: -1em -1em 1em;
      padding: 1em;

      h4 {
        font-size: 2em;
        font-weight: 300;
        margin-bottom: 0;
        color: $darkish-gray;
      }
    }
  }

  label {
    margin-bottom: 1.25em;

    input[type="text"],
    input[type="email"],
    input[type="password"] {
      border: 1px solid $medium-gray;
      border-radius: 4px;
      padding: 0.5em;

      &:focus {
        border: 1px solid $blue;
      }
    }

    span {
      font-size: 0.8em;
      color: $medium-gray;
      margin-bottom: 0.5em;
      display: block;
    }
  }

  button[type="submit"] {
    font-weight: 300;
  }
}
