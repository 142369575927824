.engagement-highlight {
  // margin-top: 12em;
  h2 {
    color: #888;
    &.highlighted {
      color: $blue;
    }
  }
  section.details {
    margin-top: 3em;
    height: 70px;
    border-left: solid 5px #ccc;
    padding-left: 1em;
    > span {
      display: inline-block;
      &:last-of-type {
        margin-left: 2.5em;
      }
    }
    strong {
      color: $blue;
    }
  }
  p {
    color: #888; // margin-top: 3em;
    // white-space: nowrap;
  }

  &.highlight {
    color: $blue;
  }

}
