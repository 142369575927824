.engagement-timeline {
  width: 99%;
  // TODO handle with skeleton state
  height: 297px;
  .highlight-linechart {
    height: 200px;
    > svg {
      width: calc(100% - 60px);
      height: 100%;
      margin-left: 40px;
      margin-right: 20px;
      position: relative;
    }
    .highlight circle {
      fill: $blue;
      stroke: #fff;
      stroke-width: 2px;
    }

  }
}
