// Hack to fill out content proper area
.content-proper > .movers-and-shakers {
  margin: -20px -20px 20px;
}


// TODO clean this up
.movers-and-shakers {
  // background-color: desaturate(lighten($teal, 50), 50);
  // box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.3);
  background-color: $teal;
  border-left: 10px solid darken($teal,10);
  clear: both;
  color: #fff;
  display: block;
  font-size: 1.1em;
  font-weight: 400;
  padding: 20px;
  text-align: left;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.25);

  .full-details {
    position: absolute;
    opacity: 0;
    top: 0px;
    right: 0px;
    transition: 0.25s;
  }

  &:hover {
    .full-details {
      opacity: 1;
      transition: 0.25s;
    }
  }

  strong {
    display: block;
  }

  button {
    float: left;
    background-color: transparent;
    padding-left: 0px;

    &:last-of-type{
      margin-right: 10px;
    }
  }

  .carouselbox {
    position: relative;
    overflow: hidden;

    .content {
      margin: 0;
      padding: 0;
      li {
        margin: 0;
        padding: 0;
        width: 100%;
        white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        list-style: none;
        text-align: center;
      }
    }

    &.enabled {
      height: 30px;
      li {
        position: absolute;
        top: 0px;
        // top: 200px;
        opacity: 0;
        transform: scale(0);
        transition: 0.35s;

        pointer-events: none;
        &.current {
          // top: 0px;
          opacity: 1;
          transform: scale(1);
          transition: 0.35s;
          pointer-events: auto;
        }
      }
    }
  }

  .current-change {
    float: left;
  }

  .metric {
    float: left;
    text-align: center;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    padding-right: 5px;

    background-color: $blue;
    border-radius: 3px;

    h4 {
      color: #fff;
      padding: 0px;
      margin: 0px;
    }

    .metric-icon {
      border-radius: 50%;
      height: 30px;
      width: 30px;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        -moz-transition: 250ms;
        -webkit-transition: 250ms;
        transition: 250ms;
        // box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.45);
        &.active {
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0);
        }
      }

      i {
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
        color: white;
      }
    }
  }
}
