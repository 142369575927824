.asset-group-picker {

  > div {
    background: #eee;
    padding: 5px;
    margin-bottom: 5px;
    float: left;
    width: calc(50% - 20px);
    margin: 0px 0px 5px 10px;
    position: relative;

    h4 {
      margin-bottom: 0px;
      float: none;
      background: $teal;
      color: #fff;
      margin: -5px -5px -5px -5px;
      padding: 10px;

      .addAssetGroup {
        cursor: pointer;
        background: $teal;
        opacity: 1;
        color: #fff;
        min-width: 40px;
        opacity: 0.5;
        padding: 10px;
        margin: -10px;
        -webkit-transition: .2s;
        -moz-transition: .2s;
        -o-transition: .2s;
        transition: .2s;
        background-color: rgba(0,0,0,0.2);
        text-align: center;

        &:hover {
          opacity: 1;
        }
      }

      span {
        cursor: pointer;
        display: block;
        flex: 1;
        margin: -10px 10px -10px -10px !important;
        padding: 10px;
        -webkit-transition: .2s;
        -moz-transition: .2s;
        -o-transition: .2s;
        transition: .2s;
        box-sizing: border-box;
        font-size: inherit !important;
        font-weight: inherit !important;

        &:hover {
          background-color: rgba(0,0,0,0.25);
        }
        &:before {
          content: "▼";
          font-size: 0.6em;
          opacity: 0.5;
          float: left;
          margin-right: 0.5em;
          margin-top: 0.5em;
        }
      }

      &.open span:before {
        content: "▲";
      }
    }

    .assetList {
      margin: 15px 0 5px;

      .asset {
        font-size: 0.8em !important;
        font-weight: inherit !important;
        margin: 0 0 0 10px !important;
      }
    }
  }

  input {
    margin-top: 10px;
  }
}
