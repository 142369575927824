/*==================================================================
=Streamed Tweets
----------------------------------------------------------------- */

.tweet {
  margin: auto;
  max-width: 500px;
  margin-bottom: 80px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -moz-box-shadow: 0px 0px 3px #ccc;
  -webkit-box-shadow: 0px 0px 3px #ccc;
  box-shadow: 0px 0px 3px #ccc;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-left: 20px solid #222;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  position: relative;
  z-index: 2;
  background: #fff;
  top: 0px;
}

.stream-overview .aggregation,
.stream-overview .stream {
  float: right;
  width: 50%;
}

.stream-overview .aggregation {
  position: absolute;
  left: 250px;
  width: calc(50% - 150px);
}

.tweets-proper {
  padding-top: 50px;
}

.tweet img.avatar {
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  height: 48px;
  width: 48px;
  -moz-box-shadow: 0px 0px 1px #ccc;
  -webkit-box-shadow: 0px 0px 1px #ccc;
  box-shadow: 0px 0px 1px #ccc;
  margin-right: 10px;
}

.tweet .user {
  line-height: 48px;
}

.tweet .user strong {
  display: inline-block;
  margin-right: 10px;
}

.tweet .user em {
  font-style: normal;
  opacity: 0.5;
}

.tweet span.timeago {
  line-height: 48px;
  float: right;
}


.tweetContainer {
  position: relative;
  display: none;
  margin: auto;
  width: 500px;
}

.tweetContainer footer {
  position: absolute;
  z-index: 1;
  width: 460px;
  left: 40px;
  top: 0px;
  height: 100px;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.tweetContainer:hover .tweet {
  top: 50px;
}

.tweetContainer:hover footer {
  top: -50px;
}

.tweetContainer footer span {
  background: #222;
  width: 15.8%;
  margin-right: 0.2%;
  height: 0px;
  float: left;
  display: block;
  position: absolute;
  bottom: 0px;
  text-align: center;
  font-weight: 900;
  color: #fff;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px 0px 25px;
  -webkit-box-shadow: inset 0px -5px 5px -5px rgba(0,0,0,.5);
  -moz-box-shadow: inset 0px -5px 5px -5px rgba(0,0,0,.5);
  box-shadow: inset 0px -5px 5px -5px rgba(0,0,0,.5);
}


.tweetContainer footer span:nth-of-type(1) { background-color: #ca5953; left: 0%; }
.tweetContainer footer span:nth-of-type(2) { background-color: #F48D6C; left: 16%; }
.tweetContainer footer span:nth-of-type(3) { background-color: #F2E07B; left: 32%; }
.tweetContainer footer span:nth-of-type(4) { background-color: #8ABE9B; left: 48%; }
.tweetContainer footer span:nth-of-type(5) { background-color: #2d9ec6; left: 64%; }
.tweetContainer footer span:nth-of-type(6) { background-color: #634a8b; left: 80%; }

.tweet header {
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
  background: #fff;
  margin-left: -20px;
  margin-top: -20px;
  padding: 20px;
  width: 100%;
  -webkit-box-shadow: inset 0px -50px 250px -50px rgba(0,0,0,.1);
  -moz-box-shadow: inset 0px -50px 250px -50px rgba(0,0,0,.1);
  box-shadow: inset 0px -50px 250px -50px rgba(0,0,0,.1);
}

.tweet .tweet-content {
  padding: 10px 0px;
}

.tweetContainer.cluster-0.open .tweet { border-left: 20px solid #ca5953 }
.tweetContainer.cluster-1.open .tweet { border-left: 20px solid #F48D6C }
.tweetContainer.cluster-2.open .tweet { border-left: 20px solid #F2E07B }
.tweetContainer.cluster-3.open .tweet { border-left: 20px solid #8ABE9B }
.tweetContainer.cluster-4.open .tweet { border-left: 20px solid #2d9ec6 }
.tweetContainer.cluster-5.open .tweet { border-left: 20px solid #634a8b }


.stop-feed {
  background: #c00;
}

.stream-overview form {
  padding: 20px 0px;
}

.stream-overview form input[type="text"] {
  border: 2px solid #ccc;
}

.stream-totals {
  position: relative;
  height: 200px;
  margin-bottom: 50px;
}

.stream-totals > div {
  width: 15.8%;
  height: 30px;
  text-align: center;
  color: #fff;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: bold;
  padding-left: 5px;
  margin-bottom: 2px;
  line-height: 30px;
  text-align: left;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  background: #ccc;
  min-width: 250px;
  white-space: nowrap;
}

.stream-totals .cluster-0 { background-color: #ca5953; border-color: #ca5953; left: 0%; }
.stream-totals .cluster-1 { background-color: #F48D6C; border-color: #F48D6C; left: 16%; }
.stream-totals .cluster-2 { background-color: #F2E07B; border-color: #F2E07B; left: 32%; }
.stream-totals .cluster-3 { background-color: #8ABE9B; border-color: #8ABE9B; left: 48%; }
.stream-totals .cluster-4 { background-color: #2d9ec6; border-color: #2d9ec6; left: 64%; }
.stream-totals .cluster-5 { background-color: #634a8b; border-color: #634a8b; left: 80%; }

.stream-categories li {
  background-color: #eee;
  margin-bottom: 2px;
  height: 30px;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.stream-categories li span {
  display: block;
  white-space: nowrap;
  padding: 5px;
}

.sample-stream {
  width: 500px;
  display: inline-block;
  float: left;
}

.stream-description {
  width: -moz-calc(100% - 520px);
  width: -webkit-calc(100% - 520px);
  width: calc(100% - 520px);
  display: inline-block;
  float: left;
  margin-left: 20px;
}

.sample-stream .twitter-frame {
  width: 500px;
}

.twitter-frame {
  width: 360px;
  margin-right: 15px;
  &.hidden {
    display: none;
  }

}

.modal .twitter-frame {
  width: 100%;
  margin-bottom: 15px;
  margin-right: 0px;
  .twitter-tweet, iframe {

    margin: 10px auto;
  }
}

.modal .ig-loaded-image {
  width: 100%;
  // float: left;
}
