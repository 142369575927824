.custom-metric-editor {
  border-radius: 6px;
  border: 1px solid rgba(0,0,0,0.1);
  padding: 1em;
  background: linear-gradient(45deg, $purple-gradient-transparent);
  color: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  font-weight: 300;

  position: absolute;
  left: 0;
  top: 60px;
  bottom: 0;
  right: 0;
  z-index: 5;

  header {
    display: flex;
    justify-content: space-between;
    a {
      color: white;
      &:hover {
        color: shade(white, 20%)
      }
    }
    .view-selector {
      a {
        color: rgba(255, 255, 255, 0.35);
        &:hover, &.active {
          color: white;
        }
      }
    }
  }

  h4 {
    font-weight: 300;
  }
  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: max-content;

    height: 95%; // Room for header
    overflow-y: auto;

    &.no-grid {
      display: block;
    }

    .pairings {
      label {
        margin-bottom: 0.5rem;

        &:hover {
          background: rgba(0,0,0,0.15);
        }
      }
    }

    > label {
      display: block;
      margin: 0.5rem;

      &.disabled {
        opacity: 0.5;
        cursor: default;
        .ember-power-select-trigger {
          cursor: default;
        }
      }

      strong, span {
        display: block;
      }
      span {
        margin-left: 1em;
      }
      input {
        border: none;
        border-bottom: 1px solid white;
        border-radius: 0;
        background: none;
        color: white;
        font-size: 1em;
        text-overflow: ellipsis;
        &::placeholder {
          color: white;
        }
        &:focus {
          color: white;
          border-bottom: 1px solid white;
        }
      }
      .ember-power-select-status-icon {
        border-color: white transparent transparent transparent;
      }
      .ember-power-select-trigger {
        background: none;
        border-color: white;
      }
    }
  }

  .paired-with {
    display: block;
    font-size: 0.8em;
    span {
      font-weight: bold;
      background-color: #eee;
      display: inline-block;
      padding: 0 0.25em;
      margin: 0 0.25em 0.25em 0;
    }
    hr {
      border: none;
      height: 20px;
      background-color: transparent;
      margin: 0;
    }
    .drag-wrapper.draggable {
      cursor: move;
    }
    .dragged-over {
      hr {
        background-color: #ccc;
      }
    }

  }

}

.custom-metric-editor {
  display: block;
}

.affinio-module-wrapper[data-module-id='Custom_overview_summary_cluster_summaries'],
.affinio-module-wrapper[data-module-id='Twitter_overview_summary_cluster_summaries'] {
  .custom-metric-editor {
    left: 10px;
    width: calc(100% - 16px);
  }
}
