.modal-datasource-actions {
  label {
    margin-bottom: 0.5rem;
  }
  .hovertip {
    margin-left: 5rem;
    // This breaks the span going over the a, but I'm ok with it to fix it going over future ones?
    z-index: unset;
    > span {
      z-index: 5;
    }
  }
  .title {
    color: #999;
  }
}
