// Categorical Colors: Deuternaopia
$colors: (
  1: #5d5a80,
  2: #4c409b,
  3: #5744c3,
  4: #8d80f5,
  5: #aca4f3,
  6: #b0acdc,
  7: #56573b,
  8: #a3a855,
  9: #939663,
  10: #eaf154,
  11: #dfe48e,
  12: #e9ecc0,
  13: #5d5a80,
  14: #4c409b,
  15: #5744c3,
  16: #8d80f5,
  17: #aca4f3,
  18: #b0acdc,
  19: #56573b,
  20: #a3a855
);

#world-container.deuteranopia, .modal-container.deuteranopia {
  @each $num, $color in $colors {
    $spot: $num - 1;
    // Tribe sidebar borders
    .sub-clusters .cluster-sub-nav.cluster-order-#{$num} {
      border-color: $color;
      color: $menu-gray;

      &:hover, &.active, &.held {
        background-color: $color;
        color: #fff;
      }
    } // .cluster-sub-nav

    .hiddenCluster:nth-of-type(#{$num}) {
      border-left: 5px solid;
      border-color: $color;
      color: $menu-gray;

      &:hover, &.active, &.held {
        background-color: $color;
        color: #fff;
      }
    }
    // Cluster stats on bar charts
    .bar-graph .bar .cluster-stats span:nth-of-type(#{$num}) {
      background-color: $color;
      border-color: $color;
    } //.bar-graph .bar .cluster-stats span

    // Cluster bar graphs
    .cluster-bar-graph a {
      .bar[data-color-order='#{$num - 1}'] {
        border-color: $color;
      }
      .hovered .bar[data-color-order='#{$num - 1}'] {
        background-color: $color;
      }
    } //.cluster-bar-graph a
    .cluster-bar-graph.spot_#{$spot} a .spot_#{$spot} .bar {
      background-color: $color;
    }

    // Members Profiles image borders
    .member-profile.member.spot_#{$spot} {
      border-color: $color;
    }

    .map-options label:nth-of-type(#{$num}).map-shown .toggle {
      background-color: $color;
    }

    .metric-instance .bar .cluster-stats span[data-color-order='#{$num}'] {
      background-color: $color;
      border-color: $color;
    }
  }
}
