.inlineNav{
  display: inline-block;
}

.otherButtons{
  img{
    display: inline-block;
  }

  p{
    display: inline-block;
    margin-left: 10px;
  }
}

.slightRight{
  margin-left: 30px;

  .alignRight{
    margin-left: 42px;
  }
}

.reportCreationSection{
  margin-top: 40px;
}

.graphSection{
  margin-top: 60px;
}

.addPadding{
  padding-top:20px;
}

.center{
  margin-left: auto;
  margin-right: auto;
}
