.heatmap-grid {
  display: flex;
  .day-of-week {
    // float: left;
    width: calc(100% / 7);
    .heat-block {
      // clear: right;
    }
  }
  .heat-block {
    float: left;
    clear: both;
    height: 25px;
    width: calc(100% - 4px);
    margin-bottom: 4px;
    border: solid 2px #fff;
    box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.2);
    // display: inline-block;
  }
}
