.support-button.worldly {
  display: block;
  z-index: 999;
  position: fixed;
  bottom: 0px;
  right: 30px;
  padding: 10px;
  width: 250px;
  text-align: center;
  color: #999;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: $action-color;
  width: auto;
  color: #fff;
  padding: 5px 20px;
  font-size: 0.8em;
  font-weight: 500;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  white-space: normal;
  &:hover {
    background-color: shade($action-color, 20%);
  }
}

.support-button{
  padding-left: 5px;
  white-space: normal;
  text-align: left;
  color: #fff;
  @include linear-gradient(135deg, $coral-gradient);
  &:hover {
    background-color: shade($action-color, 20%);
  }
}

.onPage{
  background-color: white;
}

.settingsIndex .support-button {
  background: $blue;
}
