/*==================================================================
=Tooltip/Hovertip
----------------------------------------------------------------- */

.hovertip {
  position: relative;
  float: left;
  z-index: 5;
  margin-left: 5px;
  top: -3px;
  text-align: left;
}

.hovertip > a {
  display: block;
  background: #eee;
  color: #222;
  font-weight: bold;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  height: 20px;
  width: 20px;
  line-height: 20px;
  text-align: center;
  position: absolute;
  z-index: 2;
  border: 3px solid #fff;
  font-size: 0.8em;
  box-sizing: content-box;
}


.hovertip > span {
  word-break: break-word;
  display: none;
  position: absolute;
  top: 15px;
  left: -125px;
  padding: 20px;
  width: 250px;
  background: #eee;
  -moz-box-shadow: 0px 0px 1px #bbb;
  -webkit-box-shadow: 0px 0px 1px #bbb;
  box-shadow: 0px 0px 1px #bbb;
}

.hovertip.hovered > span {
  display: block;
}

.hovertip.rightward {
  margin-left: 10px;
  float: right;
}

.hovertip.rightward > a {
  position: relative;
}
