.summaries {
  clear: both;
}

// TODO port over to cluster-summaries selectors

#overview .cluster-summary,
#overview .baseline-summary {
  background-color: #fff;
  position: relative;
  padding: 1em;
  box-shadow: 0px 1px 3px rgba(0,0,0,0.15);
  border-radius: 4px;
  color: #555;
  transition: 0.4s;
  overflow: hidden;

  .metric-visualization {
    width: 100%;
  }

  .traits {
    // width: 50%;
    float: left;
  } //.traits

  &.has-traits-card {
    .traits {
      span {
        width: calc(100% - 200px);
      }
    }
  }

  h4 {
    border-bottom: 2px solid #fff;
    margin: -1em -1em 0.5em;
    padding: 1em 1em 0.75em;
    font-size: 1.3em;
    line-height: 1.3em;
    cursor: pointer;
    transition: 250ms;

    .clusterName {
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
      margin-bottom: -0.25em;
      width: 100%;
      font-weight: 300;
      color: $dark-gray;
      small {
        float: right;
      }
      .button:first-of-type {
        margin-left: 1rem;
      }
    }

    &.disabled {
      cursor: auto;
    }
    &:not(.disabled):hover {
      color: #666;
      background-color: #f8f9fa;
    }
    & > .add {
      position: absolute;
      // right: 85px;
      float: left;
      left: calc(100% - 100px);
      top: 20px;
      min-width: 150px;
    }

  } //h4

  .interests-header {
    font-weight: 300;
    font-size: 1em;
  }

  .influencers {
    width: 100%;
    display: grid;
    grid-auto-columns: fit-content(20%);
    grid-template-areas: "a a a a a";
    justify-content: center;
    grid-gap: 0.5em;
    margin: 1em 0 2em;
    float: none;

    .profile-card {
      // width: 72px;
      // margin: auto;
      display: flex;
      background-color: transparent;
      border-radius: 100px;
      overflow: hidden;
      border: 3px solid #eee;
      box-shadow: 1px 1px 3px rgba(0,0,0,0.1);

      .baseline-diff-explainer {
        display: none;
      }

    }

  }

  .demographics {
    text-align: left;
    font-size: 1.3em;
    font-weight: 300;
    text-align: center;
    margin: 2em 1em;
    svg {
      background-color: #fff;
      box-sizing: content-box;
      border-radius: 100px;
      padding: 0.5em;
      border: 3px solid #f8f8f8;
      box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
    }
    p {
      text-align: left;
    }
    .small-sample-size {
      display: inline-block;
      background-color: #ffc;
      font-size: 0.8em;
    }
    small.audience-comparable {
      color: #999;
      white-space: nowrap;
      // font-weight: bold;
    }

  }

  .baseline-distinctiveness {
    margin-bottom: 1em;
    .so-what {
      padding: 0.25em 0.5em;
      line-height: 1.6em;
      box-decoration-break: clone; // adds padding to the ending-edge of display: inline elements
      -webkit-box-decoration-break: clone;
    }

    .baseline-explainer {
      position: relative;
      p {
          margin: 0;
        }
    }
  }

  // .active-interest {
  //   visibility: hidden !important;
  // }

  .baseline-distinctiveness {
    .so-what {
      position: relative;
      z-index: 2;
      cursor: pointer;
      margin-left: -1em;
      padding-left: 1em;
      transition: 0.2s;
      &.undetermined {
        background-color: $menu-gray;
        color: rgba(255,255,255,0.75);
      }
    }
    .baseline-explainer {
      background-color: #f5f5f5;
      @include animation($name: slideDownFadeIn, $duration: 0.5s, $delay: 0s);
      display: none;
      padding: 0.75em 1em;
      margin-left: 0.5em;
      margin-top: -0.25em;
    }
    &.baseline-explainer-shown {
      .baseline-explainer {
        display: block;
      }
    }
  }
  &:not(.baseline-summary) {
    .so-what {
      &:hover {
        box-shadow: 0px 0px 0px 3px rgba(0,0,0,0.1);
      }
    }
  }

} //.cluster-summary

#overview .baseline-summary {
  display: grid;
  grid-template-columns: 50% 1fr;
  grid-gap: 3em;

  @media (min-width: 1200px) {
    grid-template-columns: 40% 1fr;
  }

  @media (min-width: 1400px) {
    grid-template-columns: 30% 1fr;
  }

  h4 {
    border-bottom: 1px solid #ddd;
    &:hover {
      background-color: initial !important;
    }

    .clusterName {
      cursor: default;

      small {
        font-size: 0.75em;
        background-color: #f5f5f5;
        padding: 0.25em;
        &:hover {
          background-color: $red;
          color: rgba(255,255,255,0.8);
        }
      }
    }
  }

  .intro, .demographics {
    grid-column-start: 1;
    grid-column-end: 3;
    margin: 0;
  }

  &.no-baseline {
    border-radius: 20px;
    box-shadow: 5px 5px 30px -25px #1CC7D0;
    margin: 3em 0;
    width: 50%;
    margin-left: 25%;
    padding: 0;
    cursor: pointer;

    @media (max-width: 1400px) {
      margin-left: 0;
      width: 100%;
    }


    &:hover {
      // background-color: #f5f5f5;
      box-shadow: 10px 10px 65px -25px #1CC7D0;
      .intro .explanation .button {
        box-shadow: 2px 2px 20px -5px #1CC7D0;
      }
    }

    .intro {
      display: grid;
      grid-template-columns: calc(300px + 1em) 1fr;

      .illustration {
        background: linear-gradient(290deg, #FF4F81, #B84592, #8e43e6, #3369e6, #1cc7d0, #2dde98);
        background-size: 800% 800%;
        padding: 1em 2em 0 0;
        animation: illustrationBGScroll 25s ease infinite;
        @keyframes illustrationBGScroll {
            0%{background-position:0% 11%}
            50%{background-position:100% 90%}
            100%{background-position:0% 11%}
        }
      }

      .explanation {
        padding: 1em;
        h4 {
          margin: -1em -1em 1em;
          font-size: 1em;
          span {
            font-size: 1.3em;
          }
        }
        .button {
          // @include linear-gradient(-135deg, $green-gradient);
          // background-color: #1CC7D0;
          background-color: $menu-gray;
          text-shadow: 1px 1px 0px rgba(0,0,0,0.25);
        }
      }
    }
  }

  .demographics {
    p {
      text-align: center;
      font-size: 1.3rem;
    }
  }

  .baseline-distinctiveness {
    font-size: 1.3em;
    &.message {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    .so-what {
      margin-left: -0.75em;
      padding-left: 0.75em;
    }
  }
}
