.module-grid .module.page-title {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  min-height: 1em !important;
  background-color: transparent;
  box-shadow: none;
  margin-bottom: 0;

  p {
    color: #888;
    max-width: 800px;
    margin-bottom: 0.5em;

    a.active {
      border-bottom-width: 1px;
      border-bottom-style: solid;
      padding-bottom: 2px;
    }
  }

  .vis-options {
    float: right;
    clear: right;
  }
}
