.engagement-dashboard {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  grid-template-areas:
    "Timeline Timeline Timeline Timeline Timeline"
    "Heatmap Heatmap Heatmap Options-Column Options-Column"
    "Heatmap Heatmap Heatmap Options-Column Options-Column"
    "Heatmap Heatmap Heatmap Options-Column Options-Column"
    "Heatmap Heatmap Heatmap Options-Column Options-Column";

  .engagement-timeline { grid-area: Timeline; }
  .engagement-grid { grid-area: Heatmap; }
  .options-column { grid-area: Options-Column }
  .options-column {
    display: grid;
    grid-gap: 1rem;
  }
}
