// TODO this is mostly 1:1 from modal-edit-report,
.modal-edit-folder {
  .modal {
    right: 0px;
    position: fixed;
    top: 0px;
    margin: 0px;
    height: 100%;
    @include animation($name: slideLeft, $duration: 0.4s, $delay: 0.2s);
    padding: 0;
    outline: none;

    header {
      position: fixed;
      top: 0px;
      width: 600px;
      height: 4em;
      background-color: $blue;
      color: #fff;
      // margin: 0 -1em;
      padding: 1em;

      h3 {
        text-overflow: ellipsis;
        overflow: hidden;
        width: calc(100% - 50px);
        white-space: nowrap;
        font-weight: 300;
      }
      .close-modal {
        padding: 0.75em;
        color: #fff;
      }
    }

    footer {
      position: absolute;
      bottom: 0px;
      height: 3em;
      padding: 0 1em;
      width: calc(100% - 2em);
    }

    .inner {
      position: absolute;
      top: 4em;
      overflow: auto;
      height: calc(100% - 8em);
      padding: 1em;
      width: 100%;

      section {
        margin-bottom: 1em;
        padding-bottom: 1em;
        border-bottom: 1px solid $light-gray;

        &.delete-folder {
          font-size: 0.8em;

          a:not(.button).remove {
            background-color: #fee;
            color: rgba(200,0,0,.8);
            padding: 0px 3px;
          }
        }

        h4 {
          font-size: 1.3em;
          color: $dark-gray;
          font-weight: 300;
        }

        &.access-list {

          ul {
            // padding-left: 1em;
            max-height: 400px;
            overflow: auto;
            border: 2px solid $light-gray;

            li {
              // list-style-type: disc;
              // margin-left: 1em;
              font-size: 0.9em;

              label {
                padding: 0.5em;
                border-bottom: 1px solid $light-gray;
                position: relative;

                &:hover, &.checked {
                  background-color: $light-gray;
                  border-bottom: 1px solid #ddd;
                }

                &.company {
                  border-bottom: 2px solid $medium-gray;
                  em {
                    display: block;
                    font-size: 0.8em;
                    margin-left: 1.8em;
                  }
                }

                .creator-badge {
                  font-size: 0.75em;
                  display: inline-block;
                  position: absolute;
                  right: 0.5em;
                  top: 0.5em;
                  border: 2px solid #ccc;
                  border-radius: 4px;
                  background: #ddd;
                  padding: 0.2em 0.5em;
                }

                .can-edit-status {
                  border: 2px solid #ccc;
                  border-radius: 4px;
                  // padding: 0 5px;
                  font-size: 0.75em;
                  display: inline-block;
                  position: absolute;
                  right: 0.5em;
                  top: 0.5em;

                  span {
                    padding: 2px 5px;
                    display: inline-block;
                    transition: 0.1s;
                  }
                  span:first-child {
                    border-right: 2px solid #ccc;
                    padding-right: 5px;
                  }

                  .edit {
                    color: #bbb;
                  }
                  .view {
                    color: $blue;
                  }

                }

                &.can-edit {
                  .edit {
                    color: $blue;
                  }
                  .view {
                    color: #bbb;
                  }
                }

                &:not(.checked) {
                  .can-edit-status {
                    display: none;
                  }
                }

              }

            }
          }

        } // .access-list

      } // section

    } // .inner
  }
}
