.range-bar {
  width: 100%;
  position: relative;
  .bar {
    transition: background 250s;
    width: 100%;
    height: 15px;
  }
  .highlight-value {
    color: $blue;
    font-size: 0.85em;
    transition: 250ms;
    position: absolute;
    left: 0px;
    top: 0;
    height: 20px;
    width: 20px;
    transform: rotate(45deg) translate(-10px, 10px);
    span {
      white-space: nowrap;
    }
  }

  .highlight-label {
    font-weight: 400; // left: -60px;
    // float:left;
    margin-bottom: 1.8em;
    margin-left: 10px;
  }

  .highlight-value {
    border-left: 1px dashed $blue;
    background-color: $blue;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);


    left: 0px; // left: 0px;
    // height: 50px;
    opacity: 0;
    &.highlight {
      opacity: 1;
    }
    span:last-of-type {
      // height: 50px;
      // padding-top: 2em;
      // margin-top: 2em;
      // margin-left: -1px;
      strong {
        font-weight: 500;
        margin-left: 10px; // float: left;
      }
    }
  }

  .min,
  .max {
    font-size: 0.85em;
    color: #999;
    position: absolute; // transition: 250s;
  }

  .max {
    // float: right;
    right: 0;
    &.active {
      color: #4DB930;
    }
  }

  .min {
    // float: left;
    left: 0;
    &.active {
      color: #c00;
    }
  }

}

