.metric-icon {
  display: inline-block;
  // border: 5px solid $light-gray;
  border-radius: 50%;
  a {
    border-radius: 50%;
    background: linear-gradient($pink-gradient);
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.1);
    box-sizing: content-box;
  }
  &.bioKeywords {
    a {
      background-color: $teal;
    }
  }
  &.hashtags {
    a {
      background-color: $red;
    }
  }
  &.mentions {
    a {
      background-color: purple;
    }
  }
  &.tweetKeywords {
    a {
      background-color: $blue;
    }
  }
  &.influencers {
    a {
      background-color: $yellow;
    }
  }
  .fa {
    color: #fff;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  }
}
