
// TODO delete or consolidate, too much fragmentation for all these components in the heatmap, many repeated and double applied styles

.asset-row .score-cell {
  // text-align: right;
  padding: 5px;
  height: 80px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // width: 80px;
  // float: none;
  // display: inline-block;
  // margin-right: -4px;
  // border-right: 1px solid #999;
}

.asset-row .is-hidden{
  display: none;
}

.asset-row .score-cell span {
  display: block;
}

.asset-row .score-cell span.primary {
  font-weight: bold;
}
.asset-row .score-cell span.secondary {
  font-size: 0.8em;
  opacity: 0.5;
}

.asset-row .profile-card .score-cell {
  padding: 5px;
}
