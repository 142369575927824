/*==================================================================
=Notes
----------------------------------------------------------------- */
.report-notes{
  .charCount{
    float: right;
    font-weight: 300;
  }
  .message{
    float: right;
    padding-left: 1em;
    color: red;
    font-size: .8em;
  }
  span{
    font-weight: 300;
    font-size: 12px;
  }
  textarea{
    margin-bottom: 0px;
    min-height: 300px;
  }
  .hovertip{
    top: -69px;
    margin-left: 200px;
  }
}
