// *:not('.pika-table') makes this not overwrite pikadate datepicker.
button, .button {
  appearance: none;
  background-color: $light-gray;
  border: 0;
  border-radius: $base-border-radius;
  color: $darkish-gray;
  cursor: pointer;
  display: inline-block;
  font-family: $base-font-family;
  font-size: 1em;
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  line-height: 1.5em;
  padding: 10px;
  text-decoration: none;
  transition: background-color $base-duration $base-timing;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  outline: 0;

  &.fill {
    display: block;
    width: 100%;
  }

  &.primary {
    background-color: $action-color;
    color: #fff;
    transition: 0.4s;

    &:hover,
    &:focus {
      background-color: shade($action-color, 40%);
    }
  }

  &.is-dark{
    background-color: $medium-gray;
    color: #fff;
    border: 1px solid $base-border-color;
    transition: 0.1s;

    &:hover,
    &:focus {
      color: $medium-gray;
      background-color: #fff
    }
  }

  &.inline {
    padding: 2px 10px;
    width: auto;
    font-weight: 500;
  }

  &.large {
    font-size: 1.25em;
    font-weight: 600;
  }

  &:hover,
  &:focus {
    background-color: shade($darkish-gray, 20%);
    color: #fff;
  }

  &.active {
    background-color: #ddd;
    color: shade($action-color, 20%);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover,
    &:focus {
      background-color: $action-color;
    }
  }

  &.remove {
    background-color: #fee;
    color: rgba(200, 0, 0, .5);

    &:hover,
    &:focus,
    &.open {
      background-color: #c00;
      color: #fff;
    }
  }

  &.add {
    // box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.1);
    background-color: #fff;
    color: $teal;

    &:hover,
    &:focus {
      box-shadow: 0px 5px 20px -3px rgba(0, 0, 0, 0.2);
      background: #fff;
      color: $teal;
    }
  }

  &.is-outlined {

    &.is-primary {
      color: $action-color;
      border: 1px solid $action-color;
      background-color: #fff;
      transition: 0.1s;

      &:hover,
      &:focus {
        color: #fff;
        background-color: $action-color;
        border-color: $action-color;
      }
    }

    &.is-warning {
      color: $medium-gray;
      border: 1px solid $orange-gradient;
      background-color: #fff;
      transition: 0.1s;

      &:hover,
      &:focus {
        color: #fff;
        background-color: $orange-gradient;
        border-color: $medium-gray;
      }
    }

    &.is-white {
      color: $medium-gray;
      border: 1px solid $base-border-color;
      background-color: #fff;
      transition: 0.1s;

      &:hover,
      &:focus {
        color: #fff;
        background-color: $medium-gray;
        border-color: $medium-gray;
      }
    }

    &.is-secondary{
      color: $dark-blue;
      border: 1px solid $dark-blue;
      background-color: #fff;
      transition: 0.1s;

      &:hover,
      &:focus {
        color: #fff;
        background-color: $dark-blue;
        border-color: $dark-blue;
      }
    }
  }

  &.is-small {
    font-size: small;
    font-weight: normal;
    padding: 8px;
  }

  &.is-xsmall {
    font-size: smaller;
    font-weight: normal;
    padding: 6px;
  }

  & > .icon {
    &.is-right {
      //margin-left: 0.25rem;
      margin-right: calc(-0.5em - 1px);
    }

    &.is-left{
      //margin-left: 0.25rem;
      margin-left: calc(-0.5em - 1px);
    }


    &.is-small {
      font-size: small;
      font-weight: normal;
    }
  }
}

.button-group {
  display: flex;

  & > .button, & > button {
    display: block;
    border-radius: 0;
    margin-left: 1px;

    &:first-child {
      border-top-left-radius: $base-border-radius;
      border-bottom-left-radius: $base-border-radius;
      margin-left: 0;
    }

    &:last-child {
      border-top-right-radius: $base-border-radius;
      border-bottom-right-radius: $base-border-radius;
    }

  }
}

.pika-table button {
  cursor: pointer;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  outline: none;
  border: 0;
  margin: 0;
  width: 100%;
  padding: 5px;
  color: #666;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  background: #f5f5f5;
}
