.report-builder .date-input {
  > section {
    display: flex;
    flex-direction: row;
    label {
      max-width: 250px;
      &:last-child {
        margin-left: 1em;
      }
    }
    > button {
      margin-left: 1em;
    }
    select{
      width: 20%;
      margin-right: 1em;
      margin-bottom: 0px;
    }
  }
}
