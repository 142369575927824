
$colors: (
  1: rgba(31,119,180,1),
  2: rgba(174,199,232,1),
  3: rgba(255,127,14,1),
  4: rgba(255,187,120,1),
  5: rgba(44,160,44,1),
  6: rgba(152,223,138,1),
  7: rgba(214,39,40,1),
  8: rgba(255,152,150,1),
  9: rgba(148,103,189,1),
  10: rgba(197,176,213,1),
  11: rgba(140,86,75,1),
  12: rgba(196,156,148,1),
  13: rgba(227,119,194,1),
  14: rgba(247,182,210,1),
  15: rgba(127,127,127,1),
  16: rgba(199,199,199,1),
  17: rgba(188,189,34,1),
  18: rgba(219,219,141,1),
  19: rgba(23,190,207,1),
  20: rgba(158,218,229,1)
);

// TODO this code is not specific to cluster-sub-nav, should be moved to a more general location
#world-container.standardCategorical,
.modal-container.standardCategorical {
  @each $num,
  $color in $colors {
    $spot: $num - 1; // Tribe sidebar borders
    .sub-clusters .cluster-sub-nav.cluster-order-#{$num} {
      border-color: $color;
      color: $menu-gray;
      &:hover,
      &.active,
      &.held {
        background-color: $color;
        color: #fff;
      }
    } // .cluster-sub-nav
    .hiddenCluster:nth-of-type(#{$num}) {
      border-left: 5px solid;
      border-color: $color;
      color: $menu-gray;

      &:hover, &.active, &.held {
        background-color: $color;
        color: #fff;
      }
    }
    // Cluster stats on bar charts
    .bar-graph .bar .cluster-stats span:nth-of-type(#{$num}) {
      background-color: $color;
      border-color: $color;
    } //.bar-graph .bar .cluster-stats span
    // Cluster bar graphs
    .cluster-bar-graph a {
      .bar[data-color-order='#{$num - 1}'] {
        border-color: $color;
      }
      .hovered .bar[data-color-order='#{$num - 1}'] {
        background-color: $color;
      }
    } //.cluster-bar-graph a
    .cluster-bar-graph.spot_#{$spot} a .spot_#{$spot} .bar {
      background-color: $color;
    } // Members Profiles image borders
    .member-profile.member.spot_#{$spot} {
      border-color: $color;
    }
    .map-options label:nth-of-type(#{$num}).map-shown .toggle {
      background-color: $color;
    }
    .metric-instance .bar .cluster-stats span[data-color-order='#{$num}'] {
      background-color: $color;
      border-color: $color;
    }
  }
}

