.aton-about-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;

  > a {
    text-decoration: none;
    padding: 1rem;
    color: $base-font-color;

    &:first-of-type {
      grid-column-start: 2;
    }

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }

    h3,
    p {
      margin-bottom: 0;
    }
  }
}

.aton-about-img {
  display: block;
  margin: 0 auto;
  height: 13rem;
}

.taxonomy,
.data-sources {
  .info-notification {
    margin-top: 2rem !important;
  }
}

.data-sources {
  h3 {
    font-weight: 300;
    margin-top: 1em;
    margin-bottom: 0;
  }

  .data-sources-list {
    li {
      list-style-type: disc;
      margin-left: 1em;
    }
  }
}
