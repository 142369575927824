.audience-source {
  width: 24%;
  ul li, a {
    word-wrap: break-word;
    a {
      display: block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .filter-header{
    font-weight: bold;
    border-bottom: 2px solid #eee;
    display: table;
    cursor: pointer;
    &:not(:first-of-type) {
      margin-top: 1em;
    }
  }
  .union-boolean{
    font-weight: 300;
    font-style: italic;
    display: block;
  }
  .content-period{
    display: block;
  }
}
