@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes midFade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes slideRightFadeIn {
  0% {
    left: -20px;
    opacity: 0;
  }
  100% {
    left: 0px;
    opacity: 1;
  }
}

@keyframes slideDownFadeIn {
  0% {
    top: -40px;
    opacity: 0;
  }
  100% {
    top: 0px;
    opacity: 1;
  }
}

@keyframes slideDownFadeInHiddenClusters {
  0% {
    top: -25px;
    opacity: 0;
  }
  100% {
    top: 0px;
    opacity: 1;
  }
}

@keyframes slideDownFadeInSourceActions {
  0% {
    opacity: 0;
    bottom: -30px;
  }
  100% {
    opacity: 1;
    bottom: -32px;
  }
}

@keyframes slideDownFadeInSourceActionsTwoItems {
  0% {
    opacity: 0;
    bottom: -60px;
  }
  100% {
    opacity: 1;
    bottom: -63px;
  }
}
@keyframes slideDownFadeInSourceActionsThreeItems {
  0% {
    opacity: 0;
    bottom: -90px;
  }
  100% {
    opacity: 1;
    bottom: -93px;
  }
}

@keyframes focusModalArticle {
  0% {
    left: 250px;
    opacity: 0;
  }
  100% {
    left: 0px;
    opacity: 1;
  }
}

@keyframes focusModalArticleOut {
  0% {
    left: 0px;
    opacity: 1;
  }
  100% {
    left: -250px;
    opacity: 0;
  }
}

@keyframes slideDownAutoSuggest {
  0% {
    transform:scaleY(0);
    top: 45px;
    opacity: 0;
  }
  100% {
    transform:scaleY(1);
    top: 45px;
    opacity: 1;
  }
}

@keyframes traitMetaSlideIn {
  0% {
    // bottom: -20%;
    opacity: 0;
  }
  100% {
    // bottom: calc(50% - 2.25em);
    opacity: 1;
  }
}

@keyframes slideUpFadeIn {
  0% {
    top: 40px;
    opacity: 0;
  }
  100% {
    top: 0px;
    opacity: 1;
  }
}

@keyframes slideDownFadeInBackButton {
  0% {
    top: -70px;
    opacity: 0;
  }
  100% {
    top: 40px;
    opacity: 1;
  }
}

@keyframes slideUpFadeOut {
  0% {
    top: 0px;
    opacity: 1;
  }
  100% {
    top: -40px;
    opacity: 0;
  }
}

@keyframes slideDownFadeOut {
  0% {
    top: 0px;
    opacity: 1;
  }
  100% {
    top: 40px;
    opacity: 0;
  }
}

@keyframes slideUp {
  0% {
    top: 40px;
  }
  100% {
    top: 0px;
  }
}

@keyframes bigSlideUpFadeIn {
  0% {
    top: 140px;
    opacity: 0;
  }
  100% {
    top: 0px;
    opacity: 1;
  }
}

@keyframes fadeInShadow {
  0% {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  }
}

@keyframes slideDownIn {
  0% {
    top: -40px;
  }
  100% {
    top: 0px;
  }
}

@keyframes slideLeft {
  0% {
    right: -600px;
  }
  100% {
    right: 0px;
  }
}

@keyframes slideRight {
  0% {
    left: -100%;
  }
  100% {
    left: 10%;
  }
}

@keyframes navActions {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.65;
  }
}

@keyframes traitSlideOut {
  0% {
    width: 0%;
    opacity: 0;
  }
  50% {
    opacity: 1
  }
  100% {
    width: 100%;
  }
}

// Usage:
// @include animationName('slideUpFadeIn, 5s, 2s');
// or
// @include animation($name: slideUpFadeIn, $duration: 5s, $delay: 2s);

@mixin animation($name: slideUpFadeIn, $duration: 0.2s, $delay: 0s, $fillMode: both) {
  animation-name: $name;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-fill-mode: $fillMode;
}

@mixin animationBounce($name: slideUpFadeIn, $duration: 0.2s, $delay: 0s) {
  animation-name: $name;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(.5,0,.5,2);
}
