.card {
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(0,0,0,0.25);
  border-radius: 4px;
  &.hover:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    background-color: #f5f5f5;
  }
}

