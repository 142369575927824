body {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  // font-size: modular-scale(1);
  // line-height: $heading-line-height;
  // margin: 0 0 $small-spacing;
}

p {
  margin: 0 0 $small-spacing;
}

a {
  color: $action-color;
  text-decoration: none;
  transition: color $base-duration $base-timing;

  &:active,
  &:focus,
  &:hover {
    color: darken($action-color, 25%);
  }
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}

/*=========================================
Affinio Typography
Define typefaces and high-level font use patterns
for headlines, paragraph styles, list styles, etc.

"When typography is on point, words become images."
=========================================*/


/*=========================================
Headlines

Generally speaking:
  h1: title of audience, title of app ("Followers of Nike")
  h2: title of page/tab ("Members' Profiles")
  h3: title of section or modal ("Top Hashtags")
  h4: title of subsection or form label ("Tribe Colors", "Here's how it works")
=========================================*/

h1 {
}

h2 {
  font-size: 1.8em;
  font-weight: 300;
  margin-bottom: 0.5em;
}

h3 {
  font-size: 1.3em;
  font-weight: 600;
  margin-bottom: 0.5em;
}

h4 {
  font-size: 0.9em;
  font-weight: 400;
  margin-bottom: 0.5em;
  color: #999;
  // text-transform: uppercase;
  // letter-spacing: 0.05em;
}

h5 {
  font-weight: 900;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 10px 5px;
  border-bottom: 1px solid #333;
  font-size: 0.8em;
}

.content-proper h5 {
  margin-bottom: 20px;
}

h5[href] {
  cursor: pointer;
}

h6 {
}
