.traits-dashboard {
  width: 50%;
  // height: 350px;
 .network-vis-box {
    display: block;
    padding-top: 0;
  }
  .network-graph{
    position: relative;
    // margin-top: -40px;
    float: none;
    padding: 0 !important;
    margin-left: auto;
    margin-right: auto;
    // max-width: 400px;
    // border-radius: 50%;
    // border: 10px solid #F8F9FA;
    overflow: visible !important;
    height: 500px;
  }
  > svg {
      padding: 2em;
      background-color: #fff;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.05);
      // border-radius: 50%;
      overflow: visible;
      // margin-left: auto;
      // margin-right: auto;
      // max-width: 400px;
      display: block;
  }
  h3{
    color: #fff;
  }
  ul{
    color: #fff;
    text-shadow: 1px 1px 3px rgba(0,0,0,.2);
    font-size: .8em;
    max-height: 200px;
    overflow-y: auto;
  }
}
