.boolean-switcher {
  cursor: default;
  color: #999;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  // color: #3F9EEA;
  .switch {
    visibility: hidden;
    color: #eee;
    cursor: pointer;
    margin-top: 5px;
    z-index: 2;
    font-size: 14px;
    position: absolute;
    margin-left: -10px;
    transition: 0.1s;
    background-color:#999;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.2);
    padding: 10px;
    box-sizing: content-box;
    border-radius: 4px;
  }
  &:hover {
    .switch {
      visibility: initial;
    }
  }
  .switch:after {
    content: "";
    position: absolute;
    top: 0em;
    left: 50px;
    box-sizing: border-box;
    border: .5em solid black;
    border-color: transparent transparent#999#999;
    transform-origin: 0 0;
    transform: rotate(135deg);
  }
  .boolean{
    &:hover{
      color: #3F9EEA;
    }
  }
}
