.csv-upload {
  .csv-confirm-steps {
    h4 {
      // font-size: 1.3em;
      // font-weight: 400;
      // color: $menu-gray;
    }
    ol {
      li {
        // color: $dark-blue;
        list-style-type: decimal;
        list-style-position: inside;
        // font-weight: 500;
        // font-size: 1.2em;
        margin-bottom: 0.25em;
        // background-color: #eee;
        // padding: 1em;
        .icon-button {
          cursor: pointer;
        }
        img {
          // margin-top: 1em;
          display: block;
        }
      }
    }
  }
  .file-info {
    h3 {
      margin-bottom: 0.25rem;

      &:nth-of-type(2) {
        margin-top: 1rem;
      }
    }
    .csv-radio{
      display: block;
      margin: 0;
      // font-size: 0.8em;
      // font-weight: 400;
    }
  }
  .helper{
    color: #999;
    font-size: .85em;
    line-height: 180%;
    margin-bottom: 1em;
    margin-top: 1em;
    clear: both;
  }
  .import-type-selector{
    a {
      cursor: pointer;
      display: inline-block;
      background-color: #eee;
      color: $menu-gray;
      border-radius: 4px;
      padding: 0.5em;
      margin: 0 0.5em 0.5em 0;
    }
    .active{
      background-color: $blue;
      color: #fff;
    }
  }
  .no-file {
    input {
      display: none;
    }
  }
}
