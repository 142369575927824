.top-interests {
  width: calc(50% - 1em);
  .interests {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    .profile-card {

      .baseline-diff-explainer {
        display: none;
      }

      .member-image {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .preferences {
    .select-checkbox {
      float: right;
      padding-right: 30px;
      position: absolute;
      width: auto;
      margin-left: 10px;
      top: -30px;
      right: 0px;
    }
  }
}
