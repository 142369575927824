.talkwalker-integration {
  display: flex;
  padding-top: 1em;

  .lead {
    width: 100%;
    h3 {
      em {
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
      }
    }


  }

  .aside {
    width: 100%;
    .network-graph {
      max-width: 100%;
      margin: 0 auto;
      @media screen and (min-width: $xlarge-screen) {
        max-width: 100%;
      }
    }
  }

  .ad-accounts {
    width: auto;
    padding-right: 3em;
  }

  .audience-size {
    .userForm label span {
      font-weight: initial;
      font-size: initial;
    }
  }
}

.format-hover-tip {
  display: flex;
  align-items: center;
}

.format-hover-tip h3 {
  margin-right: 10px;
}

.format-description {
  width: 500px  !important;
  margin-left: 120px  !important;
}

.report-format {
  display: flex;
  align-items: center;
}

.report-format input[type=text] {
  height: 40px;
  width: 60%;
  margin-left: 5px;
}

.cluster-list {
  margin-bottom: 1rem;
  list-style: disc;
  padding-left: 2rem;
}

.note-text {
  font-size: 0.8em;

  .note {
    font-weight: bold;
  }
}