.datepicker .active {
  background-color: #00A79B !important;
  background-image: none !important;
}

/*==================================================================
=Datepicker
----------------------------------------------------------------- */

.bootstrap-datetimepicker-widget {
  width: 100%;
  left: 0px;
  position: static;
  margin: auto !important;
}

.bootstrap-datetimepicker-widget table th.next::after {
  position: relative;
  top: -2px;
  content: "»";
}

.bootstrap-datetimepicker-widget table th.prev::after {
  position: relative;
  top: -2px;
  content: "«";
}

.datepicker table tr td.today {
  background: transparent !important;
  color: #999 !important;
}

.datepicker table tr td.today::before {
  border: none !important;
}
