.modal-keynav {
  ul {
  font-size: 0.8em;

    li {
      margin-bottom: 5px;

      strong {
        background-color: #eee;
        display: inline-block;
        padding: 2px 5px;
      }

      &.inactive {
        opacity: 0.5;
      }
    }
  }
}
