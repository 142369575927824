$border-radius: 4px;
$box-shadow: 10px 25px 30px rgba(0, 0, 0, 0.15);

.dropdown-button {
  display: inline-block;
  min-width: 4rem;
  margin: 0 auto;
  position: relative;
  border-radius: $border-radius;
  background-color: $light-gray;
  margin-right: 0.75rem;

  // box-shadow: $box-shadow;

  &:hover,
  &:focus {
    background-color: shade($darkish-gray, 20%);

    .toggle {
      color: #fff;
    }
  }

  &.open {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .toggle {
    display: flex;
    cursor: pointer;
    padding: 6px 10px;
    line-height: 1.5em;

    > div {
      &:first-of-type {
        width: 85%;
        line-height: 2rem;
      }
      &:last-of-type {
        width: 15%;
        .fa {
          vertical-align: middle;
        }
      }
    }
  }
  .buttons {
    display: block;
    position: absolute;
    min-width: 100%;
    z-index: 1;
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: opacity 0.5s ease;
    background-color: #fff;
    // box-shadow: $box-shadow;

    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;

    &.open {
      opacity: 1;
      height: auto;
    }

    li {
      padding: 0.5rem;
      cursor: pointer;
      font-weight: 300;
      background: #fff;

      &:first-of-type {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }

      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }

      &.disabled {
        cursor: initial;
        opacity: 0.3;
      }
    }
  }
}
