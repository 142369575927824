.branded-dash-logo {
  height: 40px;
  width: auto;
}

.campaign-header .options.user-settings-nav {
  float: right;
  height: 50px;
  line-height: 50px;
  padding: 0px;
  background-color: darken($menu-gray,5);
  position: relative;
  margin-left: 50px;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.campaign-header .options.user-settings-nav:hover {
  //box-shadow: 0px 0px 5px rgba(0,0,0,0.25);
  background-color: lighten($menu-gray, 5);
}

.campaign-header .options.user-settings-nav.open {
  background-color: lighten($menu-gray, 20);
}

.campaign-header .options.user-settings-nav > span {
  color: #eee;
  display: block;
  padding: 0px 20px;
  font-size: 0.8em;
  cursor: pointer;
}

.campaign-header .options.user-settings-nav.open > span {
  color: #eee;
  background-color: darken($dark-gray,10);
}

.campaign-header .options.user-settings-nav > span:after {
  content: "▼";
  font-size: 0.5em;
  margin-left: 10px;
  vertical-align: top;
}

.campaign-header .options.user-settings-nav .flyout {
  line-height: 100%;
  background-color: $light-gray;
  /* border-left: 1px solid #eee; */
  /* border-right: 0px; */
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  /* max-height: 0px; */
  position: fixed;
  width: 320px;
  height: calc(100% - 50px);
  right: -415px;
  top: 50px;
  box-shadow: 0px 2px 3px rgba(0,0,0,0.25);
  z-index: 1000; // so it overlays the in-report subnav
  /*padding-bottom: 350px;*/
}

.campaign-header .options.user-settings-nav.open .flyout {
  right: -35px;
}

.campaign-header .options.user-settings-nav.open .flyout .move-left {
  margin-right: 20px;
}

.campaign-header .options.user-settings-nav .flyout .lower-buttons {
  position: absolute;
  bottom: 0px;
  background-color: #fff;
  width: 285px;
  box-shadow: 2px 0px 0px 2px rgba(0,0,0,0.3);
}

.campaign-header .options.user-settings-nav .flyout .lower-buttons a {
  color: $dark-gray;
  font-weight: 400;
  font-size: 0.8em;
  display: block;
  padding: 10px;
  line-height: 20px;
  background-color: darken($light-gray, 2);
  letter-spacing: 0em;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  border-bottom: 1px solid lighten($light-gray, 10);
}

.campaign-header .options.user-settings-nav .flyout .lower-buttons a.signout {
  color: #c00;
}

.campaign-header .options.user-settings-nav .flyout .lower-buttons a:hover {
  background-color: #eee;
}

.campaign-header .options.user-settings-nav .flyout textarea {
  margin: 10px;
  width: calc(100% - 20px);
  display: block;
  height: 150px;
  border: 2px solid #ccc;
}

.campaign-header .options.user-settings-nav .flyout li > span strong {
  font-weight: 400;
  font-size: 1.2em;
}

.campaign-header .options.user-settings-nav .flyout li > span span.got-it {
  position: absolute;
  top: 0px;
  right: -50px;
  padding: 10px;
  color: #ccc;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 0.05em;
  font-size: 0.08em;
  cursor: pointer;
  line-height: 10px;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.campaign-header .options.user-settings-nav .flyout li:not(.read) > span:hover span.got-it {
  right: 0px;
}

.campaign-header .options.user-settings-nav .flyout li > span span.got-it:hover {
  color: #00A79B;
}

.campaign-header .options.user-settings-nav .flyout li > span p {
  color: darken($medium-gray,10);
  padding: 5px 0px;
  line-height: 150%;
  display: block;
}

.campaign-header .options.user-settings-nav .flyout ul.notifications {
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  padding-bottom: 0px;
  height: 100%;
  width: 320px;
  padding-bottom: 340px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.campaign-header .options.user-settings-nav .flyout ul.notifications > div > li {
  width: 295px;
}

.campaign-header .options.user-settings-nav .flyout ul.notifications li > span {
  color: $dark-gray;
  font-weight: 400;
  font-size: 0.8em;
  letter-spacing: 0.05em;
  display: block;
  padding: 10px 30px 10px 10px;
  line-height: 20px;
  border-left: 4px solid #fff;
  margin: 10px;
  background-color: #fff;
  letter-spacing: 0em;
  position: relative;
  overflow: hidden;
  width: calc(100% - 30px);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;

  &:not([href]){
    cursor: default;
  }
}

.campaign-header .options.user-settings-nav .flyout ul.notifications a.button.inline {
  background-color: #ccc;
  margin-top: 6px;
}

.campaign-header .options.user-settings-nav .flyout ul.notifications li.read > span {
  background-color: rgba(255,255,255,0.35);
  border-left-width: 0px !important;

  &:hover {
    background-color: rgba(255,255,255,0.45);
  }
}

.campaign-header .options.user-settings-nav .flyout ul.notifications li > span:hover {
  border-color: #ccc;
  /*width: calc(100% - 27px);*/
  /*box-shadow: 2px 2px 5px 0px rgba(243, 224, 224, 0.15);*/
}

.campaign-header .options.user-settings-nav .unread {
  display: block;
  color: #fff;
  background-color: #f00;
  line-height: 17px;
  height: 20px;
  min-width: 20px;
  width: auto;
  text-align: center;
  margin: 0px 10px;
  font-size: 0.9em;
  font-weight: 900;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  position: absolute;
  top: 14px;
  right: calc(100% - 22px);
  border: 2px solid #fff;
  padding: 0px 4px;
}

.campaign-header .options.user-settings-nav .flyout .mark-all {
  display: block;
  text-transform: uppercase;
  font-size: 0.7em;
  font-weight: 800;
  color: #999;
  float: right;
  padding: 10px 20px 10px;
  letter-spacing: 0.05em;
}
