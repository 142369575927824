.categorical-color-block {
  display: block;
  width: 24px;
  height: 24px;
  float: left;
}

.benchmarks {
  &.content-proper {
    .card {
      padding: 1em;
      margin-bottom:1em;
      margin-right: 1em;
    }
    .asset-grid-container {
      .admin-prefs {
        margin-top: 2em;
      }
      // input {
      //   margin-bottom: 2em;
      // }
    }
  }
}

.settingsIntegrations {
  h3 {
    margin-top: 2rem;

    &:first-of-type {
      margin-top: 0;
    }
  }
}
