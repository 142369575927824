.report-nav {
  .sub-nav {
    visibility: hidden;
    a {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.2s;
    }
    &.show {
      visibility: visible;
      opacity: 1;
      a {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .fa-spinner {
    position: absolute;
    left: 45px;
    top: 15px;
    opacity: 0;
    &.pending {
      opacity: 1;
    }
    &.done {
      opacity: 0;
    }
  }
  .progress-bar {
    position: fixed;
    height: 2px;
    top: 94px;
    left: 0px;
    width: 100%;
    box-sizing: content-box;
    -webkit-box-shadow: 3px 0px 3px 0px rgba(0, 0, 185, 0.2);
    -moz-box-shadow: 3px 0px 3px 0px rgba(0, 0, 185, 0.2);
    box-shadow: 3px 0px 3px 0px rgba(0, 0, 185, 0.2);
    transform: translateX(-100%);
    transition: opacity 250ms, width 250ms, transform 1000ms;
    opacity: 0;
    &.pending {
      opacity: 1;
      animation-duration: 3s;
      animation-name: loadingBar;
    }
    &.done {
      opacity: 0;
      transform: translateX(0%);
      width: 100%;
    }
  }
  @keyframes loadingBar {
    0% { transform: translateX(-100%) }
    10% { transform: translateX(-90% )}
    20% { transform: translateX(-80% )}
    30% { transform: translateX(-70% )}
    40% { transform: translateX(-60% )}
    50% { transform: translateX(-50%)}
    60% { transform: translateX(-40% )}
    70% { transform: translateX(-30% )}
    80% { transform: translateX(-20% )}
    90% { transform: translateX(-10% )}
    100% { transform: translateX(0%) }

  }
}
