.ridge-lines {
  position: relative;
  transition: 0.3s;
  bottom: 0px;
  margin: -1em -1em -1.4em -1em;

  svg.axis {
    left: -1rem;
    width: calc(100% + 2rem);
    position: absolute;
    top: 100px;
    color: #999;

    g.x-axis {
      font-size: 2.5px;
      font-weight: 100;
      .tick {
        line {
          stroke-width: 0.2px;
        }
        text {
          text-anchor: start;
          transform: rotate(60deg) translate(2px, -4px);
        }
      }
    }

    .domain {
      display: none;
    }
  }

  svg.ridges {
    width: 100%;
    transition: 0.3s;
    g {
      transition: 0.3s;
    }
  }

  span {
    display: block;
    text-align: center;
    border-top: 1px solid;
    font-size: 0.65em;
    color: #fff;
    text-shadow: 1px 1px 0px rgba(0,0,0,0.25);
  }

  // TODO: future hover code. Separates the ridges out vertically.
  // Need to set height in ridge-lines to 200px and apply the viewbox to 0 0 100 200
  // -PR
  // &:hover {
  //   bottom: 100px;
  //   svg.ridges {
  //     // margin-bottom: 0px;
  //     & > g > g {
  //       @for $i from 1 through 50 {
  //         g:nth-of-type(#{$i}) {
  //           transform: translate(0,$i*10px);
  //         }
  //       }
  //     }
  //   }
  // }


}
