.members-profiles {
  width: 99%;

  h4 {
    float: left;
  }

  .member {
    width: 4.8%;
  }
}

.member-profile {
  border: 5px solid;
  margin: .1%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.member-profile .pumpup {
  padding-bottom: 100%;
  height: 0px;
}

.member-profile img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100px;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.member-profile {
  display: block; // width: 4.8%;
  // TODO verify removing this doesn't break other things
  // overflow: hidden;
  float: left;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  position: relative;
  color: #222;
}

// Print
// @media print {
//
// }
