/*==================================================================
=Basic Bar Graph
----------------------------------------------------------------- */


.bar-graph {
  padding: 5px 0px;
  margin: 5px 0px;
  clear: both;
  position: relative;
  /*overflow-x: hidden; */
}

.bar-graph .bar,
.bar-graph .lost-bar,
.bar-graph .gained-bar {
  background: rgba(0,0,0,0.08);
  margin-bottom: 1px;
  color: #222;
  white-space: nowrap;
  padding: 5px 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
  -webkit-transition: .4s;
  -moz-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
  position: relative;
  height: 28px;
  z-index: 2;
  font-size: 0.8em;
}

.embedded-media .bar-graph,
.brick .bar-graph {
  margin-bottom: -1px;
  padding-bottom: 0px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  z-index: 2;
}

.embedded-media .bar-graph .bar,
.brick .bar-graph .bar {
  background: transparent;
}

.brick .bar-graph .bar {
  width: 100% !important;
}

.embedded-media .bar-graph .bar label,
.embedded-media .bar-graph .bar a,
.brick .bar-graph .bar label,
.brick .bar-graph .bar a {
  margin-left: 15px;
  position: relative;
  top: -3px;
}

.brick .bar-graph .bar label,
.brick .bar-graph .bar a {
  margin-left: 0px;
}

.brick .bar-graph {
  background-color: rgba(255,255,255,0.75);
}

.bar label, .bar a {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.1;
  z-index: 3;
  transition: color background-color .4s;
  color: #222;

}

.bar label {
   margin-left: 5px;
   left: 0px;
}

.term-relative.bar-graph label, .term-relative.bar-graph a,
.cluster-bias.bar-graph label, .cluster-bias.bar-graph a,
.niche-ranked.bar-graph label, .niche-ranked.bar-graph a {
  overflow: hidden;
  text-overflow: ellipsis;
}

.cluster-bias.bar-graph a:hover, .term-relative.bar-graph a:hover, .niche-ranked.bar-graph a:hover {
  color: #00A79B;
}
// .term-relative.bar-graph label, .term-relative.bar-graph a {
//   overflow-x: visible;
// }

.bar label em, .bar a em {
  padding: 0px 5px;
  font-size: 0.8em;
  opacity: 0.8;
}

.normalized.bar-graph .bar.uncluster,
.cluster-bias.bar-graph .bar.uncluster,
.niche-ranked.bar-graph .bar.uncluster {
  width: 100% !important;
}

.normalized.bar-graph .bar.uncluster,
.cluster-bias.bar-graph .bar.uncluster,
.niche-ranked.bar-graph .bar.uncluster {
  width: 100% !important;
}

.normalized .bar label,
.normalized .bar a,
.niche-ranked .bar label,
.niche-ranked .bar a,
.global-comparison .bar label,
.global-comparison .bar a,
.cluster-bias .bar label
{

  background: rgba(255,255,255,0.8);
  padding: 0px 3px;
}

.timeline .bar label,
.timeline .bar a {
  background: rgba(0,0,0,.05);
  padding: 0px 3px;
  margin-left: 3px;
}

.bar-graph.in-tweet {
  margin: 0px 0px 15px;
  position: absolute;
  bottom: -5px;
  width: calc(100% - 14px);
  z-index: 1;
  left: 6px;
}

.bar-graph.in-tweet .bar .cluster-stats {
  height: 100% !important;
}


.bar-graph .bar .cluster-stats {
  -webkit-transition: .4s;
  -moz-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
  position: absolute;
  height: 32px;
  bottom: 0px;
  width: 100%;
  overflow: hidden;
  // margin-left: -5px;
  height: 3px;
}

.embedded-media .bar-graph .bar .cluster-stats,
.brick .bar-graph .bar .cluster-stats {
  height: 5px !important;
}

.embedded-media .bar-graph .bar .cluster-stats span,
.brick .bar-graph .bar .cluster-stats span {
  height: 100%;
}

.embedded-media:hover .bar-graph .bar .cluster-stats,
.brick:hover .bar-graph .bar .cluster-stats {
  height: 32px !important;
}

.normalized.bar-graph .bar .cluster-stats,
.cluster-bias.bar-graph .bar .cluster-stats,
.global-comparison.bar-graph .bar .cluster-stats,
.niche-ranked.bar-graph .bar .cluster-stats,
.bar-graph .bar:hover .cluster-stats {
  height: 100%;
}

.bar-graph .bar .cluster-stats span,
.profile-card .cluster-stats span,
.user-box .cluster-stats span {
  /*border-right: 2px solid #f00;*/
  overflow: hidden;
  display: block;
  float: left;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 32px;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  width: 0px;
  /*border-left: 3px solid;*/
}

.user-box .cluster-stats span {
  height: 16px;
}

.profile-card .cluster-stats span {
  height: 30px;
}

.cluster-stats span em {
  display: none;
}

.profile-card .cluster-stats span em {
  display: block;
  font-style: normal;
  color: #fff;
  padding: 3px;
  font-weight: 900;
  font-size: 0.8em;
  text-shadow: 1px 1px 0px rgba(0,0,0,0.2);
  line-height: 24px;
  text-align: center;
}


.bar-graph .bar .cluster-stats span:nth-of-type(1) {
  background-color: rgba(31,119,180,1);
  border-color: rgba(31,119,180,1);
}
.bar-graph .bar .cluster-stats span:nth-of-type(2) {
  background-color: rgba(174,199,232,1);
  border-color: rgba(174,199,232,1);
}
.bar-graph .bar .cluster-stats span:nth-of-type(3) {
  background-color: rgba(255,127,14,1);
  border-color: rgba(255,127,14,1);
}
.bar-graph .bar .cluster-stats span:nth-of-type(4) {
  background-color: rgba(255,187,120,1);
  border-color: rgba(255,187,120,1);
}
.bar-graph .bar .cluster-stats span:nth-of-type(5) {
  background-color: rgba(44,160,44,1);
  border-color: rgba(44,160,44,1);
}
.bar-graph .bar .cluster-stats span:nth-of-type(6) {
  background-color: rgba(152,223,138,1);
  border-color: rgba(152,223,138,1);
}
.bar-graph .bar .cluster-stats span:nth-of-type(7) {
  background-color: rgba(214,39,40,1);
  border-color: rgba(214,39,40,1);
}
.bar-graph .bar .cluster-stats span:nth-of-type(8) {
  background-color: rgba(255,152,150,1);
  border-color: rgba(255,152,150,1);
}
.bar-graph .bar .cluster-stats span:nth-of-type(9) {
  background-color: rgba(148,103,189,1);
  border-color: rgba(148,103,189,1);
}
.bar-graph .bar .cluster-stats span:nth-of-type(10) {
  background-color: rgba(197,176,213,1);
  border-color: rgba(197,176,213,1);
}
.bar-graph .bar .cluster-stats span:nth-of-type(11) {
  background-color: rgba(140,86,75,1);
  border-color: rgba(140,86,75,1);
}
.bar-graph .bar .cluster-stats span:nth-of-type(12) {
  background-color: rgba(196,156,148,1);
  border-color: rgba(196,156,148,1);
}
.bar-graph .bar .cluster-stats span:nth-of-type(13) {
  background-color: rgba(227,119,194,1);
  border-color: rgba(227,119,194,1);
}
.bar-graph .bar .cluster-stats span:nth-of-type(14) {
  background-color: rgba(247,182,210,1);
  border-color: rgba(247,182,210,1);
}
.bar-graph .bar .cluster-stats span:nth-of-type(15) {
  background-color: rgba(127,127,127,1);
  border-color: rgba(127,127,127,1);
}
.bar-graph .bar .cluster-stats span:nth-of-type(16) {
  background-color: rgba(199,199,199,1);
  border-color: rgba(199,199,199,1);
}
.bar-graph .bar .cluster-stats span:nth-of-type(17) {
  background-color: rgba(188,189,34,1);
  border-color: rgba(188,189,34,1);
}
.bar-graph .bar .cluster-stats span:nth-of-type(18) {
  background-color: rgba(219,219,141,1);
  border-color: rgba(219,219,141,1);
}
.bar-graph .bar .cluster-stats span:nth-of-type(19) {
  background-color: rgba(23,190,207,1);
  border-color: rgba(23,190,207,1);
}
.bar-graph .bar .cluster-stats span:nth-of-type(20) {
  background-color: rgba(158,218,229,1);
  border-color: rgba(158,218,229,1);
}

.bar-graph .bar {
  position: relative;
  /*overflow: hidden;*/
}

.bar-graph .lost-bar {
  background-color: #c00;
  position: absolute;
  min-width: 2px;
}

.bar-graph .gained-bar {
  background-color: #25b67c;
}

.bar-graph a {
  outline: 0;
}
.bar-graph .isHidden {
  display: none;
}

/*==================================================================
=Snapshot/Growth Bar
----------------------------------------------------------------- */

.growth-bar > .started {
  background-color: #eee;
}

.growth-bar > .bar.started {
  padding: 0px 5px;
}

.growth-bar > .added {
  background-color: #7CBF60;
}

.growth-bar > div {
  float: left;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 32px;
  line-height: 32px;
}

circle {
  z-index: 3;
  position: relative;
}

path, line {
  z-index: 1;
}

.campaign-header h6 {
  width: 220px;
  float: left;
  font-weight: 300;
  height: 50px;
  line-height: 50px;
  font-size: 1em;
  background-color: #222;
  color: #fff;
  padding: 0px 5px;
  text-align: center;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.campaign-header nav.your-campaigns {
  background: #444;
  width: 220px;
}

.campaign-header nav.your-campaigns a {
  line-height: 1em;
  height: auto;
  border-bottom: 1px solid #555;
  padding: 10px 5px;
  display: block;
}

.campaign-header .options select {
  display: inline-block;
  width: auto;
  padding: 4px 5px 4px 10px;
  margin-right: 5px;
  border: 0px solid #eee;
  border-radius: 6px;
  background-color: #eee;
  color: #555;
  font-weight: 500;
  font-size: 0.9em;
}

.campaign-header .options {
  float: right;
  height: 50px;
  line-height: 50px;
  padding: 0px 0px 0px 30px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.campaign-header .options.gear-options {
  padding-left: 10px;
  position: relative;
  float: left;
}

.campaign-header .options.fuller {
  float: none;
  padding-right: 30px;
}

.campaign-header .options span.gear {
  display: block;
  text-align: right;
  cursor: pointer;
}

.campaign-header .options span.gear img {
  height: 18px;
  margin: 10px 0px 12px 5px;
}

.campaign-header .options:hover ul {
  max-height: 100%;
}

.campaign-header .options ul li a:hover {
  color: #3CBFBC;
}

