.spark-bars {
  position: relative;
  height: 50px;
  top: -50px;
  z-index: 3;
  .spark-bar {
    padding-bottom: 1px;
    background-color: #eee;
    display: block;
    float: left;
    position: absolute;
    bottom: 0px;
    transition: 0.2s;

    @for $i from 1 to 40 {
      &:nth-of-type(#{$i}) {
        transition-delay: 0.02s * $i;
      }
    }

  }
}

.modal-focus .spark-bar {
  height: 0;
  @for $i from 1 to 40 {
    &:nth-of-type(#{$i}) {
      transition-delay: 2s + 0.02s * $i;
    }
  }
}

.modal-focus article:not(.active) .spark-bar {
  transition-delay: 0s;
  padding-bottom: 0;
}