/*==================================================================
=Campaign Header
----------------------------------------------------------------- */

#universe-container > header {
  background: #fff;
    -webkit-box-shadow: inset -50px 0px 50px -50px rgba(0,0,0,.7);
  -moz-box-shadow: inset -50px 0px 50px -50px rgba(0,0,0,.7);
  box-shadow: inset -50px 0px 50px -50px rgba(0,0,0,.7);
position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 1001;
  -webkit-box-shadow: inset 0 0px 5px 0px rgba(0,0,0,.8);
     -moz-box-shadow: inset 0 0px 5px 0px rgba(0,0,0,.8);
          box-shadow: inset 0 0px 5px 0px rgba(0,0,0,.8);
          display: none;
}

#universe-container > header nav a {
  display: block;
  line-height: 50px;
  height: 50px;
  overflow: hidden;
  color: #fff;
  font-weight: 1.5em;
  padding: 0px 25px;
    -webkit-box-shadow: inset -50px 0px 50px -50px rgba(0,0,0,.7);
  -moz-box-shadow: inset -50px 0px 50px -50px rgba(0,0,0,.7);
  box-shadow: inset -50px 0px 50px -50px rgba(0,0,0,.7);
float: left;
  font-weight: bold;
  text-transform: capitalize;
}

/*#universe-container > header nav a.active {
  background: #eee;
}
*/

.campaign-header {
  background: $menu-gray;
  color: #eee;
  position: fixed;
  left: 220px;
  width: calc(100% - 220px);
  top: 0px;
  z-index: 100001;
  height: 50px;
  padding-left: 30px;
  // -webkit-box-shadow: 3px 0px 3px 0px rgba(0,0,0,.3);
  //    -moz-box-shadow: 3px 0px 3px 0px rgba(0,0,0,.3);
  //         box-shadow: 3px 0px 3px 0px rgba(0,0,0,.3);
}



.campaign-header h2 a,
.campaign-header h2 span {
  color: #999;
  width: 100%;
  line-height: 50px;
  height: 50px;
  width: 100%;
  padding: 0px 10px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  border-bottom: 1px solid #444;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  position: relative;
  opacity: 0.2;
}

.campaign-header h2 a img,
.campaign-header h2 span img {
height: 30px;
}

.campaign-header h2 {
  height: 50px;
  line-height: 50px;
  margin-left: 0px;
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 1.2em;
  border-bottom: 0px solid #333;
  background-color: #222;
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-left: -220px;
  color: #ccc;
  padding: 0px;
  position: relative;
}

.campaign-header h2 .microinteraction {
  position: absolute;
  display: block;
  font-size: 0em;
  color: #09c;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.campaign-header h2:hover a {
  /*padding-left: 35px;*/
  color: #fff;
  opacity: 1;
}

.campaign-header h2:hover .microinteraction {
  font-size: 1em;
}


.campaign-header h2 {
  width: 220px;
  float: left;
}

.campaign-header h6 {
  width: 220px;
  float: left;
  font-weight: 300;
  height: 50px;
  line-height: 50px;
  font-size: 1em;
  background-color: #222;
  color: #fff;
  padding: 0px 5px;
  text-align: center;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.campaign-header nav.your-campaigns {
  background: #444;
  width: 220px;
}

.campaign-header nav.your-campaigns a {
  line-height: 1em;
  height: auto;
  border-bottom: 1px solid #555;
  padding: 10px 5px;
  display: block;
}

.campaign-header .options {
  float: right;
  height: 50px;
  line-height: 50px;
  padding: 0px 0px 0px 30px;
}

.campaign-header .options.gear-options {
  padding-left: 10px;
  position: relative;
  float: left;
}

.campaign-header .options.fuller {
  float: none;
  padding-right: 30px;
}

.campaign-header .options span.gear {
  display: block;
  text-align: right;
  cursor: pointer;
}

.campaign-header .options span.gear img {
  height: 18px;
  margin: 10px 0px 12px 5px;
}

.campaign-header .options:hover ul {
  max-height: 100%;
}

.campaign-header .options ul li a:hover {
  color: #3CBFBC;
}

.focused-question-footer {
  color: #999;
  padding: 10px;
  font-size: 0.8em;
}

.focused-question-footer p {
  margin-top: 10px;
}
.campaign-header .options .button {
  line-height: 150%;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.campaign-header .options .date-button {
  background: #444;
  border: 2px solid #666666;
  border-top: 4px solid #666666;
  padding: 3px 5px;
  margin-right: -20px;
  font-size: 0.8em;
  font-weight: bold;
  color: #ccc;
}

.campaign-header .options .date-button:hover {
  border-color: #999;
  color: #fff;
}

.campaign-header .tabs {
  float: left;
  height: 50px;
  line-height: 50px;
  // margin: 0px 30px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.campaign-header .tabs a {
  display: block;
  float: left;
  height: 50px;
  line-height: 50px;
  padding: 0px 10px;
  color: $medium-gray;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8em;
  letter-spacing: 0.1em;
}

.campaign-header .tabs a:hover{
  color: #fff;
}

.campaign-header .tabs a.active {
  // background-color: lighten($menu-gray, 10);
  // color: $teal;
  // border-bottom: 4px solid #00A89C;
  color: #fff;
  outline: none;
}

.campaign-header .tabs a.admin.active {
  // background-color: lighten($menu-gray, 10);
  // color: lighten($dark-blue, 30);
  // background-color: $dark-blue;
  // color: $blue;
  // border-bottom: 4px solid #00A89C;
  outline: none;
}

.campaign-header .navButtons {
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid lighten($menu-gray, 20);
  float: left;
}

.campaign-header .navButton {
  display: block;
  height: 50px;
  vertical-align: middle;
  float: left;
  line-height: 50px;
  // padding-left: 10px;
}

.campaign-header .navButton a {
  display: block;
  float: left;
  height: 50px;
  line-height: 50px;
  padding: 0px 10px;
  color: $medium-gray;
  text-transform: capitalize;

  span {
    opacity: 0.5;
  }
}

.campaign-header .navButton a:hover {
  color: white;
}
