.preferences .traits-preferences {
  text-align: center;
  width: 100%;
  position: relative;
  height: 25px;
  margin-top: -5px;
  margin-bottom: -20px;
  transition: 0.5s;

  &.open {
    height: 80px;
    .instance-search-toggle {
      line-height: 22px;
    }
  }

  .instance-search-toggle {
    background-color: #fff;
    border-radius: 50px;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.25);
    line-height: 25px;
    width: 25px;
    height: 25px;
    display: block;
    position: absolute;
    left: 50%;
    bottom: 0px;
    margin-left: -12.5px;
    overflow: visible;
    font-size: 0.8em;
  }

  h5 {
    text-transform: none;
    @include animation($name: slideDownFadeIn, $duration: 0.5s, $delay: 0.2s);
    position: relative;
    letter-spacing: 0em;
    padding: 0;
    margin: 0 0 0.5em 0;
    border: none;
    font-size: 0.8em;
    color: #999;

    // Duplicated from hovertip, don't @ me - JK Apr 2019
    a {
      background: #eee;
      color: #222;
      font-weight: bold;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      border-radius: 50px;
      height: 20px;
      width: 20px;
      line-height: 20px;
      text-align: center;
      position: absolute;
      border: 3px solid #fff;
      font-size: 0.8em;
      box-sizing: content-box;
      top: -4px;
      margin-left: 5px;
    }
  }

}
