.auto-suggest {
  position: relative;
  &.open {
    .results {
      display: block;
    }//results
  }//open
  //main input's add button
  > .button {
    margin-top: -44px;
  }
  .button {
    line-height: 1;
    &[disabled="disabled"] {
      background-color: #ccc;
    }
  }
  .results {
    display: none;
    flex-direction: column;
    position: absolute;
    width: 100%;
    z-index: 3;
    box-shadow: 0px 3px 8px rgba(0,0,0,0.2);
    border: 1px solid $blue;
    margin-top: -12px;
    max-height: 300px;
    overflow-y: auto;
    background-color: white;
    transform-origin:top;
    .result {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      padding: 10px;
      text-align: left;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 85%;
        color: $dark-gray;
        font-weight: 300;
        text-align: left;
        line-height: 1;
        vertical-align: bottom;
        margin-top: 10px;
        padding-right: 10px;
        &:first-child{
          min-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      img {
        max-height: 25px;
        width: 25px;
      }
      .button.inline {
        float: right;
        position: static;
      }
      &.active{
        outline: 1px solid $blue;
        span {
          -webkit-transition: 250ms;
          -moz-transition: 250ms;
          -o-transition: 250ms;
          transition: 250ms;
          font-weight: 300;
          color: $blue;
        }
      }//active
    }//result
  }//results
  .close {
    cursor: pointer;
    font-weight: 700;
    font-size: .9em;
    font-weight:300;
    color: $dark-gray;
    display: flex;
    flex-direction: row;
    padding: 5px;
    justify-content: center;
    align-items: center;
    .glyph {
      font-size: .85em;
      margin-left: 1em;
    }
    &:hover {
      .glyph {
        -webkit-transition: 350ms;
        -moz-transition: 350ms;
        -o-transition: 350ms;
        transition: 350ms;
        color: shade($red, 50%);
      }
    }//:hover
  }//close
}//.dropdown
