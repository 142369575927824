.content-card.report-list {
  background-color: transparent;
  padding: 0px;
  // box-shadow: none;
  z-index: 2;

  li.list-header {
    background-color: #fff;
    border: none;
    padding: 0px;

    button {
      // color: rgba(255,255,255,0.8);
      color: $medium-gray;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 0.05em;
      font-size: 0.8em;

      &:first-child {
        padding-left: 55px;
      }
    }
  }

  .button, button {
    border-radius: 0;
  }

  li.pending {
    display: none;
  }

  &.show-failed {
    li.failed {
      display: list-item;
    }
  }

  &.show-pending {
    li.pending {
      display: list-item;
    }
  }

  li {
    background-color: transparent;
    position: relative;
    line-height: 2.75em;
    border-bottom: 1px #eee solid;
    background-color: #fff;
    &.is-dragged-over {
      background-color: #f5f5f5;
    }
    &:last-child {
      border-bottom: none;
    }
    // &:hover {
    //   nav {
    //     display: block;
    //     // animation: fadeIn 0.4s ease-in;
    //   }
    //   div.column:last-child {
    //     opacity: 0;
    //     // padding-right: 96px;
    //     // padding-left: 16px;
    //   }
    // }
    &.hidden {
      display: none;
    }
    &.pending, &.failed {
      a {
        opacity: 0.4;
        cursor: grab;
      }
    }
    &.failed a {
      color: #c00;
    }

    label.report-check {
      // display: none;
      border: 2px solid $blue;
      height: 20px;
      width: 20px;
      margin: 0.5em 0;
      line-height: 100%;
      text-align: center;
      border-radius: 2px;
    }

    &.list-header {
      label.report-check {
        border-color: $light-gray;
        &.checked {
          background-color: $blue;
          background-image: url(/assets/images/icon-checkmark-white.png);
          background-size: 12px;
          background-repeat: no-repeat;
          background-position: 50% 50%;
        }
      }
    }

    &.checked {
      background-color: #f5f5f5;
      label.report-check {
        border: 2px solid $blue;
        background: $blue;
        background-image: url(/assets/images/icon-checkmark-white.png);
        background-size: 12px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
      }
    }

    .column-checkbox {
      font-size: 1.5em;
    }

    .column-checkbox:hover {
      .report-check {
        // border: 2px solid $blue;
        background-color: $blue;
      }
    }

    // &:hover:not(.list-header), &.checked {
    //   label.report-check {
    //     display: block;
    //   }
    //   i.fa {
    //     display: none;
    //   }
    // }

    // &.list-header:hover {
    //   i.fa {
    //     display: inline-block;
    //   }
    // }
  }
  nav {
    display: none;
    height: 2.75em;
    position: absolute;
    right: 0;
    top: 0;
    color: $dark-gray;
    // bottom: 0;

    & > button {
      float: left;
    }
  }
  .list-header {
    border-color: #aaa;
  }
  li > a {
    height: 2.75em;
    width: 100%;
    display: block;
    // display: -webkit-flex;
    // display: flex;
    // -webkit-flex-direction: row;
    // flex-direction: row;
    color: inherit;
    outline: none;
    // transition: 0.1s;


    &:hover {
      background-color: $light-gray;
    }
  }
  .column {
    text-align: right;
    padding: 0 10px;
    vertical-align: middle;
    overflow: hidden;
    white-space: nowrap;
    float: left;
    // border-right: 1px #eee solid;
    &:last-child {
      padding-right: 20px;
      // text-align: right;
      // border-right: none;
    }
    &:first-child {
      padding-left: 15px;
    }
    // transition: opacity 0.2s ease-in;
  }
  .column-empty {
    padding: 50px;
    h2 {
      text-align: center;
      width: 100%;
    }
    p {
      text-align: center;
      width: 100%;
    }
    .button {
      width: auto;
    }
    .button.primary {
      background-color: $blue;
      border-radius: 4px;
      width: auto;
    }
    .loading-spinner {
      text-align: center;
    }
    .empty-filler {
      width: 25px;
      display: inline-block;
    }
    .track-help {
      color: $menu-gray;
      font-weight: 500;
    }
  }
  .column-name {
    font-weight: 400;
    text-overflow: ellipsis;
    text-align: left;

    svg{
      height: 1em;
      width: 1em;

      &.x-twitter-icon {
        height: 1.1em;
        width: 1.1em;
        margin: 1px 6px -3px 1px;
        transform: translateY(1px);
      }
    }

    .fa {
      font-size: 1.2em;
      padding: 0 0 0 0px;
      // min-width: 1.5em;
      border-right: none;
      text-align: left;
      overflow: visible;
    }

    .fa-twitter {
      color: #1da1f2;
    }
    .fa-instagram {
      color: #262626;
    }
    .fa-pinterest {
      color: #bd2126;
    }
    .fa-pied-piper {
      color: #009600;
    }
    .fa-folder {
      color: #888;
    }
  }
  .column-name {

    p.no-reports {
      padding: 20px 20px 0px;
    }

    span.collecting {
      font-style: italic;
      font-size: 0.8em;
      background-color: transparentize($yellow, 0.4);
      color: $dark-gray;
      padding: 1px 3px;
      border-radius: 4px;

      .fa {
        text-align: center;
        font-size: 0.8em;
        opacity: 0.25;
        &:hover {
          opacity: 1;
        }
      }
    }

    span.translated-to {
      background-color: rgba(0,0,0,0.1);
      box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.35);
      text-align: center;
      line-height: 100%;
      padding: 0.4em 0.25em 0.1em;
      margin-right: 0.5em;
      top: 2px;
      left: 5px;
      position: relative;
      border-radius: 4px;
      display: inline-block;
    }


  }

  // .column {
  //   box-sizing: border-box !important;
  // }

  .column-checkbox {
    width: 40px;
  }

  .column-name {
    width: calc(100% - 570px);
  }
  .builder {
    width: calc(100% - 530px);
  }
  .column-clusters {
    width: 100px;
    text-align: right;
  }
  .column-owner {
    width: 120px;
    text-overflow: ellipsis;
    text-align: right;
  }
  .column-members {
    width: 100px;
    text-align: right;
  }
  .column-created {
    width: 130px;
    text-align: right;
  }
  .column-options {
    width: 40px;
    text-align: right;
    color: $blue;
  }
  .column-info {
    width: 40px;
    text-align: right;
    color: $blue;
  }

  .report-info-section {
    padding-left: 15px;
    // display: flex;
    background-color: $light-gray;
    color: #333;
    // text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    .report-filters-section {
      min-height: 5rem;
      margin-right: 3em;
      display: flex;
      flex-wrap: wrap;
      width: 76%;
      // width: calc(100% - 500px);
      .filter {
        // min-width: 30%;
        max-width: 80%;
        text-overflow: ellipsis;

        .filter-header{
          // border-bottom: 1px solid #222;
          // display: block;
        }
        li {
          background-color: rgba(0,0,0,0);
          overflow-x: hidden;
          text-overflow: ellipsis;
          border: none;
          h4 {
            line-height: 0.85em;
          }
        }
        margin-right: 1em;
      }
    }
    .recycle-button{
      // display: inline-block;
    }
  }

  // .column-clusters {
  //   @include flex-width(112px);
  // }
  // .column-owner {
  //   @include flex-width(20%);
  // }
  // .column-members {
  //   @include flex-width(112px);
  // }
  // .column-created {
  //   @include flex-width(160px);
  // }

  .column-clusters, .column-owner, .column-members, .column-created  {
    font-size: 0.9em;
  }

  header {
    position: relative;
    height: 2.75em;
    width: 100%;
    display: -webkit-flex;
  	display: flex;
  	-webkit-flex-direction: row;
  	flex-direction: row;
    button.column {
      // font-size: 1em;
      // background-color: transparent;
      height: 100%;
      border-radius: 0;
      position: relative;
      // padding-right: 20px;

      &:hover, &.active {
        background-color: rgba(0,0,0,0.1);
        // color: #555;
      }

      .fa {
        position: absolute;
        // right: 0px;
        bottom: 0px;
        left: 12px;
        font-size: 1em;
        opacity: 0.2;
        color: #000;
      }

      &.column-clusters .fa,
      &.column-members .fa,
      &.column-owner .fa,
      &.column-created .fa {
        left: inherit;
        right: 5px;
      }

    }

    &.ascending button.column .fa {
      bottom: inherit;
      top: 0px;
    }
    button, .button{
      height: 100%;
      width: 2.5em;
      font-size: 1.125em;
      background-color: transparent;
      padding: 10px;
      &:hover {
        background-color: #fff;
        // color: #00867c;

        .fa-trash {
          color: #c00;
        }
      }
      &:focus {
        background-color: shade($action-color, 20%);
        // color: #fff;

        .fa-trash {
          color: #fff;
        }
      }
    }
  }
}

.report-selection-nav {
    background: $blue;
    color: #eee;
    position: fixed;
    left: 0px;
    width: 100%;
    top: 0px;
    z-index: 100002;
    height: 50px;
    line-height: 50px;
    padding-left: 250px;
    @include animation($name: slideDownFadeIn, $duration: 0.1s, $delay: 0s);
    box-shadow: 0px 0px 50px 5px darken($blue, 20);
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background-color: darken($blue, 10);
      box-shadow: 0px 0px 5px darken($blue, 20);
    }
}

.report-list-options {
  font-size: 0.8em;
  padding: 1em;
  border-top: 1px solid #ccc;
  height: 200px;
  width: 230px;
  position: fixed;
  bottom: 0px;
  left: 0px;

  h3 {
    font-size: 1.2em;
    font-weight: 300;
  }

  label {
    color: $blue;
    display: block;
    // margin-left: 0.5em;
    padding: 0.25em 0.5em;
    margin: 0px;

    &.disabled {
      opacity: 0.4;
    }

    input {
      margin: 0px;
    }

  }
}
