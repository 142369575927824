/*==================================================================
=color blocks, for custom categoricals
----------------------------------------------------------------- */

.color-block {
  display: block;
  width: 24px;
  height: 24px;
  float: left;
}

label.color-set span {
  float: left;
  margin-right: 10px;
  margin-bottom: 0px;
}

.userForm label.color-set {
  padding: 5px 0px;
  margin-bottom: 0px;
}

label.color-set input {
  margin-right: 10px !important;
  margin-bottom: 0px;
}

label.color-set strong {
  display: block;
  float: left;
}
