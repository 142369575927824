.delta-timeline-gallery {
  width: 50%;
  hr {
    border-bottom: solid 1px #c7c7c8;
    // width: calc(100% + 50px);
    padding-right: 50px;
    // margin-left: -30px;
  }
  h4 {
    // line-height: 2em;
  }
  .spark-line.delta.empty {
    height: calc(234px);
    &:hover {
      background-color: rgba(0,0,0,0);
    }
  }
}
