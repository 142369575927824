/*==================================================================
=Cluster Name Modal
----------------------------------------------------------------- */

.name-suggestions {

  .backdrop {
    background-color: rgba(0,0,0,0.9);
  }

  .modal {
    outline: none;
    margin-top: 2em;
    margin-bottom: 0px;
    // width: calc(100% - 4em);
    // height: calc(100% - 4em);
    // margin: 2em;
    // width: 100%;
    // height: 100%;
    // margin: 0px;
    // @include animation($name: bigSlideUpFadeIn, $delay: 0.5s, $duration: 0.5s);
    // position: absolute;
    // overflow: auto;
    // overflow: hidden;
    // transition: 0s;
  }

  .modal-main {
    padding: 0px;
    margin: -20px;

    .main-ui {
      // float: left;
      // width: 50%;
      padding: 2em;
      box-sizing: border-box;
    }

    aside {
      // float: right;
      // width: 50%;
      // position: absolute;
      // height: 100%;
      // overflow: auto;
      // right: 0px;
      // border-left: 1px solid #ccc;
      // background-color: #eee;
    }

  }

  .bar-graph .cluster-stats {
    height: 100%;
  }
  .bar.uncluster {
    width: 100% !important;
    clear: both;
    margin-bottom: 10px;
    height: 20px;
  }
  .bar-graph {
    margin: 0;
  }
  footer hr:first-of-type {
    margin-top: 10px;
  }
  footer h3 {
    float: left;
    margin-bottom: 10px;
  }
  h4 {
    float: right;
    margin-bottom: 0;
    color: #999;
    font-weight: 400;
  }
  .hovertip{
    position: relative;
    float: none;
    z-index: 999;
    display: inline-block;
    margin-left: 5px;
    top: 0px;
    text-align: left;
    a {
      display: block;
      position: relative;
      z-index: 2;
    }
  }

  .cluster-stats,
  .full {
    cursor: default;
  }
  .full {
    clear: both;
  }
  .helper {
    margin-bottom: 10px;
    line-height: 140%;
    // font-size: 85%;
    color: #3d3e3e;
  }
  .helper:last-of-type {
    margin-bottom: 0;
  }  

  .recommended-name-block {
    // background-color: lighten($yellow,20);
    // background-color: darken($blue, 40);
    // background-color: #222;
    // padding: 20px;
    // color: rgba(255,255,255,0.9);
    // border-top: 1px solid #ccc;
    // margin: 2em -1em -1em calc(-1em - 8px);
    // border-left: 8px solid #000;
    font-weight: 300;

    p {
      font-size: 1.5em;
      margin-bottom: 1.5em;
      max-width: 100%;

      &.unnamed {
        font-size: 1em;
      }

      .recommended-name {
        font-style: italic;
        font-weight: 900;
        // background-color: lighten($yellow, 20);
        // color: lighten($yellow, 10);
        border-bottom: 2px solid $yellow;
        // padding: 0px 5px;
      }

      a.use-recommended-name {
        // border-bottom: 2px solid $teal;
        font-size: 0.6em;
        font-weight: 600;
      }

    }

  } //.recommended-name-block

  .traits {
    // background-color: darken($blue, 50);
    // background-color: #ddd;
    // color: #eee;
    // margin: -20px;
    padding: 20px;

    p,
    h3 {
      // color: #eee;
    }
  }

}



