.oauth-button {
  padding: 0;
  display: table;
  border-collapse: collapse;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  margin-bottom: 1em;

  &:hover,
  &:focus {
    background: #eee;
    color: initial;
  }

  > div {
    display: table-cell;
    height: 30px;
    // border: 1px solid #ddd;
    vertical-align: middle;  /* needed for Safari */
  }

  .text {
    width: 300px;
  }

  .icon {
    padding: 0 12px;
    background:#eee;
    color: #777;
    &.twitter {
      background: $dark-gray;
      
      > svg {
        width: 20px;
        height: 20px;
        transform: translateY(4px);
        fill:#fff;
    }
    }
    &.facebook {
      color:#fff;
      background: #3b5999;
    }
    &.google {
      color:#fff;
      background: #4285F4;
    }
  }

  .clear:hover {
    .fa {
      color: red;
    }
  }
}

.admin-wrapper {
  .oauth-button {
    cursor: initial;
    &:hover,
    &:focus {
      background: initial;
      color: initial;
    }
    .clear {
      cursor: pointer;
    }
  }
}
