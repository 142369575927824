.scatter-plot {
  background-color: #fff;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.2);
  padding: 20px;
  text-align: center;
  margin: auto;
  width: 49%;
   > header {
    border-bottom: none !important;
  }
   select {
    position: absolute;
    width: auto;
    top: 1em;
    right: 1em;
    padding-right: 2em;
  }
   circle {
    position: relative;
  }
  // this makes prod builds take forever
  //  @for $i from 1 to 20 {
  //   .c3-chart-line:nth-child(#{$i}) {
  //     @for $j from 1 to 200 {
  //       circle:nth-of-type(#{$j}) {
  //         @include animation($name: slideUpSVG, $duration: 0.5s, $delay: (0.03s * $j) + (0.2s * $i));
  //       }
  //     }
  //   }
  // }
  article.tooltip {
    background-color: rgba(240,240,240,0.8);
    box-shadow: 1px 1px 3px rgba(0,0,0,0.2);
    padding: 1em;
    text-align: left;
    border-left: 4px solid;
    border-radius: 4px;
    li, strong {
      white-space: nowrap;
    }
  }
}
