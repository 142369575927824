.network-graph-export {
  width: 99%;
  .network-graph {
    display: table;
    max-height: 300px;
    padding: 1em 0;
    > svg {
      height: 300px;
      max-height: 300px;
    }
  }
  .download-button {
    margin-top: 1em;
  }
  .preferences .network-rect-bg {
    width: auto;
    padding-right: 20px;
  }
}
