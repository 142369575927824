.taxonomy-selector-actions {
  .fa-pencil,
  .fa-files-o {
    color: lighten(#333333, 10);
    transition: 0.2s;
    &:hover{
      color: #333333;
    }
  }
  .fa-trash {
    transition: 0.2s;
    color: lighten(#E53935, 10);
    &:hover{
      color: #E53935;
    }
  }
}
