.modal-sign-in {
  .modal {
    padding: 0;
    width: 500px;
    margin-left: calc(50% - 250px);
  }
  .backdrop {
    background: rgba(230, 230, 230, 0.8) 50% 0% no-repeat;
  }
}
