.taxonomy-creator {
  .step {
    padding: 2rem;
    margin-bottom: 2rem;

    .affinio-table {
      .ember-table {
        max-height: 350px;
      }
      table {
        width: 100%;
      }
    }

    &.meta {
      display: grid;
      grid-template-columns: 1fr 5fr;

      h4 {
        display: inline-block;
      }

      input {
        max-width: 60rem;
      }

      .icon-display {
        cursor: default;

        img {
          height: 5rem;
          width: 5rem;
          display: block;
          margin: 0.25rem 1rem 1rem 0;
          background: rgba(0,0,0,0.1);
          border-radius: 5px;
          padding: 0.5rem
        }
      }

      .hovertip {
        float: none;
        display: inline-block;
        top: -20px;
        margin: 0;

        &.hovered {
          z-index: 6;
        }
      }
    }

    .pad-hovertip {
      .hovertip {
        margin-left: 8rem;
      }
    }
  }

  .application-selection {
    margin-top: 1rem;
  }

  .tabbed-interface {
    margin-top: 2rem;
    .content {
      min-height: initial;
      background: none;
      > label {
        margin-top: 1rem;
      }
    }
  }

  .affinio-table {
    margin-top: 1rem;
  }
}
