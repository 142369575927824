.content-proper > .main-search {
  margin-bottom: 0;
}

// Main audiences index report search

.content-card {
  &.filter {
    margin-bottom: 10px;
    box-shadow: none;
    background-color: transparent;
    // padding: 20px 0px;

    .searchfield {
      border-bottom: 1px solid #ccc;
      margin: 0px -20px;
    }

    .reportSearch {

      input[type="text"] {
        border-bottom: none;
        background-position: 10px 10px;
        padding-left: 40px;
        margin: 0px;

        &:focus {
          box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.1);
          background-color: rgba(255,255,255,0.5);
        }
      }
    }

    select.networkToggle {
      background-color: transparent;
      border: none;
      // border-bottom: 1px solid #ccc;
      color: #999;
      padding: 4px;
    }

    .search-tips {
      position: absolute;
      opacity: 0;
      background: #fff;
      padding: 20px 20px 10px 20px;
      top: 70px;
      margin: 0px -20px;
      width: calc(100% - 0px);
      box-sizing: border-box;
      z-index: -1;
      transition: .5s;
      -webkit-transition: .5s;
      -moz-transition: .5s;
      -o-transition: .5s;


      p {
        padding: 10px 20px;
        background-color: $blue;
        color: #fff;
        margin: -20px -20px 5px -20px;
      }

      ul.possible-keys {
        li {
          display: block;
          // float: left;
          // width: calc(50% - 10px);
          width: calc(100% + 40px);
          // margin-right: 10px;
          margin-bottom: 5px;
          font-size: 0.9em;
          white-space: nowrap;
          border-bottom: 1px solid #eee;
          padding: 10px 20px;
          margin: 0px -20px;
          cursor: pointer;

          &:hover {
            background-color: #eee;
          }

          &:last-of-type {
            border-bottom: none;
            margin-bottom: -5px;
          }

          strong {
            // background-color: #eee;
            // padding: 0px 3px;
            // border-bottom: 1px solid #333;
          }
        }
      }
    }

    &.show-search-tips {
      // padding-bottom: 50px;
      transition: .5s;
      -webkit-transition: .5s;
      -moz-transition: .5s;
      -o-transition: .5s;

      .search-tips {
        opacity: 1;
        z-index: 1;
        box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
      }
    }

  }
}



