.crud-table {
  padding: 2rem;

  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    input {
      max-width: 24rem;
      margin-bottom: 0;
    }
  }
}
