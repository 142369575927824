.requirements-list {
  margin-bottom: 1rem;

  .fa-times{
    color: #E53935;
  }
  .fa-check{
    color: #4CAF50;
  }
}
