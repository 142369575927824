.modal-edit-report-configuration {
  background: linear-gradient(45deg, rgba(142, 67, 230, 0.85) 0%, rgba(51, 105, 230, 0.85) 100%);
  color: #fff;
  .close-modal{
    color: #fff;
    &:hover{
      color: red;
    }
  }

  .x-details {
    p {
      max-width: initial !important;
      margin-bottom: 0.5rem;
    }
  }
}
