.fixed-side-nav {
  top: 140px;
  left: 0px;
  position: fixed;
  width: 230px;
  height: calc(100% - 120px);
  overflow: auto;
  &::-webkit-scrollbar {
    background: transparent;
    width: 4px;
  }
  &:hover::-webkit-scrollbar {
    background: #ccc;
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: $blue;
  }

  button, .button {
    width: 100%;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 0px;
    font-weight: 400;
    // color: #555;
    // font-size: 0.9em;

    &:focus {
      color: $darkish-gray;
    }

    &.active {
      font-weight: 800;
      // color: #222;
    }

    &:hover {
      background-color: rgba(0,0,0,0.05);
      color: #000;
    }

    &.make-new:hover {
      color: $blue;
    }

    .fa {
      width: 2em;
      text-align: center;
    }

    em {
      opacity: 0.7;
      margin-left: 5px;
      font-size: 0.8em;
    }
  }


  li {
    padding: 0;

    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: none;
    }
  }

  &.reports-list-side-nav {
    max-height: calc(100% - 340px);
    overflow: hidden;

    li, a {
      height: 40px;
    }

    .folder-list {
      max-height: calc(100% - 120px);
      overflow: auto;
      &::-webkit-scrollbar {
        background: transparent;
        width: 4px;
      }
      &:hover::-webkit-scrollbar {
        background: #ccc;
      }
      &:hover::-webkit-scrollbar-thumb {
        background-color: $blue;
      }


    }

  }


}
