/*=====================
Master style file.
We should deconstruct this to be real modular.
-Phil, May 2016
We should delete most of this file :D
-Jason, Jan 2018
=====================*/

/*==================================================================
=Primary Styles
----------------------------------------------------------------- */

body {
  background: $light-gray 50% 0% no-repeat;
  color: #222;
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  overflow-x: hidden;
}

//Yo move this or remove this, just a hack for now - JC June 2017
// TODO delete this, content-proper now has a '.canvas' class to apply the white background
// .reportsReportVersionClusterCompareIndex {
//    .content-proper {
//          background-color: rgba(0,0,0,0);
//          box-shadow: 0px 0px rgba(0,0,0,0);
//     }
// }

.view-bar button.active, .view-bar .button.active,
.view-bar.raw button.raw, .view-bar.raw .button.raw,
.view-bar.termNormalized button.term-normalized, .view-bar.termNormalized .button.term-normalized,
.view-bar.clusterNormalized button.cluster-normalized, .view-bar.clusterNormalized .button.cluster-normalized,
.view-bar.relativized button.relativized, .view-bar.relativized .button.relativized,
.view-bar.proportionalized button.proportionalized, .view-bar.proportionalized .button.proportionalized,
.view-bar.clusterBiased button.clusterBiased, .view-bar.clusterBiased .button.clusterBiased,
.view-bar.size a.size,
.view-bar.density a.density,
.view-bar.influencerAffinity a.influencerAffinity,
.view-bar.influencerUniqueness a.influencerUniqueness,
.view-bar.engagement a.engagement {
  background: $blue;
}

.cluster-engagement {
  padding: 20px 0px;
  border-bottom: 2px solid #222;
  border-top: 1px solid #ccc;
  margin-bottom: 20px;
  margin-top: 20px;
}

.content > p {
  margin-bottom: 25px;
  line-height: 180%;
  font-weight: 300;
  font-size: 1.1em;
}

hr {
  clear: both;
  margin: 20px 0px;
  border-top: none;
  border-bottom: 1px solid rgba(0,0,0,0.2);
}

#universe-container {
  /*border-left: 50px solid #222;*/
}

#world-container {
  position: relative;
  /*margin: auto;*/
  /*width: 1200px;*/
  /*background-color: #fff;*/
  max-width: 100%;
  padding: 10px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 70px;
  padding: 0px 20px 50px 30px;
}

.learn-more {
  border-bottom: 1px dashed $medium-gray;
}

/*==================================================================
=Content Proper
----------------------------------------------------------------- */

.content {
  margin-bottom: 0px;
}

.chart {
  // TODO this actually needed? - js 2017
  // margin-bottom: 50px;
  /*margin-left: -50px;*/
}

#campaign-upper {
  margin-bottom: 20px;
}

.content-card {
  left: 220px;
  margin-left: 0px;
  margin-bottom: 30px;
  background-color: #fff;
  padding: 20px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  width: calc(100% - 220px);
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}

.content-wrapper,
.content-proper {
  background-color: rgba(0,0,0,0);
  box-shadow: 0px 0px 0px rgba(0,0,0,0);
  padding: 20px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  &.canvas {
    background-color: #fff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  }
}

.content-proper {
  left: 220px;
  top: 40px;
  margin-left: 0px;
  margin-bottom: 30px;
  position: relative;
  width: calc(100% - 180px);

  &.full-width {
    left: 0px;
    width: 100%;
  }

  &.data-sources {
    width: calc(100% - 220px);
  }
}

// TODO: Hacky.
// Agreed very hacky - RS
// This is dirty - JK
// Y'all need a bath -PR
// lol wat -JS
.audiencesIndex,
.audiencesAudienceTribeUpdate,
 {
  #campaign-nav, .content-proper {
    top: 10px;
  }
  // .fixed-side-nav {
  //   top: 80px;
  // }
}

.settingsCompanyMetrics,
.settingsApiAccess {
  #overview {
    position: relative;
    top: 80px;
  }
}

// TODO: likely need more from above
.audiencesAudienceUpdate,
.audiencesAudienceOverview,
.audiencesAudienceAdvertising,
.audiencesAudienceTribeAdvertising,
.audiencesAudienceCompetitive,
.audiencesAudienceTribeCompetitive {
  #campaign-nav {
    top: 80px;
  }
  .content-proper {
    top: 10px;
  }
}

// TODO: also dirty
.audiencesAudienceContentExplore .content-proper,
.audiencesAudienceTribeContentExplore .content-proper {
  min-height: 0;
}

// .content-proper#campaigns .has-sub-nav {
//   padding-top: 30px;
// }

// .content-proper#campaigns .has-sub-nav nav.sub-nav {
//   margin-top: -50px;
// }

// TODO: view-bar can probably go.
// header element in metric-visualization now
// -PR, April 2017
.view-bar {
  color: #222;
  padding: 10px 0px;
  text-align: left; //TODO talk to phil about this - JK Dec 2016
  border-bottom: 2px solid #222;
}

.view-bar h4 {
  float: left;
  font-size: 1em;
}

.view-bar p.explainer {
  text-align: left;
  font-style: italic;
  font-size: 0.8em;
  margin-top: 10px;
  clear: both;
}

.view-bar a.button {
  float: right;
  margin-left: 5px;
}

.view-bar.standalone {
  border-bottom: 1px solid #aaa;
  padding-bottom: 5px;
}

.view-bar.standalone a.button {
  float: left;
  margin-left: 0px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.engagement h3 {
  position: relative;
}

// > span {
//   text-align: left;
//   padding-left: 50px;
// }

p.your-tracked {
  text-align: left;
  // font-weight: bold;
  color: #999;
}

.campaignBreadcrumb {
  color: #bbb;
  text-transform: uppercase;
  font-weight: 900;
  font-size: .9em;
}

.clusterHeadline {
  border-bottom: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

#overview .tribe-overview .vis {
  padding: 0px;
}

/*==================================================================
=Self-Serve Signup
----------------------------------------------------------------- */

#universe-container.signIn,
#universe-container.signInMs,
#universe-container.resetPassword,
#universe-container.signUp {
  #world-container {
    padding: 0px;
    margin-top: 30px;
  }
  .sign-in-section,
  .reset-password-section {
    width: 500px;
    margin: 4.5em auto 0;

    footer {
      margin-top: 1em;
      color: $medium-gray;
      font-size: 0.875em;
      text-align: center;

      p {
        display: block;
        padding: 0.5em;
        margin: auto;
      }
    }
  }
}

.sign-in-form {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 50%;
  overflow: auto;
}

/* Start: Details at the bottom, image at the top. */
// .sign-in-promo {
//   background-color: #232146;
//   color: white;
//   padding: 2% 5% 5% 5%;
//   position: fixed;
//   top: 0;
//   bottom: 0;
//   left: 50%;
//   right: 0;

//   ul {
//     li {
//       list-style: initial;
//       margin-left: 2rem;
//     }
//   }
// }

// .sign-in-promo h3 {
//   padding-top: 3%;
// }
/* End: Details at the bottom, image at the top. */


/* Start: Details at the top, image at the bottom. */
.sign-in-promo {
  padding: 2% 5%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  // background-image: url(/assets/images/login-promo-image-40.png);
  // background-size: cover;
  // background-position: center;
  color: #4B0082;
  font-family: "Poppins", sans-serif;
  background: white;

  h3{
    letter-spacing: 0.04cm;
  } 

  h1 {
    line-height: 2em;
    font-weight: 700;
    font-size: 2em;
    padding-bottom: 2%;
  }

  p {
    line-height: 1.5em;

    > b {
      margin-top: 6px;
      font-size: 1.1em;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    opacity: 0.5; // Adjust opacity as needed
    z-index: -1; // Ensure the image is behind the text
  }

  a.alt-color {
    color: #89e7ff;

    &:active,
    &:focus,
    &:hover {
      color: darken(#89e7ff, 25%);
    }
  }

  ol {
    li {
      list-style: initial;
      margin-left: 2rem;
    }
  }
}
/* End: Details at the top, image at the bottom. */

/* Start of the promo section with the image on the top and the content on the bottom. */
// .sign-in-promo {
//   background-color: #232146;
//   color: white;
//   padding: 2% 5% 5% 5%;
//   position: fixed;
//   top: 0;
//   bottom: 0;
//   left: 50%;
//   right: 0;

//   ul {
//     li {
//       list-style: initial;
//       margin-left: 2rem;
//     }
//   }
// }

// .sign-in-promo h3 {
//   padding-top: 3%;
// }

// .sign-in-promo h2 {
//   font-weight: bold;
// }
/* End of the promo section with the image on the top and the content on the bottom. */

.back-to-home {
  display: block;
  width: 100px;
  position: relative;
  top: -10px;
  left: 20px;
}

nav.sub-nav {
  z-index: 2;
  background-color: #fff;
  padding: 0px 20px;
  padding-left: 250px;
  height: 44px;
  position: fixed;
  top: 50px;
  left: 0px;
  width: calc(100% - 240px);
  box-sizing: content-box;
  -webkit-box-shadow: 3px 0px 3px 0px rgba(0,0,0,.2);
     -moz-box-shadow: 3px 0px 3px 0px rgba(0,0,0,.2);
          box-shadow: 3px 0px 3px 0px rgba(0,0,0,.2);
  &:not(.lower-influencers-nav){
    z-index: 10;
  }
}

nav.sub-nav.righty a {
  float: right;
}

nav.sub-nav.righty a:first-of-type {
  margin-right: 20px;
}

nav.sub-nav a {
  padding: 0px 15px;
  line-height: 44px;
  font-size: 0.7em;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.05em;
  display: inline-block;
  color: #666;
  outline: none;
  white-space: nowrap;

  &.disabled {
    opacity: 0.35;
    cursor: default;
    &:hover {
      color: #666;
    }
  }
}

nav.modal-sub-nav {
  border-bottom: 1px solid #eee;
  background-color: #fff;
  height: 35px;
  margin-bottom: 10px;
}

nav.modal-sub-nav a {
  padding: 0px 15px;
  line-height: 36px;
  font-size: 0.75em;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.05em;
  display: inline-block;
  color: #bbb;
  outline: none;
}

nav.influencers-nav a.influencer-nav-query {
  display: none;
}

nav.sub-nav a.active,
nav.sub-nav a:hover,
nav.modal-sub-nav a:hover,
nav.modal-sub-nav a.active,
nav.steps a.active,
nav.influencers-nav.page-1 a.influencer-nav-1,
nav.influencers-nav.page-2 a.influencer-nav-2,
nav.influencers-nav.page-3 a.influencer-nav-3,
nav.influencers-nav.page-4 a.influencer-nav-4,
nav.influencers-nav.page-5 a.influencer-nav-5,
nav.influencers-nav.page-1 a.influencer-nav-main,
nav.influencers-nav.page-2 a.influencer-nav-main,
nav.influencers-nav.page-3 a.influencer-nav-main,
nav.influencers-nav.page-4 a.influencer-nav-main,
nav.influencers-nav.page-5 a.influencer-nav-main,
nav.influencers-nav.query a.influencer-nav-query {
  color: #3F9EEA;
  display: inline-block;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

nav.steps a.active {
  background-color: $blue;
  color: #fff;
}


nav.sub-nav.lower-influencers-nav {
  top: inherit;
  position: relative;
  bottom: 0px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 0px;
  border-top: 1px solid #eee;
  background-color: #fff;
  width: 100% !important;
  margin-left: 0px !important;
  left: 0 !important;
  padding-right: 0px;
  // border: 1px solid #eee;
  box-shadow: none;
  background-color: #fff;
  clear: right;
  // box-shadow: 0px 30px 30px -10px rgba(0,0,0,0.2);
}

nav.sub-nav.lower-influencers-nav a {
  line-height: 40px;
  color: #aaa;
}

nav.sub-nav.lower-influencers-nav a:hover {
  color: #00A89C;
}

.active-tags span.active-tag {
  font-weight: 900;
}

.active-tags {
  -webkit-transition: .4s;
  -moz-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
  text-align: left;
}

.active-tags span {
  display: block;
  cursor: pointer;
  padding: 8px;
  /*border-bottom: 1px solid #eee;*/
  box-sizing: content-box;
  font-size: 0.8em;
  height: 20px;
  color: #999;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.active-tags span:not(.active-tag):hover {
  background-color: #eee;
}

.active-tags span.a {
  display: none;
}

.active-tags span.b {
  padding-left: 40px;
}

.active-tags span.c {
  padding-left: 60px;
}

.active-tags span small {
  color: #aaa;
}
// TODO move all this to interest vis css file
// aside.influencers-sidebar {
//   height: calc(100% - 50px);
//   position: relative;
//   width: 200px;
//   background: #fff;
//   margin-top: 0px;
//   // margin-left: -1em;
//   padding: 0px;
//   padding-top: 0px;
//   box-sizing: border-box;
//   // box-shadow: 0px 0px 3px rgba(0,0,0,0.25);
//   z-index: 4;
//   // overflow: auto;
//   // float: left;
// }

aside.influencers-sidebar .faded-categories a {
  color: #ddd !important;
  cursor: auto;
  background-color: transparent !important;
}

aside.influencers-sidebar a {
  padding: 10px 20px;
  display: block;
  font-size: 0.8em;
  /*text-transform: uppercase;*/
  font-weight: 400;
  /*letter-spacing: 0.05em;*/
  color: #999;
  outline: none;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

aside.influencers-sidebar a:hover {
  background-color: #eee;
}

aside.influencers-sidebar.all a.influencer-nav-main,
aside.influencers-sidebar.brands a.brands,
aside.influencers-sidebar.celebrities a.celebrities,
aside.influencers-sidebar.community a.community,
aside.influencers-sidebar.entertainment a.entertainment,
aside.influencers-sidebar.media a.media,
aside.influencers-sidebar.place a.place,
aside.influencers-sidebar.society a.society,
aside.influencers-sidebar.sport a.sport,
aside.influencers-sidebar.uncategorized a.uncategorized,
aside.influencers-sidebar .active-tags .highlit,
aside.influencers-sidebar a.active {
  color: #00A89C;
  font-weight: bold;
}

aside.influencers-sidebar.owned a.brands,
aside.influencers-sidebar.owned a.celebrities,
aside.influencers-sidebar.owned a.community,
aside.influencers-sidebar.owned a.entertainment,
aside.influencers-sidebar.owned a.media,
aside.influencers-sidebar.owned a.place,
aside.influencers-sidebar.owned a.society,
aside.influencers-sidebar.owned a.sport,
aside.influencers-sidebar.owned a.uncategorized,
aside.influencers-sidebar.targeted a.brands,
aside.influencers-sidebar.targeted a.celebrities,
aside.influencers-sidebar.targeted a.community,
aside.influencers-sidebar.targeted a.entertainment,
aside.influencers-sidebar.targeted a.media,
aside.influencers-sidebar.targeted a.place,
aside.influencers-sidebar.targeted a.society,
aside.influencers-sidebar.targeted a.sport,
aside.influencers-sidebar.targeted a.uncategorized,
aside.influencers-sidebar.owned .active-tags,
aside.influencers-sidebar.targeted .active-tags {
  display: none;
}

// TODO move to proper file - js
.combinations-box {
  position: absolute;
  background: #eee;
  padding: 10px;
  width: auto;
  margin-top: 25px;
  z-index: 1004;
  white-space: nowrap;
  left: 0px;
  display: none;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.combo-box-box.combo-box-open .combinations-box {
  display: block;
}

.combinations-box strong {
  font-size: 0.8em;
  margin-bottom: 10px;
  display: block;
  font-weight: normal;
}

.combinations-box a {
  display: block;
  clear: both;
  font-size: 0.8em;
  padding: 5px 10px;
  width: calc(100% + 20px);
  margin-left: -10px;
  line-height: 20px;
  color: #888;
  cursor: pointer;
  box-sizing: border-box;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.vis-options {
    float: right;
    padding: 10px 0;
    font-size: .7em;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: .05em
}

// todo add hr
.vis-options .combo-box-box hr {
  margin: 5px 0px;
}

.interests-vis-main.uniques .vis-options nav span.combo-box-toggle {
  color: #00A89C !important;
}
// could be moved
.combinations-box a.clear-all-toggles {
  color: #900;
}


.combinations-box a:hover {
  color: #888;
  background-color: #fff;
}

.combinations-box span.selected {
  display: block;
  height: 14px;
  width: 14px;
  background-color: transparent;
  border: 2px solid #ddd;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  float: left;
  margin-right: 5px;
}

.combinations-box span.selected {
  margin-left: 0px !important;
  padding: 0px !important;
}

.combinations-box a.active {
  color: #222;
}

.combinations-box .combo-options.exclusive-combo span.selected {
  background-color: #8CC63F;
  border: 2px solid #8CC63F;
}


nav.influencers-nav a.page-back,
nav.influencers-nav a.page-forward {
  position: absolute;
  left: 0px;
}

nav.influencers-nav a.page-forward {
  left: initial;
  right: 0px;
}

nav.influencers-nav label {
  margin: 5px 2px;
  float: right;
}

.influencers-sidebar label.searchfield {
  margin: 0px 20px 30px 0px;
  display: block;
}

.influencers-sidebar label.searchfield input {
  width: 160px;
}

.influencers-sidebar input[type="text"],
nav.influencers-nav input[type="text"],
.help-content input[type="text"],
.searchfield input[type="text"],
.asset-group-list-search-box[type="text"]  {
  background: transparent url(/assets/images/icon-loupe.png) 0px 10px no-repeat;
  background-position: 10px;
  padding-left: 35px;
}

.searchfield input[type="text"] {
  margin-bottom: 0px;
}

footer.continue-box {
  clear: both;
}

footer.continue-box.step1 button,
.adsForm footer.continue-box button,
footer.continue-box.step2 button {
  width: 200px;
}

.adsForm footer.continue-box button[disabled="disabled"] {
  width: auto;
}

.adsForm .step3 footer.continue-box button {
  float: right;
}

footer.continue-box button,
footer.continue-box .button {
  width: 100%;
}

footer.continue-box .continue,
footer.continue-box .launch {
}


.newCampaignForm label.communities span,
.newCampaignForm label.minmax span,
.newCampaignForm label.explicit span {
  float: left;
}

.newCampaignForm #dropzone-button {
  clear: both;
  float: left;
  margin-top: 20px;
  margin-bottom: 40px;
}

// .newCampaignForm .tiny-add-button {
//   margin-top: 11px;
// }

.newCampaignForm .socialnetworks {
  margin-bottom: 20px;
}

.newCampaignForm label.explicit .word-to-track-type {
  background: #eee;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  font-size: 0.6em;
  color: #222;
  float: right;
  position: relative;
  top: 40px;
  right: 5px;
  padding: 0px 5px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: bold;
}

label.explicit .explicit-words {
  background: #eee;
  padding: 20px;
  border: 1px solid #ccc;
  margin: 20px 0px;
}

label.explicit .explicit-words span {
  border-bottom: 1px dotted #999;
  display: inline-block;
  float: none;
  font-size: 0.8em;
  margin-right: 10px;
}


.newCampaignForm .button[type="submit"] {
  background-color: $blue;
  color: #fff;
}

.newCampaignForm .summary .button[type="submit"] {
  width: 100%;
  margin-top: 10px;
}

.newCampaignForm .estimated-audience .bignum {
  display: block;
  font-weight: 900;
  font-size: 2.8em;
  text-align: center;
  padding: 40px 0px;
}

.newCampaignForm .estimated-audience .bignum.calculating {
  opacity: 0.1;
}

.newCampaignForm p {
  margin-bottom: 20px;
}

.clusterExplanation {
  position: relative;
  width: 500px;
  background-color: #eee;
  padding: 20px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  margin-bottom: 40px;
  float: left;
}

.unexplain {
  color: #222;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 20px;
  font-size: 2em;
  line-height: 40%;
}

.clusterExplanation h4 {
  max-width: 500px;
  margin-bottom: 20px;
}

.clusterExplanation p {
  margin-bottom: 20px;
}

.hint {
  color: #333;
  font-size: 0.8em;
  font-style: italic;
  padding: 10px;
  border: 10px solid #fff6e3;
}

.summary-group {
  margin-bottom: 10px;
}

.summary-group.assets-group em {
  display: none;
}

.assets-group.owned-enabled em,
.assets-group.targeted-enabled em {
  display: block;
}

.summary-group em {
  margin-bottom: 3px;
  color: #999;
  display: block;
}

.summary-group em strong.filename {
  color: #222;
  font-style: normal;
  display: block;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tiny-add-button {
  position: absolute;
  right: 5px;
  margin-top: 5px;
  background-color: $blue;
  color: #fff;
}

.need-help {
  float: right;
  padding: 13px 20px;
  font-style: italic;
}


/************************/


.campaign-preview {
  position: relative;
  left: -20px;
  margin-bottom: -20px;
  top: 0px;
  float: left;
  width: 500px;
}

.cluster-preview {
  display: block;
}

.campaign-preview {
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: 20%;
}


/*==================================================================
=Campaign Overview
----------------------------------------------------------------- */

header.innerHeader {
  width: 48%;
  padding-right: 2%;
  float: left;
  margin: 0px 0px 20px 0px;
}

header.innerHeader:nth-of-type(2) {
  padding-right: 0%;
  padding-left: 2%;
}

/*
header.innerHeader > section {
  float: left;
  width: 48%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
*/

header.innerHeader:nth-of-type(1) .view-bar {
  margin-bottom: 10px;
}

.campaignName, .campaignSource, .campaignDetails {
  margin-bottom: 20px;
}

.campaignSource > ul > li:not(:last-of-type) {
  margin-bottom: 2px;
}
.campaignSource > ul > li:last-of-type {
  .filterBoolean{
    display: none;
  }
}

.innerHeader .campaignDetails,
.innerHeader .campaignSource {
  float: left;
  width: 50%;
}

.campaignSource .filterBoolean{
  font-size: 14px;
  font-weight: 400;
  margin-left: 70px;
}


.expand-toggle {
  color: #3CBFBC;
  display: inline-block;
}

.campaignGrowth p {
  margin-bottom: 10px;
}

.ofTotal {
  border: 2px solid #eee;
  background-color: #eee;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  height: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ofTotalLine {
  background: #333;
  position: relative;
  display: block;
  left: -2px;
  top: -2px;
  height: 40px;
}

.ofTotal.cluster-0 .ofTotalLine { background-color: #ca5953;}
.ofTotal.cluster-1 .ofTotalLine { background-color: #F48D6C;}
.ofTotal.cluster-2 .ofTotalLine { background-color: #F2E07B;}
.ofTotal.cluster-3 .ofTotalLine { background-color: #8ABE9B;}
.ofTotal.cluster-4 .ofTotalLine { background-color: #2d9ec6;}
.ofTotal.cluster-5 .ofTotalLine { background-color: #634a8b;}

.summary-stats,
.community-stats {
  width: 50%;
  padding-right: 2%;
  float: left;
  margin: 0px 0px 20px 0px;
}

.summary-stats {
  padding-right: 10px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.summary-stats:nth-of-type(even) {
  padding-right: 0;
  padding-left: 10px;
}

.community-stats:nth-of-type(even) {
  padding-right: 0%;
  padding-left: 2%;
}

.summary-stats:nth-of-type(2n+1),
.community-stats:nth-of-type(2n+1) {
  clear: both;
}

.summary-stats.tribe-overview .vis {
  width: 100%;
  margin-bottom: 20px;
}

.summary-stats.tribe-overview .vis ul{
  margin-top: 10px;
}

.summary-stats > article {
  clear: both;
}

.community-stats .legend {
  display: none;
}

.community-stats .donut {
  width: auto;
}

.community-stats h4 small {
  font-style: italic;
  font-size: 0.8em;
  color: #aaa;
  font-weight: normal;
}

.community-stats label.showLabels {
  display: block;
  font-size: 0.8em;
  margin-top: -11px;
  clear: none;
  float: right;
}

.community-stats label.showLabels input {
  margin-right: 5px;
}

.community-stats.network.size label.showLabels {
  display: none;
}

.community-stats .lurker-average {

}


// .community-stats .lurker-average strong:last-of-type {
//   color: #00A79B;
// }

.bar label em.bad {
  color: #900;
}

// .community-stats h4 {
//   margin-bottom: 30px;
//   float: left;
// }

.community-stats .tribe-overview-header h4 {
  margin-bottom: 0;
  float: left;
  color: black;
}

.tribe-overview-header {
  border-bottom: none;
}

.tribe-overview li {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
}

h4.floater {
  float: left;
}

// TODO remove?
/*==================================================================
=Export Button
------------------------------------------------------------------ */

.exportButton {
  position: relative;
  float: left;
  z-index: 3;
  margin-left: 35px;
  /*top: -3px;*/
  text-align: left;
}

.exportButton.unleft {
  float: left;
  margin-left: 0px;
  margin-right: 20px;
}

.interests-vis-main .vis-options .exportButton.unleft {
  margin-right: 0px;
}

.exportButton > a {
  display: block;
  background: #eee;
  color: #222;
  font-weight: bold;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  height: 20px;
  width: 120px;
  line-height: 20px;
  text-align: center;
  position: absolute;
  z-index: 2;
  /*border: 3px solid #fff;*/
  font-size: 0.8em;
}

.exportButton.unleft > a {
  position: relative;
}

/*==================================================================
=Snapshot/Growth Bar
----------------------------------------------------------------- */

.growth-bar > .started {
  background-color: #eee;
}

.growth-bar > .bar.started {
  padding: 0px 5px;
}

.growth-bar > .added {
  background-color: #7CBF60;
}

.growth-bar > div {
  float: left;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 32px;
  line-height: 32px;
}

circle {
  z-index: 3;
  position: relative;
}

path, line {
  z-index: 1;
}

/*==================================================================
=Campaigns
----------------------------------------------------------------- */

.campaign-link {
  cursor: pointer;
  color: #222;
}

.campaign-link:hover {
  color: #09c;
}

.asplode-campaign {
  display: block;
}

.campaigns-table {
  width: 100%;
  font-size: 0.9em;
}

.summary-group {
  margin-bottom: 20px;
  clear: both;
}

.newCampaignForm.content.step3 .summary-group.andgroup ~ .summary-group.andgroup:before {
  text-shadow: 0px 1px 0px #eee,0px 2px 0px #eee,0px 3px 0px #eee,0px 4px 0px #eee,0px 5px 0px #eee,0px 6px 0px #eee,0px 7px 0px #eee,0px 8px 0px #eee,0px -1px 0px #eee,0px -2px 0px #eee,0px -3px 0px #eee,0px -4px 0px #eee,0px -5px 0px #eee,0px -6px 0px #eee,0px -7px 0px #eee,0px -8px 0px #eee;
}

.summary-group em {
  margin-bottom: 3px;
  color: #999;
  display: block;
}

.summary-group em strong.filename {
  color: #222;
  font-style: normal;
  display: block;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

// TODO death to style.scss - js
.summary-group .benchmark-group {
  max-height: 350px;
  overflow-y: auto;
  &.hidden {
    display: none;
  }
}

.ad-hoc-benchmarks {
  display: flex;
  flex-direction: row;
  > section {
    width: 100%;
    &:last-of-type {
      margin-left: 1em;
    }
  }
}

.added_user {
  background: #eee;
  margin-bottom: 5px;
  padding: 10px;
  position: relative;
}

.added_user.inline:first-of-type {
  clear: both;
}

.added_user img {
  float: left;
  width: 48px;
  height: 48px;
  margin-right: 10px;
}

.added_user p,
.smallWarning {
  font-size: 0.8em;
  color: #999;
  font-style: italic;
}

.added_user a.remove {
  position: absolute;
  top: 0px;
  right: 0px;
  line-height: 50%;
  color: #999;
  font-weight: 300;
  font-size: 1.5em;
  padding: 10px;
}

.added_user.inline a.remove {
  padding: 13px;
}


/************************/


.campaign-preview {
  position: relative;
  left: -20px;
  margin-bottom: -20px;
  top: 0px;
  float: left;
  width: 500px;
}

.cluster-preview {
  display: block;
}

.campaign-preview {
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: 20%;
}

/************************/


.lilloader {
  width: 80%;
  margin: auto;
  left: 10%;
  margin-top: -45px;
  height: 4px;
  position: absolute;
  overflow: hidden;
}
.big {
  height: 16px;
  margin: 8px 0 0 0;
}
.lilloader > div {
  position: absolute;
  height: 100%;
  left: 50%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.green {
  background: #27ae60;
}
.red {
  background: #c0392b;
}
.blue {
  background: #2980b9;
}
.yellow {
  background: #f1c40f;
}
.sd0 {
  -webkit-animation: stretch 2.8s ease 0s infinite;
  -moz-animation: stretch 2.8s ease 0s infinite;
  -ms-animation: stretch 2.8s ease 0s infinite;
  -o-animation: stretch 2.8s ease 0s infinite;
  animation: stretch 2.8s ease 0s infinite;
}
.sd05 {
  -webkit-animation: stretch 2.8s ease .4s infinite;
  -moz-animation: stretch 2.8s ease .4s infinite;
  -ms-animation: stretch 2.8s ease .4s infinite;
  -o-animation: stretch 2.8s ease .4s infinite;
  animation: stretch 2.8s ease .4s infinite;
}
.sd1 {
  -webkit-animation: stretch 2.8s ease .8s infinite;
  -moz-animation: stretch 2.8s ease .8s infinite;
  -ms-animation: stretch 2.8s ease .8s infinite;
  -o-animation: stretch 2.8s ease .8s infinite;
  animation: stretch 2.8s ease .8s infinite;
}
.sd15 {
  -webkit-animation: stretch 2.8s ease 1.2s infinite;
  -moz-animation: stretch 2.8s ease 1.2s infinite;
  -ms-animation: stretch 2.8s ease 1.2s infinite;
  -o-animation: stretch 2.8s ease 1.2s infinite;
  animation: stretch 2.8s ease 1.2s infinite;
}
@keyframes "stretch" {
 0% {
    padding: 0 0 0 0;
    left: 50%;
    z-index: 4;
 }
 25% {
    z-index: 3;
 }
 50% {
    padding: 0 50% 0 50%;
    left: 0;
    z-index: 2;
 }
 100% {
    padding: 0 50% 0 50%;
    left: 0;
    z-index: 1;
 }
}
@-moz-keyframes stretch {
 0% {
   padding: 0 0 0 0;
   left: 50%;
   z-index: 4;
 }
 25% {
   z-index: 3;
 }
 50% {
   padding: 0 50% 0 50%;
   left: 0;
   z-index: 2;
 }
 100% {
   padding: 0 50% 0 50%;
   left: 0;
   z-index: 1;
 }
}
@-webkit-keyframes "stretch" {
 0% {
   padding: 0 0 0 0;
   left: 50%;
   z-index: 4;
 }
 25% {
   z-index: 3;
 }
 50% {
   padding: 0 50% 0 50%;
   left: 0;
   z-index: 2;
 }
 100% {
   padding: 0 50% 0 50%;
   left: 0;
   z-index: 1;
 }
}
@-ms-keyframes "stretch" {
 0% {
   padding: 0 0 0 0;
   left: 50%;
   z-index: 4;
 }
 25% {
   z-index: 3;
 }
 50% {
   padding: 0 50% 0 50%;
   left: 0;
   z-index: 2;
 }
 100% {
   padding: 0 50% 0 50%;
   left: 0;
   z-index: 1;
 }
}
@-o-keyframes "stretch" {
 0% {
   padding: 0 0 0 0;
   left: 50%;
   z-index: 4;
 }
 25% {
   z-index: 3;
 }
 50% {
   padding: 0 50% 0 50%;
   left: 0;
   z-index: 2;
 }
 100% {
   padding: 0 50% 0 50%;
   left: 0;
   z-index: 1;
 }
}


.purchaseForm .card_number {
  width: calc(70% - 10px);
  float: left;
  margin-right: 10px;
}

.purchaseForm .card_cvc {
  width: 30%;
  float: left;
  clear: none;
}

.purchaseForm .card_month,
.purchaseForm .card_year {
  width: 25%;
  float: left;
  clear: none;
  margin-right: 10px;
}

.purchaseForm .card_month {
  clear: both;
}

.purchaseForm hr,
.purchaseForm .submit {
  clear: both;
}

.purchaseForm hr {
  margin: 50px 0px;
}

.purchaseForm .form-proper {
  padding: 20px;
}

.purchaseForm .submit,
.newCampaignForm .submit {
  background-color: #00A79B;
  color: #fff;
  width: 100%;
  font-size: 1.3em;
  font-weight: 300;
}


.purchaseForm .address_city,
.purchaseForm .address_country {
  width: calc(50% - 10px);
  margin-right: 10px;
  float: left;
  clear: none;
}

.purchaseForm .address_country {
  margin-left: 10px;
  margin-right: 0px;
}

.purchaseForm .address_city {
  clear: both;
}

.purchaseForm .address_zip {
  width: calc(50% - 10px);
  clear: both;
}

.purchaseForm select {
  padding: 5px;
}

.payment-errors {
  padding: 10px;
  background-color: #fcc;
  border: 1px solid #900;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 30px;
  display: block;
}

.purchaseForm label {
  margin-bottom: 10px;
}


.userForm.purchaseForm label input,
.userForm.purchaseForm label select {
  margin-bottom: 0px;
}

.purchaseForm p {
  margin-bottom: 10px;
}

.purchaseForm .reminder {
  color: #999;
  clear: both;
  margin-top: -10px;
  font-style: italic;
}

.purchaseForm .total {
  margin-bottom: 20px;
}

.purchaseForm .total li {
  padding: 5px 0px;
  border-bottom: 1px solid #ddd;
  display: block;
}

.purchaseForm .total li.realTotal {
  font-size: 1.3em;
}

.purchaseForm .howMany input {
  width: 10%;
  float: left;
}

.purchaseForm .howMany em {
  display: inline-block;
  padding: 5px;
  margin-left: 10px;
  font-weight: 700;
}

.purchaseForm .summary,
.newCampaignForm .summary {
  border: 1px solid #ccc;
  padding: 20px;
  clear: both;
  background-color: #f5f5f5;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  margin-top: 50px;
  width: 100%;
  float: left;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 50px;
}

.newCampaignForm .summary {
  border: 0px solid #ccc;
  margin-top: 20px;
  margin-bottom: 0px;
}

.newCampaignForm .summary h4 {
  font-size: 1.3em;
  margin-bottom: 10px;
}

.billingPrompt .button {
  font-size: 1.5em;
  margin-bottom: 10px;
  font-weight: 300;
}
.advertisingForm p,
.updateForm p {
  margin-bottom: 30px;
}

.advertisingForm label,
.updateForm label {
  margin-bottom: 5px;
}

.newCampaignForm input[type="radio"],
.advertisingForm input[type="radio"],
.userForm input[type="radio"],
.updateForm input[type="radio"] {
  margin-top: 4px;
  margin-right: 5px;
  float: left;
}

.advertisingForm .form-proper {
  width: auto;
}

.newCampaignForm .radio-label {
  margin-bottom: 0px;
  display: inline-block;
  margin-right: 20px;
  margin-top: -20px;
  clear: none;
  font-size: .9em;
  white-space: nowrap;
  position: relative;
  top: -20px;
}

.updateForm .update-now-instead {
  display: inline-block;
  padding: 0px 5px;
  font-size: 0.85em;
  background-color: #eee;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.updateForm .cancel-updates {
  display: inline-block;
  width: auto;
  background-color: #f55;
  color: #fff;
}

.updateForm .cancel-updates:hover {
  background-color: #c00;
}

.userForm label.minmax input:first-of-type {
  clear: both;
}

.userForm label.minmax input {
  width: 100px;
  float: left;
  margin-right: 10px;
}

.updateForm .step {
  float: left;
  width: 33%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 50px;
}

.userForm.updateForm label span {
  font-size: 1em;
}

.disabled-form-fields-container {

}

.disabled-form-fields {
  opacity: 0.3;
}

/*==================================================================
=Export Button
------------------------------------------------------------------ */

.exportButton {
  position: relative;
  float: left;
  z-index: 3;
  margin-left: 35px;
  /*top: -3px;*/
  text-align: left;
}

.exportButton.unleft {
  float: left;
  margin-left: 0px;
  margin-right: 20px;
}

.interests-vis-main .vis-options .exportButton.unleft {
  margin-right: 0px;
}

.exportButton > a {
  display: block;
  background: #eee;
  color: #222;
  font-weight: bold;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  height: 20px;
  width: 120px;
  line-height: 20px;
  text-align: center;
  position: absolute;
  z-index: 2;
  /*border: 3px solid #fff;*/
  font-size: 0.8em;
}

.exportButton.unleft > a {
  position: relative;
}



/*==================================================================
=Snapshot/Growth Bar
----------------------------------------------------------------- */

.growth-bar > .started {
  background-color: #eee;
}

.growth-bar > .bar.started {
  padding: 0px 5px;
}

.growth-bar > .added {
  background-color: #7CBF60;
}

.growth-bar > div {
  float: left;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 32px;
  line-height: 32px;
}

circle {
  z-index: 3;
  position: relative;
}

path, line {
  z-index: 1;
}

.campaign-header h6 {
  width: 220px;
  float: left;
  font-weight: 300;
  height: 50px;
  line-height: 50px;
  font-size: 1em;
  background-color: #222;
  color: #fff;
  padding: 0px 5px;
  text-align: center;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.campaign-header nav.your-campaigns {
  background: #444;
  width: 220px;
}

.campaign-header nav.your-campaigns a {
  line-height: 1em;
  height: auto;
  border-bottom: 1px solid #555;
  padding: 10px 5px;
  display: block;
}

.campaign-header .options select {
  display: inline-block;
  width: auto;
  padding: 4px 5px 4px 10px;
  margin-right: 5px;
  border: 0px solid #eee;
  border-radius: 6px;
  background-color: #eee;
  color: #555;
  font-weight: 500;
  font-size: 0.9em;
}

.campaign-header .options {
  float: right;
  height: 50px;
  line-height: 50px;
  padding: 0px 0px 0px 30px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.campaign-header .options.gear-options {
  padding-left: 10px;
  position: relative;
  float: left;
}

.campaign-header .options.fuller {
  float: none;
  padding-right: 30px;
}

.campaign-header .options span.gear {
  display: block;
  text-align: right;
  cursor: pointer;
}

.campaign-header .options span.gear img {
  height: 18px;
  margin: 10px 0px 12px 5px;
}

.campaign-header .options:hover ul {
  max-height: 100%;
}

.campaign-header .options ul li a:hover {
  color: #3CBFBC;
}


/*==================================================================
=Edit Name Interface
---------------------------------------------------------------- */

input.name-change-input {
  margin-top: 10px;
  padding: 4px;
  width: 100%;
  // border: 2px solid #eee;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 0px;
}

.name-change-form .checkboxbox {
  font-size: 0.8em;
  margin-top: 10px;
}

form.name-change-form {
  position: relative;
  font-size: 1.3em;
}

form.name-change-form .name-change-button {
  font-size: 0.7em;
}

.name-change-form .checkboxbox {
  // font-size: 0.8em;
  margin-top: 10px;
}

/* Campaign */

h2 form.name-change-form {
  position: relative;
}

h2 form.name-change-form .name-change-button {
  // font-size: 0.7em;
}

h2 .name-change-input {
  width: 100%;
  height: 40px;
  border: 2px solid #eee;
  // font-size: 0.8em;
}

/*==================================================================
=Report Failure info window
----------------------------------------------------------------- */

.recent-failure {
  padding: 20px;
  background-color: rgba(0,167,155,.1);
  color: #222;
  margin: 20px 0px;
  box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.25);
  border-left: 5px solid #00A79B;
  border-radius: 0px;
  margin-bottom: 50px;
}

form.newCampaignForm .recent-failure {
  margin-top: 0px;
}

.recent-failure p {
  max-width: 1000px;
  margin-bottom: 20px;
}

.recent-failure p:last-child {
  margin-bottom: 0px;
}

/*==================================================================
=Share Interface
----------------------------------------------------------------- */

.share-interface .raw-email-input,
.share-interface textarea {
  margin-top: 10px;
  padding: 4px;
  width: 100%;
  border: 2px solid #ccc;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.share-interface textarea {
  height: 120px;
  margin-top: 30px;
}

.share-interface label.checkboxbox {
  display: block;
  font-size: 0.8em;
}

.share-interface label.checkboxbox input {
  margin-right: 5px;
}

.share-interface p.sharenote {
  clear: both;
  color: #999;
  margin: 10px 0px;
  font-size: 0.8em;
}

.share-interface .campaign-shared,
.share-interface .campaign-shared-error {
  background-color: $blue;
  color: #fff;
  padding: 20px;
  margin: -22px -20px -20px;
  font-size: 1.5em;
}

.share-interface .campaign-shared-error {
  background-color: #c00;
}

.modal-success > .modal-title,
.modal-error > .modal-title {
  background-color: $blue;
  color: #fff;
  padding-top: 20px;
  margin-top: -20px;
  font-size: 1.5em;
}

.modal-error > .modal-title {
  background-color: #c33;
}

/*==================================================================
=Export Interface
----------------------------------------------------------------- */

.export-interface label.checkboxbox {
  display: block;
  padding: 3px 0px;
}

.export-interface label.checkboxbox span {
  position: relative;
  top: 1px;
}

.export-interface .export-downloaded {
  background-color: $blue;
  color: #fff;
  padding: 20px;
  margin: -22px -20px -20px;
  font-size: 1.5em;
}

/*==================================================================
=Export Tab Interface
----------------------------------------------------------------- */

label.checkboxbox {
  display: block;
  font-size: 0.9em;
  padding: 5px;
  margin-bottom: 0px;
  box-sizing: border-box;
}

.export-tab-interface {
  clear: both;

  .influencer-categories-selection {
    max-height: 400px;
    overflow: scroll;
    width: 100%;
    float: none;
    background-color: #f4f4f4;
    // border: 1px solid #ddd;
    // border-left: 5px solid #eee;
    // border-right: none;
    // margin: 10px 0px;
    margin-bottom: 10px;
    margin-left: 10px;

    label.checkboxbox {

      float: left;
      width: 50%;
      border-bottom: none;

      &:hover {
        background-color: #eee;
      }

      &:nth-child(odd) {
        // border-right: 1px solid #ddd;
      }

      &:not(.selected) {
        opacity: 0.7;
      }

      input {
        -webkit-transition: .2s;
        -moz-transition: .2s;
        -o-transition: .2s;
        transition: .2s;
      }

      span {
        position: relative;
        top: 1px;
      }

      small {
        font-style: italic;
        opacity: 0.5;
      }
    }
  } //influencer-categories-selection

  .selection-aggregation,
  .selection-uniq,
  .selection-intersection {
    font-size: 0.8em;
    float: right;
    margin-right: 5px;

    span {
      float: left;
      padding: 3px 10px;
      cursor: pointer;
      background-color: #eee;
      border-radius: 4px;
      color: #888;
      &:hover {
        background-color: #ddd;
      }
      &:nth-child(1) {
        margin-right: 5px;
      }
    }

    &.intersection {
      span.intersection {
        background-color: $teal;
        color: #fff;
      }
    }

    &.top,
    &.uniq {
      span.top,
      span.uniq {
        background-color: $teal;
        color: #fff;
      }
    }

    &.compiled,
    &.all {
      span.compiled,
      span.all {
        background-color: $teal;
        color: #fff;
      }
    }
  }

  p.big-compilations-warning {
    margin: 5px 0px;
    font-style: italic;
    clear: both;
  }

  .button {
    display: inline-block;

    &.primary {
      float: left;
      clear: both;
      margin-top: 10px;
    }

    &.toggle-categories {
      background-color: #ddd;
      color: #444;
      width: 100%;
      border-radius: 0px;
      margin-top: -2px;
      padding: 3px 5px;
      margin-top: 10px;
      margin-left: 10px;
    }
  }

  .export-downloaded {
    background-color: $blue;
    color: #fff;
    padding: 20px;
    margin: -22px -20px -20px;
    font-size: 1.5em;
  }

  h4 {
    padding: 10px 0px;
    margin-bottom: 0px;
  }
} //export-tab-interface

//TODO: community-stats probably doesnt belong here.
.community-stats .export-tab-interface h4 {
  float: none;
  margin-bottom: 0px;
}


/*==================================================================
=Loading Bar
----------------------------------------------------------------- */

.loading-bar-holster {
  width: 800px;
  margin: 1em auto 1em auto;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background-color: #eee;
  border: 1px solid #eee;
}

.loading-bar {
  height: 12px;
  background: #00A79B;
  width: 0px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin: 3px 3px 3px 3px;
  max-width: calc(100% - 10px);
}

#loader-holster {
  position: fixed;
  background: #fff;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

#loader-holster svg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  top: 0px;
  left: 0px;
}

.logo {
  width: 800px;
  margin: 1.5em auto;
}

.logo img {
  display: block;
  width: 130px;
  margin: 1.5em auto;
}

 #loader-holster p {
  text-align: center;
  color: #999;
  font-size: .85em;
  line-height: 180%;
  margin: 1.5em auto;
  min-width: 800px;
}

#loader-holster p em {
  display: inline-block;
  max-width: 600px;
}
#loader-holster  .phrase em,#loader-holster  .dots {
  display: none;
}
@keyframes dots-1 { from { opacity: 0; } 25% { opacity: 1; } }
@keyframes dots-2 { from { opacity: 0; } 50% { opacity: 1; } }
@keyframes dots-3 { from { opacity: 0; } 75% { opacity: 1; } }
@-webkit-keyframes dots-1 { from { opacity: 0; } 25% { opacity: 1; } }
@-webkit-keyframes dots-2 { from { opacity: 0; } 50% { opacity: 1; } }
@-webkit-keyframes dots-3 { from { opacity: 0; } 75% { opacity: 1; } }

.dots span {
    animation: dots-1 4.5s infinite steps(1);
    -webkit-animation: dots-1 4.5s infinite steps(1);
}

.dots span:first-child + span {
    animation-name: dots-2;
    -webkit-animation-name: dots-2;
}

.dots span:first-child + span + span {
    animation-name: dots-3;
    -webkit-animation-name: dots-3;
}


/*==================================================================
=Network Vis
----------------------------------------------------------------- */

#vis {
  position: absolute;
  left: 0px;
}

.control a {
  display: inline-block;
  border: 1px solid #333;
  padding: 4px;
  margin-right: 10px;
}

.sample-stream .twitter-frame {
  width: 500px;
}

#vis line {
  stroke-opacity: 0.1 !important;
}

.control {
  margin-bottom: 20px;
}

.control input {
  border: 1px inset #ccc;
}

// /*==================================================================
// =Trait Identification
// ----------------------------------------------------------------- */
//
// .traits li {
//   list-style-type: disc;
//   margin-left: 20px;
//   margin-bottom: 5px;
// }
//
// .traits li span {
//   font-size: 0.8em;
//   opacity: 0.5;
//   display: none;
// }
//
// .traits li strong.likelihood {
//   font-size: 1.15em;
// }
//
// .community-traits {
//   width: 48%;
//   padding-right: 2%;
//   float: left;
//   margin: 0px 0px 20px 0px;
// }
//
// .community-traits:nth-of-type(2) {
//   padding-right: 0%;
//   padding-left: 2%;
// }
//
// .community-traits .profile-card {
//   width: 10%;
// }
//
// .community-traits .notification {
//   padding: 5px;
//   background-color: #ffc;
//   font-size: 0.9em;
//   margin-bottom: 10px;
// }
//
// .community-traits h5 {
//   font-weight: bold;
//   padding: 0px;
//   border: none;
//   text-transform: none;
//   letter-spacing: 0em;
//   font-size: 1em;
//   margin-bottom: 0px;
// }
//
// .traitslist li {
//   background-color: #eee;
//   white-space: nowrap;
//   margin-bottom: 3px;
//   padding: 3px;
// }

/*==================================================================
=Lurker Pyramid, Network Graph hide/show
----------------------------------------------------------------- */

.lurker-chart .c3-chart-lines .c3-line-all {
  stroke-width: 2px;
  opacity: 1 !important;
  stroke-dasharray: 3,3;
}

// .lurker-chart,
// .cross-tribe-lurkers,
// .cluster-bar-graph {
//   display: none;
//   clear: both;
// }

.lurker-pyramid {
  width: 100%;
  clear: both;
  padding-bottom: 90%;
  position: relative;
  overflow: hidden;
  max-width: 500px;
  max-height: 450px;
  display: none;
}

.lurkerpercentage .lurker-pyramid,
.postsPerMonthCBG .cluster-bar-graph,
.engagementbreakdown .lurker-chart,
.lurkerpercentage .cross-tribe-lurkers,
.network.size .donut {
  display: block;
  visibility: visible;
}

.lurker-pyramid .triangle {
  background: transparent url(/assets/images/triangle-cover.png) 0% 0% no-repeat;
  width: 100%;
  height: 90%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-size: cover;
  max-width: 500px;
  max-height: 450px;
}

.lurker-pyramid .inner {
  width: 100%;
  height: 90%;
  position: absolute;
  top: 0px;
  left: 0px;
  max-width: 500px;
  max-height: 450px;
}

.lurker-pyramid .active-users-bar {
  display: block;
  background-color: #3CBFBC;
}

.lurker-pyramid .lurkers-bar {
  background-color: #333;
}

.active-user-description, .lurker-description {
  position: absolute;
  padding: 2px 0px;
  color: #333;
  font-size: 0.8em;
  font-weight: bold;
  right: 0px;
  text-align: right;
  width: 50%;
  margin-top: 5px;
  border-bottom: 1px solid #000;
}

.active-user-description span,
.lurker-description span {
  background-color: rgba(255,255,255,0.5);
}

.active-user-description {
  /*background: rgba(255,255,255,.5);*/
  color: #3CBFBC;
  top: 0;
}

.community-stats.lurkerpercentage a.lurkerpercentage,
.community-stats.engagementbreakdown a.engagementbreakdown,
.community-stats.postsPerMonthCBG a.postsPerMonthCBG,
.community-stats.engagement a.engagement,
.community-stats.engagement_comments a.engagement_comments,
.community-stats.engagement_shares a.engagement_shares,
.community-stats.engagement_likes a.engagement_likes,
.community-stats.influencerAffinity a.influencerAffinity,
.community-stats.lurkers a.lurkers,
.community-stats.uniqueInfluencers a.uniqueInfluencers,
.community-stats.density a.density,
.network.networkgraph a.networkgraph,
.network.size a.size {
  color: #3CBFBC;
}

section.community-stats {
  min-height: 600px;
  /*max-height: 600px;*/
}

.lurker-pyramid-box {
  max-height: 600px;
  overflow: hidden;
  width: 100%;
}

.community-stats.network .vis-options {
  margin-top: -20px;
  margin-bottom: 20px;
  margin-left: -5px;
  float: left;
  clear: both;
}

.community-stats .vis-options {
  margin: -5px 0px 0px;
  float: right;
  clear: both;
}

.community-stats .view-bar {
  border: none;
}

.network svg {
  max-height: 500px;
}

/*==================================================================
=Map
----------------------------------------------------------------- */

.googlemap {
  margin: -20px -20px 20px -20px;
  /*display: none !important;*/
  // width: calc(100% + 40px);
  position: relative;
  z-index: 100;
}

.googlemap .map-holster {
  width: 100%;
  height: 500px;
}

.googlemap img {
  max-width: none;
}

.googlemap > .map-options {
  display: none;
}

.map-options {
  padding: 0px 5px;
  width: auto;
  max-height: 450px;
  max-width: 150px;
  overflow-y: auto;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  background: rgba(255,255,255,.8);
  border: 1px solid #ccc;
  margin: 10px;
  -moz-box-shadow: 0px 0px 3px #bbb;
  -webkit-box-shadow: 0px 0px 3px #bbb;
  box-shadow: 0px 0px 3px #bbb;
}

.map-options label {
  display: block;
  padding: 5px 0px;
  color: #222;
}

.map-options label:hover {
  background-color: #eee;
}

.map-options a.map-hidden {
  color: #ccc;
}

.map-options label .toggle {
  display: inline-block;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  height: 5px;
  width: 5px;
  margin: 3px 3px 0px 3px;
  border: 1px solid #ccc;
  background-color: #fff;
  -webkit-transition: .1s;
  -moz-transition: .1s;
  -o-transition: .1s;
  transition: .1s;
}

.map-options label.map-shown .toggle {
  background-color: #00A79B;
  border: 1px solid #00a79b;
}

.map-options label:nth-of-type(1).map-shown .toggle {
  background-color: rgba(31,119,180,1);
}
.map-options label:nth-of-type(2).map-shown .toggle {
  background-color: rgba(174,199,232,1);
}
.map-options label:nth-of-type(3).map-shown .toggle {
  background-color: rgba(255,127,14,1);
}
.map-options label:nth-of-type(4).map-shown .toggle {
  background-color: rgba(255,187,120,1);
}
.map-options label:nth-of-type(5).map-shown .toggle {
  background-color: rgba(44,160,44,1);
}
.map-options label:nth-of-type(6).map-shown .toggle {
  background-color: rgba(152,223,138,1);
}
.map-options label:nth-of-type(7).map-shown .toggle {
  background-color: rgba(214,39,40,1);
}
.map-options label:nth-of-type(8).map-shown .toggle {
  background-color: rgba(255,152,150,1);
}
.map-options label:nth-of-type(9).map-shown .toggle {
  background-color: rgba(148,103,189,1);
}
.map-options label:nth-of-type(10).map-shown .toggle {
  background-color: rgba(197,176,213,1);
}
.map-options label:nth-of-type(11).map-shown .toggle {
  background-color: rgba(140,86,75,1);
}
.map-options label:nth-of-type(12).map-shown .toggle {
  background-color: rgba(196,156,148,1);
}
.map-options label:nth-of-type(13).map-shown .toggle {
  background-color: rgba(227,119,194,1);
}
.map-options label:nth-of-type(14).map-shown .toggle {
  background-color: rgba(247,182,210,1);
}
.map-options label:nth-of-type(15).map-shown .toggle {
  background-color: rgba(127,127,127,1);
}
.map-options label:nth-of-type(16).map-shown .toggle {
  background-color: rgba(199,199,199,1);
}
.map-options label:nth-of-type(17).map-shown .toggle {
  background-color: rgba(188,189,34,1);
}
.map-options label:nth-of-type(18).map-shown .toggle {
  background-color: rgba(219,219,141,1);
}
.map-options label:nth-of-type(19).map-shown .toggle {
  background-color: rgba(23,190,207,1);
}
.map-options label:nth-of-type(20).map-shown .toggle {
  background-color: rgba(158,218,229,1);
}
.googlemap * {
  -webkit-transform: translateZ(0);
}

/*==================================================================
=Instagram Locations Image Wall
----------------------------------------------------------------- */

.sliced-images {
  display: none;
}

.sliced-images .brick {
  overflow: hidden;
  background-color: #ddd;
}

.sliced-images .brick img {
  width: 100%;
  min-height: 100%;
}

// /*==================================================================
// =Content Links Terms page (link topics)
// ----------------------------------------------------------------- */
// section.content-topic-summary {
//     display: -webkit-flex;
//     display: -ms-flexbox;
//     display: flex;
//
//     -webkit-flex-wrap: wrap;
//     -ms-flex-wrap: wrap;
//     flex-wrap: wrap;
// }
// section.content-topic-summary section {
//     width: 30%;
//     float: left;
//     padding-right: 1.5%;
//     margin: 0 1.5% 20px 0;
//     border-right: solid 1px #eee;
// }
// section.content-topic-summary section.content-topic-summary-double {
//     width: 60%;
//     border-right: none;
//     text-align: center;
// }
// .topics-notify {
//   display: inline-block;
//   margin: 25% auto 0;
//   background-color: #eee;
//   padding: 10px 20px;
//   border-radius: 15px;
// }
// .topic-link-block {
//     display: block;
//     min-height: 50px;
//     padding: 10px 30px 10px 30px;
//     cursor: pointer;
//     margin: 0 -5%; /* Somehow the math seems off to me, but this works - JK July 2015*/
//     -moz-box-sizing: border-box;
//     -webkit-box-sizing: border-box;
//     box-sizing: border-box;
//     border-bottom: 1px solid #eee;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     overflow: hidden;
// }
// .topic-link-block:last-child {
//     border-bottom: none;
// }
// .topic-link-block.active,
// .topic-link-block:hover {
//     background-color: #eee;
// }
// .topic-link-block.active {
//     font-weight: bold;
//     border-top: 1px solid #ddd;
//     border-bottom: 1px solid #ddd;
// }
// .content-topic-summary .bar {
//     cursor: pointer;
// }
// .content-topic-summary .bar.faded {
//     opacity: 0.6;
// }
// .content-topic-summary .hovered .bar {
//     opacity: 1;
// }
// .content-topic-summary h4.topic-title{
//     margin-bottom: 30px;
// }
// .content-topic-summary .view-bar{
//     padding: 0;
//     border: none;
// }
// .content-topic-summary .noborder {
//     border: none !important;
// }
// .vis-options.content-links span {
//     float: left;
// }
// .vis-options.content-links a.active {
//     color: #00A89C;
// }
// .help-content {
//     padding: 25px 0;
// }
// .help-content .faq h4 {
//   margin-top: 20px;
//   border-bottom: 1px solid #ccc;
// }
// .disabled-free {
//   color: lightgrey;
// }
// .disabled-free label {
//   cursor: default;
// }
// .top-emoji text { opacity: 1 !important; }
//
// .help-content .updates {
//   max-width: 600px;
// }
//
// .help-content .updates p {
//   margin-bottom: 20px;
// }
//
// .help-content .updates ul li {
//   list-style-type: disc;
//   margin-left: 20px;
//   margin-bottom: 5px;
// }
//
// .help-content .updates small {
//   display: block;
//   // margin-top: -20px;
//   margin-bottom: 1em;
// }


/*==================================================================
=Maintenance Mode
----------------------------------------------------------------- */

.maintenance-mode {
  background: #ff9;
  padding: 20px;
  color: #222;
  margin: -30px 0px 50px 0px;
  box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.25);
  border-left: 5px solid #cc0;
  border-radius: 0px;
  font-size: 1.5em;

  &.mini {
    margin: 0px 0px 10px 0px;
    font-size: inherit;
    padding: 10px;
  }
}

.maintenance-mode p {
  margin-bottom: 1em;
  max-width: 800px;
}

.maintenance-mode p:last-child {
  margin-bottom: 0;
}

.nestedAdminInfo {
  margin-left: 25px;
}

/*==================================================================
=Non-Semantic Helper Classes
----------------------------------------------------------------- */
.ir { display: block; border: 0; text-indent: -999em; overflow: hidden; background-color: transparent; background-repeat: no-repeat; text-align: left; direction: ltr; *line-height: 0; }
.ir br { display: none; }
.hidden { display: none !important; visibility: hidden; }
.visuallyhidden { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus { clip: auto; height: auto; margin: 0; overflow: visible; position: static; width: auto; }
.invisible { visibility: hidden; }
.clearme { clear: both; }
.blocky { display: block; }
.moveable { cursor: move; }
.clickable { cursor: pointer; }
.clearfix:before, .clearfix:after, .bar-chart li:before, .bar-chart li:after { content: ""; display: table; }
.clearfix:after, .bar-chart li:after { clear: both; }
.clearfix, .bar-chart li{ zoom: 1; }
.flex {
    // Flexbox, y'all
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
}
.signup-error{
  border: 5px solid rgb(255,196,0);
  padding: 50px;
  border-radius: 6px;
}
.limit-warning {
  color:#B80000;
  font-style: italic;
  margin: 5px 1em auto auto;
  float: right;
}
.low-affinity-red {
  color: rgba(214,39,40,1);
}
// TODO: delete
// /*http://tobiasahlin.com/spinkit/*/
// .spinner {
//   width: 40px;
//   height: 40px;
//   background-color: #333;
//
//   margin: 100px auto;
//   -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
//   animation: sk-rotateplane 1.2s infinite ease-in-out;
// }

@-webkit-keyframes sk-rotateplane {
  0% { -webkit-transform: perspective(120px) }
  50% { -webkit-transform: perspective(120px) rotateY(180deg) }
  100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
  } 50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
  } 100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

/*==================================================================
=Actions
----------------------------------------------------------------- */

.advertising .ad-accounts {
  max-width: 300px;
}

/*==================================================================
=New Audience Summay Stuff
----------------------------------------------------------------- */
.twitter-csv-upload-file{
  display: inline-block !important;
}

.remove-twitter-csv-upload{
  display: inline-block !important;
}

.ms-hr{
  width: 100%;
  height: 20px;
  border-bottom: 1px solid darken($light-gray, 5);
  text-align: center;
  span{
    font-size: 14px;
    background-color: white;
    padding: 0 10px;
    position: relative;
    top: 5px;
    color: #7E8890;
  }
  margin-bottom: 20px;
}
.ms-login{
  cursor: pointer;
  text-align: center;
  background: $light-gray;
  transition: .4s;
  border-radius: 4px;
  &:hover{
    background: darken($light-gray, 5);
  }
}
.four-o-four {
  text-align: center;
  display: block;
}

.info-notification {
  display: block;
  // background-color: desaturate(lighten($teal, 50), 50);
  background-color: $blue;
  border-left: 10px solid darken($blue,20);
  // box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.3);
  font-size: 1.1em;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.25);
  color: #fff;
  font-weight: 400;
  clear: both;
  text-align: left;
  padding: 20px;

  a {
    color: white;
    text-decoration: underline;
  }

  p {
    margin: 0px 0px 10px 0px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.content-proper > .info-notification {
  margin: -20px -20px 20px;
}

.report-search{
  display: block;
  input{
    border-bottom: 1px solid #222;
    width: calc(100% - 25px);
    display: inline-block;
    background-color: transparent;
    border-radius: 0px;
    border-top: none;
    border-right: none;
    border-left: none;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .sign-in-promo h2 {
    font-size: 16px;
    font-weight: bolder;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .sign-in-promo h2 {
    font-size: 13px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .sign-in-promo h2 {
    font-size: 13px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .sign-in-promo h2 {
    font-size: 14px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .sign-in-promo h2 {
    font-size: 18px;
  }
}