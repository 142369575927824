/*==================================================================
=Screenshot Modal
----------------------------------------------------------------- */

.modal-screenshot {
  .modal-main {
    padding: 10px;
    .cluster-name {
      text-shadow: 0 2px 1px rgba(0,0,0,.1);
    }
    p {
      margin-bottom: 2em;
    }
  }
}
