.module-grid .module.cluster-summaries {
  padding: 1rem 0 0 0;
  .clusters {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-gap: 1em;
    .skeleton-img .fallbackSrc{
      width: 48px;
    }
    a.profile-card.is-unique {
      display: flex;
    }
    .member-image.icon_img {
      &:before {
        content: "";
        padding-top: 100%;
      }
      display: flex;
      align-items: center;
    }
  }
}
