.numeric-visualization {
  width: 49%;
  margin-bottom: 2em;

  .numeric-box {
    padding: 1em 0;

    .value {
      font-size: 5em;
      display: block;
      text-align: center;
      font-weight: 700;
      letter-spacing: -0.05em;
      font-weight: 300;
    }
    .compare-to {
      text-align: center;
      display: block;
      text-transform: capitalize;
      opacity: 0.5;
      font-style: italic;
    }
    .compare {
      display: flex;
      justify-content: space-around;

      .compare-item {

        .value {
          font-size: 4em;
          display: block;
          text-align: center;
          font-weight: 700;
          letter-spacing: -0.05em;
          font-weight: 300;
        }
        .name {
          text-align: center;
          display: block;
        }
      }
    }
  }


  .preferences {
    padding: 0px;

    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.05em;

    .select-dropdown {
      float: right;
      padding-right: 30px;
      position: absolute;
      width: auto;
      margin-left: 10px;
      top: -44px;
      right: 0px;
      max-width: 8rem;
    }
  }

}
