

.trait-card {
  padding: 1em;
  background-color: #fff;
  box-shadow: 0 0 3px rgba(0,0,0,.2);
  margin-top: 1em;
  // margin-right: 1em;
  overflow: hidden;
  .metric-icon {
    border: 5px solid #eee;
    box-sizing: content-box;
    // box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  }
  .metric-visualization {
    width: 100%;
  }
  .metric {
    float: right;
    text-align: center;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    .metric-icon {
      border-radius: 50%;
      height: 30px;
      width: 30px;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        -moz-transition: 250ms;
        -webkit-transition: 250ms;
        transition: 250ms;
        // box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.45);
        &.active {
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0);
        }
      }
      i {
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
        color: white;
       }
    }
  }
 .relevance {
    height: 5px;
    background-color: #00A79B;
    margin: 5px 0 10px 0;
  }
  .bar.uncluster {
    -moz-transition: none !important;
    -webkit-transition: none !important;
    transition: none !important;
    width: 100% !important;
    clear: both;
    margin-bottom: 10px;
    height: 20px;
  }
  .cluster-stats {
    -moz-transition: none !important;//sorry
    -webkit-transition: none !important;//sorry
    transition: none !important;
    height: 100% !important;
    span {
      // -moz-transition: none !important;
      // -webkit-transition: none !important;
      // transition: none !important;
    }
  }
  p {
    &.helper{
      margin-bottom: 1em;
      color: #333;

    }
    margin-bottom: 1em;
  }
  h4 {
    float: right;
    margin-bottom: 0 !important;
    // margin-top: 0;
    // color: #999;
    margin-right: 1em;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0);
    font-size: 0.7em;
    font-weight: 400;
    color: #00A79B;
    -moz-transition: 250ms;
    -webkit-transition: 250ms;
    transition: 250ms;
    &.active {
      text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
      color: shade(#00A79B, 25%);
    }
  }
  &.large {
    h2 {
      float: left;
      margin-bottom: 10px;
      max-width: 100%;
      word-wrap: break-word;
    }
    // h4 {
    //   float: right;
    //   margin-bottom: 0;
    //   color: #999;
    //   font-weight: 400;
    //   // color: #00A79B;
    //   &:hover, &:focus {
    //     color: shade(#00A79B, 25%);
    //   }
    // }
    p {
      font-size: .85em;
    }
  }
  &.medium {
    h3 {
      float: left;
      margin-bottom: 10px;
      max-width: 100%;
      word-wrap: break-word;
    }
    h4 {

      margin-top: 0;

    }
    .metric-icon span {
      box-shadow: 0 0 0 rgba(0,0,0,0);
    }
  }
  &.small {
    width: 100%;
    .bar-graph {
      margin-top: 0;
      padding-top: 0;
    }
    h2 {
    }
    h3 {
      margin-top: -0.5em;
      padding: 0;
    }
  }

}
