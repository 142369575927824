.report-builder--taxonomy-selector {
  .info {
    display: flex;
    justify-content: space-between;
  }

  .affinio-table .ember-table {
    max-height: 300px;

    tr {
      cursor: pointer;
    }
  }
}
