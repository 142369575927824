.module-grid .module.media-gallery {
  width: 100%;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  .twitter-frame {
    transition: 400ms 600ms;
    opacity: 0;
    float: left;
    &.show {
      opacity: 1;
    }
  }
  h3{
    clear: both
  }
  .empty {
    color: #666;
  }
}

#universe-container.reportsReportVersionClusterMembersStream {
  .media-gallery {
    width: 550px;
  }
}

