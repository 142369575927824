.toolbox-drawer {
  margin-bottom: .5em;
  @include animation($name: slideDownFadeIn, $duration: 1s, $delay: 0s);

  &.has-expanded-tool {
    position: relative;
    z-index: 2;
  }

  .drawer-label{
      color: $darkish-gray;
      font-size: 18px;
      font-weight: 300;
      cursor: pointer;
      position: relative;
  }
  .drawer-tools{
    // border-left: 1px solid $medium-gray;
    // border-radius: 3px;
    padding-left: 10px;
  }
}
