.multi-button {
  ul {
    // TODO likely handle this better
    max-width: 200px;
    background-color: white;
    position: absolute;
    li {
      padding: 0.5rem;
      border: 1px solid rgba(0,0,0,0.25);
      cursor: pointer;

      &:hover {
        background-color: rgba(0,0,0,0.25);
      }
    }
  }
}
