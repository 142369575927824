.metric-finder {
  padding: 1em;
  display: block;
  float: none;
  .found-metrics {
    a {
      border: none;
      border-bottom: 1px dotted #aaa;
      display: block;
       span.metric {
        font-weight: bold;
      }
       span.category {
        font-size: 0.8em;
      }
     }
  }
}
