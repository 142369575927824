.boolean-input-optimizer {
  position: relative;
  select.tweet-about {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 200px;
  }
  p.helper {
    margin-bottom: 1em;
  }
  .hovertip {
    position: relative;
    margin-left: 5px;
    top: -30px;
    // z-index: 1;
    float: right;
    transition: opacity 200ms;
    > a {
      background-color: #fff;
      border-color: #eee;
      z-index: 1;
      width: 38px;
      position: relative;
    }
  }
  textarea {
    height: 150px;
  }
  .optimizer-gallery {
    display: flex;
    flex-direction: row;
    .optimizer-header {
      width: 50%;
      .primary-input {
        display: flex;
        flex-direction: row;
        width: 100%;
        button {
          margin-left: 1em;
        }
      }
      button.remove {
        line-height: 1;
        margin-top: 1em;
      }
    }
    .optimizer-showcase {
      width: 50%;
      margin-left: 2em;
    }
  }

  .tweet-content-types {
    margin-bottom: 2em;
    a {
      cursor: pointer;
      display: inline-block;
      background-color: #eee;
      color: $menu-gray;
      border-radius: 4px;
      padding: 0.5em;
      margin: 0 0.5em 0.5em 0;
      &:first-of-type {
        padding-right: 0.5em;
      }
      &:last-of-type {
        padding-left: 0.5em;
      }
    }
  }
  .tweet-content-types:not(.boolean) a {
    &.input {
      background-color: $blue;
      color: #fff;
    }
  }
  .tweet-content-types.boolean a {
    &.boolean {
      background-color: $blue;
      color: #fff;
    }
  }
  .sample-tweets {
    // float: left;
    width: 100%;
    padding: 0px;
    box-sizing: border-box;
    position: relative;
    // overflow: hidden;
    // width: calc(40% - 20px);
    padding-right: 20px;
    .tweet-gallery {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      .sample-tweet {
        width: 48%;
        // margin-left: 1em;
        .tweet-box {
        }
      }
    }
  }

  .sample-tweets .twitter-frame {
    width: 1000px;
  }

  .vote-column .votable-word,
  .top-terms-column .votable-word {
    display: block;
    width: 100%;
    padding: 0px 20px;
    font-size: 1em;
    font-weight: 900;
    margin: 0px -20px;
    position: relative;
    line-height: 40px;
    height: 40px;
    background-color: transparent;
    overflow: hidden;
    box-sizing: content-box;
    // margin: 0px 5px 5px 0px;
    // box-shadow: 0px 0px 1px rgba(0,0,0,0.15);
    // background-color: #eee;
    // border-radius: 2px;
    // cursor: pointer;
  }

  .vote-column .votable-word {
    display: inline-block;
    width: auto;
    margin: 2px 0px;
    padding: 0px;
    line-height: 100%;
    height: auto;
    background-color: #444;
    color: #eee;
    // min-width: 100px;
    padding: 5px;
    font-weight: 400;
    border-radius: 4px;
    overflow: visible;
  }

  .vote-column .votable-word .votable-block {
    right: 0px;
    background-color: #eee;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    opacity: 0;
  }

  .vote-column .votable-word:hover {

  }

  .vote-column .votable-word:hover .votable-block {
    opacity: 1;
    top: 40px;
  }

  .vote-column .votable-word .votable-block span {
    display: inline-block;
    float: none;
    width: 50%;
  }

  .top-terms-column .votable-word:hover {
    background-color: #eee;
    transition: 0.2s;
  }

  .custom-top-term .votable-word:hover {
    background-color: transparent;
  }

  .vote-column .votable-word:hover {
    background-color: #222;
    color: #fff;
    transition: 0.2s;
  }

  .vote-column .votable-word .estimate,
  .top-terms-column .votable-word .estimate {
    font-weight: 300;
    font-size: 0.8em;
    margin-left: 5px;
    // float: right;
    transition: 0.2s;
  }

  .vote-column .votable-word .estimate {
    margin-left: 0px;
  }

  .vote-column .votable-word .remove-word {
    color: #fff;
    float: right;
    // background-color: rgba(255,0,0,0.5);
    display: block;
    padding: 5px;
    margin: -5px -5px -5px 0px;
    border-radius: 5px;

    &:hover {
      color: #f55;
    }
  }

  .vote-column .votable-word span.label {
    display: inline-block;
  }

  .top-terms-column .votable-word:hover .estimate {
    padding-right: 70px;
  }

  .votable-word .votable-block {
    // display: none;
    position: absolute;
    transition: 0.2s;
    right: -80px;
    top: 0px;
    z-index: 1;
    // background-color: #fff;
    // transition-delay: 0.3s;
  }

  .custom-top-term .votable-word.input.has-custom-term .votable-block,
  .votable-word:hover .votable-block {
    right: 0px;
    // transition-delay: 0s;
  }

  .votable-word .votable-block span {
    display: block;
    float: left;
    background-color: transparent;
    font-size: 1.5em;
    font-weight: 400;
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin: 5px 5px 5px 0px;
    text-align: center;
    cursor: pointer;
  }

  .votable-word .votable-block span.upvote {
    color: #4DB930;
  }

  .votable-word .votable-block span.downvote {
    color: #F52324;
  }



  .downvoted-words,
  .upvoted-words {
    padding: 20px;
    background: #fff;
    border-top: 5px solid;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.2);
    overflow: auto;
    max-height: 500px;
  }

  .downvoted-words {
    // background-color: #fee;
    border-top-color: #DE032C;
    margin-bottom: 20px;
  }
  .upvoted-words {
    // background-color: #efe;
    border-top-color: #4DB930;
    margin-bottom: 20px;
    width: 100%;
  }

  .upvoted-words .initial-engager-query {
    // border-bottom: 1px solid #ccc;
    // padding-bottom: 10px;
    background-color: #eee;
    margin: -20px;
    padding: 20px;
    margin-bottom: 10px;
  }

  .downvoted-words span,
  .upvoted-words span {
    display: block;
  }


  .top-terms-column {
    // width: 40%;
    // float: left;
  }

  .top-terms-box {
    box-sizing: border-box;
    // padding: 0px 20px;
    // background: #fff;
    // border-top: 5px solid #222;
    // box-shadow: 0px 0px 8px rgba(0,0,0,0.1);
    max-height: 500px;
    // margin-bottom: 40px;
  }

  .custom-top-term {
    span.votable-word {
      height: 48px;
      line-height: 48px;
    }
  }

  .threshold-box {
    box-sizing: border-box;
    // border-bottom: 1px solid #333;
    // margin-bottom: 40px;
    width: 100%;
    section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      input[type="range"] {
        width: 80%;
      }
      .threshold {
        text-align: center;
        color: $dark-blue;
        font-size: 1.2em;
        font-weight: bold;
      }
    }
  }


  // .top-terms-box {
  //   padding-bottom: 0px;
  // }

  .custom-top-term .votable-word.input {
    border: 0px;
  }

  .custom-top-term .votable-word.input input {
    width: 100%;
    transition: 0.2s;
  }

  .custom-top-term .votable-word.input:hover input,
  .custom-top-term .votable-word.input.has-custom-term input {
    width: calc(100% - 60px);
  }

  p.tweet-box {
    padding: 10px;
    background-color: #fff;
    // border: 1px solid #ccc;
    box-shadow: 0px 0px 4px rgba(0,0,0,0.2);
    min-height: 70px;
    padding-left: 70px;
    // background-image: url(/assets/images/facebook_avatar.jpg);
    background-image: url(/assets/images/twitter-user.png);
    background-position: 15px 15px;
    background-size: 40px;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  .newCampaignForm.Facebook p.tweet-box {
    background-image: url(/assets/images/facebook_avatar.jpg);
  }

  p.tweet-box p:before {
    content: "\201C"
  }

  p.tweet-box p:after {
    content: "\201D"
  }

  p.tweet-box span {
    // cursor: pointer;
  }

  p.tweet-box span.hovered {
    background-color: #eee;
    // color: #fff;
  }

  .vote-box {
    display: none;
    position: absolute;
    transition: 0.1s;
    // transition-delay: 0.3s;
    background-color: rgba(60,60,60,1);
    box-shadow: 0px 0px 3px rgba(0,0,0,0.2);
    padding: 10px;
    width: 55px;
    box-sizing: content-box;
  }

  .sample-tweets:hover .vote-box,
  .vote-column:hover .vote-box {
    display: block;
  }

  .vote-box:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0em;
    bottom: -1em;
    left: 10px;
    box-sizing: border-box;

    border: .5em solid black;
    border-color: transparent transparent rgba(60,60,60,1) rgba(60,60,60,1);

    transform-origin: 0 0;
    transform: rotate(-45deg);

    // box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.4);
  }

  // .vote-box.shown {
  //   display: block;
  // }

  .vote-box .upvote,
  .vote-box .downvote {
    display: block;
    float: left;
    // padding: 20px;
    color: #222;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    font-size: 1.5em;
    font-weight: bold;
  }

  .vote-box .upvote {
    color: #4DB930;
    margin-right: 5px;
  }

  .vote-box .downvote {
    color: #DE032C;
    margin-left: 5px;
  }

  .explainer {
    // height: 120px;
    margin-bottom: 1em;
  }


  .vote-column .ui-slider {
    // margin: 20px 0px;
  }
}
