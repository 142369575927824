.star-rating {
  display: inline-block;
  position: relative;
  top: 2px;
 .star {
    color: inherit;
    text-decoration: none;
    float: left;
    background-color: #ff0;
    background: transparent url(/assets/images/star.png) 50% 50% no-repeat;
    height: 14px;
    width: 14px;
    background-size: 14px;
    padding: 0px;
    border: 0px;

    &.empty {
      background: none;
      background-color: transparent;
    }
 }
}
