.audience-visualization {
  width: 49%;
  height: 640px;
  .network-graph, .pie-chart {
    max-height: 500px;
    > svg,
    .c3 > svg {
      height: 500px;
      max-height: 500px;
      display: block;
      margin: auto;
    }
  }
  .audience-viz-bg {
    width: auto;
    padding-right: 20px;
  }
}

// Print
@media print {
  .audience-visualization {
    height: auto !important;
    .network-graph {
      > svg {
        height: auto !important;
      }
    }
  }
}
