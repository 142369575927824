.permissions-view {
  .permissions-holder{
    @include animation(fadeIn)
    margin-top: 5px;
    height: auto;
    width: 100%;
    padding-left: 10px;
    border-left: 1px solid lighten($dark-blue, 10);
  }
}
