/*==================================================================
=Cluster Nav
----------------------------------------------------------------- */

.cluster-nav {
  position: fixed;
  top: 125px;
  bottom: 30px;
  width: 250px;
  left: 0px;
  overflow: auto;
  padding-bottom: 0px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 5;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  transition: 1s;

  &::-webkit-scrollbar-thumb {
    background: #ddd !important;
  }

}

/* Let's get this party started */
.cluster-nav::-webkit-scrollbar,
.option-boxes section ul::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.cluster-nav::-webkit-scrollbar-track,
.option-boxes section ul::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0,0,0,0);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: transparent;
}

/* Handle */
.cluster-nav::-webkit-scrollbar-thumb,
.option-boxes section ul::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255,255,255,.4);
}
.option-boxes section ul::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,.4);
}
.cluster-nav::-webkit-scrollbar-thumb:window-inactive,
.option-boxes section ul::-webkit-scrollbar-thumb:window-inactive {
  background: transparent;
}

.cluster-nav h5 {
  color: #999;
}

.cluster-nav > a,
.cluster-nav strong {
  display: block;
  padding: 10px;
  font-size: 0.9em;
  color: #fff;
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  outline: none;

  /*border-bottom: 1px solid #222;*/
}

.cluster-nav a:hover,
.cluster-nav a.active {
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

//TODO :thinking-face: -js
// nav.cluster-nav a.new-campaign-button {
//   color: $teal;
// }

// nav.cluster-nav a.new-campaign-button:hover {
//   background-color: #009489;
// }

nav.cluster-nav strong {
  background-color: transparent;
  font-weight: normal;
  border-bottom: 1px solid #333;
}

.cluster-nav .sub-clusters {
  // border-left: 5px solid #aaa;
  border-bottom: none;
  // float: left;
}

.cluster-nav .sub-nav-top {
  display: flex;
}

.cluster-sub-nav span.title {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  opacity: 0.8;
  text-overflow: ellipsis;
}

//All Cluster
.cluster-nav > a.all {
  padding: 0px;
  background-color: transparent;
  // background-position: 12px 12px;
  background-position: 15px calc(100% - 22px);
  background-repeat: no-repeat !important;
  background-size: 16px 16px;
  padding-bottom: 20px;

  section {
    padding-left: 15px;
    color: $medium-gray;
    font-weight: 300;
    font-size: 1.1em;
    i.fa {
      color: $medium-gray;
      font-size: 1.125em;
    }
    svg {
      width: 1.125em;
      height: 1.125em;
      vertical-align: middle;

      &.x-twitter-icon {
        margin: 0 4px 2px 0;
        fill: $medium-gray;
      }
    }
  }

  span.title {
    text-overflow: visible;
    color: $menu-gray;
    text-shadow: none;
    white-space: normal;
    font-size: 1.8em;
    line-height: 1.2em;
    font-weight: 300;
    width: 100%;
    padding: 15px;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
  }

  &:hover {
    // background-color: lighten($light-gray, 10);
    span.title {
      opacity: 0.5;
    }

    section {
      color: #000;
      i.fa {
        color: #000;
      }
    }

  }

}// All Cluster


nav.cluster-nav > a span.mini-metric {
  display: none;
  font-size: 0.8em;
}

nav.cluster-nav > a.active span.mini-metric {
  display: block;
  clear: both;
}

nav.cluster-nav > a.held,
nav.cluster-nav > a.active,
nav.cluster-nav > a:hover {
  text-shadow: 1px 1px 3px rgba(0,0,0,0.2);
  // -webkit-box-shadow: inset -50px 0px 50px -50px rgba(0,0,0,.7);
  // -moz-box-shadow: inset -50px 0px 50px -50px rgba(0,0,0,.7);
  // box-shadow: inset -50px 0px 50px -50px rgba(0,0,0,.7);
  color: #fff;
}

.cluster-nav .cluster-sub-nav:not(.all) {
  float: left;
  position: relative;
  width: 100%;
}

.cluster-nav a.all{
  border: none;
  position: relative;
  // background-color: #333;
}

.cluster-nav a:not(.all) {
  border-left: 5px solid;
  position: relative;
  // background-color: #333;
  padding: 10px 10px 10px 10px;
  color: #222;
  font-size: 0.85em;
  // color: #fff;

  .mini-metrics {
    display: none;
    font-size: 0.8em;

    span {
      display: block;
      width: 100%;
      margin-bottom: 0.1rem;

      &:last-of-type {
        border: none;
      }
    }// span

  }// mini-metrics

  &.active {
    // text-shadow: 1px 1px 3px rgba(0,0,0,.2);
    .title {
      padding-bottom: 5px;
    }
    .mini-metrics {
      display: block;
      // text-shadow: 1px 1px 3px rgba(0,0,0,.2);
    }// mini-metrics
  }// active
  &:hover {
    // text-shadow: 1px 1px 3px rgba(0,0,0,.2);
    .title {
      opacity: 1;
    }
  }

}//.cluster-nav a:not(.all)

.cluster-nav a.all.active {
  // background-color: #333;
}
.cluster-nav a.all:hover {
  // background-color: rgba(0,0,0,0.5);
}

.cluster-nav .percentage {
  opacity: 0.5;
  display: block;
  flex-basis: 3em;
  text-align: right;
}

/*==================================================================
=Left Nav Edit
----------------------------------------------------------------- */

.cluster-nav .edit-cluster, .cluster-nav .edit-notes {
  display: none;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  width: 3em;
  opacity: 0.65;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  // -webkit-transition: .2s;
  // -moz-transition: .2s;
  // -o-transition: .2s;
  cursor: pointer;

}

.cluster-nav .edit-cluster .fa,
.cluster-nav .edit-notes .fa {
  width: 12px;
  height: 12px;
  display: block;
  margin: 4px auto 0;
}

.cluster-nav .edit-cluster .fa,
.cluster-nav .edit-notes .fa {
  color: #fff;
}

.cluster-sub-nav:hover .edit-cluster,
.cluster-nav .active .edit-cluster,
.cluster-sub-nav:hover .edit-notes,
.cluster-nav .active .edit-notes {
  display: block;
}

.cluster-nav .edit-notes:hover,
.cluster-nav .edit-cluster:hover {
  opacity: 1;
}

/*==================================================================
=Making left-nav toggleable
----------------------------------------------------------------- */
.toggle-spot {
  display: block;
  float: right;
  padding: 5px;
  margin-top: 5px;
}

.toggle-overlay {
  background-color: transparent;
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 14px;
}

.is-toggleable {
  background-color: #fff;
  border-bottom: 1px solid #eee !important;
  overflow-x: auto;
  overflow-y: hidden;

  // &:hover {
  //   color: #333 !important;
  // }

  .percentage {
    display: none;
  }
} //.is-toggleable

.notes-btn{
  padding-right: 10px;
  z-index: 6;
}

.name-btn{
  z-index: 6;
}
// hidden/removed cluster styles
.cluster-sub-nav{
  &.isHidden, &.isClusterRemoved {
    display: none;
  }
}
