/*==================================================================
=Nav and General Styles
----------------------------------------------------------------- */

.admin-nav{
  top: 80px;
  left: 0px;
  position: fixed;
  width: 230px;
  height: calc(100% - 80px);
  overflow: auto;
  a{
    color: lighten($dark-blue, 10);
    display: block;
  }
  // a.active{
  //   color: $dark-blue;
  //   border-radius: 3px;
  //   background-color: rgba(0, 0, 0, 0.05);
  // }
  .nav-header{
    width: 100%;
    padding-left: 20px;
    margin-bottom: 0.5em;
    border-bottom: 1px #aaa solid;
  }
  .nav-item{
    padding: 10px;
    padding-left: 20px;
    transition:.3s;
    font-size: 16px;
    i{
      display: inline-block;
    }
    span{
      font-size: 14px;
      display: inline-block;
      // display: block;
    }
    &:hover{
      color: $dark-blue;
      border-radius: 3px;
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
  .internal-admin-nav{
    margin-top: 0.5em;
  }
}

.admin-wrapper{
  margin-left: 230px;
}

h2{
  display: inline-block;
}

.active-toggle{
  font-weight: 300;
  font-size: 18px;
  display: inline-block;
  .toggle-on{
    i{
      color: #4CAF50;
      cursor: pointer;
      &:hover{
        color: darken(#4CAF50, 10);
      }
    }
  }
  .toggle-off{
    i{
      color: #E53935;
      cursor: pointer;
      &:hover{
        color: darken(#E53935, 10);
      }
    }
  }
}
div.credit-input > .required::before , .seat-input > .required::before {
  content: "*";
  color: red;
}

.role-toggle{
  display: inline-block;
  margin-right: 10px;
}

.save-button{
  margin-left: 10px;
  display: inline-block;
  position: relative;
  top: -5px;
  button{
    padding: 2px 7px 2px 7px;
    color: #fff;
    background-color: lighten($dark-blue, 10);
    box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.2);
    &:hover{
      box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.6);
    }
  }
}

.delete-button{
  margin-left: 10px;
  display: inline-block;
  position: relative;
  top: -5px;
  button{
    padding: 2px 7px 2px 7px;
    color: #fff;
    background-color: #E53935;
    box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.2);
    &:hover{
      box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.6);
    }
  }
}

.create-new-button{
  top: -3px;
  position: relative;
  margin-left: 10px;
  border-radius: 3px;
  font-size: 14px;
  padding: 7px 7px 7px 7px;
  color: #fff;
  background-color: lighten($dark-blue, 10);
  box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.2);
  &:hover{
    box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.6);
    color: #fff;
  }
}

.back-to-company{
  display: block;
  color: lighten($dark-blue, 10);
  font-weight: 300;
  font-size: 18px;
  // padding-top: 5px;
  margin-bottom: 0.5em;
  &:hover{
    color: $dark-blue;
  }
}

.report-search-feild{
  margin-bottom: 7px;
  background-color: #fff;
  width: 100%;
  padding-top: 5px;
  border-radius: 3px;
  box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.2);
  i{
    margin-left: 10px;
    margin-right: 5px;
    display: inline-block;
    color: lighten($dark-blue, 10);
    font-size: 18px;
    cursor: pointer;
    &:hover{
      color: $dark-blue;
    }
  }
  input[type=text]{
    padding: 0;
    padding-bottom: 2px;
    padding-top: 9px;
    border: none;
    border-bottom: 1px solid lighten($dark-blue, 10);
    width: calc(100% - 65px);
    display: inline-block;
    border-radius: 0px;
    &:focus{
      border: none;
      border-bottom: 1px solid lighten($dark-blue, 10);
    }
  }
}

/*==================================================================
=Company Page Styles
----------------------------------------------------------------- */

.company-info{
  span{
    font-weight: 300;
    font-size: 18px;
  }
  .input{
    margin-right: 7px;
  }
  .credit-input{
    display: inline-block;
  }
  .seat-input{
    display: inline-block;
  }
  .sfid-input{
    display: inline-block;
  }
  .trial-input{
    display: inline-block;
    input{
      width: 150px;
    }
    .toggle-on{
      color: #4CAF50;
      cursor: pointer;
      &:hover{
        color: darken(#4CAF50, 10);
      }
    }
    .toggle-off{
      color: #E53935;
      cursor: pointer;
      &:hover{
        color: darken(#E53935, 10);
      }
    }
  }
  input[type=text]{
    margin-bottom: 0;
    margin-right: 5px;
    margin-left: 5px;
    padding: 0 0 0 0;
    border-bottom: 1px solid $dark-blue;
    display: inline-block;
    border-radius: 0px;
    &:focus{
      border: none;
      border-bottom: 1px solid $dark-blue;
    }
  }
}
.section-header{
  cursor: pointer;
  font-weight: 300;
  display: block;
  font-size: 18px;
  line-height: 2.75em;
}
.business-type-input{
  display: inline-block;
  label{
    font-size: 18px;
    font-weight: 300;
    display: inline-block;
  }
  .business-type-options{
    display: inline-block;
    width: 100px;
  }
}
.vertical-type-input{
  display: inline-block;
  label{
    font-size: 18px;
    font-weight: 300;
    display: inline-block;
  }
  .vertical-type-options{
    display: inline-block;
    width: 140px;
  }
}

.business-type-info{
  margin-top: 12px;
}


/*==================================================================
=User Page Styles
----------------------------------------------------------------- */
.user-info{
  .name{
    font-size: 1.8em;
    font-weight: 300;
    margin-right: 5px;
  }
  .dash{
    position: relative;
    top: -3px;
    margin-right: 5px;
  }
  .note-input{
    span{
      font-weight: 300;
      font-size: 18px;
    }
    textarea{
      min-height: 70px;
      width: 95%;
      border: 1px solid $dark-blue;
      border-radius: 3px;
      &:focus{
        border: 1px solid $dark-blue;
      }
    }
  }
}

.group-info{
  display: inline-block;
  .name{
    font-size: 1.8em;
    font-weight: 300;
  }
}

.no-add{
  margin-left: 10px;
  border-radius: 3px;
  font-size: 14px;
  padding: 7px 7px 7px 7px;
  color: #fff;
  background-color: darken(#E53935, 10);
  box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.2);
}

.add-more{
  margin-left: 5px;
  font-size: 1.4em;
  font-weight: 300;
}

/*==================================================================
=Metrics Section Styles
----------------------------------------------------------------- */

.company-select{
  font-weight: 300;
  cursor: pointer;
  display: inline-block;
  background-color: #fff;
  border: none;
  padding: 0.5em;
  width: 370px;
  color: $dark-blue;
  transition: .2s;
  &:hover{
    box-shadow: 0 5px 20px -3px rgba(0,0,0,.2);
  }
}

.select-label{
  font-weight: 300;
  display: inline-block;
  margin-right: 10px;
}

.dash{
  margin-right: 10px;
}

.metrics-date{
  font-weight: 300;
  display: inline-block;
  input{
    cursor: pointer;
    font-size: 16px;
    width: 85px;
    color: $dark-blue;
    margin-right: 5px;
    margin-left: 5px;
    padding: 5px 0 5px 0;
    display: inline-block;
    // background-color: #eeeeee;
    border-radius: 0px;
    &:focus{
      border: none;
    }
    &:hover{
      box-shadow: 0 5px 20px -3px rgba(0,0,0,.2);
    }
  }
}

.sub-title{
  display: block;
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 15px;
}

.metrics-hr{
  clear: none;
  margin-top: 0;
  margin-bottom: 20px;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.export-metrics{
  cursor: pointer;
  border-radius: 3px;
  font-size: 14px;
  padding: 7px 7px 7px 7px;
  color: #fff;
  background-color: lighten($dark-blue, 10);
  box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.2);
  &:hover{
    box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.6);
    color: #fff;
  }
}

.metrics-table{
  margin-top: 10px;
  @for $i from 2 through 20 {
    li:nth-of-type(#{$i}) {
      @include animation($name: slideUpFadeIn, $duration: 0.2s, $delay: ($i*0.03s));
    }
  }
  li{
    span{
      display:inline-block;
      margin-right: 2%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    span:nth-child(1){
      width: 30%;
    }
    span:nth-child(2){
      width: 35%;
    }
    span:nth-child(3){
      width: 25%;
    }
  }
  li.subtable-header{
    display: grid;
    grid-template-columns: repeat(2, 30%) repeat(3, 10%) 20% 20% 10% 15% 10%;
    overflow-x: scroll;
    span{
      &:nth-child(3){
        padding-left: 0;
      }
      &:nth-child(1n) {
        background-color: #c0c0c0;
        padding-left: 7px;
      }
      &:nth-child(n+11) {
        background-color: #f0f0f0;
      }
      display:inline-block;
      margin-right: 2%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      padding: 0; //TODO: this is getting overwritten
    }
  }
  .table-header{
    margin-bottom: 10px;
    border-radius: 3px;
    background-color: #fff;
    padding:10px 20px 10px 20px;
    box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.2);
  }
  .table-rows{
    span:nth-child(3){
      padding-left: 7px;
    }
    span:nth-child(4){
      padding-left: 7px;
    }
    span:nth-child(5){
      padding-left: 7px;
    }
    span:nth-child(6){
      padding-left: 7px;
    }
  }
  .table-row{
    // border-radius: 3px;
    background-color: #fff;
    padding: 0px 15px;
    line-height: 2.75em;
    // margin-bottom: 10px;
    // box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.2);
    transition: 0.1s;
    border-bottom: 1px #eee solid;
    &:hover{
      background-color: #f5f5f5;
    }
  }
}

.search-field{
  margin-top: 20px;
  background-color: #fff;
  padding-top: 5px;
  border-radius: 3px;
  box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.2);
  i{
    margin-left: 10px;
    margin-right: 5px;
    display: inline-block;
    color: lighten($dark-blue, 10);
    font-size: 18px;
  }
  input[type=text]{
    padding: 0;
    padding-bottom: 2px;
    padding-top: 9px;
    border: none;
    border-bottom: 1px solid lighten($dark-blue, 10);
    width: calc(100% - 65px);
    display: inline-block;
    border-radius: 0px;
    &:focus{
      border: none;
      border-bottom: 1px solid lighten($dark-blue, 10);
    }
  }
}

/*==================================================================
= Loading Spinner Style
----------------------------------------------------------------- */

.metrics-spinner{
  margin-left: 40%;
  margin-top: 20px;
  // position: relative;
  // top: -40px;
}


/*==================================================================
= Notify Styles
----------------------------------------------------------------- */
.ember-notify-cn {
  z-index: 100000000 !important; // To go above modal backdrop
}
.ember-notify {
  color: white;
  a {
    color: white;
  }
}
.ember-notify.success {
    background-color: darken(#4CAF50, 10);
    border-color: darken(#4CAF50, 10);
    color: white;
}

.ember-notify.error {
    background-color: darken(#E53935, 10);
    border-color: darken(#E53935, 10);
    color: white;
}

.ember-notify.info {
    background-color: darken($blue, 10);
    border-color: darken($blue, 15);
    color: white;
}
//TODO: Using this since I wasnt actually throwing an error. Align alert and error colors.
.ember-notify.alert {
    background-color: darken(#E53935, 10);
    border-color: darken(#E53935, 10);
    color: white;
}


/*==================================================================
=Tracker
----------------------------------------------------------------- */
.tracker-card {
  display: block;
  padding: 1em;
  // background-color: lighten($dark-blue, 10);
  background-color: #fff;
  // border-left: 10px solid $dark-blue;
  // font-size: 1.1em;
  box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.2);
  color: $dark-gray;
  text-align: left;
  margin-bottom: 10px;

  h2 {
    margin: 0px;
    background-color: $dark-blue;
    color: #fff;
    display: block;
    margin: -1em -1em 1em;
    font-size: 1em;
    padding: 0.5em 1em;

    span {
      font-size: 1.5em;
    }
  }

  &.folded {
    h2 {
      margin-bottom: -1em;
    }
  }

  .title{
    margin-left: 10px;
  }

  table{
    thead {
      tr th {
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
      }
    }
    tbody{
      td {
        width: 10%;
        line-height:25px;
        margin-bottom: 0px;
        padding: 0px;
        transition: 0.1s;
        line-height: 2.75em;
        border-bottom: 1px #eee solid;
        clear: both;

      }
    }
  }
}

.admin-hr{
  clear: none;
  margin-bottom: 5px;
  margin-top: 5px;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.admin-hr-info{
  position: relative;
  top: 0;
  width: 95%;
  clear: none;
  margin-bottom: 5px;
  margin-top: -25px;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.admin-hr-bottom{
  clear: none;
  margin-bottom: 5px;
  margin-top: 15px;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.report-search-spinner{
  margin-left: 45%;
}

/*==================================================================
=Loading Route
----------------------------------------------------------------- */

.centered-admin-loading {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  margin-top: 5rem;
  padding: 25px;
}
