.age-breakdown {
  width: 49%;
  .demographic-data {
    margin-top: 1em;
    height: 380px;
    &.no-baseline {
      height: 300px;
    }
    &.no-compare {
      height: 300px;
      &.no-baseline {
        height: 200px;
      }
    }
  }
}
