.module-info {
  clear: both;
  width: 250px;
  position: absolute;
  z-index: 100;
  background: white;
  box-shadow: 0px 0px 30px rgba(0,0,0,0.25);
  padding: 10px;
  top: 20px;
  right: 0px;

  a {
    color: $red;
  }

  h3 {
    margin-bottom: 0;
  }
}
