@import 'modals/modal';
@import 'modals/modal-cluster-name';
@import 'modals/modal-feedback';
@import 'modals/modal-keynav';
@import 'modals/modal-share';
@import 'modals/modal-boolean';
@import 'modals/modal-sign-in';
@import 'modals/modal-text';
@import 'modals/modal-join-data-source';
@import 'modals/modal-edit-report';
@import 'modals/modal-edit-report-nav';
@import 'modals/modal-edit-report-configuration';
@import 'modals/modal-edit-folder';
@import 'modals/modal-new-folder';
@import 'modals/modal-screenshot';
@import 'modals/modal-focus';
@import 'modals/modal-data-source-selection';
@import 'modals/modal-data-set-upload';
@import 'modals/modal-data-set-selection';
@import 'modals/modal-datasource-actions';
@import 'modals/modal-datasource-column-preview';
@import 'modals/modal-parquet-upload';
@import 'modals/modal-persona';
