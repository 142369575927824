/*=========================================
Cluster Comparisons
=========================================*/

#universe-container.audiencesAudienceCompareIndex .content-proper {
  box-shadow: none;
  background-color: transparent;
  padding-right: 0px;
  width: calc(100% - 210px);
}

.scatter-legend {
  margin: 20px auto;
   span {
    display: inline-block;
    padding: 5px 10px;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.2);
    margin: 5px;
    color: #fff;
    text-shadow: 1px 1px 0px rgba(0,0,0,0.25);

    &.bioKeywords {
      background: $teal;
    }
    &.hashtags {
      background: $red;
    }
    &.locations {
      background: $blue;
    }
    &.interestCategories {
      background: darken($yellow, 20);
    }
  }
}

.compare-display {
  width: 100%;
}

.cluster-compare-bars {
  // background-color: #fff;
  padding: 20px;
  width: calc(33.333% - 20px);
  float: left;
  margin: 10px;
  box-sizing: border-box;
  position: relative;
  animation-name: comparecolumn;
  animation-duration: 1s;
  // animation-timing-function: cubic-bezier(0.5,1,0,1);
  // animation-delay: 0.5s;
  animation-fill-mode: both;

  &:nth-of-type(1) {
    clear: both;
    animation-delay: 1.5s;
  }
  &:nth-of-type(2) {
    animation-delay: 1.7s;
  }
  &:nth-of-type(3) {
    animation-delay: 1.9s;
  }

  h4 {
    font-size: 1.2em;
    // font-style: italic;
    font-weight: 300;
    color: #222;
    margin: -10px;
    margin-bottom: -30px;
    small {
      font-size: 0.65em;
      font-weight: bold;
    }
  }

  .comparison {
    display: block;
    clear: both;
    margin-bottom: 10px;
    margin-left: -10px;
    margin-right: -20px;
    padding: 20px 20px 25px 30px;
    background-color: #fff;
    // box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
    position: relative;
    color: $base-font-color;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    -o-transition: .2s;

    small {
      float: left;
      border-radius: 120px;
      height: 30px;
      width: 30px;
      text-align: center;
      color: #222;
      position: relative;
      background-color: $teal;
      margin-top: 0px;
      margin-right: 10px;
      border: 5px solid #eee;
      box-sizing: content-box;
      left: -20px;
      position: absolute;
      top: 50%;
      margin-top: -25px;
      overflow: hidden;
      // box-shadow: inset 1px 1px 100px rgba(255,255,255,0.8);
      // display: none;

      i {
        color: #fff;
        font-size: 1em;
        line-height: 30px;
        text-shadow: 1px 1px 0px rgba(0,0,0,0.25);
      }

    }

    strong {
      font-weight: normal;
      font-size: 0.8em;
      opacity: 0.8;
      margin-bottom: -5px;
      margin-top: -5px;
      display: block;
      background-color: transparent !important;
      position: relative;
      word-break: break-word;
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        -webkit-transition: .2s;
      }
    }

    h6 {
      font-weight: 500;
      font-size: 1.2em;
      text-transform: capitalize;
      float: left;
      position: relative;
      top: 0px;
      opacity: 1;
      transition: .2s;
      -webkit-transition: .2s;
      -moz-transition: .2s;
      -o-transition: .2s;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    em {
      font-size: 0.8em;
      float: left;
      clear: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 70%;
    }

    .skewbox {
      float: right;
    }

    .splits {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;

      .split {
        display: block;
        float: left;
        overflow: hidden;
        box-sizing: border-box;
        padding: 5px 0px;
        overflow: hidden;
        height: 0px;
        background-color: #fff;
      }
    }

    // Teal = self-description
    &.bioKeywords small, &.bioKeywords strong,
    &.lifestyles small, &.lifestyles strong,
    &.jobTitles small, &.jobTitles strong,
    &.interfacesUsed small, &.interfacesUsed strong,
    &.ages small, &.ages strong,
    &.ageBrackets small, &.ageBrackets strong,
    &.genders small, &.genders strong,
    &.gender small, &.gender strong,
    &.device small, &.device strong,
    &.parentalSituation small, &.parentalSituation strong,
    &.creditCard small, &.creditCard strong,
    &.lifestyle small, &.lifestyle strong,
    &.industry small, &.industry strong,
    &.retailSpend small, &.retailSpend strong,
    &.politicalBelief small, &.politicalBelief strong,
    &.language small, &.language strong,
    &.languages small, &.languages strong,
    &.relationshipStatus small, &.relationshipStatus strong,
    &.lifeEvent small, &.lifeEvent strong,
    &.country small, &.country strong,
    &.householdIncome small, &.housholdIncome strong,
    &.houseHoldComposition small, &.houseHoldComposition strong,
    &.homeOwnership small, &.homeOwnership strong,
    &.educationLevel small, &.educationLevel strong,
    &.jobCategories small, &.jobCategoris strong,
    &.jobSectors small, &.jobSectors strong,
    &.customSegments small, &.customSegments strong,
    &.degreeGroups small, &.degreeGroups strong,
    &.employerSize small, &.employerSize strong,
    &.followedIndustries small, &.followedIndustries strong,
    &.seniorities small, &.seniorities strong,
    &.careerProfiles small, &.careerProfiles strong,
    &.educationStatuses small, &.educationStatuses strong,
    &.industryFollowers small, &.industryFollowers strong,
    &.interestCategoriesFb small, &.interestCategoriesFb strong,
     {
      background: $teal;
      color: $teal
    }

    // Red = Content
    &.hashtags small, &.hashtags strong,
    &.mentions small, &.mentions strong,
    &.emojis small, &.emojis strong,
    &.topTopics small, &.topTopics strong {
      background: $red;
      color: $red;
    }

    // Blue = Location
    &.locations small, &.locations strong {
      background: $blue;
      color: $blue;
    }

    // Yellow = Interests
    &.interestCategories small, &.interestCategories strong,
    &.genres small, &.genres strong,
    &.artists small, &.artists strong,
    &.albums small, &.albums strong,
    &.tracks small, &.tracks strong {
      background: $yellow;
      color: $yellow;
    }

    .influencer-cluster-bar {
      position: absolute;
      height: 10px;
      bottom: 0px;
      left: 0px;
      width: 100%;
    }

  } //comparison



  a.comparison {
    -webkit-transition: .2s;
    -moz-transition: .2s;
    -o-transition: .2s;

    // h6{
    //   width: calc(100% - 80px);
    // }

    .skewbox {
      // float: right;
      top: -10px;
      position: relative;
      -webkit-transition: .2s;
      -moz-transition: .2s;
      -o-transition: .2s;
      // margin-bottom: 10px;
    }

    .interest{
      position: relative;
      top: 15px;
      width: calc(100%  - 60px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .splits {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;

      .split {
        display: block;
        float: left;
        overflow: hidden;
        box-sizing: border-box;
        padding: 5px 0px;
        overflow: hidden;
        height: 0px;
        background-color: #fff;
      }
    }

    small {
      -webkit-transition: .2s;
      -moz-transition: .2s;
      -o-transition: .2s;
    }

    strong {
      top: 0px;
      left: 0px;
      -webkit-transition: .2s;
      -moz-transition: .2s;
      -o-transition: .2s;

      &:before {
        content: attr(data-href);
        position: absolute;
        opacity: 0;
        // top: -10px;
        font-size: 1.3em;
        font-weight: 700;
        margin-top: -20px;
        line-height: 25px;
        display: block;
        -webkit-transition: .2s;
        -moz-transition: .2s;
        -o-transition: .2s;
      }
    }

    &:hover {
      box-shadow: 0px 3px 5px rgba(0,0,0,0.2);
      background-color: #f5f5f5;

      .skewbox{
        opacity: 0;
      }

      strong {
        animation-name: slideDown;
        animation-delay: 0.2s;
        animation-duration: 0.2s;
        animation-timing-function: ease-out;
        animation-fill-mode: both;

        &:before {
          animation-name: opacIn;
          animation-delay: 0.2s;
          animation-duration: 0.2s;
          animation-timing-function: ease-out;
          animation-fill-mode: forwards;
        }
      }

      h6 {
        opacity: 0;
        top: 20px;
      }
    } //hover

  } //a.comparison



} //cluster compare bars

header.compare {
  // background-color: #ccc;
  clear: both;
  width: 100%;
  padding: 0px;
  // padding: 20px;
  position: relative;
  margin-top: 50px !important;
  // margin-bottom: 70px;


  .page-title {
    float: left;
    overflow: hidden;
    width: 0%;
    padding: 0px;
    margin: 0px;
    animation-name: comparebanner;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.5,1,0,1);
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    position: static;
    right: 50%;
    white-space: nowrap;

    &:nth-child(2) {
      text-align: right;
      float: right;
      right: inherit;
      left: 50%;
    }

    h2 {
      color: #fff;
      text-shadow: 1px 1px rgba(0,0,0,0.25);
      max-width: 100%;
      small {
        display: block;
        font-size: 0.65em;
      }
    }

    p {
      margin: 0px;
      color: #fff;
      text-shadow: 1px 1px rgba(0,0,0,0.25);
      max-width: 100%;
    }
  }

  .radar-chart {
    background-color: #fff;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
    border: 20px solid $light-gray;
    // padding: 20px;
    // float: right;
    position: absolute;
    border-radius: 300px;
    // bottom: -50%;
    margin-top: -40px;
    left: 50%;
    margin-left: -180px;
    width: 360px;
    height: 360px;
    animation-name: radar;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }

}

header.compare.custom{
  margin-bottom: 120px !important; // TODO this may need to change depending  on dynamic radar chart
}

.no-radar header.compare {
  margin: 0 !important;
}

@keyframes slideDown {
  0% {
    top: 0px;
  }
  100% {
    top: 20px;
  }
}

@keyframes opacIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes radar {
  0% {
    margin-top: -40px;
    opacity: 0;
  }
  100% {
    margin-top: -80px;
    opacity: 1;
  }
}

@keyframes comparecolumn {
  0% {
    top: 40px;
    opacity: 0;
  }
  100% {
    top: 0px;
    opacity: 1;
  }
}

@keyframes comparebanner {
  0% {
    width: 0%;
  }
  10% {
    padding: 20px;
  }
  100% {
    width: 50%;
    padding: 20px;
  }
}
