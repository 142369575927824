.ember-table{
  .multi-select{
    padding: 0 10px 0 10px;
    .ember-basic-dropdown{
      .ember-power-select-trigger{
        background-color: transparent !important;
        border-bottom: none;
      }
    }
    .ember-power-select-options{
      font-size: 0.7em;
    }
  }
}
