.traits-summary {
  // height: 400px;
  width: 50%;
  position: relative;
  .preferences {
    .select-dropdown {
      float: right;
      padding-right: 30px;
      position: absolute;
      width: auto;
      margin-left: 10px;
      top: -44px;
      right: 0px;
    }
  }

}
