.report-list-item {
  > a {

  }
  .expanded {
    border-bottom: solid 1px $base-border-color;
  }
  .report-info-section {
    .contentFrom{
      order: 100;
    }
    .contentTo{
      order: 101;
    }
    .minInfluencerFollowers{
      order: 102
    }
    .maxInfluencerFollowers{
      order: 103
    }
    .recycle-button {
      // height: 100%;
      border-radius: 4px;
      margin-top: 1em;
      padding: 0.25em 1em;
      background-color: tint(green, 45%);
      opacity: 1 !important;
      color: #fff;
      transition: 250ms;
      position: absolute;
      top: 40px;
      right: 10px;
      &:hover {
        background-color: green;
      }
    }
  }
}
