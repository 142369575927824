.summary-card {
  padding: 0.7em;
  width: 100%;
  background: #333c4e;
  color: #fff;
  margin-bottom: 5px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  padding: 10px;
  position: relative;
  display: inline-block;
  word-wrap: break-word;
  > .skeleton-img {
    width: 50px;
    float: left;
    margin-right: 10px;
  }
  .value{
    display: block;
  }
  .estimate{
    font-size: 0.7em;
    font-weight: 300;
  }
  .remove {
    float: right;
    color: #999;
    font-weight: 300;
    font-size: 1.5em;
    position: absolute;
    padding: 13px;
    top: 0;
    right: 0;
    line-height: 50%;
    transition: 250ms;
    &:hover {
      color: $dark-gray;
      // color: red;
    }
  }
}
