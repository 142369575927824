.access{
  margin-top: 30px;
}

.hasAccess{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  height: 50px;
}

.removeAccess{
  height: 40px;
  background-color: #fee;
  color: rgba(200,0,0,.5);
}

.removeAccess:hover{
  background-color: #c00;
  color: #fff;
}

.addSharedWith{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.addAccess{
  height: 42px;
  margin-top: 25px;
  background-color: #C7FFC7;
  color: rgba(0,200,0,.5);
}

.addAccess:hover{
  background-color: #2dc200;
  color: #fff;
}

.permissions{
  font-size: 12px;
  width: 120px;
  height: 40px;
  margin-right: 10px;
}

.permissions-new{
  font-size: 12px;
  width: 120px;
  height: 42px;
  margin-top: 25px;
  margin-left:135px;
  margin-right: 10px;
}

.person{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  width: 400px;
}

.email{
  font-size: 14px;
  margin-top: -25px;
  margin-left: 21px;
}

.owner{
  margin-left: 110px;
  font-size: 16px;
}

.modal-share hr{
  margin-top: 0px;
  margin-bottom: 10px;
}

.owner{
  margin-top: 10px;
}

.addForm{
  margin-top: 30px;
}

.closed{
  display: none;
}

.suggestionInput{
  border-bottom: 1px solid black;
  width: 100%;
  height: 40px;
}

.suggestionInput:focus{
  border-bottom: 1px solid #00A79B;
}

.suggestionBtn{
  text-align: left;
  background-color: white;
  width: 100%;
  white-space: normal;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

.fa{
  width: 20px;
  text-align: center;
}

.suggestionBtn:focus, .suggestionBtn:hover{
  background-color: #00A79B;
  color: #eee;
}

.share-notes{
  width: 100%;
  textarea{
    width: 100%;
    border-radius: 0px;
    margin-bottom: 0px;
  }
  small{
    margin-top: -10px;
    font-weight: 300;
  }
}

.sharedWithHeader{
  .fa{
    float: right;
  }
}

.sendEmailBox{
  float: right;
  font-size: 14px;
}

.share-interface .column-input {
  width: calc(100% - 160px);
}


//TODO: old code remove or rework
.modal.share-interface {

  // a.close-modal {
  //   line-height: 80%;
  // }

  h3 {

    background-color: #eee;
    margin-top: -20px;
    padding-top: 20px;
    border-bottom: none;

    input {
      // display: inline;
      // width: auto;
      font-size: 1em;
      background-color: #fff;
      border-bottom: 1px solid rgba(0,0,0,0.25);
      box-shadow: 0px 5px 20px -2px rgba(0,0,0,0.1);
      padding: 4px;
      margin-bottom: 0px;
    }
  }

  h4 {
    margin-bottom: 0px;
    clear: both;
    border-bottom: 1px solid #ccc;
    font-size: 1.3em;
    color: #222;
    margin: 20px -20px 0px;
    padding: 20px;
  }

  .shared-entities {
    max-height: 400px;
    overflow: auto;
    margin: 0px -20px;
    .user-card {
      margin: 0px;
    }
  }

  .user-card {
    background-color: #fff;
    color: #333;
    // box-shadow: 0px 0px 3px rgba(0,0,0,0.25);
    border-bottom: 1px solid #eee;
    margin-bottom: 30px;
    position: relative;
    padding: 5px 20px;
    margin: 0px -20px;
    overflow: hidden;

    &.overflowable {
      overflow: visible;
    }

    header {
      float: left;
      width: 50%;
      // color: #222;
      // background: #069;
      // border-bottom: 2px solid #046;

      h5 {
        color: #222;
        font-size: 1em;
        font-weight: 700;
        border: none;
        padding: 0px;
        margin: 0px;
        text-transform: none;
        letter-spacing: 0px;
        i {
          font-size: 1em;
        }
      }
      small {
        margin-top: -5px;
        display: block;
      }
    }
    nav {
      display: block;
      width: calc(50% - 30px);
      float: left;

      select {
        padding: 5px 25px 6px 5px;
        width: auto;
        border: none;
        background-color: transparent;
        color: $teal;
        opacity: 0.8;
        cursor: pointer;
        margin: 0px 10px 0px 0px;
        position: relative;
        top: 3px;
        -webkit-transition: .2s;
        -moz-transition: .2s;
        -o-transition: .2s;
        transition: .2s;
        box-shadow: 0px 0px 0px rgba(0,0,0,0.2);
        float: right;

        &:hover, &:focus {
          box-shadow: 0px 5px 20px -3px rgba(0,0,0,0.2);
        }
      }

    }

    & > button {
      position: absolute;
      top: 0px;
      right: 0px;
      height: 100%;
      padding: 0px 20px;
      border: none;
      border-radius: 0px;
      background-color: #fff;
      font-weight: 100;
      box-shadow: 0px 0px 40px 5px rgba(0,0,0,0.1);
      -webkit-transition: .2s;
      -moz-transition: .2s;
      -o-transition: .2s;
      transition: .2s;

      &.remove {
        color: #c00;
      }

      &.add {
        color: $teal;
      }

      &:hover{
        box-shadow: 0px 0px 0px rgba(0,0,0,0.2);
        background: #f4f4f4;
      }

    }

    &.buttonless {
      nav {
        text-align: right;
        width: 50%;
        select {
          float: right;
          top: 0px;
        }
      }
    }

  }

  .removed {
    background-color: #fcc;
  }
  .changed {
    background-color: #ffc;
  }

}
