$firstColWidth: 8rem;
$cellWidth: 6rem;
$cellHeight: 2rem;
$hiddenColor: rgba(0, 0, 0, 0.05);
$border: 1px solid rgba(0, 0, 0, 0.05);
$fontColor: rgba(34, 34, 34, 0.5);
$legendGradient: #FFF 0%, #b9f6ca 100%;

.correlation-matrix {
  .legend {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span {
      color: #999;
      margin: 0 0.25rem;
      font-size: 75%;
    }
    .range {
      width: 150px;
      height: 10px;
      @include linear-gradient(90deg, $legendGradient);
    }
  }

  .loading-spinner {
    text-align: center;
  }

  ul {
    display: grid;
    max-width: 100%;
    overflow: auto;
    font-size: 75%;

    &:hover {
      li {
        color: $fontColor;
      }
    }

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      width: $cellWidth;
      height: $cellHeight;
      border: $border;
      color: rgba(0, 0, 0, 0);
      transition: color 0.3s;

      &.header,
      &.triangle-header {
        border: none;
        color: $fontColor;
      }

      &.header.row {
        width: $firstColWidth;
        justify-content: flex-end;
        padding-right: 0.5rem;
      }

      &.triangle-hidden {
        opacity: 0;
        &.triangle-header {
          opacity: 1;
        }
      }

      &.hide-cell:not(.triangle-header) {
        background-color: $hiddenColor !important;
      }
    }
  }
}
