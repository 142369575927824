.modal-baseline {
  .modal {
    width: 1000px;
    margin-left: calc(50% - 500px);
    margin-bottom: 0;

    .intro {
      display: grid;
      grid-template-columns: 2fr 1fr;
      margin-bottom: 1em;
    }

    .content-card.report-list {
      left: 0px;
      width: 100%;
      max-height: 350px;
      overflow: auto;
      position: relative;

      .list-header {
        position: sticky;
        top: 0px;
        z-index: 2;

        header {
          display: grid;
          grid-template-columns: 40px 3fr 1fr 1fr 1fr;
          border-bottom: 3px solid #222;

          .column {
            float: none;
            width: auto;
            font-weight: 900;
            cursor: pointer;
            &.column-checkbox {
              cursor: initial;
            }
          }

          &:before {
            content: none; // needed so the :before content set elsewhere doesn't take up a grid spot
          }
        }
      }

      .report {
        cursor: pointer;
        display: grid;
        grid-template-columns: 40px 3fr 1fr 1fr 1fr;

        &:hover {
          background-color: #eee;
        }
        &.checked {
          background-color: #ddd;
          label.report-check {
            border-width: 7px;
          }
        }
        &.baseline-report {
          border-bottom: 4px solid #eee;
          margin-top: -3px;
        }

        .column {
          float: none;
          width: auto;
        }

        label.report-check {
          border-radius: 14px;
          background-image: none;
          background-color: white;
        }

      }

      &.sort-applied {
        .report {
          &.baseline-report {
            border: none;
            margin-top: 0;
          }
        }
      }
    }

    &.loading {
      width: 700px;
      margin-left: calc(50% - 350px);
      text-align: center;

      h3 {
        border-bottom: none;
        // background-color: #fff;
        text-align: center;
        font-weight: 300;
        text-shadow: 1px 1px 3px rgba(0,0,0,0.25);
        padding: 0;
        font-size: 2em;
      }

      .close-modal {
        display: none;
      }
    }
  }
}
