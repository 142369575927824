.custom-checkbox {
  .checkbox{
    .check {
      border: 2px solid $blue;
      height: 20px;
      width: 20px;
      border-radius: 2px;
    }
    &:hover{
      .check {
        background-color: $blue;
      }
    }
  }
  .checkbox.disabled{
    .check{
      border: 2px solid #bdbdbd;
    }
    &:hover{
    .check {
        background-color: #bdbdbd;
      }
    }
  }
  .checkbox.checked.disabled{
    .check{
      background-color: #bdbdbd;
    }
  }
  .checked {
    .check {
      border: 2px solid $blue;
      background: $blue;
      background-image: url(/assets/images/icon-checkmark-white.png);
      background-size: 12px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
    &:hover{
      .check {
          background-color: $blue;
        }
    }
  }
  small{
    margin-left: 5px;
  }
}
