.modal-feedback {
  label em {
    font-size: 0.8em;
    color: transparentize($base-font-color, 0.5);
  }
  .form-section {
    margin-top: 1em;

    .button-group, .button-tweet {
      margin-top: 0.5em;
      float: left;
    }
    .button-tweet {
      margin-left: 1em;
    }
  }
}
