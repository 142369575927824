.modal-container .modal-edit-report-nav {
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  background: linear-gradient(45deg, rgba(142, 67, 230, 0.85) 0%, rgba(51, 105, 230, 0.85) 100%);
  color: #fff;
  .close-modal{
    color: #fff;
    &:hover{
      color: red;
    }
  }
}
