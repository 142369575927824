.metric-instance {
  display: block;
  position: relative;
  z-index: 1;
  transition: 0.8s;

}

@for $i from 1 through 50 {
  .metric-instance:nth-of-type(#{$i}) {
    // transition-delay: $i*0.02 + 0s;

    .bar {
      transition-delay: $i*0.03 + 0s;

      // .cluster-stats {
      //   transition-delay: $i*0.03 + 0s;
      // }
    }
  }
}



// translateX shouldn't exist for timeline view
.metric-visualization.timeline .metric-instance {
  transform: translateY(0px) !important;
}


/*=========================================
Global comparison view bars
=========================================*/

.metric-visualization.fulfilled .bar.global-bar {
  background-color: #444;
  clear: both;
  margin: 0px;
  line-height: 30px;
  font-size: 0.8em;
  height: 0px;
  transition: 1s;
  transition-delay: 0s;
  padding: 0px;
}

.metric-visualization.global-comparison .metric-instance {
  margin-top: 0.8em;

  .bar {
    height: 8px;
    z-index: 4;
    margin: 1px 0;

    label {
      z-index: 100;
      background-color: rgba(255,255,255,0.8);
      margin: 0;
      position: absolute;
      top: -15px;
      padding: 0px;
      font-variant-numeric: proportional-nums;

      em {
        padding: 0;
        opacity: 1;
        font-size: 9px;
      }
    }

    &.global-bar {
      height: 10px;
      opacity: 1;
      position: relative;
      transition-delay: 0s !important;
      transition: 0.4s;
      z-index: 3;
      left: 0px;

      &.not-hovered {
        height: 3px;
        opacity: 0.5;
        label {
          opacity: 0;
        }
      }

      &.hovered {
        height: 16px;
        opacity: 1;
        label {
          opacity: 1;
          height: 16px;
          em {
            line-height: 16px;
          }
        }
      }

      &:hover {
        padding-left: 4px;
      }

      label {
        position: absolute;
        z-index: 2;
        color: inherit;
        height: 10px;
        line-height: 10px;
        left: calc(100% + 5px);
        font-size: 0.5em;
        top: 0;
        left: initial;
        right: 4px;
        padding: 0 2px;
      }
    }

  }


  &.greater {
    .bar:not(.global-bar) {
      background-color: $baseline-greater;
      label {
        em {
          color: $baseline-greater;
          margin-left: 2px;
        }
      }
    }
    .bar.global-bar {
      label {
        em {
          margin-left: -40px;
        }
      }
    }
  }

  &.lesser {
    .bar:not(.global-bar) {
      background-color: $baseline-lesser;
      label {
        em {
          position: relative;
          color: $baseline-lesser;
          margin-left: -40px;
        }
      }
    }
  }

  &.relativizeCompareViewByAll {
    min-height: 20px;
    width: calc(100% - 180px);
    left: 150px;

    &:after {
      border-bottom: 1px dashed #ddd;
      display: block;
      position: fixed;
      width: calc(100% + 70px);
      left: -40px;
      top: 6px;
    }

    .gap {
      border-bottom: 3px solid;
      display: block;
      position: absolute;
      opacity: 1;
      top: 5px;
      margin-left: -5px;
      z-index: 2;

      .diff-label {
        text-align: center;
        display: block;
        position: absolute;
        left: 50%;
        top: 5px;
        margin-left: -1em;
        font-weight: 900;
        color: #222;
        font-size: 0.5em;
      }
    }

    .global-bar {
      label {
        left: 10px;
      }
    }

    label {
      position: fixed;
      background-color: transparent;
      padding: 0.05em 0.1em;
      left: -50px;

      span {
        top: -2px;
        left: -150px;
        position: fixed;
        display: block;
        text-align: right;
        width: 100px;
        // background-color: #f0f;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 1.3em;
      }

    }

    .bar {
      position: absolute;
      width: 10px;
      border-radius: 5px;
      margin-left: -10px;

      &:not(.global-bar) {
        label em {
          position: fixed;
          left: calc(50% + 5px);
          top: 1px;
        }
      }


      &.not-hovered {
        height: 10px;
        opacity: 0.3;
        label {
          opacity: 0;
        }
      }

      &.global-bar.hovered {
        height: 10px;
        opacity: 1;
        label {
          opacity: 1;
          height: 10px;
          left: 10px;
          background-color: transparent !important;
          em {
            line-height: 10px;
            top: 0px;
          }
        }
      }

    }
  }

  // Transition animation for global bars
  @for $i from 1 through 50 {
    &:nth-of-type(#{$i}) {
      .bar.global-bar {
        transition-delay: $i*0.01 + 0.5s;
      }
    }
  }

  &:hover {
    .global-bar label {
      opacity: 1;
    }
    .bar:not(.global) label em {
      opacity: 1;
    }
  }

  .cluster-stats {
    height: 0 !important;
  }

}

.metric-instance .bar .cluster-stats span[data-color-order='1'] {
  background-color: rgba(31, 119, 180, 1);
  border-color: rgba(31, 119, 180, 1);
}

.metric-instance .bar .cluster-stats span[data-color-order='2'] {
  background-color: rgba(174, 199, 232, 1);
  border-color: rgba(174, 199, 232, 1);
}

.metric-instance .bar .cluster-stats span[data-color-order='3'] {
  background-color: rgba(255, 127, 14, 1);
  border-color: rgba(255, 127, 14, 1);
}

.metric-instance .bar .cluster-stats span[data-color-order='4'] {
  background-color: rgba(255, 187, 120, 1);
  border-color: rgba(255, 187, 120, 1);
}

.metric-instance .bar .cluster-stats span[data-color-order='5'] {
  background-color: rgba(44, 160, 44, 1);
  border-color: rgba(44, 160, 44, 1);
}

.metric-instance .bar .cluster-stats span[data-color-order='6'] {
  background-color: rgba(152, 223, 138, 1);
  border-color: rgba(152, 223, 138, 1);
}

.metric-instance .bar .cluster-stats span[data-color-order='7'] {
  background-color: rgba(214, 39, 40, 1);
  border-color: rgba(214, 39, 40, 1);
}

.metric-instance .bar .cluster-stats span[data-color-order='8'] {
  background-color: rgba(255, 152, 150, 1);
  border-color: rgba(255, 152, 150, 1);
}

.metric-instance .bar .cluster-stats span[data-color-order='9'] {
  background-color: rgba(148, 103, 189, 1);
  border-color: rgba(148, 103, 189, 1);
}

.metric-instance .bar .cluster-stats span[data-color-order='10'] {
  background-color: rgba(197, 176, 213, 1);
  border-color: rgba(197, 176, 213, 1);
}

.metric-instance .bar .cluster-stats span[data-color-order='11'] {
  background-color: rgba(140, 86, 75, 1);
  border-color: rgba(140, 86, 75, 1);
}

.metric-instance .bar .cluster-stats span[data-color-order='12'] {
  background-color: rgba(196, 156, 148, 1);
  border-color: rgba(196, 156, 148, 1);
}

.metric-instance .bar .cluster-stats span[data-color-order='13'] {
  background-color: rgba(227, 119, 194, 1);
  border-color: rgba(227, 119, 194, 1);
}

.metric-instance .bar .cluster-stats span[data-color-order='14'] {
  background-color: rgba(247, 182, 210, 1);
  border-color: rgba(247, 182, 210, 1);
}

.metric-instance .bar .cluster-stats span[data-color-order='15'] {
  background-color: rgba(127, 127, 127, 1);
  border-color: rgba(127, 127, 127, 1);
}

.metric-instance .bar .cluster-stats span[data-color-order='16'] {
  background-color: rgba(199, 199, 199, 1);
  border-color: rgba(199, 199, 199, 1);
}

.metric-instance .bar .cluster-stats span[data-color-order='17'] {
  background-color: rgba(188, 189, 34, 1);
  border-color: rgba(188, 189, 34, 1);
}

.metric-instance .bar .cluster-stats span[data-color-order='18'] {
  background-color: rgba(219, 219, 141, 1);
  border-color: rgba(219, 219, 141, 1);
}

.metric-instance .bar .cluster-stats span[data-color-order='19'] {
  background-color: rgba(23, 190, 207, 1);
  border-color: rgba(23, 190, 207, 1);
}

.metric-instance .bar .cluster-stats span[data-color-order='20'] {
  background-color: rgba(158, 218, 229, 1);
  border-color: rgba(158, 218, 229, 1);
}

.metric-instance .bar {
  position: relative;
  /*overflow: hidden;*/
}

.metric-instance .lost-bar {
  background-color: #c00;
  position: absolute;
  min-width: 2px;
}

.metric-instance .gained-bar {
  background-color: #25b67c;
}

.metric-instance a {
  outline: 0;
}
