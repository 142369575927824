// Categorical Colors: Tritanopia
$colors: (
  1: #006c8a,
  2: #883546,
  3: #00a8d8,
  4: #ff889f,
  5: #00cbfb,
  6: #c5003b,
  7: #ff6388,
  8: #ffdae0,
  9: #95e7fd,
  10: #006c8a,
  11: #883546,
  12: #00a8d8,
  13: #ff889f,
  14: #00cbfb,
  15: #c5003b,
  16: #ff6388,
  17: #ffdae0,
  18: #95e7fd,
  19: #006c8a,
  20: #883546
);

#world-container.tritanopia, .modal-container.tritanopia {
  @each $num, $color in $colors {
    $spot: $num - 1;
    // Tribe sidebar borders
    .sub-clusters .cluster-sub-nav.cluster-order-#{$num} {
      border-color: $color;
      color: $menu-gray;

      &:hover, &.active, &.held {
        background-color: $color;
        color: #fff;
      }

    } // .cluster-sub-nav

    .hiddenCluster:nth-of-type(#{$num}) {
      border-left: 5px solid;
      border-color: $color;
      color: $menu-gray;

      &:hover, &.active, &.held {
        background-color: $color;
        color: #fff;
      }
    }

    // Cluster stats on bar charts
    .bar-graph .bar .cluster-stats span:nth-of-type(#{$num}) {
      background-color: $color;
      border-color: $color;
    } //.bar-graph .bar .cluster-stats span

    // Cluster bar graphs
    .cluster-bar-graph a {
      .bar[data-color-order='#{$num - 1}'] {
        border-color: $color;
      }
      .hovered .bar[data-color-order='#{$num - 1}'] {
        background-color: $color;
      }
    } //.cluster-bar-graph a
    .cluster-bar-graph.spot_#{$spot} a .spot_#{$spot} .bar {
      background-color: $color;
    }

    // Members Profiles image borders
    .member-profile.member.spot_#{$spot} {
      border-color: $color;
    }

    .map-options label:nth-of-type(#{$num}).map-shown .toggle {
      background-color: $color;
    }

    .metric-instance .bar .cluster-stats span[data-color-order='#{$num}'] {
      background-color: $color;
      border-color: $color;
    }
  }
}
