.activation-dashboard {
  width: 99%;

  > p {
    margin-bottom: 0;
  }

  > .group {
    margin-bottom: 1rem;
    button {
      padding: 3px 10px;
      color: #ffffff;

      &.twitter {
        background-color: tint($dark-gray, 65%);
        &:hover, &.active {
          background-color: $dark-gray;
        }
      }
      &.facebook {
        background-color: tint(#3b5999, 65%);
        &:hover, &.active {
          background-color: #3b5999;
        }
      }
    }
  }
}
