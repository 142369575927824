/*=========================================
A user should be able to set their own
categorical colo(u)rs.
Define 'em here.
=========================================*/

@import 'standard-categorical';
@import 'ellsworth-kelly';
@import 'affinio';
@import 'alternate-categorical';
@import 'deuteranopia';
@import 'protanopia';
@import 'tritanopia';
@import 'titan';