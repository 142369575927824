.report-builder .benchmark-groups {
  // TODO remove hovertip hacks
  h3{
    &:hover{
      cursor: pointer;
    }
  }
  position: relative;
  .admin-prefs {
    position: relative;
    z-index: 2;
    > section:last-of-type {
      margin-top: 2em;
    }
    .controls {
      margin-bottom: 1em;
    }
  }
  .hovertip{
    position: absolute;
    margin-left: 5px;
    right: 10px;
    top: 3px;
    // z-index: 1;
    // float: right;
    > a {
      background-color: #fff;
      border-color: #eee;
      z-index: 1;
      width: 38px;
      position: relative;
    }
  }
  .auto-suggest .tiny-add-button {
    top: 0;
    margin: 12px 0;
  }
}
