/*==================================================================
=Sparkline
----------------------------------------------------------------- */

.spark-line {
  position: relative;
  // TODO less global styles (make sure removing doesnt break things)
  // top: -54px;
  clear: both;
  z-index: 1;
  display: block;
  height: 32px;
  .chart {
    height: 32px;
  }
}
.spark-line.delta {
  position: relative;
  // TODO less global styles (make sure removing doesnt break things)
  // top: -54px;
  margin-bottom: 2em;
  overflow: visible;
  height: 234px;
  transition: 250ms;
  background-color: rgba(0,0,0,0);
  overflow-x: visible !important;
  * {
    overflow-x: visible !important;
  }
  .c3-axis path {
    stroke: #999;
    opacity: 0 !important;
  }
  .c3-axis .tick {
    opacity: 0 !important;
  }
  .c3-shapes {
    circle {
      opacity: 0 !important;
    }
  }
  .c3-axis-x {
    g.tick:last-of-type {
      right: 100px;
      text {
      }
    }
  }
  &:hover {
    background-color: #e2e2e2;

    .c3-shapes {
      circle {
        opacity: 1 !important;
      }
    }
    .c3-axis path {
      opacity: 1 !important;
    }
    .c3-axis .tick {
      opacity: 1 !important;
    }

  }
  .chart {
    clear: both;
    height: 100px;
    margin-bottom: 0em;
    transition: 200ms;
  }
  .details {
    .min, .max {
      display: flex;
      flex-direction: row;
    }
  }
}

.metric-visualization.timeline .cluster-stats {
  display: none;
}

.metric-visualization.timeline .metric-instance .bar {
  background-color: transparent;
}

.metric-visualization.timeline .chart {
  margin-top: 30px;
  transition: 0.5s;
}
