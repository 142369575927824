.select-inline {
  display: flex;
  flex-direction: row;
  align-items: center;
  a {
    font-size: 0.85em;
    padding: 10px;
    border-left: solid 1px #ccc;
    color: #bbb;
    white-space: nowrap;
    transition: 200ms;
    &:first-of-type {
      border-left: none;
    }
    &.active {
      color: $blue;
      font-weight: 500;
    }
    &:not(.active):hover {
      color: #666;
    }
  }
}
