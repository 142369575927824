  .interests-charts {
    .metric-instance {
      display: block;

      .bar {
        height: 28px;
        margin-bottom: 1px;
        font-size: 0.8em;
        padding: 5px 0;
        -webkit-transition: .4s;
        -moz-transition: .4s;
        -o-transition: .4s;
        transition: .4s;

        label {
          width: max-content;
          display: inline-block;
          position: relative;
          vertical-align: middle;
        }

        a {
          display: inline-block;
        }

        .bar label em, .bar a em {
          padding: 0px 5px;
          opacity: 0.8;
        }

        .cluster-stats {
          -webkit-transition: .4s;
          -moz-transition: .4s;
          -o-transition: .4s;
          transition: 0.6s;
          transition-delay: 0.2s;
          position: absolute;
          bottom: 0px;
          width: 100%;
          overflow: hidden;
          height: 3px;
        
          &.full-height {
            height: 100%;
          }
        
          span {
            overflow: hidden;
            display: block;
            float: left;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            height: 28px;
            width: 0px;
          }
        }
      }
    }
  }

  .interests-charts.cluster-bias {
    .bar {
      width: 100% !important;

      .cluster-stats {
        height: 100%;
      }
    }
  }

  .interests-charts.term-relative {
    .bar {
      background-color: rgba(0, 0, 0, 0.08);
    }

    .bar:hover {
      .cluster-stats {
        height: 100%;
      }
    }
  }

  .interests-charts.niche-ranked {
    .bar {
      background-color: rgba(0, 0, 0, 0.08);
      width: 100% !important;

      .cluster-stats {
        height: 100%;
      }
    }
  }