.auth-notify-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  left: 200%;
  right: -200%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
  opacity: 0;
  transition: opacity 0.3s ease;
  &.visible {
    opacity: 1;
    left: 0;
    right: 0;
  }
}

.auth-notify {
  position: fixed;
  background-color: $light-gray;
  bottom: 1em;
  right: -1000px;
  width: 250px;
  padding: 1em;
  box-sizing: border-box;
  border-radius: 6px;
  z-index: 10000000;
  opacity: 0;
  transition: all 0.3s ease;

  a.button {
    background-color: $blue;
    color: #fff;
    display: block;
    width: 100%;
    font-weight: 300;
    text-align: center;
    font-size: 1.2em;
    &:hover {
      background-color: darken($blue, 20);
    }
  }

  &.visible {
    right: 1em;
    opacity: 1;
  }
}
