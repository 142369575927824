.engagement-card {
  .summary {
    transition: 200ms;
    h3 {
      transition: color 200ms;
    }
    -webkit-transition: 200ms;
    background-color: #fff;
    padding: 1em;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
    outline: 1px solid rgba(0,0,0,0); // border-left: 3px solid #4DB930;
    width: 100%;
    cursor: pointer;
    section:last-of-type {
      // display: flex;
      // flex-direction: row;
      // justify-content: space-between;
      span {
        display: inline-block;
        &:last-of-type {
          margin-left: 2.5em;
        }
      }
      h3 {
        margin-bottom: 0em;
      }
    }
    &.max {
      border-left: solid 5px #4DB930;
    }
    &.min {
      border-left: solid 5px #c00;
    }
  } // summary
  &.active {
    .summary.max {
      box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
      outline: 1px solid #FFBA33; // border-left: 3px solid #4DB930;
      h3 {
        color: #4DB930;
      }
    }
    .summary.min {
      box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
      outline: 1px solid #FFBA33; // border-left: 3px solid #4DB930;
      h3 {
        color: #c00;
      }
    }
  } // active
  .summary.inActive {
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0);
    background-color: rgba(0,0,0,0);
    outline: 1px solid rgba(0,0,0,0); // border-left: 3px solid #4DB930;
  }
}
