// Categorical Colors: Protanopia
$colors: (
  1: #696781,
  2: #3a259c,
  3: #5e4fc1,
  4: #9c92f4,
  5: #9387f3,
  6: #c5c3d9,
  7: #35343d,
  8: #888b56,
  9: #a5a961,
  10: #eaf154,
  11: #ecf28d,
  12: #e5e8c0,
  13: #696781,
  14: #3a259c,
  15: #5e4fc1,
  16: #9c92f4,
  17: #9387f3,
  18: #c5c3d9,
  19: #35343d,
  20: #888b56
);

#world-container.protanopia, .modal-container.protanopia {
  @each $num, $color in $colors {
    $spot: $num - 1;
    // Tribe sidebar borders
    .sub-clusters .cluster-sub-nav.cluster-order-#{$num} {

      border-color: $color;
      color: $menu-gray;

      &:hover, &.active, &.held {
        background-color: $color;
        color: #fff;
      }

    } // .cluster-sub-nav
    .hiddenCluster:nth-of-type(#{$num}) {
      border-left: 5px solid;
      border-color: $color;
      color: $menu-gray;

      &:hover, &.active, &.held {
        background-color: $color;
        color: #fff;
      }
    }

    // Cluster stats on bar charts
    .bar-graph .bar .cluster-stats span:nth-of-type(#{$num}) {
      background-color: $color;
      border-color: $color;
    } //.bar-graph .bar .cluster-stats span

    // Cluster bar graphs
    .cluster-bar-graph a {
      .bar[data-color-order='#{$num - 1}'] {
        border-color: $color;
      }
      .hovered .bar[data-color-order='#{$num - 1}'] {
        background-color: $color;
      }
    } //.cluster-bar-graph a
    .cluster-bar-graph.spot_#{$spot} a .spot_#{$spot} .bar {
      background-color: $color;
    }

    // Members Profiles image borders
    .member-profile.member.spot_#{$spot} {
      border-color: $color;
    }

    .map-options label:nth-of-type(#{$num}).map-shown .toggle {
      background-color: $color;
    }

    .metric-instance .bar .cluster-stats span[data-color-order='#{$num}'] {
      background-color: $color;
      border-color: $color;
    }
  }
}
