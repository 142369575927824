.ember-table{
  .basic-cell {
    height: 100%;
    padding: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
