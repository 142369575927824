.modal-open {
  // position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.modal-container {
  height: 0;
  overflow: hidden;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 100000000;
}

.modal-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 100%;
}

.modal-container .backdrop {
  background: rgba(0,0,0,0.8);
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
}

.modal-container.shown .backdrop {
  opacity: 1;
}

.combo-box-open .backdrop {
  opacity: 0;
}

.combo-box-open .modal-container {
  height: 100%;
  overflow: auto;
  z-index: 999;
}

.modal-container.shown {
  height: 100%;
  overflow: auto;
}

.modal-container .modal {
  position: relative;
  // overflow: auto;
  width: 600px;
  margin-left: calc(50% - 300px);
  opacity: 0;
  background-color: #fff;
  margin-bottom: 100px;
  margin-top: 100px;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  z-index: 2;
  padding: 20px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(0,0,0,0.25);

  p:not(.trait-bar) {
    max-width: calc(100% - 3em);
  }
}

.modal-container .modal.signup-modal {
  width: calc(100% - 40px);
  left: 20px;
  margin-left: 0px;
  top: 20px;
}


.modal-container.shown .modal.print-modal,
.modal-container .modal.print-modal {
  // width: calc(100% - 100px);
  // left: 50px;
  // margin-left: 0px;
  margin-left: auto;
  margin-right: auto;
  top: 50px;
  max-height: inherit;
}

.modal-container.shown .modal.signup-modal {
  width: calc(100% - 40px);
  left: 20px;
  margin-left: 0px;
  top: 20px;
}

.modal-container .modal.datechange {
  width: 450px;
  margin-left: calc(50% - 225px);
  padding-bottom: 50px;
  padding-left: 0px;
  padding-right: 0px;
  -webkit-transition: 0s;
  -moz-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
  overflow: hidden !important;

  h3 {
    margin-left: 0px;
    margin-right: 0px;
  }

  ul {
    max-height: 300px;
    overflow: auto;
    li {
      padding: 10px 10px 10px 20px;
      border-bottom: 1px solid $light-gray;
      button {
        float: right;
      }

    }
  }
}

.modal-container.tweetstream .modal {
  width: 540px;
  margin-left: -270px;
  padding-bottom: 20px;
  -webkit-transition: 0s;
  -moz-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
}


.modal-container.shown .modal {
  opacity: 1;
}

.modal > h3 {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 20px;
  margin-left: -20px;
  margin-right: -20px;
  padding: 0px 60px 20px 20px;
}

.modal .close-modal {
  position: absolute;
  top: 0px;
  right: 0px;
  line-height: 50%;
  color: rgba(0,0,0,0.6);
  font-weight: 300;
  font-size: 2em;
  padding: 25px 20px;
  outline: none;
}

.modal .close-modal:hover {
  color: #900;
}

.modal footer {
  clear: both;
  padding: 20px 0px 0px;
}

.modal footer .button,
.modal footer button {
  display: inline-block;
  // padding: 5px 10px;
  width: auto;
  margin-right: 5px;
  line-height: 1;
}

.modal footer .button.support {
  background-color: #222;
  color: #fff;
}

.modal footer .button.support:hover {
  background-color: #eee;
  color: #555;
}

#ads .modal .button.primary,
#ads .modal button.primary,
#ads button.primary,
#ads .button.primary {
  background-color: #8CC63F;
}


.modal-loader {
  background-color: $blue;
  color: #fff;
  font-size: 1.5em;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 0%;
  height: 25px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
}

.modal label.msa-modal-label input {
  float: left;
  margin-right: 20px;
  margin-top: 4px;
  margin-bottom: 20px;
}

.modal-sub-nav-header {
  margin-bottom: 1em;
}

.modal.modal-toolbox-asset-groups {
  width: 800px;
}
