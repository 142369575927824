.affinio-table-header {
  height: 100%;
  z-index: 10000;
  .header{
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 10px;
    height: 100%;
    color: #7E8890;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.05em;
    font-size: 0.8em;
    .hovertip{
      position: relative;
      top: -12px;
    }
    .custom-checkbox{
      margin-right: 0.5em;
    }
    .fa {
      position: absolute;
      bottom: 0px;
      left: 7px;
      font-size: 1em;
      opacity: 0.2;
      color: #000;
    }
    &:hover{
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  .ascending{
    .fa{
      bottom: inherit;
      top: 0px;
    }
  }
  .active{
    background-color: rgba(0, 0, 0, 0.1);
  }
}
