// Breakpoints
$medium-screen: 600px;
$large-screen: 900px;
$xlarge-screen: 1500px;

// Typography
$base-font-family: (
  'Roboto',
  'Fira Sans',
  'Droid Sans',
  'Helvetica Neue',
  sans-serif
);
$heading-font-family: $base-font-family;

// Font Sizes
$base-font-size: 1em;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 4px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Brand Colors
$teal: #00A79B;
$red: #FF0066;
// $yellow: #FFBA33;
$blue: #3F9EEA;
$menu-gray: #333c4e;
$dark-blue: #0C3054;
$blue-gray: #524b5b;
$talkwalker-blue: #213979;

// New Brand Colors
$purple: #433F67;
$pink: #EF436E;
$coral: #FA715F;
$dark-blue: #0C3054;
$blue-gray: #524b5b;
$yellow: #D3A50C;

// Audience Builder variables
$pink-gradient: #B84592 0%, #FF4F81 100%;
$orange-gradient: #FFC168 0%, #FF6C5F 100%;
$purple-gradient: #8E43E6 0%, #3369E6 100%;
$purple-gradient-transparent: rgba(142, 67, 230, 0.85) 0%, rgba(51, 105, 230, 0.85) 100%;
$green-gradient: #1CC7D0 0%, #2DDE98 100%;
$coral-gradient: #EF456E 0%, #FA715F 100%;

// General Gradients
$blue-white-gradient: #fff 0%, $blue 100%;

//Grays
$dark-gray: #222;
$darkish-gray: #444;
$medium-gray: #7E8890;
$audience-summary-gray: #ebebeb;
$light-gray: #F8F9FA;

// Font Colors
$base-font-color: $dark-gray;
$action-color: $blue;

// Border
$base-border-color: #ccc;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: #fff;
$secondary-background-color: $light-gray;

// Baseline Colors
$baseline-greater: rgba(45,222,152,1);
$baseline-lesser: rgba(239,69,110,1);


// Forms
// $form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
// $form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

// Animations
$base-duration: 150ms;
$base-timing: ease;

@mixin linear-gradient($direction: 135deg, $color-stops...) {
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}
