.asset-group-list {
  .controls {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    input {
      margin-right: 1em;
    }
    .button {
      display: inline-table;
    }
  }
  .welcome {
    margin: 1em 0em;
  }
  .group-list {
    thead {
      border-bottom: solid 1px #eee;
      display:table;
      width:100%;
      table-layout:fixed;
      th {
        padding: 1em 10px;
        color: #7E8890;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 0.05em;
        font-size: 0.8em;
        transition: 200ms;
        position: relative;
        &:not(.actions) {
          cursor: pointer;
          &:hover {
            background-color: #eee;
          }
        }
        &.asc, &.dsc {
          background-color: #F8F9FA;
          i {
            display: inline;
          }
        }
        &.asc {
          i {
            top: 3px;
          }
        }
        &.dsc {
          i {
            bottom: 3px;
          }
        }
        i {
          font-size: 0.85em;
          position: absolute;
          right: 10px;
          display: none;
        }
      }
    }
    tbody {
      max-height: 500px;
      overflow-y: auto;
      display: block;
      width: 100%;
      p.helper {
        margin: 1em;
      }
      tr {
        display:table;
        width:100%;
        table-layout:fixed;
        border-bottom: solid 1px #eee;
        transition: 200ms;
        &:hover {
          background-color: #F8F9FA;
        }
      }
    }
    td {
      padding: 1em 10px;
      font-size: 0.9em;
      margin-left: 10px;
      margin-right: 10px;

    }
    width: 100%;
    .name {
      width: 40%;
      text-align: left;
      &.untitled {
        font-style: italic;
      }
    }
    .handles {
      width: 10%;
      text-align: right;
    }
    .updated {
      width: 30%;
      text-align: right;
    }
    .actions {
      text-align: right;
      width: 20%;
      color: $medium-gray;
      .add {
        background-color: #eee;
        border-radius: 2px;
      }
      span {
        padding: 5px;
        cursor: pointer;
        transition: 200ms;
        &:hover {
          background-color: #ddd;
        }

        &.disabled {
          pointer-events: none;
          filter: brightness(1.6);
        }
      }
    }
  }
}
