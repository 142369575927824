.api-key-input {
  padding: 0;
  display: table;
  border-collapse: collapse;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  margin-bottom: 1em;
  background-color: #EEEEEE;

  > div {
    display: table-cell;
    height: 30px;
    vertical-align: middle;
  }

  .hovertip-question {
    margin: 5px 0px 0px 15px;
  }

  .format-description {
    margin-left: 135px  !important;
  }

  .input {
    width: 300px;
    input {
      border: none;
      margin: 0;
      height: 100%;
      padding: 0 1rem;
      &:disabled,
      &:disabled:hover {
        border: none;
        border-radius: none;
      }
    }
  }

  .icon {
    padding: 0 9px;
    background: $talkwalker-blue;
    color: #777;

    img {
      max-height: 100%;
      padding: 5px 5px;
    }
  }

  &.is-admin {
    .input p {
      text-align: center;
      margin: 0;
      opacity: 0.5;
    }

    .icon.save {
      cursor: initial;
      background: none;
      color: initial;
      opacity: 0.3;
    }
  }
}

.save, 
.clear {
  cursor: pointer;
  padding: 0 9px;
  background:#eee;
  color: #777;
}

.save {
  background: $blue;
  color: white;
  &:hover {
    background: shade($blue, 10%)
  }
}

.clear:hover {
  color: $red;
}